import { useRef, useCallback, MutableRefObject } from 'react';
import { GoogleMarkerInterface, HereMarkerInterface } from 'common/utils/MapMarker';

export type TMarkers = GoogleMarkerInterface | HereMarkerInterface | null;
export type TMarkersRef = MutableRefObject<TMarkers>;
export type TSetMarkers = (value: TMarkers) => void;
interface IResult {
  markersRef: TMarkersRef;
  markers: TMarkers;
  setMarkers: TSetMarkers;
}
type TUseMarkersState = () => IResult;

const useMarkersState: TUseMarkersState = () => {
  let markersRef = useRef<TMarkers>(null);

  const markers = markersRef.current;

  const setMarkers = useCallback((value: TMarkers) => {
    markersRef.current = value;
  }, [markersRef]);

  return { markersRef, markers, setMarkers };
};

export default useMarkersState;
