interface SearchParams {
  key: string;
  value: string;
}
interface Value {
  [key: string]: any;
}
type binarySearchFn = <T extends Value>(searchParams: SearchParams, arr: T[]) => T | undefined;

const binarySearch: binarySearchFn = ({ key = '', value = ''}, arr) => {
  let i = 0;
  let arrLength = arr.length;
  let k: number;

  while (i < arrLength) {
    k = Math.floor((i + arrLength) / 2);
    if (value <= arr[k][key]) arrLength = k;
    else i = k + 1;
  }

  if (arr[i] && arr[i][key] === value) return arr[i];
  return undefined;
};

export default binarySearch;
