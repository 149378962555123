import { makeStyles } from 'e100-react-components';

import { LocationIconProps } from './LocationIcon';

import { getNavigationHistoryIconColor } from '../../../../utils';

interface StylesProps extends Pick<
  LocationIconProps,
  'type'
> {}

export const useStyles = makeStyles(({
  spacing,
  palette
}) => ({
  container: {
    display: 'table-cell',
    width: spacing(3),
    height: spacing(3),
    backgroundColor: palette.common.white,
    lineHeight: 0,
    fontSize: 0,
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  icon: {
    display: 'inline-block',
    width: spacing(1.25),
    height: spacing(1.25),
    borderRadius: '50%',
    border: ({ type }: StylesProps) => `2px solid ${getNavigationHistoryIconColor(type)}`
  }
}));
