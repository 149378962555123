import { reduceAsync, isActionItem } from 'common/utils';
import { Station, StationCode } from 'dataSources/Typicode/stations/types';
import binarySearch from 'common/utils/binarySearch';
import { BBox } from '@turf/helpers';
import { turfLineString } from 'shared/utils';
import turfBbox from '@turf/bbox';

interface MappingStationCodesResult {
  stations: Station[];
  actionStations: Station[];
  stationsBBox: BBox;
  actionStationsBBox: BBox;
}

interface ReduceResult extends Pick<
  MappingStationCodesResult,
  'stations' | 'actionStations'
> {
  stationCoords: [
    Station['coordinates']['longitude'],
    Station['coordinates']['latitude']
  ][];
  actionStationCoords: [
    Station['coordinates']['longitude'],
    Station['coordinates']['latitude']
  ][];
}

export const mappingStationCodes = async (stationCodes: StationCode[], stations: Station[]): Promise<MappingStationCodesResult> => {
  const result = await reduceAsync<StationCode, ReduceResult>(
    stationCodes,
    (acc, stationCode) => {
      const station = binarySearch<Station>({ key: 'code', value: stationCode }, stations);

      if (station && station.code && station.coordinates) {
        acc.stations.push(station);
        acc.stationCoords.push([
          station.coordinates.longitude,
          station.coordinates.latitude
        ]);
  
        if (isActionItem(station)) {
          acc.actionStations.push(station);
          acc.actionStationCoords.push([
            station.coordinates.longitude,
            station.coordinates.latitude
          ]);
        }
      }
      return acc;
    },
    {
      stations: [],
      actionStations: [],
      stationCoords: [],
      actionStationCoords: []
    }
  );

  return {
    ...result,
    stationsBBox: turfBbox(turfLineString(result.stationCoords)),
    actionStationsBBox: turfBbox(turfLineString(result.actionStationCoords))
  };
}
