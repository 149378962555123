import React, { useState } from 'react';

import { EBoxLocationButton } from './EBoxLocationButton';
import { EBoxLocationsPopover } from './EBoxLocationsPopover/EBoxLocationsPopover';

import {
  useCarOperations,
  useHasCarEBoxLocations,
  useIsCarEBoxLocationsDone,
  CarEBoxLocations
} from 'entities/cars';
import { useIsAutorizedUser } from 'store/features/auth/selectors';

import { getButtonElement } from 'shared/utils';

export const CarEBoxLocationsButton = () => {
  const [ buttonAnchorElement, setButtonAnchorElement ] = useState<EventTarget | undefined>();

  const {
    resetCarEBoxLocations,
    getCarEBoxLocations
  } = useCarOperations();
  const hasCarEBoxLocations = useHasCarEBoxLocations();
  const isCarEBoxLocationsDone = useIsCarEBoxLocationsDone();
  const isAutorizedUser = useIsAutorizedUser();

  return (
    <>
      <EBoxLocationButton
        onClick={async (event) => {
          const element = getButtonElement(event);
          if (isCarEBoxLocationsDone && hasCarEBoxLocations) {
            resetCarEBoxLocations();
            return;
          } else if (!isAutorizedUser) {
            setButtonAnchorElement(element);
          } else {
            try {
              const { payload } = await getCarEBoxLocations() as any as { payload: CarEBoxLocations };
              if (!payload.length) {
                setButtonAnchorElement(element);
              }
            } catch (e) {}
          }
        }}
      />
      <EBoxLocationsPopover
        anchorEl={buttonAnchorElement}
        onClose={() => {
          setButtonAnchorElement(undefined);
        }}
      />
    </>
  );
};
