import React, { ReactNode } from 'react';

import { getLocalDate } from 'common/utils';

import {
  Grid,
  Typography,
  Tooltip
} from 'e100-react-components';
import { Icon } from 'components';
import { CopyIconButton } from 'entities/application';

import { HistoryLocation } from '../../../types';

import { useStyles } from './styles';
import { useIntl } from 'react-intl';

export const Location = ({
  latitude,
  longitude,
  timestampUtc,
  icon,
  warningMessage
}: LocationProps) => {
  const hasWarningMessage = !!warningMessage;
  const classes = useStyles({ hasWarningMessage });
  const { formatMessage } = useIntl();

  return (
    <Grid
      container
      spacing={0}
      alignItems='center'
      justify='space-between'
    >
      <Grid item xs={9}>
        <div className={classes.textContainer}>
          {
            icon && (
              <span className={classes.iconContainer}>
                {icon}
              </span>
            )
          }
          <Grid container spacing={0}>
            {
              longitude && latitude && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    classes={{ root: classes.text }}
                  >
                    {`GPS: ${latitude}, ${longitude}`}
                  </Typography>
                </Grid>
              )
            }
            <Grid item>
              <Grid container spacing={0} alignItems="center">
                {
                  timestampUtc && (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        color='secondary'
                        component="p"
                        classes={{ root: classes.date }}
                      >
                        {getLocalDate(timestampUtc)}
                      </Typography>
                    </Grid>
                  )
                }
                {
                  warningMessage && (
                    <Grid item>
                      <Tooltip
                        title={<span dangerouslySetInnerHTML={{ __html: warningMessage }} />}
                        enterDelay={0}
                        enterTouchDelay={0}
                      >
                        <Icon
                          kind={'warningOutlined'}
                          classes={{
                            root: classes.warningIcon
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item>
        <div className={classes.copyIconButtonContainer}>
          <CopyIconButton
            title={formatMessage({ id: 'copyСoordinates' })}
            message={`${latitude}, ${longitude}`}
          />
        </div>
      </Grid>
      <Grid item xs={12}><br /></Grid>
    </Grid>
  );
};

interface LocationProps extends Pick<
  HistoryLocation,
  'latitude' | 'longitude' | 'timestampUtc'
> {
  icon?: ReactNode;
  warningMessage?: string | null;
}