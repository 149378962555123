export const APPLICATION_STATE = 'APPLICATION';

export const NOTICE_TYPES = {
  error: 'error',
  warning: 'warning',
  success: 'success'
} as const;

export const MODALS = {
  navigationHistoryFilter: 'navigationHistoryFilter',
  navigationHistory: 'navigationHistory',
  stationsFilter: 'stationsFilter',
  station: 'station',
  route: 'route',
  carEBoxLocationsClientCards: 'carEBoxLocationsClientCards'
} as const;

export const URL_PARAMS = {
  boundCountries: 'bound-countries',
  activeStation: 'active-station'
} as const;

export const MAP_NAMES = {
  google: 'Google',
  here: 'Here'
} as const;