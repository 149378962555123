import { useState, useCallback } from 'react';

export type Errors<Keys extends string | number | symbol>  = {
  [Property in Keys]?: string;
};
export type setErrors<Keys extends string | number | symbol> = React.Dispatch<React.SetStateAction<Errors<Keys>>>;
export type TResetError = (e: React.ChangeEvent<HTMLInputElement>) => void;

const useErrors = <Keys extends string | number>(defaultErrors: Errors<Keys>  = {}) => {
  const [errors, setErrors] = useState<Errors<Keys>>(() => defaultErrors);

  const resetErrorsField: TResetError = useCallback((event) => {
    const target = event.target as HTMLInputElement;
    const name = target.name as Keys;
    setErrors((prevErrors) => {
      const prevErrorsCopy = { ...prevErrors };
      delete prevErrorsCopy[name];
      return prevErrorsCopy;
    });
  }, [setErrors]);

  return [errors, setErrors, resetErrorsField] as const;
};

export default useErrors;