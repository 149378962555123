import React, { useRef, ReactNode, useEffect } from 'react';

import { Paper } from 'e100-react-components';

import { MAP_INFO_WINDOW_ID } from '../../constants';

import { Map } from 'common/utils/Map/types/map';
import { Marker } from 'common/utils/MapMarker/types';

import { useMediaQuery } from 'common/hooks';
import { useUpdateInfoWindow } from '../../hooks';
import { useStyles } from './styles';

export const MapInfoWindow = ({
  coordinates,
  children,
  onClose,
  map,
  markers,
  onOpen = () => {}
}: MapInfoWindowProps) => {
  const { isMobile } = useMediaQuery();
  const classes = useStyles({ isMobile });
  const rootRef = useRef<RootElementRef>(null);

  useUpdateInfoWindow({
    map,
    markers,
    coordinates,
    rootRef
  }, {
    onClose
  });

  useEffect(() => {
    onOpen();
  // eslint-disable-next-line 
  }, []);

  return (
    <>
      <div
        className={classes.root}
        ref={rootRef}
        id={MAP_INFO_WINDOW_ID}
      >
        <Paper
          classes={{
            root: classes.paperRoot
          }}
        >
          {children}
        </Paper>
      </div>
    </>
  );
};

export type RootElementRef = HTMLDivElement | null;

export interface MapInfoWindowProps {
  coordinates?: [number, number] | null, // [latitude, longtitude]
  children: ReactNode;
  onClose?: () => void;
  map: Map | null;
  markers: Marker | null;
  onOpen?: () => void;
}