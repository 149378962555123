import React from 'react';

import { ModalActionButtonsProps } from '../ModalActionButtons';

import Grid from 'e100-react-components/lib/components/Grid';
import Button from 'e100-react-components/lib/components/Button';

export const CancelButton = ({
  labelCancel,
  onClose,
  isLoading
}: CancelButtonProps) => {
  if (!labelCancel) return null;
  return (
    <Grid item>
      <Button
        onClick={onClose}
        disabled={isLoading}
        color="default"
      >
        {labelCancel}
      </Button>
    </Grid>
  );
};

interface CancelButtonProps extends Pick<
  ModalActionButtonsProps,
  'labelCancel' | 'onClose' | 'isLoading'
> {}
