import { GoogleMap, HereMap, Map } from 'common/utils/Map';

import { GoogleMarker } from './GoogleMarker';
import { HereMarker } from './HereMarker';

export const markerFactory = (map: Map) => {
  if (map instanceof GoogleMap) {
    return new GoogleMarker(map);
  }

  if (map instanceof HereMap) {
    return new HereMarker(map);
  }

  throw Error('There is no marker for such marker');
};

