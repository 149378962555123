import { TUserType } from 'store/features/auth/types';

export enum USER_TYPES {
  unknown,
  autorized,
  unautorized
};

export const isAutorizedUser = (userType: TUserType) =>
  (USER_TYPES.autorized === userType);

export const isUnautorizedUser = (userType: TUserType) =>
  (USER_TYPES.unautorized === userType);

export const isUnknownUser = (userType: TUserType) =>
  (USER_TYPES.unknown === userType);