import GoogleDirectionsService from './GoogleDirectionsService';
import { HereDirectionsService } from './HereDirectionsService';
import { DirectionsService } from './types/directionsService';
import { GoogleMap, HereMap, Map } from 'common/utils/Map';

export const directionsServiceFactory = (map: null | Map): DirectionsService => {
  if (map instanceof GoogleMap) {
    return new GoogleDirectionsService(map);
  }

  if (map instanceof HereMap) {
    return new HereDirectionsService(map);
  }

  throw Error('There is no a directions service for such a map')
};
