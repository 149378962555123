import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { IconedDateTimePickerProps } from './ui';

interface StyleProps extends Pick<
  IconedDateTimePickerProps,
  'variant'
> {
  hideBorderBottom: boolean;
}

export const useStyles = makeStyles(({
  spacing,
  palette,
  typography
}) => ({
  container: {
    position: 'relative',
  },
  datePicker: ({ hideBorderBottom, variant }: StyleProps) => ({
    '& > div': {
      '&:after': {
        display: 'none'
      },
      '&:before': {
        display: 'none'
      }
    },
    '& input': {
      padding: variant === 'default' ? `${spacing(1.5)}px 0` : `${spacing(2.25)}px ${spacing(7.25)}px`,
      fontSize: typography.button.fontSize,
      ...!hideBorderBottom && {
        borderBottom: `1px solid ${palette.grey[200]}`,
      }
    }
  }),
  errorMessage: {
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5)
  }
}));
