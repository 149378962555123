import { useEffect, useCallback } from 'react';
import { useOperations as useStationOperations } from 'store/features/station';
import { useMapContext } from '../context/map';

const useOnMapClick = () => {
  const { resetSelectedPlace, updateSelectedCoordinates } = useStationOperations();
  const { map, addListenerClick } = useMapContext();

  const onClick = useCallback(() => {
    resetSelectedPlace();
    updateSelectedCoordinates();
  }, [resetSelectedPlace, updateSelectedCoordinates]);

  useEffect(() => {
    if (map) addListenerClick(onClick)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

}

export default useOnMapClick;
