import { createReduxAsyncThunk } from 'shared/utils';
import { reduceAsync } from 'common/utils';

import dataSources from 'dataSources';

import { STATIONS_STATE } from '../../constants';

import { StationWithPrices, StationsWithPricesMap } from '../../types';

interface Result {
  stationsWithPrices: StationWithPrices[];
  stationsWithPricesMap: StationsWithPricesMap;
}

export const getStationsWithPrices = createReduxAsyncThunk<Result>(
  `${STATIONS_STATE}/getStationsWithPrices`,
  async () => {
    const stationsWithPrices = await dataSources.stationsWithPrices.getStationsWithPrices();
    const stationsWithPricesMap = await reduceAsync<StationWithPrices, StationsWithPricesMap>(
      stationsWithPrices,
      (acc, stationWithPrices) => {
        acc[stationWithPrices.code] = stationWithPrices;
        return acc;
      }, {}
    );

    return {
      stationsWithPrices,
      stationsWithPricesMap
    };
  }
);