import React, { ChangeEvent } from 'react';
import Checkbox from 'e100-react-components/lib/components/Checkbox';
import Grid from 'e100-react-components/lib/components/Grid';
import Icon, { Props as IconProps } from 'components/Icon';

import Label from './Label';
import useStyles from './useStyles';

const CheckboxListItem = ({
  icon,
  iconColor,
  onChange,
  state,
  itemKey: key,
  title,
  subtitle,
  labelColor,
  indeterminate = false
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      wrap={'nowrap'}
    >
        { icon && (
          <Grid item classes={{ item: classes.iconContainer }}>
            <Icon kind={icon} style={{ color: iconColor }} color={'secondary'} />
          </Grid>
        ) }
      <Grid item classes={{ item: classes.checkboxContainer }}>
        <Checkbox
          classes={{
            formControlLabel: {
              labelPlacementStart: classes.labelPlacementStart
            },
            checkbox: {
              root: classes.checkboxRoot
            }
          }}
          onChange={onChange}
          isChecked={!!state[key]}
          labelPlacement={'start'}
          label={<Label
            title={title}
            subtitle={subtitle}
            labelColor={labelColor}
          />}
          value={key}
          indeterminate={indeterminate && !state[key]}
        />
      </Grid>
    </Grid>
  );
};
export interface Props {
  onChange?: (event: ChangeEvent<Element>, isChecked: boolean) => void;
  icon?: IconProps['kind'];
  iconColor?: string;
  itemKey: string | number;
  title: string;
  subtitle?: string;
  state: Record<string, boolean>;
  labelColor?: 'inherit' | 'primary' | 'secondary' | 'default';
  indeterminate?: boolean
};

export default CheckboxListItem;
