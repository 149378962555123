import { PayloadAction } from "@reduxjs/toolkit";

import { ReportsState } from "../../types";

export const setReport = (
  state: ReportsState,
  action: PayloadAction<ReportsState['report']['data']>
) => {
  state.report.data = action.payload;
};
