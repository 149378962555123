import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from 'common/constants/locales';
import { useLocale } from 'entities/application';

import useGetTranslates from './useGetTranslates';
import { setLocale } from '../storageLocale';

interface Props {
  children: JSX.Element;
};

const Translations = (props: Props) => {
  const { children } = props;

  const currentLocale = useLocale();
  const translates = useGetTranslates(currentLocale);
  useEffect(() => {
    document.documentElement.lang = currentLocale;
    setLocale(currentLocale);
  }, [currentLocale]);

  return (
    <IntlProvider
      locale={currentLocale}
      defaultLocale={DEFAULT_LOCALE}
      messages={translates}
    >
      {children}
    </IntlProvider>
  );
};

export default Translations;
