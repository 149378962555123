import React from 'react'

import useStyles from './useStyles';

const Link = (props: Props) => {
  const { children, href, target, color, onClick } = props;
  const classes = useStyles({ color });
  return (
    <a href={href} onClick={onClick} className={classes.link} rel="noopener noreferrer" target={target}>
      {children}
    </a>
  )
}

interface Props {
  children: any;
  target?: string;
  href: string;
  color?: string;
  onClick?: React.MouseEventHandler<{}>;
}

export default Link;
