import { makeStyles } from 'e100-react-components';

import { LocationTitleProps } from './LocationTitle';

interface StylesProps extends Pick<
  Required<LocationTitleProps>,
  'padding'
> {}

export const useStyles = makeStyles(({
  spacing
}) => ({
  title: {
    paddingTop: ({ padding }: StylesProps) => spacing(padding.top || 0.5),
    paddingBottom: ({ padding }: StylesProps) => spacing(padding.bottom || 1.5),
  }
}));
