export * from './mapNames';
export * from './log';
export * from './styles';
export * from './common';

export const STATUS_LOADING = {
  load: 'LOAD',
  done: 'DONE',
  error: 'ERROR'
} as const;

export type IStatusLoading = typeof STATUS_LOADING;

export enum StatusLoading {
  Default,
  Load,
  Done,
  Error
};

export const CODE_ICON: { [key: string]: string } = {
  serviceFormat1          : 'agent',
  serviceFormat2          : 'pos',
  local_parking           : 'localParking',
  wifi                    : 'wifi',
  wc_outlined             : 'wcOutlined',
  tire                    : 'tire',
  local_car_wash_outlined : 'localCarWashOutlined',
  bathtub_outlined        : 'bathtubOutlined',
  hotel_outlined          : 'hotelOutlined',
  monetization_on_outlined: 'monetizationOnOutlined',
  store_outlined          : 'storeOutlined',
  local_cafe_outlined     : 'localCafeOutlined',
  restaurant_outlined     : 'restaurantOutlined',
  local_shipping          : 'localShippingOutlined'
};

export const REPORT_STATUSES = {
  processing: 'processing',
  successed: 'successed',
  failed: 'failed'
} as const;

export const REPORT_FILE_TYPES = {
  xlsx: 'xlsx',
  csv: 'csv',
} as const;

export enum reportTypes {
  report = 'report',
  reportWithPrice = 'reportWithPrice',
};

export const CONTENT_TYPE = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'application/octet-stream',
} as const;

export enum roadsideServiceCode {
  gasStation = 1,
  roadPay,
  parking,
  carWash,
  autoService,
  guard,
  boat,
  railway,
  store,
  cafe,
  truckWash,
  automobileWash
};

export const ROADSIDE_SERVICES_CODES = {
  gasStation: 1,
  roadPay: 2,
  parking: 3,
  carWash: 4,
  autoService: 5,
  guard: 6,
  boat: 7,
  railway: 8,
  store: 9,
  cafe: 10,
  truckWash: 11,
  automobileWash: 12
};

export enum stationCategoryCode {
  common = 1,
  discountPlus,
  powerMax = 4,
  economy,
};

export enum ServicesFormats {
  automatic = 1,
  halfautomatic,
  operator
}

export const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong!';

export enum DaysOfWeek {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
};