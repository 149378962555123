import { NavigationHistoryPathType } from '../types';

import { LOCATION_HISTORY_PATH_TYPES } from '../constants';

export const checkIsTrackedLocationHistoryPath = (value: NavigationHistoryPathType) => (
  value === LOCATION_HISTORY_PATH_TYPES.tracked
);

export const checkIsUntrackedLocationHistoryPath = (value: NavigationHistoryPathType) => (
  value === LOCATION_HISTORY_PATH_TYPES.untracked
);

export const checkIsParkingLocationHistoryPath = (value: NavigationHistoryPathType) => (
  value === LOCATION_HISTORY_PATH_TYPES.parking
);
