import React, { createContext, useContext, useMemo } from 'react';
import { useBoolState } from 'common/hooks';

import {
  MapSettingsContext as Context
} from './types';

const MapSettingsContext = createContext({} as Context);
export const useMapSettingsContext = () => useContext(MapSettingsContext);

export const MapSettingsProvider = ({ children }: { children?: React.ReactNode }) => {
  const [ showStations, toggleShowStations ] = useBoolState(true);

  const value = useMemo(
    () => ({
      showStations,
      toggleShowStations
    }),
    [
      showStations,
      toggleShowStations
    ]
  );

  return (
    <MapSettingsContext.Provider value={value}>{children}</MapSettingsContext.Provider>
  )
};
