import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

export const StartLocation = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M12.0007 22.6667C17.8917 22.6667 22.6673 17.8911 22.6673 12C22.6673 6.109 17.8917 1.33337 12.0007 1.33337C6.10961 1.33337 1.33398 6.109 1.33398 12C1.33398 17.8911 6.10961 22.6667 12.0007 22.6667Z" />
    <path d="M8.01042 16L10.9635 7.54492H13.0436L15.9909 16H14.1335L13.4948 13.9492H10.5065L9.86784 16H8.01042ZM11.9831 9.18555L10.9167 12.625H13.0846L12.0182 9.18555H11.9831Z" fill="white"/>
  </SvgIcon>
);
