import { IGetAuthDataResult } from './parseAuthData';
import { setAccessToken } from './accessToken';
import { setRefreshToken } from './refreshToken';
import { setExpiryTime } from './expiryTime';

const setAuthData = ({ accessToken, refreshToken, expiryTime }: IGetAuthDataResult) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setExpiryTime(expiryTime);
};

export default setAuthData;
