import { useCallback } from 'react';
import resetAuthData from 'common/utils/auth/resetAuthData';

const useOnLogout = () => {
  const onLogout = useCallback(() => {
    resetAuthData();
    window.location.reload();
  }, []);

  return onLogout;
};

export default useOnLogout;
