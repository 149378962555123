import React from 'react';

import ZoomControl from './ZoomControl';
import { Props as MapProps } from '../index';

const Controls = ({ showZoomControl }: Props) => (
  <ZoomControl showZoomControl={showZoomControl} />
);

export interface Props extends Pick<MapProps, 'showZoomControl'> { }

export default Controls;
