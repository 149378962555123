import slice from '../reducer/slice';

import { NavigationAutosCoordinates } from 'entities/navigation';

const { setAutosCoordinates } = slice.actions;

type Operation = (autosCoordinates?: NavigationAutosCoordinates) => void;

const operation: Operation = autosCoordinates => setAutosCoordinates(autosCoordinates);

export default operation;
