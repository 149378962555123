import { makeStyles } from 'e100-react-components';
import { ModalActionButtonsProps } from './ModalActionButtons';

interface Props extends Pick<ModalActionButtonsProps, 'margin'> {}

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    padding: `${spacing(0.5)}px ${spacing()}px`,
    margin: ({ margin = {} }: Props) => {
      const { top = 2, right = 0, bottom = 0, left = 0 } = margin;
      return `${spacing(top)}px ${spacing(right)}px ${spacing(bottom)}px ${spacing(left)}px`;
    }
  }
}));
