import { getMarkerType } from './getMarkerType';
import { STATION_ICONS_Z_COORDS } from '../constants';

const getZCoord = (
  d: any
) => {
  const { markerType = '' } = getMarkerType(d);
  return STATION_ICONS_Z_COORDS[markerType as keyof typeof STATION_ICONS_Z_COORDS] || 1;
};

export const getPosition = (
  d: any
): number[] => {
  const isSelectedStation = d.selectedStation && d.properties && (d.selectedStation === d.properties.code);
  if (isSelectedStation) return [...d.geometry.coordinates, STATION_ICONS_Z_COORDS.cluster - 1];
  return [...d.geometry.coordinates, getZCoord(d)];
}
