import { makeStyles } from 'e100-react-components';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  helperText: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: palette.common.white,
    width: spacing(8),
    margin: `-${spacing()}px ${spacing(1.5)}px 0 auto`,
    display: 'inline-block'
  }
}));
