import { STATION_TYPES } from 'common/constants/stationTypes';

export const MARKER_TYPES = {
  ...STATION_TYPES,
  cluster: 'cluster',
  place: 'place'
} as const;

export type MarkerTypes = typeof MARKER_TYPES;
export type MarkerTypeKey = keyof MarkerTypes;
export type MarkerType = typeof MARKER_TYPES[MarkerTypeKey];
