import { useEffect, useCallback } from 'react';

import { useMarkersContext } from '../context/markers';
import { useActiveMarkersContext } from '../context/activeMarkers';
import { useIsActiveGoogleMap } from 'entities/application';
import { useMapContext } from '../context/map';

const checkIsMarker = ({ object }: any = { object: {} }) => !!(object && object?.coordinates);
const checkIsCluster = ({ object }: any = { object: {} }) => !!(object && object?.cluster);

const useHandlerGoogleStationMarkersHover = () => {
  const { setOverlayProps } = useMarkersContext();
  const { setHoveredMarker } = useActiveMarkersContext();
  const { addListenerDragStart } = useMapContext();

  const handlerGoogleStationMarkersHover = useCallback(() => {
    const mapElement = document.getElementById('map');
    setOverlayProps({
      onHover: (props: any) => {
        if (checkIsMarker(props)) {
          setHoveredMarker({
            viewportCoords: {
              x: props.x,
              y: props.y
            },
            station: props.object
          });
        } else {
          setHoveredMarker(null);
        }

        if (checkIsMarker(props) || checkIsCluster(props) || !!props.object) {
          if (mapElement) mapElement.style.cursor = 'pointer';
        } else {
          if (mapElement) mapElement.style.cursor = '';
        }
      }
    });

    addListenerDragStart(() => {
      setHoveredMarker(null);
    })
  }, [
    setOverlayProps,
    setHoveredMarker,
    addListenerDragStart
  ]);

  return handlerGoogleStationMarkersHover;
};

export const useHandlerHereStationMarkersHover = () => {
  const { setHoveredMarker } = useActiveMarkersContext();

  const handlerHereStationMarkersHover = useCallback(({
    target = {
      getData: () => ({})
    },
    originalEvent: {
      layerX,
      layerY
    },
    type
  }: any) => {
    if (type === 'pointermove') {
      const { data } = target.getData();
      if (data) {
        setHoveredMarker({
          viewportCoords: {
            x: layerX,
            y: layerY
          },
          station: data
        });
      }
    } else {
      setHoveredMarker(null);
    }
  }, [setHoveredMarker]);

  return handlerHereStationMarkersHover;
};

export const useInitHandlerStationMarkersHover = () => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const handlerGoogleStationMarkersHover = useHandlerGoogleStationMarkersHover();

  useEffect(() => {
    if (isActiveGoogleMap) {
      handlerGoogleStationMarkersHover();
    }
  }, [
    isActiveGoogleMap,
    handlerGoogleStationMarkersHover
  ]);
};
