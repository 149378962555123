import {
  useMemo
} from 'react';

import {
  GoogleLayer,
  HereLayer,

} from 'common/utils/MapMarker/types';
import { Stations } from 'dataSources/Typicode/stations';
import { OnMarkerClick } from 'components/Map/hooks/useOnStationMarkerClick';

import {
  getIcon,
  getPosition,
  getSize
} from '../utils';

import { useIsActiveGoogleMap } from 'entities/application';
import { useSelectedStationCode } from 'store/features/station';
import { useActiveStationsContext } from 'pages/Map/context/activeStations';
import { useOnStationGoogleMarkerClick } from './useOnStationGoogleMarkerClick';
import { useHandlerHereStationMarkersHover } from 'components/Map/hooks/useInitHandlerStationMarkersHover';

import getLargestClusterIcon from '../assets/mapIcons/cluster/largest';
import getDefaultClusterIcon from '../assets/mapIcons/cluster/default';

import {
  MIN_LARGE_CLUSTER_WEIGHT,
  CLUSTER_HEIGHT,
  CLUSTER_WIDTH,
  LARGEST_CLUSTER_HEIGHT,
  LARGET_CLUSTER_WIDTH
} from '../constants';

const useGoogleStationMarkersLayer = ({
  data
}: StationMarkersLayerProps, {
  onMarkerClick
}: StationMarkersLayerHadlers) => {
  const selectedStationCode = useSelectedStationCode();
  const { isRouteStations } = useActiveStationsContext();
  const onStationGoogleMarkerClick = useOnStationGoogleMarkerClick(onMarkerClick);

  const googleStationMarkersLayer = useMemo((): GoogleLayer => ({
    id: 'stations',
    type: 'cluster' as const,
    // @ts-ignore
    data,
    selectedStation: selectedStationCode,
    isRouteStations,
    onClick: onStationGoogleMarkerClick,
    // @ts-ignore
    getIcon,
    // @ts-ignore
    getPosition,
    getSize
  }), [
    data,
    selectedStationCode,
    isRouteStations,
    onStationGoogleMarkerClick
  ]);

  return googleStationMarkersLayer;
};

const useHereStationMarkersLayer = ({
  data
}: StationMarkersLayerProps, {
  onMarkerClick
}: StationMarkersLayerHadlers) => {
  const selectedStationCode = useSelectedStationCode();
  const mapDOMElement = document.getElementById('map');
  const handlerHereStationMarkersHover = useHandlerHereStationMarkersHover();

  const hereStationMarkersLayer = useMemo((): HereLayer => ({
    data,
    name: 'stations',
    type: 'cluster',
    selectedStation: selectedStationCode,
    getCoordinates: ({ coordinates: {
      latitude,
      longitude
    } }) => ([latitude, longitude]),
    getCluster: (clusterWeight) => ({
      icon: clusterWeight < MIN_LARGE_CLUSTER_WEIGHT
        ? getDefaultClusterIcon(clusterWeight)
        : getLargestClusterIcon(clusterWeight),
      size: clusterWeight < MIN_LARGE_CLUSTER_WEIGHT
        ? { w: CLUSTER_WIDTH / 2, h: CLUSTER_HEIGHT / 2 }
        : { w: LARGET_CLUSTER_WIDTH / 2, h: LARGEST_CLUSTER_HEIGHT / 2 },
      anchor: clusterWeight < MIN_LARGE_CLUSTER_WEIGHT
        ? { x: CLUSTER_WIDTH / 4, y: CLUSTER_HEIGHT / 4 }
        : { x: LARGET_CLUSTER_WIDTH / 4, y: LARGEST_CLUSTER_HEIGHT / 4 }
    }),
    getMarker: ({ station, selectedStation }) => {
      const iconProps = {
        properties: {
          cluster: false,
          ...station
        },
        selectedStation
      };

      const {
        url,
        height,
        width
      } = getIcon(iconProps);

      return ({
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4, y: height / 4 }
      })
    },
    onClick: ({ data }) => {
      if (onMarkerClick && data) {
        onMarkerClick(data);
      }
    },
    onPointermove: (event) => {
      handlerHereStationMarkersHover(event);
      if (mapDOMElement) {
        mapDOMElement.style.cursor = 'pointer';
      }
    },
    onPointerenter: (event) => {
      handlerHereStationMarkersHover(event);
      if (mapDOMElement) {
        mapDOMElement.style.cursor = '';
      }
    },
    onDragStart: (event) => {
      handlerHereStationMarkersHover(event);
    }
  }), [
    data,
    onMarkerClick,
    handlerHereStationMarkersHover,
    selectedStationCode,
    mapDOMElement
  ]);

  return hereStationMarkersLayer;
};

interface StationMarkersLayerProps {
  data: Stations;
}

interface StationMarkersLayerHadlers {
  onMarkerClick?: OnMarkerClick;
}

export const useStationMarkersLayer = (
  props: StationMarkersLayerProps,
  handlers: StationMarkersLayerHadlers
) => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const googleStationMarkersLayer = useGoogleStationMarkersLayer(props, handlers);
  const hereStationMarkersLayer = useHereStationMarkersLayer(props, handlers);

  return isActiveGoogleMap ? googleStationMarkersLayer : hereStationMarkersLayer;
};
