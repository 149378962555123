import IconLayer from 'common/utils/DeckGl/Layers/IconLayer';
import { GoogleLayer } from '../../types';

import coommonLayerSettings from '../utils/commonLayerSettings';

const markerLayerCreator = ({
  data,
  id = 'marker-icon',
  ...otherProps
// @ts-ignore
}: GoogleLayer) => new IconLayer({
  ...coommonLayerSettings,
  id,
  data,
  ...otherProps
});

export default markerLayerCreator;
