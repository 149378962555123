import { PayloadAction } from "@reduxjs/toolkit";

import { IState } from "../types";

const setStationCodeForStationPrices = (
  state: IState,
  action: PayloadAction<IState['stationPrices']['stationCode']>
) => {
  state.stationPrices.stationCode = action.payload;
};

export default setStationCodeForStationPrices;