import { HereMapInterface } from "common/utils/Map";
import {
  Route,
  Params
} from '../types';
import { Params as SetOptionsParams } from '../types/setOptions';
import { IGeoCoords } from 'dataSources/Typicode/stations/types/station';
import { DEFAULT_SERVICE_POINT_DISPLAY_RADIUS } from 'common/constants/servicePointDisplayRadius';
import { AVERAGE_OVERVIEW_PATH_ITEMS } from '../../directionsService/constants';

import getGUID from 'common/utils/getGUID';
import { getIcon } from 'common/utils/waypointMarker';
import { getInfoWindow } from '../utils';

export class HereRoute implements Route {
  private _map: HereMapInterface;
  private _id: string;
  private _directionResult?: Params['directionResult'];
  private _routeLine?: any;
  private _fuelRouteLine?: any;
  private _group?: any;
  private _waypointsGroup?: any;
  private _infoWindow?: any;
  public polylines?: string[];
  public time: Params['time'];
  public startAddress: Params['startAddress'];
  public endAddress: Params['endAddress'];
  public distance: Params['distance'];
  public bounds: Params['bounds'];
  // public nativeBounds: Params['nativeBounds'];
  public legs: Params['legs'];
  public overviewPath: Params['overviewPath'] = [];
  public overviewPolyline: Params['overviewPolyline'];
  public multilineString: Params['multilineString'];
  public fuel?: Params['fuel'];
  private _polylineOptions = {
    strokeColor: '#008DC9',
    lineWidth: 5
  };

  constructor(map: HereMapInterface, params: Params) {
    const {
      time,
      startAddress,
      endAddress,
      distance,
      bounds,
      // nativeBounds,
      legs,
      directionResult,
      multilineString,
      fuel,
      polylines
    } = params;

    this._map = map;
    this._id = getGUID();
    this._directionResult = directionResult;
    this.time = time;
    this.startAddress = startAddress;
    this.endAddress = endAddress;
    this.distance = distance;
    this.bounds = bounds;
    // this.nativeBounds = nativeBounds;
    this.legs = legs;
    this.multilineString = multilineString;
    this.fuel = fuel;
    this.polylines = polylines;
    this._initGroup();
    this._initRouteLine();
    this._addRouteLineToGroup();
    this._initFuelRouteLine();
    this._addFuelRouteLineToGroup();
    this._initOverviewPath();
  }

  public get id() {
    return this._id;
  }

  _initOverviewPath() {
    const overviewPath: any[] = [];

    this._routeLine.getGeometry().getGeometries().forEach((geometry: any) => {
      geometry.eachLatLngAlt((lat: number, lng: number) => {
        overviewPath.push({ lat, lng });
      }, 0);
    });

    const shift = Math.round(overviewPath.length / AVERAGE_OVERVIEW_PATH_ITEMS);

    this.overviewPath = overviewPath.reduce((acc, item, index) => {
      if (index === 0 || index % shift === 0 || index === overviewPath.length - 1) {
        acc.push(item);
      }

      return acc;
    }, [] as any[]);
  }

  _getOverviewPathMidpoint() {
    return this.overviewPath[Math.round(this.overviewPath.length / 2)];
  }

  _initRouteLine() {
    this._routeLine = new window.H.map.Polyline(this.multilineString, {
      style: {
        ...this._polylineOptions
      },
      zIndex: 0
    });
  }

  _initFuelRouteLine() {
    if (this.polylines && this.fuel?.pointCompletion) {
      const fuelRouteLine = new window.H.geo.LineString();
      let isFoundPoint = false;

      this.polylines.forEach((polyline) => {
        const lineString = window.H.geo.LineString.fromFlexiblePolyline(polyline);
        lineString.eachLatLngAlt((lat: string, lng: string) => {
          if (`${lat}` === `${this.fuel?.pointCompletion?.lat}` && `${lng}` === `${this.fuel?.pointCompletion?.lng}`) {
            isFoundPoint = true;
          }

          if (isFoundPoint) {
            fuelRouteLine.pushLatLngAlt(lat, lng);
          }
        });
      });

      this._fuelRouteLine = new window.H.map.Polyline(fuelRouteLine, {
        style: {
          ...this._polylineOptions
        },
        zIndex: 1
      });
    }
  }

  _addFuelRouteLineToGroup() {
    if (this._group && this.fuel?.pointCompletion) {
      this._group.addObject(this._fuelRouteLine);
    }
  }

  _initGroup() {
    this._group = new window.H.map.Group();
  }

  _addRouteLineToGroup() {
    if (this._group) {
      this._group.addObject(this._routeLine);
    }
  }

  _addWaypointMarkers() {
    if (this.legs) {
      this._waypointsGroup = new window.H.map.Group();

      this.legs.forEach(({ location, name }) => {
        this._waypointsGroup.addObject(
          new window.H.map.Marker(location, {
            icon: new window.H.map.Icon(getIcon(name), {
              size: {
                w: 32,
                h: 32
              }
            })
          })
        )
      });

      this._map.getNativeMap().addObject(this._waypointsGroup);
    }
  }

  _deleteWaypointMarkers() {
    this._map.getNativeMap().removeObject(this._waypointsGroup);
  }

  addInfoWindow() {
    this._infoWindow = new window.H.ui.InfoBubble(this._getOverviewPathMidpoint(), {
      content: getInfoWindow({
        distance: this.distance,
        time: this.time
      })
    });
    this._map.mapUi.addBubble(this._infoWindow);
  }

  deleteInfoWindow() {
    this._map.mapUi.removeBubble(this._infoWindow);
  }

  setOptions(params: SetOptionsParams = {}) {
    this._routeLine.setStyle({
      ...this._polylineOptions,
      strokeColor: params.isActive ? '#4FBC46' : this._polylineOptions.strokeColor
    });
    
    if (this._fuelRouteLine) {
      this._fuelRouteLine.setStyle({
        ...this._polylineOptions,
        strokeColor: params.isActive ? '#ff0000' : this._polylineOptions.strokeColor
      });
    }

    this._group.setZIndex(params.isActive ? 5 : 0);
  }

  show() {
    this._map.getNativeMap().addObject(this._group);
    this._addWaypointMarkers();
  }

  delete() {
    this._map.getNativeMap().removeObject(this._group);
    this._deleteWaypointMarkers();
    this.deleteInfoWindow();
  }

  checkIsLocationOnRoute(point: IGeoCoords, tolerance: number = DEFAULT_SERVICE_POINT_DISPLAY_RADIUS): boolean {
    return true;
  }
}