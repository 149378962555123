import { AppThunk } from 'store';
import dataSources from 'dataSources';
import{ STATUS_LOADING } from 'common/constants';
import { getResponseError } from 'common/utils/index';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { statusLoading, setStationInfo } = slice.actions;
const { setErrorNotice } = applicationActions;

type TOperation = (code: string) => AppThunk;

const operation: TOperation = (code) => async (dispatch: any) => {
  try {
    dispatch(statusLoading(STATUS_LOADING.load));
    const stationInfo = await dataSources.stations.getStationInfo(code)
    dispatch(setStationInfo(stationInfo));
    dispatch(statusLoading(STATUS_LOADING.done));
  } catch (e) {
    const error = await getResponseError(e);
    dispatch(setErrorNotice(error));
    dispatch(setStationInfo({}));
    dispatch(statusLoading(STATUS_LOADING.error));
  }
};

export default operation;
