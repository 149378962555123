import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { checkIsLoading } from 'common/utils/checkLoading';
import { turfBounds } from 'shared/utils';

import { RootState } from 'store';

import { NAVIGATION_STATE } from '../../constants';
import { NavigationHistoryPath } from '../../types';

import {
  checkIsUntrackedLocationHistoryPath,
  checkIsParkingLocationHistoryPath
} from '../../utils';

export const useNavigationLocationsHistoryLoadingStatus = () => {
  const navigationLocationsHistoryLoadingStatus = useSelector((state: RootState) => state[NAVIGATION_STATE].history.loadingStatus);
  return navigationLocationsHistoryLoadingStatus;
};

export const useIsNavigationLocationsHistoryLoading = () => {
  const navigationLocationsHistoryLoadingStatus = useNavigationLocationsHistoryLoadingStatus();
  return checkIsLoading(navigationLocationsHistoryLoadingStatus);
};

export const useNavigationHistory = () => {
  const navigationHistory = useSelector((state: RootState) => state[NAVIGATION_STATE].history.data);
  return navigationHistory;
};

export const useNavigationHistoryPath = () => {
  const { path } = useNavigationHistory() || {};
  return path;
};

export const useNavigationHistoryBounds = () => {
  const navigationHistoryPath = useNavigationHistoryPath();

  const navigationHistoryBounds = useMemo(() => {
    const coords = (navigationHistoryPath || []).reduce((acc, { locations }) => {
      locations.forEach(({ latitude, longitude }) => {
        acc.push([ longitude, latitude ]);
      });
      return acc;
    }, [] as [number, number][]);

    return coords.length ? turfBounds(coords) : null
  }, [navigationHistoryPath]);

  return navigationHistoryBounds;
};

export const useNavigationUntrackedHistoryPath = () => {
  const navigationHistory = useNavigationHistory();

  const navigationUntrackedHistoryPath = useMemo(() => (navigationHistory?.path || []).reduce((acc, item) => {
    if (checkIsUntrackedLocationHistoryPath(item.type)) {
      acc.push(item);
    }
    return acc;
  }, [] as NavigationHistoryPath[]), [
    navigationHistory
  ]);

  return navigationUntrackedHistoryPath;
};

export const useNavigationParkingHistoryPath = () => {
  const navigationHistory = useNavigationHistory();

  const navigationParkingHistoryPath = useMemo(() => navigationHistory?.path.reduce((acc, item) => {
    if (checkIsParkingLocationHistoryPath(item.type)) {
      acc.push(item);
    }
    return acc;
  }, [] as NavigationHistoryPath[]), [
    navigationHistory
  ]);

  return navigationParkingHistoryPath;
};

export const useNavigationAutoObuPairsLoadingStatus = () => {
  const navigationAutoObuPairsLoadingStatus = useSelector((state: RootState) => state[NAVIGATION_STATE].autos.loadingStatus);
  return navigationAutoObuPairsLoadingStatus;
};


export const useNavigationAutoObuPairs = () => {
  const navigationAutoObuPairs = useSelector((state: RootState) => state[NAVIGATION_STATE].autos.data);
  return navigationAutoObuPairs;
}

export const useNavigationAutoObuPairList = () => {
  const navigationAutoObuPairs = useNavigationAutoObuPairs();

  const navigationAutoObuPairList = useMemo(() => (navigationAutoObuPairs || []).map(({
    autoNumber,
    obuNumber
  }) => ({
    key: autoNumber,
    obuNumber,
    value: `${autoNumber} (OBU ${obuNumber})`
  })), [
    navigationAutoObuPairs
  ]);

  return navigationAutoObuPairList;
};

