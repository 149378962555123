import React from 'react';

import {
  Grid,
  IconButton,
  Typography
} from 'e100-react-components';

import { useStyles } from './styles';

export const DrawerHeader = ({
  title,
  onClick
}: DrawerHeaderProps) => {
  const classes = useStyles();

  return (
    <Grid
    container
    justify={'space-between'}
    alignItems={'center'}
    classes={{ container: classes.root }}
  >
    <Grid item>
      <Typography>{title}</Typography>
    </Grid>
    <Grid item>
      <IconButton
        kind={'close'}
        onClick={onClick}
      />
    </Grid>
  </Grid>
  );
};

interface DrawerHeaderProps {
  title?: string;
  onClick?: () => void;
}