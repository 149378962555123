import { setLocale } from "./setLocale";
import { resetNotice } from "./resetNotice";
import { setNotice } from "./setNotice";
import { setSuccessNotice } from "./setSuccessNotice";
import { setErrorNotice } from "./setErrorNotice";
import { toggleShowOrderCardsModal } from './toggleShowOrderCardsModal';
import { setActiveModals } from "./setActiveModals";
import { resetActiveModals } from "./resetActiveModals";
import { addActiveModal } from './addActiveModal';
import { deleteActiveModal } from './deleteActiveModal';

export const applicationOperations = {
  setLocale,
  resetNotice,
  setNotice,
  setSuccessNotice,
  setErrorNotice,
  toggleShowOrderCardsModal,
  setActiveModals,
  resetActiveModals,
  addActiveModal,
  deleteActiveModal
};
