import makeStyles from 'e100-react-components/lib/styles/makeStyles';

interface IProps {
  marginBottom: number;
  marginTop: number;
}

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    fontWeight: 500,
    marginBottom: ({ marginBottom }: IProps) => spacing(marginBottom),
    marginTop: ({ marginTop }: IProps) => spacing(marginTop)
  }
}));

export default useStyles;
