import { IntlFormatters } from '@formatjs/intl';
import { Distance } from 'common/utils/Route/types/distance';

type GetDistance = (sections: any, formatMessage: IntlFormatters['formatMessage']) => Distance;

export const getDistance: GetDistance = (sections, formatMessage) => {
  const distance = Math
    .round(sections.reduce((acc: number, { travelSummary: { length } }: any) => (length ? acc + length : acc), 0) / 1000);

  return `${distance} ${formatMessage({ id: 'km' })}`
}
