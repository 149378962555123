import { RootState } from 'store';

import { STATE } from '../types/state';

const selector = (state: RootState): number => {
  const { stations: { data } } = state[STATE];
  return data.length;
};

export default selector;
