import { AppThunk } from 'store';
import { batch } from 'react-redux';
import{ STATUS_LOADING } from 'common/constants';
import { getResponseError } from 'common/utils/index';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';
import { STATIONS_STATE } from 'entities/stations';

const {
  setStationPrices,
  setPricesStatusLoading,
  setStationCodeForStationPrices
} = slice.actions;
const { setErrorNotice } = applicationActions;

type Operation = (stationCode: string) => AppThunk;

const operation: Operation = (stationCode) => async (dispatch: any, getState) => {
  try {
    batch(() => {
      dispatch(setStationCodeForStationPrices(stationCode));
      dispatch(setPricesStatusLoading({
        statusLoading: STATUS_LOADING.load
      }));
    });

    const state = getState();
    const stationsWithPricesMap = state[STATIONS_STATE].stationsWithPrices.dataMap || {};
    const data = stationsWithPricesMap[stationCode];

    batch(() => {
      dispatch(setPricesStatusLoading({
        statusLoading: STATUS_LOADING.done,
        stationCode
      }));
      dispatch(setStationPrices({
        data: data ? data.servicePrices : [],
        stationCode
      }));
    });
  } catch (e) {
    const error = await getResponseError(e);
    dispatch(setErrorNotice(error));
    dispatch(setPricesStatusLoading({
      statusLoading: STATUS_LOADING.error,
      stationCode
    }));
  }
};

export default operation;
