// @ts-ignore
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { GoogleMapsOverlayProps } from '@deck.gl/google-maps/typed';

import { GoogleMapInterface } from 'common/utils/Map';
import {
  GoogleMarkerInterface,
  OverlayProps,
  GoogleLayers
} from '../types';

import clusterLayerCreator from './utils/clusterLayerCreator';
import markerLayerCreator from './utils/markerLayerCreator';

export class GoogleMarker implements GoogleMarkerInterface {
  private _map?: GoogleMapInterface;
  private _marker?: google.maps.Marker;
  private _overlay?: GoogleMapsOverlay;
  private _clickHandlers:  GoogleMapsOverlayProps['onClick'][] = [];

  constructor(map?: GoogleMapInterface) {
    this._map = map;
    this._initOverlay();
    this.setMap(map?.getNativeMap());
  }

  private _initOverlay() {
    this._overlay = new GoogleMapsOverlay({
      useDevicePixels: 1.6,
      interleaved: false,
      onClick: (info: any, event: any) => {
        this._revealCluster(info);
        this._clickHandlers.forEach(clickHandler => {
          if (clickHandler) {
            clickHandler(info, event);
          }
        })
      },
      layers: [],
    });
  }

  private _revealCluster({ layer, object, coordinate }: any) {
    if (object && object.cluster && coordinate && this._map) {
      const expansionZoom = layer.state.index.getClusterExpansionZoom(object.cluster_id);
      const cluster = layer.state.index.getCluster(object.cluster_id);
      this._map.setZoom(expansionZoom + 1);
      setTimeout(() => {
        this._map?.setCenter({
          lat: cluster.lat,
          lng: cluster.lng
        });
      });
    }
  }

  addClickHandler(handler: GoogleMapsOverlayProps['onClick']) {
    this._clickHandlers.push(handler);
  }

  deleteClickHandler(handler: GoogleMapsOverlayProps['onClick']) {
    this._clickHandlers = this._clickHandlers.filter(clickHandler => clickHandler !== handler);
  }

  public setOverlayProps(props: OverlayProps) {
    if (this._overlay) {
      this._overlay.setProps(props);
    }
  }

  public setMap(map?: google.maps.Map) {
    if (this._overlay && map) {
      this._overlay.setMap(map);
    }
  }

  updateLayers(layers: GoogleLayers<{}> = []) {
    if (this._overlay) {
      this._overlay.setProps({
        layers: layers.map(layer => (
          layer.type === 'cluster'
            ? clusterLayerCreator(layer)
            : markerLayerCreator(layer)
        ))
      });
    }
  }

  deleteMarker() {
    this._marker?.setMap(null);
  }
}
