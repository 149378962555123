import React from 'react';
import Typography from 'e100-react-components/lib/components/Typography';

import { Props as TitledSectionProps } from '../index';
import useStyles from './useStyles';

const Descriptin = (props: Props) => {
  const { description } = props;
  const classes = useStyles();

  if (!description) return null;

  return (
    <Typography
      variant="caption"
      gutterBottom
      color="primary"
      classes={{ root: classes.text }}
    >
      {description}
    </Typography>
  );
};

interface Props extends Pick<TitledSectionProps, 'description'> {}

export default Descriptin;
