import { AppThunk } from 'store';
import { batch } from 'react-redux';

import { getResponseError } from 'common/utils';
import dataSources from 'dataSources';
import { navigationActions } from '../reducer';
import { applicationActions } from 'entities/application';

import { LOADING_STATUS } from 'shared/constants';

import { GetLocationsHistoryForm } from '../../types';

const {
  setHistory,
  setHistoryLoadingStatus
} = navigationActions;
const {
  setErrorNotice
} = applicationActions;

export const getLocationsHistory = (
  form: GetLocationsHistoryForm
): AppThunk => async (dispatch) => {
  try {
    dispatch(setHistoryLoadingStatus(LOADING_STATUS.load));

    const locationsHistory = await dataSources.navigation.getLocationsHistory(form);

    batch(() => {
      dispatch(setHistory(locationsHistory));
      dispatch(setHistoryLoadingStatus(LOADING_STATUS.done));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setHistoryLoadingStatus(LOADING_STATUS.done));
    });
  }
};
