// @ts-nocheck
import DeckIconManager from '@deck.gl/layers/dist/esm/icon-layer/icon-manager';

const DEFAULT_CANVAS_WIDTH = 4000;

export default class IconManager extends DeckIconManager {
  constructor(gl, props) {
    super(gl, props);
    this._canvasWidth = DEFAULT_CANVAS_WIDTH;
  }
}
