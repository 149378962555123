import { RootState } from 'store';

import { STATE } from '../types/state';

const selector = (state: RootState): boolean => {
  const { filter: { data } } = state[STATE];
  return !!data.hasActions;
};

export default selector;
