import { PayloadAction } from "@reduxjs/toolkit";

import { IState } from "../types";

interface Payload {
  data: IState['stationPrices']['prices'];
  stationCode: IState['stationPrices']['stationCode'];
}

const setStationPrices = (state: IState, action: PayloadAction<Payload>) => {
  if (state.stationPrices.stationCode === action.payload.stationCode) {
    state.stationPrices.prices = action.payload.data;
  }
};

export default setStationPrices;