import { Props } from '../index';
import useInitMarkers from './useInitMarkers';
import useUpdateLayers from './useUpdateLayers';
import { useInitHandlerStationMarkersHover } from './useInitHandlerStationMarkersHover';

export interface MarkerHandlers {
  onMarkerClick?: Props['onMarkerClick'];
}

export interface MarkerLocations extends Pick<
  Props,
  'stations' | 'extraStations' | 'layers'
> {}

type UseMarkers = (
  markerLocations: MarkerLocations,
  handlers: MarkerHandlers
) => void;

const useMarkers: UseMarkers = (markerLocations, handlers) => {
  useInitMarkers();
  useUpdateLayers(markerLocations, handlers);
  useInitHandlerStationMarkersHover();
};

export default useMarkers;
