import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { TextColor } from '../../../hooks/useTextColor';

interface Props {
  textColor: TextColor
}

const useStyles = makeStyles(() => ({
  iconRoot: {
    color: ({ textColor }: Props) => textColor
  },
  label: {
    color: ({ textColor }: Props) => textColor
  }
}));

export default useStyles;