import { PayloadAction } from "@reduxjs/toolkit";

import { ReportsState } from "../../types";

export const setReportsLoadingStatus = (
  state: ReportsState,
  action: PayloadAction<ReportsState['reports']['loadingStatus']>
) => {
  state.reports.loadingStatus = action.payload;
};
