
import { ReportMetadata } from './types';

class ReportsMetadataLocalStore {
  addReportMetadata(reportGuid: string, reportMetadata: ReportMetadata) {
    window.localStorage.setItem(reportGuid, JSON.stringify(reportMetadata));
  }

  deleteReportMetadata(reportGuid: string) {
    window.localStorage.removeItem(reportGuid);
  }

  getReportMetadata(reportGuid: string): ReportMetadata | null {
    const reportMetadataJSON = window.localStorage.getItem(reportGuid);
    return reportMetadataJSON ? JSON.parse(reportMetadataJSON) : null;
  }
}

export const reportsMetadataLocalStore = new ReportsMetadataLocalStore();
