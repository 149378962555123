
import slice from '../reducer/slice';
import { TUserType } from '../types';

type TOperation = (userType: TUserType) => void;

const { setUserType } = slice.actions;

const operation: TOperation = userType => setUserType(userType);

export default operation;
