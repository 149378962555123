import defaultTheme from "pages/app/theme/defaultTheme";

export const INNER_PADDING = 2;

export const MARKERS_LAYER_Z_INDEX = 100;
export const CONTROLS_Z_INDEX = MARKERS_LAYER_Z_INDEX + 1;
export const EXPORT_REPORTS_WINDOW_Z_INDEX = 1000;
export const FILTER_MODAL_Z_INDEX = defaultTheme.zIndex.mobileStepper + 1;
export const BUILDING_ROUTE_MODAL_Z_INDEX = FILTER_MODAL_Z_INDEX;
export const STATION_MODAL_Z_INDEX = defaultTheme.zIndex.mobileStepper + 2;
export const INFO_WINDOW_Z_INDEX = MARKERS_LAYER_Z_INDEX;
export const MOBILE_INFO_WINDOW_Z_INDEX = STATION_MODAL_Z_INDEX + 1;
