import slice from '../reducer/slice';

import { StatusLoading } from 'common/constants/statusLoading';

const { setAutosCoordinatesStatusLoading } = slice.actions;

type Operation = (statusLoading?: StatusLoading) => void;

const operation: Operation = StatusLoading => setAutosCoordinatesStatusLoading(StatusLoading);

export default operation;
