import { IntlFormatters } from '@formatjs/intl';
import { Time } from 'common/utils/Route/types/time';

type GetRouteTime = (legs: google.maps.DirectionsLeg[], formatMessage: IntlFormatters['formatMessage']) => Time;

const getRouteTime: GetRouteTime = (legs, formatMessage) => {
  const timestamp = legs.reduce((acc, { duration }) => (duration ? acc + duration.value : acc) , 0);
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) % 60;
  return `${hours} ${formatMessage({ id: 'hours' })} ${minutes} ${formatMessage({ id: 'min' })}`;
};

export default getRouteTime;
