import React, { useMemo } from 'react';
import DOMPurify from 'dompurify'
import { ICellComponentProps } from 'e100-react-components/lib/components/DataGrid/types/props/columns';

import { useGetReportTableColumnSettings } from './useGetReportTableColumnSettings';
import { useMappedReport } from './useMappedReport';

import { PricesCell } from '../ui/ReportTable/Cells/Prices/Prices';

import { REPORT_COLUMN_NAMES } from '../constants';

export const useReportTableColumns = () => {
  const { columnNames = [] } = useMappedReport();

  const getReportTableColumnSettings = useGetReportTableColumnSettings();
  
  const reportTableColumns = useMemo(() => (
    columnNames.map(({ title, id }) => (id === REPORT_COLUMN_NAMES.prices ? {
      id: id,
      label: title,
      cellComponent: PricesCell,
      ...getReportTableColumnSettings(id)
    } : {
        id: id,
        label: title,
        cellComponent: ({ rowData, column }: ICellComponentProps) => (
          <div
            {...id === REPORT_COLUMN_NAMES.address && {
              style: { wordBreak: 'break-word' }
            }}
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rowData[column.id])}}></div>
        ),
        ...getReportTableColumnSettings(id)
      }))
  ), [
    columnNames,
    getReportTableColumnSettings
  ]);

  return reportTableColumns;
};
