import { BaseResource } from 'e100-data-providers';
import { TRestProvider } from 'dataSources/types/restProvider';

// Types
import { UserSettings } from './types/userSettings';
import { OptionsUpdateSettings } from './types/updateSettings';

class User extends BaseResource {
  path: string = '';

  constructor(provider: TRestProvider) {
    super(provider);
    this.path = 'user';
  }

  async getSettings(): Promise<UserSettings> {
    const data = await this.getChildResource('locator/view/config').getItems();
    return { ...data, distanceFromRoute: Number(data.distanceFromRoute) / 1000 };
  }

  updateSettings(form: OptionsUpdateSettings) {
    const updatedForm = { ...form, distanceFromRoute: Number(form.distanceFromRoute) * 1000 };
    return this.send(this.getChildResource('locator/view/config').request.post().setBody(updatedForm));
  }
};

export default User;

