import { HereMapInterface } from "common/utils/Map/types";
import { DirectionsService, getRoutes } from '../types';

import { routeFactory } from "common/utils/Route";

import {
  getRouteTime,
  getDistance,
  getRouteBounds,
  getMultiLineStringFromPolylines,
  mappingLegs,
  mappingRoutePoint,
  getPointCompletionFuel
} from './utils';
import { getAddress } from '../utils';

export class HereDirectionsService implements DirectionsService{
  private _map: HereMapInterface;

  constructor(map: HereMapInterface) {
    this._map = map;
  }

  getRoutes: getRoutes = async (
    {
      firstPoint,
      lastPoint,
      waypoints
    },
    formatMessage,
    routeModifiers = {},
    routesSettings = {}
  ) => new Promise((resolve, reject) => {
    const router = this._map.getPlatform().getRoutingService(null, 8);

    router.calculateRoute({
      'routingMode': 'short',
      'transportMode': 'truck',
      'alternatives': '4',
      origin: mappingRoutePoint(firstPoint),
      destination: mappingRoutePoint(lastPoint),
      // Include the route shape in the response
      'return': ['polyline', 'travelSummary', 'routeLabels', 'tolls', 'passthrough'],
      'spans': ['consumption', 'segmentId'],
      'vehicle[axleCount]': Number(routesSettings.axesNumber),
      'vehicle[grossWeight]': Number(routesSettings.grossWeight || 0),
      ...routesSettings.carHeight && {
        'vehicle[height]': Number(routesSettings.carHeight)
      },
      ...routesSettings.weightPerAxle && {
        'vehicle[weightPerAxle]': Number(routesSettings.weightPerAxle)
      },
      ...routesSettings.emissionType && {
        'tolls[emissionType]': routesSettings.emissionType 
      },
      ...routesSettings.trafficMode && {
        'traffic[mode]': routesSettings.trafficMode
      },
      'via': new window.H.service.Url.MultiValueQueryParameter(
        waypoints.map(item => mappingRoutePoint(item))
      ),
      ...routesSettings.departureTime && {
        departureTime: routesSettings.departureTime
      },
      ...routesSettings.arrivalTime && {
        arrivalTime: routesSettings.arrivalTime
      },
      ...routesSettings.averageFuelConsumption && {
        'fuel[type]': 'diesel',
        'fuel[freeFlowSpeedTable]': `0,${routesSettings.averageFuelConsumption/100},125,${routesSettings.averageFuelConsumption/100}`
      }
    }, (result: any) => {
      const {
        averageFuelConsumption,
        fuelInTankAmount,
        tanksTotalVolume,
        fuelRemainingPercentageInTank
      } = routesSettings;

      if (result.routes.length) {
        const routeBounds = getRouteBounds(result.routes);
        const routes = result.routes.map((directionRoute: any) => {
        const multilineString = getMultiLineStringFromPolylines(directionRoute.sections);

          return routeFactory(
            this._map,
            {
              time: getRouteTime(directionRoute.sections, formatMessage),
              startAddress: getAddress(firstPoint),
              endAddress: getAddress(lastPoint),
              distance: getDistance(directionRoute.sections, formatMessage),
              bounds: routeBounds,
              legs: mappingLegs(directionRoute.sections, [firstPoint, ...waypoints, lastPoint], formatMessage),
              overviewPath: [],
              polylines: directionRoute.sections.map(({ polyline }: any) => polyline),
              multilineString,
              fuel: {
                pointCompletion: getPointCompletionFuel(directionRoute.sections, {
                  averageFuelConsumption,
                  fuelInTankAmount,
                  tanksTotalVolume,
                  fuelRemainingPercentageInTank
                })
              }
            }
          );
        })

        resolve(routes);
      }

      reject();
    },
    (error: any) => {
      console.error(error.message);
    });

    return undefined;
  })
}