import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useReportsOperations } from './useReportsOperations';

import { useIsAutorizedUser } from 'store/features/auth/selectors';

export const useInitReportByURLParam = () => {
  const { reportGuid = '' } = useParams();
  const { getReport } = useReportsOperations();
  const isAutorizedUser = useIsAutorizedUser();

  useEffect(() => {
    if (isAutorizedUser) {
      getReport(reportGuid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutorizedUser]);
};
