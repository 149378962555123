import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { PERMISSIONS } from 'common/constants/permissions';
import { checkIsLoading, checkIsLoadingDone, checkIsLoadingError } from 'common/utils/checkLoading';

import { STATE } from '../types/state';

interface Permissions {
  [key: string]: boolean;
}

export const permissions = createSelector(
  (state: RootState) => state[STATE].permissions.data || [],
  userPermissions => Object.keys(PERMISSIONS)
    .reduce((acc, permissionName) => {
      const hasPermission = !!userPermissions.find(userPermission => userPermission === PERMISSIONS[permissionName]);
      return {
        ...acc,
        [permissionName]: hasPermission
      };
    }, {} as Permissions)
);

export const permissionsLoadingStatus = (state: RootState) => (
  state[STATE].permissions.loadingStatus
);

export const isPermissionsLoading = (state: RootState) => (
  checkIsLoading(permissionsLoadingStatus(state))
);

export const isPermissionsDone = (state: RootState) => (
  checkIsLoadingDone(permissionsLoadingStatus(state))
);

export const isPermissionsError = (state: RootState) => (
  checkIsLoadingError(permissionsLoadingStatus(state))
);
