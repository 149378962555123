import { RootState } from 'store';
import { IListItem } from 'common/types/common/listItem';

import { STATE } from '../types/state';

const selector = (state: RootState): IListItem[] => {
  const { roadsideService } = state[STATE];
  return roadsideService.map(({ code, name }) => ({ key: code, value: name }));
};

export default selector;
