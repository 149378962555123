import { useEffect } from 'react';
import { useMarkersContext } from '../context/markers';
import { useMapSettingsContext } from '../context';
import {
  useStationMarkersLayer,
  useExtraStationMarkersLayer
} from 'features/stations';
import {
  useCarMarkersLayer,
  useCarNumberMarkersLayer,
  useCarHistoryMarkersLayer
} from 'features/cars';
import { EMPTY_ARRAY } from 'common/constants';

import { MarkerLocations, MarkerHandlers } from './useMarkers';

const useUpdateLayers = ({
  stations,
  extraStations,
  layers = EMPTY_ARRAY
}: MarkerLocations, {
  onMarkerClick
}: MarkerHandlers = {}) => {
  const { updateLayers } = useMarkersContext();

  // Stations
  const { showStations } = useMapSettingsContext();
  
  // Autos
  const carMarkersLayer = useCarMarkersLayer();
  const carNumberMarkersLayer = useCarNumberMarkersLayer();
  const carHistoryMarkersLayer = useCarHistoryMarkersLayer();

  const stationMarkersLayer = useStationMarkersLayer({
    data: showStations ? stations as any : [],
  }, {
    onMarkerClick
  });
  const extraStationMarkersLayer = useExtraStationMarkersLayer({ extraStations });

  useEffect(() => {
    updateLayers([
      stationMarkersLayer,
      extraStationMarkersLayer,
      carMarkersLayer,
      carNumberMarkersLayer,
      carHistoryMarkersLayer,
      ...layers as any
    ])
  }, [
    // Stations
    stationMarkersLayer,
    extraStationMarkersLayer,
    carMarkersLayer,
    carNumberMarkersLayer,
    carHistoryMarkersLayer,
    stations,
    extraStations,
    showStations,
    // Layers
    layers,
    // Handlers
    updateLayers
  ]);
};

export default useUpdateLayers;
