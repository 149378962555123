import getStationInfo from './getStationInfo';
import getStationPrices from './getStationPrices';
import updateSelectedStation from './updateSelectedStation';
import updateSelectedPlace from './updateSelectedPlace';
import resetSelectedStation from './resetSelectedStation';
import resetSelectedPlace from './resetSelectedPlace';
import updateSelectedCoordinates from './updateSelectedCoordinates';

const operations = {
  getStationInfo,
  getStationPrices,
  updateSelectedStation,
  resetSelectedStation,
  updateSelectedPlace,
  resetSelectedPlace,
  updateSelectedCoordinates
};

export default operations;

