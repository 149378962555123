import { useMemo, useCallback } from 'react';
import { RootState } from 'store';
import { CountryBrand } from 'dataSources/Typicode/common/types';
import { useSelector } from 'react-redux';
import { ListItem } from 'common/types/common';

import { STATE } from '../types/state';

export const useBrands = () => {
  const { countriesBrands: { countries } = {} } = useSelector((rootState: RootState) => rootState[STATE]);

  const brands = useMemo(() => Object.values((countries || []).reduce((acc, countryBrands) => {
    countryBrands.brands.forEach((item) => {
      acc[item.billingId] = item;
    });
    return acc;
  }, {} as Record<string, CountryBrand>)), [countries]);

  return brands;
};

export const useBrandMap = () => {
  const brands = useBrands();

  const brandMap = useMemo(() => brands.reduce((acc, brand) => {
    acc[brand.billingId] = brand;
    return acc;
  }, {} as { [key: CountryBrand['billingId']]: CountryBrand; }), [brands]);

  return brandMap;
};

export const useBrandByBillingId = (billingId: CountryBrand['billingId'] = '') => {
  const brandMap = useBrandMap();

  return brandMap[billingId];
};

export const useBrandByCode = (code?: CountryBrand['title']) => {
  const brands = useBrands();

  const brandMap = useMemo(() => brands.reduce((acc, brand) => {
    acc[brand.title] = brand;
    return acc;
  }, {} as { [key: CountryBrand['billingId']]: CountryBrand; }), [brands]);

  return brandMap[code || ''];
};

export const useGetBrandByBillingId = () => {
  const brandMap = useBrandMap();

  const getBrandByBillingId = useCallback((billingId: CountryBrand['billingId'] = '') => (
    brandMap[billingId]
  ), [brandMap]);

  return getBrandByBillingId;
};

export const useGetBrandListByBillingIds = () => {
  const brandMap = useBrandMap();

  const getBrandListByBillingIds = useCallback((billingIds: CountryBrand['billingId'][] = []) => (
    billingIds.reduce((acc, billingId) => {
      const item = brandMap[billingId];
      if (item) {
        acc.push({
          key: item.billingId,
          value: item.title
        });
      }
      return acc;
    }, [] as ListItem[])
  ), [brandMap]);

  return getBrandListByBillingIds;
};

export const useBrandList = () => {
  const brands = useBrands();

  const brandList = useMemo(() => brands
    .map(({ billingId, title }) => ({
      key: billingId,
      value: title
    })
  ), [brands]);

  return brandList;
};

