import dataSources from 'dataSources';
import { batch } from 'react-redux';
import{ StatusLoading } from 'common/constants';
import{ STATUS_LOADING } from 'common/constants';
import { AppThunk } from 'store';
import { getResponseError } from 'common/utils/index';
import { IStation } from 'dataSources/Typicode/stations/types';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';
import { IStationFilter } from '../types/stationFilter';
import { STATE } from '../types/state';
import { mappingStationCodes } from '../utils';

export type GetStationsResult = AppThunk<Promise<{
  latLngBounds: google.maps.LatLngBounds,
  stations: IStation[]
} | undefined>>;

const {
  setFilterStatusLoading,
  setIsLoadingStations,
  setFilter,
  setStations,
  setActionStations,
  setActionStationsFitBounds,
  setStationsFitBounds
} = slice.actions;
const { setErrorNotice } = applicationActions;

const operation = (
  filter: IStationFilter,
  isAutoscaling: boolean = true
): AppThunk => async (dispatch, getState) => {
  try {
    batch(() => {
      dispatch(setFilterStatusLoading(STATUS_LOADING.load));
      dispatch(setIsLoadingStations(StatusLoading.Load));
    });
  
    const state = getState();
    const {
      allStations: { data }
    } = state[STATE];

    const stationCodes = await dataSources.stations.getStationCodes(filter);
    const {
      stations,
      actionStations,
      stationsBBox,
      actionStationsBBox
    } = await mappingStationCodes(stationCodes, data);

    batch(() => {
      dispatch(setFilterStatusLoading(STATUS_LOADING.done));
      dispatch(setIsLoadingStations(StatusLoading.Done));
      if (isAutoscaling) dispatch(setStationsFitBounds(stationsBBox));
      dispatch(setActionStationsFitBounds(actionStationsBBox));
      dispatch(setFilter(filter));
      dispatch(setStations(stations));
      dispatch(setActionStations(actionStations));
    });

    return {
      stations,
      stationsBBox
    };
  } catch(e) {
    const error = await getResponseError(e);
    dispatch(setErrorNotice(error));
    dispatch(setFilterStatusLoading(STATUS_LOADING.error));
    dispatch(setIsLoadingStations(StatusLoading.Error));
  }
};

export default operation;
