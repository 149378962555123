import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { StatusLoading } from 'common/constants/statusLoading';
import { checkIsLoading, checkIsLoadingDone, checkIsLoadingError } from 'common/utils/checkLoading';

import { IStationFilter } from '../types/stationFilter';
import { STATE } from '../types/state';

export const filter = (state: RootState): IStationFilter => {
  const { filter: { data } } = state[STATE];
  return data;
};

export const useFilter = () => {
  const { data } = useSelector((state: RootState) => state[STATE].filter);
  return data;
};

export const selectorFilterStatusLoading = (state: RootState): StatusLoading | undefined => {
  const { filter: { statusLoading} } = state[STATE];
  return statusLoading;
};

export const useFilterStatusLoading = () => {
  const filterStatusLoading = useSelector(selectorFilterStatusLoading);
  return filterStatusLoading;
};

export const useIsFilterDone = () => {
  const filterStatusLoading = useFilterStatusLoading();
  return checkIsLoadingDone(filterStatusLoading);
};

export const useIsFilterLoading = () => {
  const filterStatusLoading = useFilterStatusLoading();
  return checkIsLoading(filterStatusLoading);
};

export const useIsFilterError = () => {
  const filterStatusLoading = useFilterStatusLoading();
  return checkIsLoadingError(filterStatusLoading);
};
