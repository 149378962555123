import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "../../types";

import { ActiveModals } from '../../types';

type Payload = ActiveModals | null;

export const setActiveModals = (
  state: ApplicationState,
  { payload }: PayloadAction<Payload>
) => {
  state.activeModals = payload ? payload : [];
}