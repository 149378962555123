import { useMemo, useCallback } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { IStation } from 'dataSources/Typicode/stations/types';

import { STATE } from '../types/state';

export const stations = (state: RootState): IStation[] => {
  const { stations: { data } } = state[STATE];

  return data;
};

export const useStations = () => {
  const result = useSelector((state: RootState) => state[STATE].stations.data);
  return result;
};

export const useStationsBounds = () => {
  const stationsBounds = useSelector((state: RootState) => state[STATE].stations.fitBounds);
  return stationsBounds;
};

interface StationMap {
  [key: IStation['code']]: IStation;
}

export const useStationsMap = () => {
  const stations = useStations();

  const stationsMap = useMemo(() => stations.reduce((acc, station) => {
    acc[station.code] = station;
    return acc;
  }, {} as StationMap), [stations]);

  return stationsMap;
};

export const useGetStationByCode = () => {
  const stationsMap = useStationsMap();

  const getStationByCode = useCallback((code: IStation['code']): IStation | undefined => (
    stationsMap[code]
  ), [stationsMap]);

  return getStationByCode;
};

