import { RootState } from 'store';
import { IStationCategory } from 'common/types/common';

import { STATE } from '../types/state';

interface IResult {
  [key: string]: IStationCategory;
}

const selector = (state: RootState): IResult => {
  const { stationCategories } = state[STATE];
  return stationCategories
    .reduce((acc: IResult, item: IStationCategory) => ({
      ...acc,
      [item.code]: item
    }), {});
};

export default selector;
