import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing }) => ({
  labelPlacementStart: {
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: spacing(0.75)
  },
  iconContainer: {
    width: spacing(5)
  },
  checkboxContainer: {
    width: '100%'
  },
  checkboxRoot: {
    marginRight: -spacing(0.3)
  }
}));

export default useStyles;
