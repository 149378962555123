import { RootState } from 'store';

import { STATE } from '../types/state';
import { IFirstStep } from '../types/firstStep';

const selector = (state: RootState): IFirstStep => {
  const { orderFirstStep: { data } } = state[STATE];
  return data;
};

export default selector;
