import { MAP_NAMES  } from 'entities/application';
import { FitBounds } from 'common/types/map/fitBounds';
import config from 'config';

import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM
} from '../constants';

import {
  GoogleMapInterface,
  ContainerRef,
  MapParams,
  MapPadding,
  OnChangeBounds,
  MapEventListener,
  OnChangeMapStreetView,
  MapType,
  Zoom,
  CenterCoords,
  OnMapEventListener
} from '../types';

export class GoogleMap implements GoogleMapInterface {
  name: string;
  private _map?: google.maps.Map;
  private _containerRef: ContainerRef;

  constructor (containerRef: ContainerRef) {
    this._containerRef = containerRef;
    this.name = MAP_NAMES.google;
  }

  initMap(params?: MapParams) {
    const {
      center = DEFAULT_MAP_CENTER
    } = params || {};

    if (this._containerRef && window.google) {

      this._map = new window.google.maps.Map(
        this._containerRef,
        {
          center,
          zoom: DEFAULT_MAP_ZOOM,
          fullscreenControl: false,
          streetViewControl: true,
          mapTypeControl: false,
          zoomControl: false,
          clickableIcons: false,
          mapId: config.mapId,
          gestureHandling: 'auto',
          draggableCursor: 'inherit',
          keyboardShortcuts: false,
          streetViewControlOptions: {
            position:google.maps.ControlPosition.RIGHT_CENTER
          }
        }
      );

      window.map = this._map;
    }
  }

  getNativeMap(): google.maps.Map | undefined {
    return this._map;
  }

  fitBounds(value: FitBounds, padding?: MapPadding) {
    const latLngBounds = new google.maps.LatLngBounds(value.sw, value.ne);
    this._map?.fitBounds(latLngBounds, padding);
  }

  zoomIn() {
    if (this._map) {
      const currentZoom = this._map.getZoom() || 0;
      this._map.setZoom(currentZoom + 1);
    }
  }

  zoomOut() {
    if (this._map) {
      const currentZoom = this._map.getZoom() || 0;
      this._map.setZoom(currentZoom - 1);
    }
  }

  setZoom(value: Zoom) {
    if (this._map) {
      this._map.setZoom(value);
    }
  }

  setCenter({ lat, lng }: CenterCoords = {}) {
    if (this._map && lat && lng) {
      this._map.panTo({ lat, lng: lng });
    }
  }

  private _getTopRightPos() {
    return {
      latitude: this._map?.getBounds()?.getNorthEast().lat(),
      longitude: this._map?.getBounds()?.getNorthEast().lng()
    };
  }

  private _getBottomLeftPos() {
    return {
      latitude: this._map?.getBounds()?.getSouthWest().lat(),
      longitude:this._map?.getBounds()?.getSouthWest().lng()
    };
  }

  setMapType(mapType: MapType) {
    if (this._map) {
      this._map.setMapTypeId(mapType);
    }
  }

  addListenerDragStart(fn: OnMapEventListener): MapEventListener {
    return this._map?.addListener('dragstart', fn);
  }

  addListenerClick(fn: OnMapEventListener): MapEventListener {
    return this._map?.addListener('click', fn);
  }

  addListenerBoundsChanged(fn: OnChangeBounds): MapEventListener {
    return this._map?.addListener('bounds_changed', () => fn({
      TopRight: this._getTopRightPos(),
      BottomLeft: this._getBottomLeftPos()
    }));
  }

  addListenerStreetViewChange(fn: OnChangeMapStreetView): MapEventListener {
    return this._map?.getStreetView()?.addListener('visible_changed', () => {
      const isStreetView = !!this._map?.getStreetView()?.getVisible();
      fn(isStreetView)
    });
  }
};
