import { GoogleMapInterface } from "common/utils/Map/types";
import {
  DirectionsService,
  getRoutes
} from '../types';
import { routeFactory } from "common/utils/Route";

import mappingRoutePoint from './utils/mappingRoutePoint';
import mappingWaypoints from './utils/mappingWaypoints';
import getRouteTime from './utils/getRouteTime';
import getDistance from "./utils/getDistance";
import getBounds from './utils/getBounds';
import mappingLegs from './utils/mappingLegs';
import { getAddress } from '../utils';

class GoogleDirectionsService implements DirectionsService {
  private _map: GoogleMapInterface;
  private _service: google.maps.DirectionsService;

  constructor(map: GoogleMapInterface) {
    this._map = map;
    this._service = new google.maps.DirectionsService();
  }

  getRoutes: getRoutes = async (
    {
      firstPoint,
      lastPoint,
      waypoints
    },
    formatMessage,
    routeModifiers = {}
  ) => new Promise((resolve, reject) => {
    this._service.route(
      {
        origin: mappingRoutePoint(firstPoint),
        destination: mappingRoutePoint(lastPoint),
        waypoints: mappingWaypoints(waypoints),
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        ...routeModifiers
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result && !!result.routes.length) {
          const routes = result.routes.map((directionRoute, index) => routeFactory(
            this._map,
            {
              time: getRouteTime(directionRoute.legs, formatMessage),
              startAddress: getAddress(firstPoint),
              endAddress: getAddress(lastPoint),
              distance: getDistance(directionRoute.legs, formatMessage),
              bounds: getBounds(directionRoute.bounds),
              nativeBounds: directionRoute.bounds,
              legs: mappingLegs(directionRoute.legs, [firstPoint, ...waypoints, lastPoint]),
              overviewPath: directionRoute.overview_path.map(item => ({ lat: item.lat(), lng: item.lng() })),
              directionResult: { ...result, routes: [result.routes[index]] }
            }
          ));
          resolve(routes);
        } else {
          reject(status);
        }
      }
    )
  })
}

export default GoogleDirectionsService;
