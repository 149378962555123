import { makeStyles } from 'e100-react-components';

export const useStyles = makeStyles(() => ({
  groupedOutlinedHorizontal: {
    '& > button:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    '& > button:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }
}));
