import { useEffect } from 'react';

import { useStationsOperations } from 'store/features/stations/hooks/useOperations';

export const useInitStations = () => {
  const { initStations } = useStationsOperations();

  useEffect(() => {
    initStations(undefined, false);
  // eslint-disable-next-line
  }, []);
};
