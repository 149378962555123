import { PayloadAction } from "@reduxjs/toolkit";

import { State } from "../types/state";
import { OptionsUpdateSettings } from 'dataSources/Typicode/user/types/updateSettings';

const updateSettings = (state: State, action: PayloadAction<OptionsUpdateSettings>) => {
  state.settings.data = { ...state.settings.data, ...action.payload };
};

export default updateSettings;
