import { PayloadAction } from "@reduxjs/toolkit";
import { StatusLoading } from 'common/constants/statusLoading';

import { State } from "../types/state";

const action = (state: State, actionData: PayloadAction<StatusLoading | undefined>) => {
  state.settings.updateStatusLoading = actionData.payload;
};

export default action;
