import React from 'react';

import Icon from 'components/Icon';
import { IconedDateTimePickerProps } from '../ui';

import { useStyles } from './styles';

export const DateTimePickerIcon = ({
  icon
}: DateTimePickerIconProps) => {
  const classes = useStyles();

  if (!icon) {
    return null;
  }

  return (
    <Icon
      kind={icon}
      color={'secondary'}
      classes={{
        root: classes.icon
      }}
    />
  );
};

interface DateTimePickerIconProps extends Pick<
  IconedDateTimePickerProps,
  'icon'
> {}