import { PayloadAction } from "@reduxjs/toolkit";
import { ISelectedPlace } from '../types/selectedPlace';

import { IState } from "../types";

const setSelectedPlace = (state: IState, action: PayloadAction<ISelectedPlace>) => {
  state.selectedPlace = action.payload;
};

export default setSelectedPlace;
