import { useEffect } from 'react';

import { useMarkersContext } from '../context/markers';
import { useMapContext } from '../context/map';

const useInitMarkers = () => {
  const { map } = useMapContext();
  const { initMarkers } = useMarkersContext();

  useEffect(() => {
    initMarkers(map);
  }, [initMarkers, map]);
};

export default useInitMarkers;
