import {
  HereMapInterface,
  MapParams,
  ContainerRef,
  MapPadding,
  Zoom,
  CenterCoords,
  MapType,
  OnMapEventListener,
  MapEventListener,
  OnChangeBounds,
  OnChangeMapStreetView
} from '../types';
import { FitBounds } from 'common/types/map/fitBounds';
import config from 'config';

import { MAP_NAMES } from 'entities/application';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM
} from '../constants';

// import mapStyles from './mapStyles.json';

export class HereMap implements HereMapInterface {
  name: string;
  private _map?: Here;
  private _containerRef: ContainerRef;
  private _platform: any;
  private _tilePlatform: any;
  private _mapUI?: any;
  private _mapFeatures = "vehicle_restrictions:active_and_inactive,pois:disabled,environmental_zones:all,congestion_zones:all";
  private _pixelRatio = window.devicePixelRatio || 1;
  private _largeBreakpoints = {
    width: 4096,
    height: 2160
  };
  private _middleBreakpoints = {
    width: 1920,
    height: 1080
  };
  private _tilePPI = {
    100: 'DEFAULT',
    DEFAULT: 200,
    200: 'MIDDLE',
    MIDDLE: 200,
    400: 'LARGE',
    LARGE: 400,
  };

  constructor (containerRef: ContainerRef) {
    this._containerRef = containerRef;
    this.name = MAP_NAMES.here;
  }

  initMap(params?: MapParams) {
    const {
      center = DEFAULT_MAP_CENTER,
      lang = 'en'
    } = params || {};

    if (this._containerRef) {
      this._platform = new window.H.service.Platform({
        'apikey': config.hereAppKey,
      });

      // this._tilePlatform = window.H.Extension.tilePlatform({
      //   'apikey': config.hereAppKey,
      // });

      // const engineType = window.H.Map.EngineType['P2D'];
      // const engineType  = window.H.map.render.RenderEngine.EngineType.P2D;

      // const style = new window.H.map.render.harp.Style(mapStyles);

      // const vectorTileService = this._platform.getOMVService();
      // const vectorTileProvider = new window.H.service.omv.Provider(vectorTileService, style, {
      //   engineType,
      //   // lg: 'ru-RU',
      //   // lg2: lang,
      // });
      // const vectorTileLayer = new window.H.map.layer.TileLayer(vectorTileProvider);

      var defaultLayers = this._platform.createDefaultLayers({
        // engineType,
        lg: lang
      });

      defaultLayers.vector.normal.truck.setMin(3);

      // defaultLayers.raster.normal.map = this._getBaseLayer();

      this._map = new window.H.Map(
        this._containerRef,
        defaultLayers.vector.normal.truck,
        // defaultLayers.raster.satellite.base,
        // defaultLayers.vector.satellite.map,
        // defaultLayers.raster.normal.map,
        {
          zoom: DEFAULT_MAP_ZOOM,
          // engineType,
          // pixelRatio: 1,
          // hiRes: true,
          center
        }
      );

      window.map = this._map;

      this._resizeMap();
      this._enableMapEvents();
      this._addGrabMapStyle();
      this._initMapUi(defaultLayers);
    }
  }

  _getBaseLayer() {
    const rasterTile = this._platform.getRasterTileService({
        queryParams: {
            style: "logistics.day",
            features: this._mapFeatures,
            size: 512,
            lang: 'ru'
        }
    });
    const resterTileProvider = new window.H.service.rasterTile.Provider(rasterTile);
    return new window.H.map.layer.TileLayer(resterTileProvider);
  }

  _getSatelliteMap() {
    const rasterTileService = this._platform.getRasterTileService({
        queryParams: {
            style: 'explore.satellite.day',
            features: this._mapFeatures,
            size: this._defineTileSize(),
            lang: 'ru',
            ppi: this._defineTilePPI()
        }
    });
    const rasterTileProvider = new window.H.service.rasterTile.Provider(rasterTileService);
    const tileLayer = new window.H.map.layer.TileLayer(rasterTileProvider);
    tileLayer.tileStyle = 'explore.satellite.day';
    return tileLayer;
  }

  _defineTilePPI() {
    switch (!0) {
      case (window.screen.height > this._largeBreakpoints.height && window.screen.width > this._largeBreakpoints.width):
          return this._tilePPI.LARGE;
      case (window.screen.height > this._middleBreakpoints.height && window.screen.width > this._middleBreakpoints.height):
          return this._tilePPI.MIDDLE;
      default:
          return this._tilePPI.DEFAULT
    }
  }

  _defineTileSize() {
    return this._pixelRatio === 1 ? 256 : 512
  }

  _initMapUi(defaultLayers: any) {
    if (this._map) {
      this._mapUI = window.H.ui.UI.createDefault(this._map, defaultLayers);
      this._mapUI.removeControl('zoom');
      this._mapUI.removeControl('mapsettings');
    }
  }

  _resizeMap() {
    // Enable dynamic resizing of the map, based on the current size of the enclosing container
    window.addEventListener('resize', () => {
      if (this._map) {
        this._map.getViewPort().resize();
      }
    });
  }

  _enableMapEvents() {
    if (this._map) {
      new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(this._map));
    }
  }

  _addGrabMapStyle() {
    const mapDOMElement = document.getElementById('map');

    this._map.addEventListener('dragstart', () => {
      if (mapDOMElement) {
        mapDOMElement.style.cursor = 'grab';
      }
    });

    this._map.addEventListener('dragend', () => {
      if (mapDOMElement) {
        mapDOMElement.style.cursor = '';
      }
    });
  }

  get mapUi() {
    return this._mapUI;
  }

  getNativeMap(): Here | undefined {
    return this._map;
  }

  getPlatform(): any {
    return this._platform;
  }

  fitBounds({
    sw,
    ne
  }: FitBounds,
  padding: MapPadding = {}) {
    const { top = 0, right = 0, bottom = 0, left = 0 } = padding;
    this._map.getViewPort().setPadding(top, right, bottom, left);
    this._map.getViewModel().setLookAtData({
      bounds: new window.H.geo.Rect(sw?.lat, sw?.lng, ne?.lat, ne?.lng)
    }, 1.25);
  }

  zoomIn() {
    if (this._map) {
      const currentZoom = this._map.getZoom() || 0;
      this._map.setZoom(currentZoom + 1);
    }
  }

  zoomOut() {
    if (this._map) {
      const currentZoom = this._map.getZoom() || 0;
      this._map.setZoom(currentZoom - 1);
    }
  }

  setZoom(value: Zoom) {
    this._map?.setZoom(value);
  }

  setCenter({ lat, lng }: CenterCoords = {}) {
    this._map?.setCenter({
      lat,
      lng
    });
  }

  setMapType(mapType: MapType) {}

  addListenerDragStart(fn: OnMapEventListener): MapEventListener {
    return undefined;
  }

  addListenerClick(fn: OnMapEventListener): MapEventListener {
    return this._map.addEventListener('tap', fn);
  }

  addListenerBoundsChanged(fn: OnChangeBounds):  MapEventListener {
    return undefined;
  }

  addListenerStreetViewChange(fn: OnChangeMapStreetView): MapEventListener {
    return undefined;
  }
}