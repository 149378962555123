import { LOGIN_RESPONSE_ERROR } from 'common/constants/login';
import { CARD_NAME } from 'common/constants/card';
import { MOBILITY_NAME } from 'common/constants/mobility';

const translates = {
  "yes": "Yes",
  "no": "No",
  "ok": "Ok",
  "apply": "Apply",
  "save": "Save",
  "cancel": "Close",
  "company": "Company",
  "details": "Details",
  "name": "Name",
  "email": "E-mail",
  "phoneNumber": "Phone",
  "volume": "Volume",
  "download": "Download",
  "print": "Print",
  "downloadFile": "Download file",
  "station": "Station",
  "brand": "Brand",
  "gpsLongitude": "Longitude",
  "gpsLatitude": "Latitude",
  "gpsLongitudeAndLatitude": "Latitude, longitude",
  "stationCategory": "Station category",
  "suitableForTrucks": "Available for trucks",
  "address": "Address",
  "route": "Route",
  "prices": "Prices",
  "reset": "Reset",
  "whereFrom": "From where",
  "where": "Where",
  "noData": "No data",
  "filter": "Filter",
  "legend": "Legend",
  "exit": "Exit",
  "login": "Login",
  "password": "Password",
  "requestAccess": "Request Access",
  "enter": "Login",
  "nip": "Tax id. no. (NIP)",
  "shares": "Special offers",
  "showResult": "Show result",
  "additionalParameters": "Additional parameters",
  "acceptableValuesRange": "Allowed range of values: {range}",
  "cm": "cm.",
  "kg": "kg.",
  "default": "By default",
  "disabled": "Disabled",
  "vehicleParameters": "Vehicle parameters",
  "duty": "Toll fees",
  "timeManagement": "Time management",
  "now": "Now",
  "any": "Any",
  "departure": "Departure",
  "arrival": "Arrival",
  "timeZoneHours": "Time zone hours",
  "timeZoneMinutes": "Time zone minutes",
  "typeOfRoadsideService": "Type of roadside service",
  "offerAgreement": "Contract offer",
  "paymentInvoice": "Invoice",
  "aboutE100Card": "About E100 card",
  "orderCard": "Order a card",
  "stationActive": "Ordinary",
  "stationDiscont": "Discount+",
  "stationPM": "Power MAX",
  "stationEconom": "Economy",
  "store": "Roadside service",
  "stationSale": "Promotional refuel station",
  "cluster": "Several gas stations at",
  "stationInfo": "Service point information",
  "stationAddress": "Station address",
  "stationNotes": "Station notes",
  "priceUpdated": "Price is updated",
  "country": "Country",
  "relevantOn": "Current on",
  "axesNumber": "Number of axles",
  "grossWeight": "Vehicle gross weight",
  "emissionType": "Emission class",
  "carHeight": "Vehicle height",
  "weightPerAxle": "Weight per axle",
  "trafic": "Traffic",
  "additionalServices": "Additional services",
  "additionalServices.subtitle": "Without the use of a fuel card",
  "buildingRoute.buildRoute": "Build a route",
  "buildingRoute.routeDetails": "Route details",
  "login.toTheE100Website": "Back to site",
  "east.login.toTheE100Website": "Back to site",
  "login.logInToPersonalAccount": "Log in",
  "login.goBackToPersonalAccount": "Client Login",
  "login.orderCard": "Order a card",
  "login.account": "Account",
  "login.unauthorizedUser": "Unauthorized user",
  "print.listOfRouteServicePoints": "List of services on the route",
  "print.listOfRouteServicePointsWithPrices": "List of services on the route with prices",
  "buildingRoute.stationsFoundOnRoute": "Found stations on route",
  "buildingRoute.intermediatePoint": "Intermediate point",
  "buildingRoute.addPoint": "Add point",
  "buildingRoute.addPoint.infoMessage": "You can add a maximum of 8 intermediate points",
  "buildingRoute.error.noSuchCityOrCountry": "There is no such city or country",
  "buildingRoute.error.impossibleToBuildRoute": "Impossible to plot a route",
  "buildingRoute.error.noStartAndEndPointsSelected": "It is impossible to plot a route. Start and end points are not selected.",
  "buildingRoute.error.noStartingPointSelected": "It is impossible to plot a route. Start point are not selected.",
  "buildingRoute.error.noEndpointSelected": "It is impossible to plot a route. End point are not selected.",
  "buildingRoute.error.fuelInTankAmountMoreThantanksTotalVolume": "Fuel amount cannot exceed tank capacity",
  "filter.additionalParameters": "Additional options",
  "filter.gasStationBrand": "Gas station brand",
  "filter.findGasStationBrand": "Find gas station brand",
  "filter.findCountry": "Find a country",
  "filter.selectCountry": "Choose a country",
  "filter.findRegion": "Find Region",
  "filter.region": "Region",
  "filter.servicesAtStation": "Station services",
  "filter.serviceFormat": "Service format",
  "filter.stationCategory": "Station category",
  "filter.findTrack": "Find Track",
  "filter.track": "Track",
  "filter.gasStationFilter": "Filter of the stations",
  "filter.listSP": "List of stations",
  "filter.displayListOfSP": "Display stations list",
  "filter.displayListOfSPWithPrices": "Display stations list with prices",
  "filter.listSPWithPrices": "List of stations with prices",
  "filter.stationFilter": "Filter of the stations",
  "filter.filterResetModal.title": "Reset filter",
  "filter.filterResetModal.text": "Are you sure you want to reset the settings?",
  "filter.authorizationLink1": "To view prices, please",
  "filter.authorizationLink2": "log in",
  "stationInfo.priceUpdated": "Price updated",
  "languages.ru": "Русский",
  "languages.lt": "Lietuvių",
  "languages.en": "English",
  "languages.tr": "Türkçe",
  "languages.pl": "Polski",
  "languages.uk": "Український",
  "languages.es": "Español",
  "orderCardsModal.unitMeasureFuelValume": "l./month",
  "orderCardsModal.possibleLending": "Crediting is possible",
  "orderCardsModal.privacyPoliceDescription": "I agree to e-mail contact",
  "orderCardsModal.representativeOfLegalEntity": "I agree to be contacted via phone",
  "orderCardsModal.privacyPoliceModal.title": "Consent to be contacted by Email",
  "orderCardsModal.privacyPoliceModal.text": "I consent to the processing by E100 International Trade Sp. z o.o. my personal data in the form of an e-mail address for the purpose of sending me marketing information regarding products and services offered by E100 International Trade Sp. z o.o. by means of electronic communication, pursuant to the provisions of art. 10 sec. 1 and 2 of the Act on the provision of electronic services. Detailed rules regarding the processing of personal data and marketing consents are set out in the information clause. Before giving marketing consent, please read its content.",
  "orderCardsModal.representativeOfLegalEntityModal.title": "Consent to be contacted by phone",
  "orderCardsModal.representativeOfLegalEntityModal.text": "I consent to the processing of my personal data in the form of the telephone number provided by me by E100 International Trade Sp. z o.o. in order to conduct marketing activities with the use of telecommunications terminal equipment and automatic calling systems within the meaning of the Telecommunications Law. Detailed rules regarding the processing of personal data and marketing consents are set out in the information clause. Before giving marketing consent, please read its content.",
  "orderCardsModal.orderCard": "Order a card",
  "orderCardsModal.sendRequest": "Send a request",
  "orderCardsModal.description": "Would you like to test E100 fuel card for free? Fill out the form and we will contact you to clarify the terms of delivery.",
  "orderCardsModal.title": "Order a card for free",
  "orderCardsModal.cardCount": "Number of cards",
  "orderCardsModal.prePay": "Prepayment amount",
  "orderCardsModal.prePay.recommendedAmount": "The recommended prepayment amount is 1000 rubles.",
  "orderCardsModal.totalAmount": "Total amount",
  "orderCardsModal.finishRegistration": "Finish registration",
  "orderCardsModal.formSentSuccess": "The order has been sent",
  "orderCardsModal.ru.secondStep.title": "Ordering fuel cards and issuing a payment document",
  "orderCardsModal.ru.thirdStep.title1": "Your order has been accepted,",
  "orderCardsModal.ru.thirdStep.title2": "our manager will contact you soon.",
  "orderCardsModal.ru.thirdStep.subtitle": "Thank you for choosing E100!",
  "orderCardsModal.ru.thirdStep.numberOfCards": "Number of E100 cards",
  "orderCardsModal.ru.thirdStep.prePay": "Prepayment amount",
  "orderCardsModal.ru.thirdStep.description1": "Below you can find a commercial proposal,",
  "orderCardsModal.ru.thirdStep.description2": "a contract offer, and an invoice for payment.",
  "orderCardsModal.ru.thirdStep.conditionTitle": "draw your attention to",
  "orderCardsModal.ru.thirdStep.conditionText1": "Payment of the invoice is acceptance of the terms of the contract offer.",
  "orderCardsModal.ru.thirdStep.conditionText2": "In each invoice in the column 'purpose of payment,' it is necessary to indicate the number of your contract offer. Your contract offer number.",
  "stationSearch.title": "Search (gas station code, coordinates, address)",
  "stationInfo.availableAtGasStations": "Available at petrol stations",
  "stationInfo.aboutStation": "About station",
  "stationInfo.refillsByCard": "Refueling by fuel card",
  "stationInfo.serviceDescriptionForSpain": "* The price includes a discount from the Spanish government of 0.20 € (valid until 12/31/2022).",
  "error.fieldCannotBeEmpty": "The field cannot be empty",
  "error.onlyLatinLettersAndNumbers": "Only Latin numbers and letters",
  "error.allowedNumberCharacters": "The allowed number of characters",
  "erorr.emailNotValid": "Е-mail is not valid",
  "error.invalidCharacter": "Invalid character",
  "error.phoneNotValid": "The phone number is not valid",
  "buildingRoute.error.consumptionAwareRouting": "Incorrect data. The remaining fuel in the tank is {liters} liters.",
  [`error.${LOGIN_RESPONSE_ERROR.userOrPasswordInvalid}`]: 'Invalid data',
  [`error.${LOGIN_RESPONSE_ERROR.accessError}`]: 'Please contact your E100 regional manager',
  [`error.${LOGIN_RESPONSE_ERROR.passwordNeedsToBeReplaced}`]: 'Your password must be changed',
  [`error.${LOGIN_RESPONSE_ERROR.loginTemporarilyBlocked}`]: 'The ability to enter was blocked for 15 minutes.',
  [`error.${LOGIN_RESPONSE_ERROR.loginFromWrongTerritory}`]: 'Incorrect login credentials entered. Please call the hotline',
  "error.startAndEndDates": "The end date and time shall be later than the start date and time",
  "error.rangeStartAndEndDates": "Search range cannot exceed 7 days",
  "km": "km.",
  "loading": "Loading...",
  "filter.closeWindow.text": "Are you sure you want to close the window (the parameters will be reset)",
  "hours": "h.",
  "min": "min.",
  "24/7": "24 hours a day",
  "withoutTechnicalBreaks": "Without taking into account technical breaks",
  "workingHours": "Working hours",
  "monday": "MO",
  "tuesday": "TU",
  "wednesday": "WE",
  "thursday": "TH",
  "friday": "FR",
  "saturday": "SA",
  "sunday": "SU",
  "weekend": "Closed",
  "noWorkHours": "There are no data on working hours",
  "refuelingMode": "Refueling mode",
  "avoid": "Avoid",
  "highways": "Highways",
  "tolls": "Tolls",
  "ferries": "Ferries",
  "netto.serviceDescription": `The Net price on the ${CARD_NAME} invoice may differ depending on the local time of the station, as well as due to rounding, currency conversions, devaluation processes, and the time of arrival at the refuel stations.`,
  "includeNettoPrices": "Include Net price",
  "confirmSubmitFilterFormModal.title": "Attention",
  "confirmSubmitFilterFormModal.text1": "The stations shown may have gas stations that only support refueling through a mobile application.",
  "confirmSubmitFilterFormModal.text2": "Pay attention to the \"Refueling mode\" filter.",
  "confirmSubmitFilterFormModal.link": `Learn more about ${MOBILITY_NAME}`,
  "brutto": "Gross price",
  "netto": "Net price",
  "availableServicesAtStation": "Available services at the station",
  "copy": "Copy",
  "replicate": "Copy",
  "copied": "Copied",
  "authModal.title": "Log in",
  "authModal.info": "Dear Customer! In order to protect your account from fraudsters, the ability to enter will be blocked for 15 minutes in case you enter an incorrect login or password more than 10 times",
  "map": "Map",
  "satellite": "Satellite",
  'feedbackModal.addReview': 'Leave a review',
  'feedbackModal.attachFile': 'Attach a file',
  'feedbackModal.fileMaxSize': 'Files in the following formats can be attached PNG, JPG, PDF, MOV, MPEG. Total volume of files should not exceed 25 MB.',
  'feedbackModal.review': 'Your review',
  'feedbackModal.fileMaxSizeErr': 'The file was not added. Total size of the files exceeds {size} MB',
  'feedbackModal.contactPhone': 'Contact phone number',
  'feedbackModal.contactEmail': 'Contact email address',
  'feedbackModal.reviewInfoMsg': 'Leave feedback about the Client Portal',
  'feedbackModal.reviewInfoMsg2': 'Write your review about the work of your personal account to improve our service.',
  'feedbackModal.send': 'Send',
  'feedbackModal.cancel': 'Cancel',
  'feedbackModal.name': 'Name',
  'feedbackModal.phoneOrEmail': 'Contact email or phone number',
  "feedbackModal.fieldCannotBeEmpty": "Field can not be empty",
  "feedbackModal.invalidPhoneNumber": "Invalid phone number",
  "feedbackModal.invalidEmail": "Invalid email address",
  "feedbackModal.success": "Thank you. Your review was sent",
  "feedbackModal.error": "Something went wrong. Please try again later.",
  "somethingGoWrong": "Something went wrong",
  "e100OnlineMessage.title": "Only online refuelling is possible",
  "e100OnlineMessage.description": `Only refueling via the ${CARD_NAME} mobile app is available. Plastic fuel cards are not accepted. Learn more about online refueling:`,
  "locatorSettings": "Locator settings",
  "netPriceDisplay": "Display of NET prices",
  "autoScaling": "Autoscaling",
  "servicePointDisplayRadius": "Radius for displaying service stations on the route",
  "servicePointDisplayRadius.label": "Radius in km",
  "differentPrice.title": 'The sign "~" indicates that the cost may vary depending on the selected product or service.',
  "showCars": "Show vehicles",
  "hideCars": "Hide vehicles",
  "secureEBox": "Secure E-BOX",
  "clientCars.modal.text1": "Do you want to see the location of your vehicles in the E100 locator?",
  "clientCars.modal.text2": "Login and activate the Secure E-BOX service.",
  "clientCars.modal.text3": "Activate the Secure E-BOX service.",
  "clientCars.modal.text4": "Do you want to check your vehicles' travel thistory using the E100 locator?",
  "auto": "Vehicle",
  "card": "Card",
  "OBU": "OBU",
  "coordinates": "Coordinates",
  "updateTime": "Update time",
  "moreDetailed": "More details",
  "reports": "Reports",
  "preparationForExport": "Export preparation",
  "readyToDownload": "Ready to download",
  "hasStationLimitBeenExceeded": "The number of stations should not exceed 1000",
  "reportErrorTooltip": "An error occurred, please try again",
  "hideStations": "Hide Stations",
  "showStations": "Show Stations",
  "comment": "Comment",
  "share": "Share",
  "sharedStationLinkModal.text1": "Write a comment for the driver (optional).",
  "sharedStationLinkModal.text2": "Send the copied text to the driver in any convenient way (for example, through messaging apps).",
  "shareStationLinkMessage.text1": "Share information about the gas station",
  "shareStationLinkMessage.text2": "is here",
  "shareStationLinkMessage.text3": "If you don't have the app installed, be sure to download",
  "shareStationLinkMessage.text4": "Continue after installation",
  "messageCopied": "Text copied",
  "copyMessage": "Copy text",
  "cardsLinkedToOBU": "Cards linked to OBU",
  "showLinkedCards": "Show added cards",
  "routesHistory": "Route history",
  "autoNumber": "Vehicle registration plate",
  "showHistory": "Show history",
  "start": "Start",
  "end": "End",
  "communicationLackWithOBU": "No communication with OBU",
  "trafficStop": "Travel stop",
  "copyСoordinates": "Copy coordinates",
  "navigationHistoryStartLocationWarningMessage": "A discrepancy has been detected between the specified start date and the actual date when the first coordinate was received. <br /><br /> Perhaps, the OBU was switched off at the start time you've specified",
  "navigationHistoryEndLocationWarningMessage": "A discrepancy has been detected between the specified end date and the actual date when the last coordinate was received. <br /><br /> Perhaps, the OBU was switched off before the expiration of time you've specified",
  "consumptionAwareRouting": "Routing with consumption consideration",
  "fuelConsumptionTable": "Fuel consumption table",
  "km/h": "km/h",
  "l.": "L",
  "vehicleSpeed": "Vehicle speed",
  "сonsumption": "Consumption",
  "averageFuelConsumption": "Average fuel consumption",
  "fuelInTankAmount": "Fuel amount in tank",
  "tanksTotalVolume": "Total tank capacity",
  "fuelRemainingPercentageInTank": "Remaining fuel percentage",
  "fuelBestPrice": "Best price fuel type"
};

export default translates;
