import { useMemo } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  checkIsLoading,
  checkIsLoadingDone,
  checkIsLoadingError
} from 'common/utils/checkLoading';
import { REPORTS_STATE } from '../../constants';

import { checkIsReportBeingGenerated } from '../../utils';

const useReportsState = () => {
  const reportsState = useSelector((state: RootState) => state[REPORTS_STATE].reports);
  return reportsState;
};

export const useReportsLoadingStatus = () => {
  const { loadingStatus } = useReportsState();
  return loadingStatus;
};

export const useIsReportsLoading = () => {
  const reportsLoadingStatus = useReportsLoadingStatus();
  return checkIsLoading(reportsLoadingStatus);
};

export const useIsReportsDone = () => {
  const reportsLoadingStatus = useReportsLoadingStatus();
  return checkIsLoadingDone(reportsLoadingStatus);
};

export const useIsReportsError = () => {
  const reportsLoadingStatus = useReportsLoadingStatus();
  return checkIsLoadingError(reportsLoadingStatus);
};

export const useReports = () => {
  const { data } = useReportsState();
  return data;
};

export const useSortedReports = () => {
  const reports = useReports();

  const sortedReports = useMemo(() => (
    ([...reports || []]).sort((firstItem, secondItem) => (
      firstItem.id < secondItem.id ? 1 : -1
    ))
  ), [reports]);

  return sortedReports;
};

export const useReportsLength = () => {
  const reports = useReports();
  return reports ? reports.length : 0;
};

export const useHasGeneratingReport = () => {
  const reports = useReports();

  const hasGeneratingReport = useMemo(() => (
    !!(reports || []).find(({ state }) => checkIsReportBeingGenerated(state))
  ), [reports]);

  return hasGeneratingReport;
};
