import { createSlice } from '@reduxjs/toolkit';

import { IState, STATE } from "../types/state";

import statusLoading from './statusLoading';
import setStationInfo from './setStationInfo';
import setPricesStatusLoading from './setPricesStatusLoading';
import setStationPrices from './setStationPrices';
import setSelectedStation from './setSelectedStation';
import setSelectedPlace from './setSelectedPlace';
import resetSelectedPlace from './resetSelectedPlace';
import setSelectedCoordinates from './setSelectedCoordinates';
import setStationCodeForStationPrices from './setStationCodeForStationPrices';

const initialState: IState = {
  stationInfo: {},
  selectedStation: '',
  selectedPlace: null,
  stationPrices: {
    prices: []
  },
  selectedCoordinates: ''
};

const slice = createSlice({
  name: STATE,
  initialState,
  reducers: {
    statusLoading,
    setStationInfo,
    setPricesStatusLoading,
    setStationPrices,
    setSelectedStation,
    setSelectedPlace,
    resetSelectedPlace,
    setSelectedCoordinates,
    setStationCodeForStationPrices
  }
});

export default slice;
