import config from 'config';

import useCheckSessionByTimeout from './useCheckSessionByTimeout';
import useCheckResponseCodeFromAPI from './useCheckResponseCodeFromAPI';

const useKeepAliveSession = () => {
  useCheckSessionByTimeout(config.authCheckTimeout);
  useCheckResponseCodeFromAPI();
};

export default useKeepAliveSession;
