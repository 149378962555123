import { BaseResource } from 'e100-data-providers';
import { TRestProvider } from 'dataSources/types/restProvider';
import config from 'config';

import { IAuthToken, AuthForm } from './types/authToken';
import { IRefreshToken } from './types/refreshToken';
import { IPermissions } from './types/permissions';
import { IIdentity } from './types/identity';

class Auth extends BaseResource {
  path: string = '';

  constructor(provider: TRestProvider) {
    super(provider);
    this.path = '';
  }

  getAuthTokens(form: AuthForm): IAuthToken {
    return this.send(this.getChildResource('Authorization/Token').request.post().setBody(form));
  }

  getAuthTokensByIdentityToken(identityToken: string, clientId?: string): IAuthToken {
    return this.send(this.getChildResource('IdentityAuthorization/Token')
      .request.post().setBody({ client_id: clientId || config.authClientId, identity: identityToken }));
  }

  refreshToken(refreshToken: string): IRefreshToken {
    return this.send(this.getChildResource('Authorization/Refresh')
      .request.post().setBody({ token: refreshToken }));
  }

  getAnonymousPermissions(clientId: string): IPermissions {
    return this.getChildResource(`UserPermission/Anonymous?clientId=${clientId}`).getItems();
  }

  getPermissions(): IPermissions {
    return this.getChildResource('UserPermission').getItems();
  }

  getIdentity(): IIdentity {
    return this.send(this.getChildResource('IdentityAuthorization/Token').request.put());
  }
};

export default Auth;
