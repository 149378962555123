import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const E100Logo = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M12 1.125C14.5098 1.12437 16.9426 1.9919 18.8858 3.58044C20.8289 5.16898 22.1628 7.38074 22.6612 9.84061C23.1595 12.3005 22.7917 14.857 21.6201 17.0766C20.4485 19.2962 18.5452 21.0422 16.233 22.0185C14.7488 22.6452 13.144 22.9343 11.5344 22.8649C9.9249 22.7955 8.35087 22.3694 6.92613 21.6174C5.5014 20.8653 4.26155 19.8061 3.29622 18.5163C2.33088 17.2265 1.66417 15.7383 1.34427 14.1594C1.02437 12.5804 1.05929 10.9501 1.44649 9.38629C1.83368 7.82248 2.56349 6.36422 3.58316 5.11693C4.60283 3.86964 5.88689 2.86448 7.34251 2.17411C8.79813 1.48374 10.389 1.1254 12 1.125ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519937 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693604 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0Z" />
    <path d="M17.1592 18.1672H16.6447V5.32723C16.6448 5.25698 16.6311 5.18739 16.6042 5.12247C16.5774 5.05755 16.538 4.99855 16.4883 4.94888C16.4387 4.8992 16.3797 4.85982 16.3147 4.83298C16.2498 4.80614 16.1802 4.79238 16.11 4.79248H9.80624C9.73599 4.79238 9.66641 4.80614 9.60149 4.83298C9.53656 4.85982 9.47758 4.8992 9.4279 4.94888C9.37823 4.99855 9.33883 5.05755 9.312 5.12247C9.28516 5.18739 9.27139 5.25698 9.27148 5.32723V18.1672H8.75698C8.71103 18.1672 8.66697 18.1855 8.63448 18.218C8.60199 18.2505 8.58374 18.2945 8.58374 18.3405V18.9937C8.58374 19.0397 8.60199 19.0837 8.63448 19.1162C8.66697 19.1487 8.71103 19.167 8.75698 19.167H17.157C17.2029 19.167 17.247 19.1487 17.2795 19.1162C17.312 19.0837 17.3302 19.0397 17.3302 18.9937V18.3405C17.3304 18.3179 17.3262 18.2954 17.3177 18.2745C17.3092 18.2535 17.2966 18.2344 17.2807 18.2183C17.2648 18.2022 17.2459 18.1894 17.2251 18.1807C17.2042 18.1719 17.1818 18.1673 17.1592 18.1672ZM15.9165 11.076C15.9165 11.1219 15.8982 11.166 15.8658 11.1985C15.8333 11.231 15.7892 11.2492 15.7432 11.2492H10.1722C10.1263 11.2492 10.0822 11.231 10.0497 11.1985C10.0172 11.166 9.99898 11.1219 9.99898 11.076V5.71422C9.99898 5.66827 10.0172 5.62422 10.0497 5.59172C10.0822 5.55923 10.1263 5.54098 10.1722 5.54098H15.741C15.7638 5.54088 15.7863 5.54529 15.8074 5.55396C15.8285 5.56263 15.8476 5.57539 15.8637 5.5915C15.8798 5.6076 15.8926 5.62675 15.9013 5.64781C15.9099 5.66888 15.9143 5.69144 15.9142 5.71422V11.076H15.9165Z" />
    <path d="M5.3557 10.9777H5.2582C5.20143 10.9782 5.1453 10.9659 5.094 10.9416C5.04269 10.9173 4.99758 10.8817 4.96205 10.8374C4.92652 10.7931 4.90149 10.7414 4.88887 10.6861C4.87625 10.6307 4.87637 10.5732 4.8892 10.5179C5.0596 9.73315 5.41628 9.0009 5.92915 8.38296C6.44202 7.76501 7.09602 7.27951 7.83595 6.96744C7.91795 6.93128 8.0076 6.91591 8.09696 6.92271C8.18632 6.92952 8.27262 6.95828 8.3482 7.00644C8.4892 7.09944 8.58295 7.26594 8.3962 7.55919C8.0212 8.14194 6.42745 8.93619 6.3547 10.8502C6.37124 12.338 6.13175 13.8176 5.6467 15.2242C5.23045 16.3897 5.7307 17.3062 6.3967 17.2657C7.0627 17.2252 7.60495 16.8494 7.5217 15.7252C7.43845 14.6009 7.18945 12.3089 8.2297 11.8499C9.19495 11.4247 9.6472 11.8087 9.6472 11.8087V12.9764C9.59238 12.8914 9.51871 12.8202 9.43191 12.7683C9.34511 12.7163 9.24751 12.6851 9.14669 12.677C9.04587 12.6688 8.94454 12.684 8.85054 12.7214C8.75655 12.7588 8.67242 12.8173 8.6047 12.8924C8.0377 13.3837 8.64596 15.1844 8.47946 16.4332C8.31296 17.6819 7.56221 18.5999 6.14696 18.3082C4.73171 18.0164 4.23071 16.4737 4.64696 15.0584C5.14755 13.7576 5.38833 12.3712 5.3557 10.9777Z" />
  </SvgIcon>
);

export default E100Logo;
