import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Gas = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M16.4547 5.91098V3.8485C16.8252 3.71822 17.091 3.36824 17.091 2.95569C17.091 2.43224 16.6636 2.00781 16.1365 2.00781H15.5001H8.50013H7.86377C7.33664 2.00781 6.90922 2.43224 6.90922 2.95569C6.90922 3.36817 7.1751 3.71822 7.54559 3.8485V5.91098C5.72363 6.34013 4.36377 7.96818 4.36377 9.90678L4.36378 16.6307C4.36378 18.406 5.50392 19.9214 7.09511 20.4938C6.97882 20.6509 6.90924 20.8442 6.90924 21.0542C6.90924 21.5776 7.33665 22.002 7.86378 22.002H16.1365C16.6636 22.002 17.0911 21.5776 17.0911 21.0542C17.0911 20.8442 17.0215 20.6509 16.9052 20.4938C18.4964 19.9214 19.6365 18.406 19.6365 16.6307L19.6365 9.90678C19.6365 7.96818 18.2766 6.34013 16.4547 5.91098ZM9.45468 3.90356H14.5456V5.79931H12.8992C12.768 5.43141 12.4155 5.1674 12.0001 5.1674C11.5846 5.1674 11.2322 5.43141 11.101 5.79931H9.45468V3.90356ZM17.7274 16.6307C17.7274 17.8503 16.7283 18.8424 15.5001 18.8424H8.50015C7.27203 18.8424 6.27287 17.8503 6.27287 16.6307L6.27286 9.90678C6.27286 8.68759 7.2716 7.69548 8.49929 7.69514H15.5001C16.7279 7.69548 17.7274 8.68759 17.7274 9.90678L17.7274 16.6307Z" fill="#637282"/>
    <path d="M11.2001 8.99915C10.7758 8.99915 10.3688 9.16772 10.0687 9.46777C9.76867 9.76783 9.6001 10.1748 9.6001 10.5991V15.3991C9.6001 15.8235 9.76867 16.2305 10.0687 16.5305C10.3688 16.8306 10.7758 16.9991 11.2001 16.9991H12.8001C13.2244 16.9991 13.6314 16.8306 13.9315 16.5305C14.2315 16.2305 14.4001 15.8235 14.4001 15.3991V12.1991H12.8001V15.3991H11.2001V10.5991H14.4001V8.99915H11.2001Z" fill="#4FBC46"/>
  </SvgIcon>
);

export default Gas;
