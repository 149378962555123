import { RootState } from 'store';
import { TIsLoading } from 'common/types/common';
import {
  checkIsDoneStatus,
  checkIsLoadStatus,
  checkIsErrorStatus
} from 'common/utils/loadingStatus';

import { useSelector } from 'react-redux';

import { STATE } from '../types/state';

export const selectorAllStationsStatusLoading = (state: RootState): TIsLoading => {
  const { allStations: { isLoading } } = state[STATE];
  return isLoading;
};

export const selectAllStationsIsDone = (state: RootState): boolean => (
  checkIsDoneStatus(selectorAllStationsStatusLoading(state))
);

const useAllStationsLoadingStatus = () => {
  const allStationsLoadingStatus = useSelector((state: RootState) => state[STATE].allStations.isLoading);
  return allStationsLoadingStatus;
};

export const useIsAllStationsDone = () => {
  const allStationsLoadingStatus = useAllStationsLoadingStatus();
  return checkIsDoneStatus(allStationsLoadingStatus);
};

export const isAllStationsLoading = (state: RootState): boolean => (
  checkIsLoadStatus(selectorAllStationsStatusLoading(state))
);

export const isAllStationsError = (state: RootState): boolean => (
  checkIsErrorStatus(selectorAllStationsStatusLoading(state))
);
