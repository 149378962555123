import { makeStyles } from "e100-react-components";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingLeft: spacing(0.5),
    paddingRight: spacing(0.5),
    minWidth: spacing(7.5)
  },
  label: {
    whiteSpace: 'nowrap'
  }
}));

export default useStyles;
