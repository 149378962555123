import { PayloadAction } from "@reduxjs/toolkit";
import { IStation } from 'dataSources/Typicode/stations/types';

import { IState } from '../types/state';

const setStations = (state: IState, action: PayloadAction<IStation[]>) => {
  state.stations.data = action.payload;
}

export default setStations;
