import { TStationCode } from 'dataSources/Typicode/stations/types';

import slice from '../reducer/slice';
const { setSelectedStation } = slice.actions;

type TOperation = (selectedStation: TStationCode) => void;

const operation: TOperation = (selectedStation) => (dispatch: any) => {
  dispatch(setSelectedStation(selectedStation));
};

export default operation;
