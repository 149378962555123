import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, shape, palette, zIndex }) => ({
  container: {
    position: 'relative'
  },
  root: {
    '& input': {
      padding: spacing(1.5),
      borderBottomWidth: 0,
      backgroundColor: palette.background.default
    },
    '& > label': {
      transform: 'translate(12px, 14px) scale(1)',
      '&[data-shrink="true"]': {
        transform: 'translate(12px, -5px) scale(0.75)',
      }
    },
    '& > div': {
      borderRadius: shape.borderRadius,
      backgroundColor: 'transparent'
    },
    '& > div:after, & > div:before': {
      display: 'none'
    }
  },
  rootWithIcon: {
    '& input': {
      paddingLeft: spacing(5.8)
    },
    '& > label': {
      transform: 'translate(46px, 14px) scale(1)'
    },
  },
  iconContainer: {
    position: 'absolute',
    left: spacing(1.5),
    top: spacing(2.5),
    zIndex: zIndex.drawer
  }
}));

export default useStyles;
