import { RootState } from 'store';

import { STATE, IState } from '../types/state';

const selector = (state: RootState): IState['actionStations']['data'] => {
  const { actionStations: { data } } = state[STATE];

  return data;
};

export default selector;
