import React from 'react';
import CircularProgress from 'e100-react-components/lib/components/CircularProgress';

import useStyles from './useStyles';
import { IProps as MultiSelectProps } from '../index';

const StartAdornment = ({ children, progress }: Props) => {
  const classes = useStyles();

  if (!children && !progress) return null;

  return (
    <span className={classes.root}>
      { progress && <CircularProgress classes={{ root: classes.circularProgressRoot }} /> }
      { (!!children && !progress) && children }
    </span>
  )
}

interface Props extends Pick<MultiSelectProps, 'progress'> {
  children?: any;
}

export default StartAdornment;

