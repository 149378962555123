import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  flagIconContainer: {
    paddingLeft: spacing()
  },
  flagIcon: {
    verticalAlign: 'middle',
    width: spacing(2.5),
    marginRight: spacing(2.5),
  },
  option: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1)
  },
  text: {
    marginLeft: spacing(0.5)
  },
  textContainer: {
    flexGrow: 3
  },
  checkbox: {
    padding: 0
  },
  icon: {
    marginRight: spacing(1.5)
  }
}));

export default useStyles;
