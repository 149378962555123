import { PayloadAction } from "@reduxjs/toolkit";
import { IStation } from 'dataSources/Typicode/stations/types';

import { IState } from '../types/state';

const setActionStations = (state: IState, action: PayloadAction<IStation[]>) => {
  state.actionStations.data = action.payload;
}

export default setActionStations;
