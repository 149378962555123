import { PayloadAction } from "@reduxjs/toolkit";
import { IOrderFirstStepResult } from 'dataSources/Typicode/cards/types/orderFirstStep';

import { IState } from "../types/state";

const action = (state: IState, payloadAction: PayloadAction<IOrderFirstStepResult>) => {
  state.orderFirstStep.data = payloadAction.payload;
};

export default action;
