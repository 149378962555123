import React, { FC, useCallback, useRef, useEffect, useMemo, memo } from 'react';
import { FormHelperText } from 'e100-react-components';
import Autocomplete from 'e100-react-components/lib/components/Autocomplete';
import { IPropTypes as AutocompleteProps} from 'e100-react-components/lib/components/Autocomplete/types/props';
import { IDirectory } from 'common/types/common/directory';
import { useBoolState } from 'common/hooks';
import { useIntl } from 'react-intl';
import { Props as IconProps } from 'components/Icon';

import useStyles from './styles';
import InputIcon from './InputIcon';
import useOnKeyPress from './hooks/useOnKeyPress';

const IconedSelect: FC<IProps> = ({
  icon,
  placeholder = '',
  label = '',
  multiple = false,
  options,
  onChange,
  value,
  disabled = false,
  renderOption,
  disableClearable = false,
  classes,
  getStartAdornment,
  id,
  hideBorderBottom = false,
  error
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const internalClasses = useStyles({ hideBorderBottom });
  const [isActive, onToggleActive, setActive] = useBoolState(false);
  const defaultLabel = isActive ? placeholder : label;
  const { formatMessage } = useIntl();
  const values = value as IDirectory[];
  const currentLabel = !isActive && multiple && values.length ? '' : defaultLabel;

  const renderTagsFn = useCallback((values: string[]) => {
    if (isActive) return (<div className={internalClasses.tagsContainer}>{''}</div>)
    return (
      <div className={internalClasses.tagsContainer}>
        {
          values
            // @ts-ignore
            .reduce((acc, { value }) => `${acc}, ${value}`, '')
            .slice(1)
        }
      </div>);
  }, [isActive, internalClasses]);

  const getOptionLabel = useCallback(({ value }: { value?: string } = {}) => (value || ''), []);

  useEffect(() => {
    inputEl?.current?.blur();
  }, [value, inputEl]);

  const startAdornment = useMemo(() => (
    getStartAdornment ? getStartAdornment(isActive) : <InputIcon isActive={isActive} icon={icon} />
  ), [icon, getStartAdornment, isActive]);

  const onClose = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      inputEl?.current?.blur();
    }, 50)
  }, [setActive]);

  const changeOption = useCallback((_: any, val: any) => onChange && onChange({}, val, ''), [onChange]);

  const onKeyPress = useOnKeyPress(changeOption, options);

  return (
    <div
      className={internalClasses.field}
    >
      <Autocomplete
        forcePopupIcon={!isActive}
        loadingText={`${formatMessage({ id: 'loading' })}...`}
        onOpen={onToggleActive}
        onClose={onClose}
        disablePortal
        renderOption={renderOption}
        multiple={multiple}
        blurOnSelect
        // @ts-ignore
        renderTags={renderTagsFn}
        disabled={disabled}
        disableClearable={disableClearable}
        // @ts-ignore
        getOptionLabel={getOptionLabel}
        options={options}
        value={value}
        defaultValue={value}
        onChange={onChange}
        selectOnFocus={false}
        id={id}
        classes={{
          root: internalClasses.search,
          popperDisablePortal: internalClasses.popper,
          focused: isActive ? internalClasses.focused : '',
          paper: internalClasses.paper,
          endAdornment: internalClasses.endAdornment,
          ...classes,
        }}
        inputProps={{
          classes: { root: internalClasses.input },
          placeholder: currentLabel,
          inputRef: inputEl,
          InputProps: {
            startAdornment,
            onKeyPress: onKeyPress
          }
        }}
      />
      {
        error && (
          <FormHelperText
            classes={{
              root: internalClasses.errorMessage
            }}
            error
          >
            {error}
          </FormHelperText>
        )
      }
    </div>
  );
};

export default memo(IconedSelect);

export interface IProps {
  placeholder?: string;
  icon: IconProps['kind'];
  label: string;
  multiple?: boolean;
  options: any[];
  value?: any;
  onChange?: <T>(event: object, value: T | T[], reason: string) => void;
  disabled?: boolean;
  renderOption?: AutocompleteProps['renderOption'],
  disableClearable?: boolean,
  classes?: AutocompleteProps['classes'],
  getStartAdornment?: (isActive: boolean) => React.ReactNode;
  id?: string;
  hideBorderBottom?: boolean;
  error?: string;
};