import { createSlice } from '@reduxjs/toolkit';
import { ReportsState } from '../../types';

import { REPORTS_STATE } from '../../constants';

import { setReportsLoadingStatus } from './setReportsLoadingStatus';
import { setReports } from './setReports';

import { setReportLoadingStatus } from './setReportLoadingStatus';
import { setReportDownloadStatus } from './setReportDownloadStatus';
import { setReportGuid } from './setReportGuid';
import { resetReportGuid } from './resetReportGuid';
import { setReport } from './setReport';

const initialState: ReportsState = {
  reports: {},
  report: {}
};

export const reportsSlice = createSlice({
  name: REPORTS_STATE,
  initialState,
  reducers: {
    setReportsLoadingStatus,
    setReports,

    setReportLoadingStatus,
    setReportDownloadStatus,
    setReportGuid,
    resetReportGuid,
    setReport
  }
});

export const reportsActions = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
