import { PayloadAction } from "@reduxjs/toolkit";
import { IStation } from 'dataSources/Typicode/stations/types';

import { IState } from '../types/state';

const setAllStations = (state: IState, action: PayloadAction<IStation[]>) => {
  state.allStations.data = action.payload;
  state.allStations.dataMap = action.payload
    .reduce((acc, item) => {
      // @ts-ignore
      acc[item.code] = {
        ...item,
        roadsideServiceCodes: item.roadsideServiceCodes || []
      };
      return acc;
    }, {});
}

export default setAllStations;
