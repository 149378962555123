import { isFirefoxBrowser } from 'common/utils/checkBrowserType';

const getIcon = (clusterWeight: any) => (
  `<svg version="1.1" width="128" height="128" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	    viewBox="-10 12 128 128" style="enable-background:new -10 12 128 128;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:#4FBC46;fill-opacity:${isFirefoxBrowser ? 1 : 0.48};}
        .st1{fill:#4FBC46;stroke:#379544;stroke-width:4.4256;stroke-miterlimit:10;}
        .st2{fill:#FFFFFF;}
        .st3{font-family:'Roboto-Regular', Arial, Helvetica, sans-serif;}
        .st4{font-size:33.7403px; text-anchor: middle;}
      </style>
      <circle class="st0" cx="53.9" cy="76.2" r="62.2"/>
      <path id="XMLID_45_" class="st1" d="M36.2,33.1c5.6-2.3,11.7-3.5,17.8-3.5s12.1,1.2,17.8,3.5c5.6,2.3,10.8,5.8,15.1,10.1
        c4.3,4.3,7.7,9.4,10.1,15.1c2.3,5.6,3.5,11.7,3.5,17.8c0,12.3-4.9,24.1-13.6,32.9c-8.7,8.7-20.5,13.6-32.9,13.6
        c-6.1,0-12.1-1.2-17.8-3.5c-5.6-2.3-10.8-5.8-15.1-10.1c-8.7-8.9-13.6-20.7-13.6-33s4.9-24.1,13.6-32.9
        C25.5,38.8,30.6,35.4,36.2,33.1z"/>
      <text id="XMLID_44_" transform="matrix(1 0 0 1 52.1081 85.8653)" class="st2 st3 st4">${clusterWeight}</text>
    </svg>`
);

export default getIcon;
