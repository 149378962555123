import { TIsLoading } from 'common/types/common';

import { IFirstStep } from './firstStep';
import { ISecondStep } from './secondStep';

export const STATE = "CARDS";

export interface IState {
  orderFirstStep: {
    isLoading: TIsLoading;
    data: IFirstStep;
  },
  orderSecondStep: {
    isLoading: TIsLoading;
    data: ISecondStep;
  }
};
