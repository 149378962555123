import { useMemo } from 'react';
import useMediaQuery from 'common/hooks/useMediaQuery';

import useStyles from '../useStyles';

const useClasses = () => {
  const { isMobile } = useMediaQuery();
  const classes = useStyles({ isMobile });

  const multiSelectClasses = useMemo(() => ({
    formControl: {
      root: classes.formControlRoot
    },
    select: {
      root: classes.selectRoot,
      icon: classes.selectIcon
    },
    menuItem: {
      root: classes.menuItemRoot,
      text: { root: classes.menuItemText },
      checkboxContainer: classes.checkboxContainer
    },
    menuHeader: {
      search: { root: classes.menuHeaderSearch },
      searchIcon: { root: classes.searchIcon }
    }
  }), [classes]);

  return { classes, multiSelectClasses };
};

export default useClasses;
