
import { PayloadAction } from "@reduxjs/toolkit";

import { ReportsState } from "../../types";

export const setReportGuid = (
  state: ReportsState,
  action: PayloadAction<ReportsState['report']['guid']>
) => {
  state.report.guid = action.payload;
};
