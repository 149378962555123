import isLoadingFirstStep from './isLoadingFirstStep';
import isLoadingSecondStep from './isLoadingSecondStep';
import firstStep from './firstStep';
import secondStep from './secondStep';

const selectors = {
  isLoadingFirstStep,
  isLoadingSecondStep,
  firstStep,
  secondStep
};

export default selectors;
