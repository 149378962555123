import React from 'react';

import {
  BottomNavigationAction,
  Icon
} from 'components';
import { Grid } from 'e100-react-components';

import useStyles from './useStyles';
import {
  useButtonText,
  useTextColor
} from '../../../hooks';

import { CLIENT_EBOX_LOCATION_BUTTON_ID } from '../../../constants';

import { LocationButtonProps } from '../EBoxLocationButton';

export const EBoxLocationMobileButton = ({
  onClick
}: LocationMobileButtonProps) => {
  const buttonText = useButtonText();
  const textColor = useTextColor();
  const classes = useStyles({ textColor });

  return (
    <Grid item>
      <BottomNavigationAction
        label={buttonText}
        // @ts-ignore
        onClick={onClick}
        icon={<Icon
          kind={'auto'}
          classes={{
            root: classes.iconRoot
          }}
        />}
        showLabel
        id={CLIENT_EBOX_LOCATION_BUTTON_ID}
        classes={{ label: classes.label }}
      />
    </Grid>
  );
};

interface LocationMobileButtonProps extends Pick<
  LocationButtonProps,
  'onClick'
> {}
