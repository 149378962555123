import React from 'react';

import Typography, { IPropTypes as TypographyProps } from 'e100-react-components/lib/components/Typography';

import { Props as CheckboxListItemProps } from '../';

const Label = ({
  title,
  subtitle,
  labelColor = 'default'
}: Props) => {
  return (
    <>
      <Typography
        color={labelColor as TypographyProps['color']}
      >
          {title}
      </Typography>
      {
        subtitle && (
          <Typography
            variant={'caption'}
          >
              {subtitle}
          </Typography>
        )
      }
    </>
  );
};

interface Props extends Pick<
  CheckboxListItemProps,
  'title' | 'subtitle' | 'labelColor'
> {}

export default Label;
