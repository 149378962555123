import getSettings from './getSettings';
import updateSettings from './updateSettings';
import resetUpdateSettingsStatusLoading from './resetUpdateSettingsStatusLoading';
import getAutosCoordinates from './getAutosCoordinates';
import setAutosCoordinates from './setAutosCoordinates';
import setAutosCoordinatesStatusLoading from './setAutosCoordinatesStatusLoading';
import resetAutosCoordinates from './resetAutosCoordinates';

const operations = {
  getSettings,
  updateSettings,
  resetUpdateSettingsStatusLoading,
  getAutosCoordinates,
  setAutosCoordinates,
  setAutosCoordinatesStatusLoading,
  resetAutosCoordinates
};

export default operations;

