import getTypicode from './Typicode';
import config from 'config';

const dataSources = {
  ...getTypicode(config)
};

export type TDataSources = typeof dataSources;

export default dataSources;
