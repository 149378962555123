import { makeStyles } from 'e100-react-components';

export const useStyles = makeStyles(({ typography, spacing }) => ({
  container: {
    order: 2,
    marginTop: spacing(-1.5),
    marginBottom: spacing(3),
    '& input': {
      fontSize: typography.body1.fontSize
    }
  }
}));