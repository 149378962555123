import { useCallback } from 'react';
import { useMapContext } from '../../../context/map';

interface IResult {
  onZoomIn: () => void;
  onZoomOut: () => void;
}
type TUseControlHandlers = () => IResult;

const useControls: TUseControlHandlers = () => {
  const { map } = useMapContext();

  const onZoomIn = useCallback(() => {
    if (map) map.zoomIn();
  }, [map]);

  const onZoomOut = useCallback(() => {
    if (map) map.zoomOut();
  }, [map]);

  return { onZoomIn, onZoomOut };
};

export default useControls;
