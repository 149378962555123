import React, { useEffect } from 'react';
import { Snackbar, Grid }  from 'e100-react-components';
import { useBoolState } from 'common/hooks';
import {
  useApplicationOperations,
  useNotice
} from 'entities/application';

import useStyles from './useStyles';
import Actions from './Actions';
import useMessage from './hooks/useMessage';

const Notice = () => {
  const notice = useNotice();
  const [open, , , onHide, onVisible] = useBoolState(false);
  const { resetNotice } = useApplicationOperations();
  const classes = useStyles();

  const message = useMessage(notice);

  useEffect(() => {
    if (notice && message) onVisible();
  }, [message, onVisible, notice]);

  useEffect(() => {
    if (!open) {
      resetNotice();
    }
  }, [
    open,
    resetNotice
  ]);

  if (!open) return null;
  return (
    <Snackbar
      open={open}
      onClose={() => {
        onHide();
      }}
      classes={{
        root: classes.root
      }}
      TransitionProps={{
        onExited: () => {
          resetNotice();
        }
      }}
      message={
        <Grid container alignItems="center" justify="space-between">
          <Grid item>{message}</Grid>
          <Grid item><Actions onHide={onHide} /></Grid>
        </Grid>
      }
      autoHideDuration={2000}
    />
  );
};

export default Notice;
