import { makeStyles } from 'e100-react-components';

export const useStyles = makeStyles(({ spacing }) => ({
  buttonRoot: {
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: spacing(5)
  }
}));
