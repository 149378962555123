import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  checkIsLoading,
  checkIsLoadingDone
} from 'common/utils/checkLoading';

import { CARS_STATE } from '../../constants';

const useCarEBoxLocationsLoadingStatus = () => {
  const carsEBoxLocationsLoadingStatus = useSelector((state: RootState) => state[CARS_STATE].eBoxLocations.loadingStatus);
  return carsEBoxLocationsLoadingStatus;
};

export const useIsCarEBoxLocationsLoading = () => {
  const carEBoxLocationsLoadingStatus = useCarEBoxLocationsLoadingStatus();
  return checkIsLoading(carEBoxLocationsLoadingStatus);
};

export const useIsCarEBoxLocationsDone = () => {
  const carEBoxLocationsLoadingStatus = useCarEBoxLocationsLoadingStatus();
  return checkIsLoadingDone(carEBoxLocationsLoadingStatus);
};

export const useCarEBoxLocations = () => {
  const carEBoxLocations = useSelector((state: RootState) => state[CARS_STATE].eBoxLocations.data);
  return carEBoxLocations;
};

export const useHasCarEBoxLocations = () => {
  const carEBoxLocations = useCarEBoxLocations() || [];
  return !!carEBoxLocations.length;
};
