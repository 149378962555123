import { RootState } from 'store';
import { StatusLoading } from 'common/constants/statusLoading';
import { checkIsLoading, checkIsLoadingDone, checkIsLoadingError } from 'common/utils/checkLoading';

import { STATE } from '../types/state';

export const isLoading = (state: RootState): StatusLoading | undefined => (
  state[STATE].isLoading
);

export const isDirectoriesLoading = (state: RootState) => (
  checkIsLoading(isLoading(state))
);

export const isDirectoriesDone = (state: RootState) => (
  checkIsLoadingDone(isLoading(state))
);

export const isDirectoriesError = (state: RootState) => (
  checkIsLoadingError(isLoading(state))
);
