import { StatusLoading, STATUS_LOADING } from 'common/constants/statusLoading';

export const checkIsLoading = (statusLoading?: StatusLoading) => (
  statusLoading === STATUS_LOADING.load
);

export const checkIsLoadingDone = (statusLoading?: StatusLoading) => (
  statusLoading === STATUS_LOADING.done
);

export const checkIsLoadingError = (statusLoading?: StatusLoading) => (
  statusLoading === STATUS_LOADING.error
);