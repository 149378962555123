import { useMemo } from 'react';

import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';

import { DateTimePickerProps } from '../ui/ui';

export const useParseValue = (
  value: DateTimePickerProps['value']
) => {
  const parsedValues = useMemo(() => ({
    date: dayjs(value).format(DATE_FORMAT),
    time: dayjs(value).format(TIME_FORMAT)
  }), [value]);

  return parsedValues;
};
