import { createSlice } from '@reduxjs/toolkit';

import { CarsState } from '../../types';

import { CARS_STATE } from '../../constants';

import { setEBoxLocations } from './setEBoxLocations';
import { resetEBoxLocations } from './resetEBoxLocations';

import { setSelectedEBoxLocation } from './setSelectedEBoxLocation';

const initialState: CarsState = {
  eBoxLocations: {}
}

export const carsSlice = createSlice({
  name: CARS_STATE,
  initialState,
  reducers: {
    resetEBoxLocations,

    setSelectedEBoxLocation
  },
  extraReducers: (builder) => {
    setEBoxLocations(builder);
  }
});

export const carsActions = carsSlice.actions;
export const carsReducer = carsSlice.reducer;
