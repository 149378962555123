import { getCarEBoxLocations } from './getCarEBoxLocations';
import { resetCarEBoxLocations } from './resetCarEBoxLocations';
import { setSelectedCarEBoxLocation } from './setSelectedCarEBoxLocation';
import { resetSelectedCarEBoxLocation } from './resetSelectedCarEBoxLocation';

export const carOperations = {
  getCarEBoxLocations,
  resetCarEBoxLocations,

  setSelectedCarEBoxLocation,
  resetSelectedCarEBoxLocation
};
