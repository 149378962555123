import Superclaster from 'supercluster';

export default class Cluster extends Superclaster {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(options: any) {
    super(options);
  }

  getCluster(clusterId: string) {
    // @ts-ignore
    const originId = this._getOriginId(clusterId);
    // @ts-ignore
    const originZoom = this._getOriginZoom(clusterId);
    const errorMsg = 'No cluster with the specified id.';

    // @ts-ignore
    const index = this.trees[originZoom];
    if (!index) throw new Error(errorMsg);

    const origin = index.points[originId];
    if (!origin) throw new Error(errorMsg);

    return {
      ...origin,
      lat: yLat(origin.y),
      lng: xLng(origin.x)
    };
  }
}

const xLng = (x: number) => {
  return (x - 0.5) * 360;
}
const yLat = (y: number) => {
  const y2 = (180 - y * 360) * Math.PI / 180;
  return 360 * Math.atan(Math.exp(y2)) / Math.PI - 90;
}