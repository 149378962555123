import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import popoverRectangle from 'assests/popover-rectangle.svg';

const DECORATION_RECTANGLE_SIZE = {
  width: 29,
  height: 8
}

export const useStyles = makeStyles(({
  spacing,
  shape
}) => ({
  popoverRoot: {
    width: spacing(31.25),
    padding: spacing(1.5),
    marginTop: spacing(2),
    overflow: 'visible',
    '&:after': {
      content: "' '",
      width: DECORATION_RECTANGLE_SIZE.width,
      height: DECORATION_RECTANGLE_SIZE.height,
      background: `url(${popoverRectangle}) center center`,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: -DECORATION_RECTANGLE_SIZE.height,
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },
  dialogRoot: {
    inset: 'auto !important',
    bottom: '0 !important',
    width: '100%',
    height: 'auto'
  },
  contentRoot: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    borderTopColor: 'transparent'
  },
  titleRoot: {
    backgroundColor: 'transparent'
  },
  dialogPaper: {
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius
  }
}));
