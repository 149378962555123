import { PayloadAction } from "@reduxjs/toolkit";
import { sortByName } from 'common/utils/sorting';

import { IState } from "../types";

interface IAction extends Pick<
  IState,
  'countries' | 'roadsideService' | 'stationCategories' | 'serviceFormats' |
  'additionalParams' | 'additionalServices' | 'services' | 'countriesBrands' |
  'paymentTypes'
> {}

const initDirectories = (state: IState, action: PayloadAction<IAction>) => {
  const {
    countries, roadsideService, stationCategories, serviceFormats, additionalParams,
    services, additionalServices, countriesBrands, paymentTypes
  } = action.payload;
  state.countries = countries.sort(sortByName);
  state.roadsideService = roadsideService.sort(sortByName);
  state.stationCategories = stationCategories.sort(sortByName);
  state.serviceFormats = serviceFormats.sort(sortByName);
  state.additionalParams = additionalParams.sort(sortByName);
  state.additionalServices = additionalServices.sort(sortByName);
  state.countriesBrands = countriesBrands;
  state.services = services;
  state.paymentTypes = paymentTypes;
};

export default initDirectories;
