import makeStyles from 'e100-react-components/lib/styles/makeStyles';
import { INNER_PADDING } from 'common/constants/styles';
import { CONTROLS_Z_INDEX } from 'common/constants/styles';
interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    position: 'absolute',
    bottom: ({ isMobile }: Props) => isMobile ? spacing(8) : spacing(INNER_PADDING),
    left: spacing(INNER_PADDING),
    right: spacing(INNER_PADDING),
    zIndex: CONTROLS_Z_INDEX
  }
}));

export default useStyles;
