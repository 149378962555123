import { PayloadAction } from "@reduxjs/toolkit";

import { ReportsState } from "../../types";

export const setReportDownloadStatus = (
  state: ReportsState,
  action: PayloadAction<ReportsState['report']['downloadStatus']>
) => {
  state.report.downloadStatus = action.payload;
};
