import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const DATE_FORMAT = 'YYYY-MM-DD';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());

export const getDate = (date: string) => dayjs(date).format(DATE_FORMAT);
export const getTime = (date: string = '') =>{
  const timezoneOffset = new Date().getTimezoneOffset() * -1;
  const utcOffset = Math.round(timezoneOffset / 60);
  const utcOffsetSymbol = utcOffset < 0 ? '' : '+';
  const time = dayjs.utc(date).add(timezoneOffset, 'm').format('HH:mm');

  return `${time} (UTC ${utcOffsetSymbol}${utcOffset})`;
};

export const getLocalDate = (date: string = '') => (
  `${getDate(date)} ${getTime(date)}`
);
