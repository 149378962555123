import React, { useCallback } from 'react';
import IconButton from 'e100-react-components/lib/components/IconButton';

import useStyles from './useStyles';
import { IProps as IMultiSelectProps } from '../index';

const EndAdornment = ({ value, onChange }: IProps) => {
  const classes = useStyles();
  const onClick = useCallback(() => {
    onChange(null, []);
  }, [onChange]);

  if (!value || (Array.isArray(value) && !value.length)) return null;
  return (
    <span className={`rootButtonClear ${classes.rootIconButton}`}>
      <IconButton
        kind={'close'}
        classes={{
          button: { root: classes.iconButton },
          icon: { root: classes.icon }
        }}
        onClick={onClick}
      />
    </span>
  )
}

interface IProps extends Pick<IMultiSelectProps, 'value' | 'onChange'> {}

export default EndAdornment;
