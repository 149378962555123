const getBounds = (routeBounds: google.maps.LatLngBounds) => ({
  TopRight: {
    latitude: routeBounds.getNorthEast().lat(),
    longitude: routeBounds.getNorthEast().lng()
  },
  BottomLeft: {
    latitude: routeBounds.getSouthWest().lat(),
    longitude: routeBounds.getSouthWest().lng()
  }
});

export default getBounds;
