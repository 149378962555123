import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { TextColor } from '../../../hooks/useTextColor';

interface Props {
  textColor: TextColor;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: ({
    textColor
  }: Props) => ({
    color: textColor,
    padding: `${spacing()}px ${spacing(1.25)}px`,
    minWidth: spacing(5.5)
  }),
  iconRoot: ({ textColor }: Props) => ({
    position: 'relative',
    top: spacing(0.15),
    color: textColor,
    marginRight: 0
  })
}));

export default useStyles;