import { useCallback } from 'react';
import { useCreateClipboardMessages } from './useCreateClipboardMessages';
import { useApplicationOperations } from 'entities/application';

import * as clipboard from "clipboard-polyfill";

import { Station } from 'dataSources/Typicode/stations';

export const useSetClipboard = (
  comment: string,
  stationCode: Station['code']
) => {
  const createClipboardMessages = useCreateClipboardMessages();
  const { setSuccessNotice, setErrorNotice } = useApplicationOperations();

  const setClipboard = useCallback(async () => {
    try {
      const { textHtml, textPlain } = createClipboardMessages(comment, stationCode);
      const textHtmlBlob = new Blob([textHtml], { type: 'text/html' });
      const textPlainBlob = new Blob([textPlain], { type: 'text/plain' });

      const data = [new clipboard.ClipboardItem({
        'text/html': textHtmlBlob,
        'text/plain': textPlainBlob
      })];
      await clipboard.write(data);
      setSuccessNotice({ translateId: "messageCopied" });
    } catch (e) {
      console.error(e);
      setErrorNotice();
    }
  }, [
    comment,
    stationCode,
    setSuccessNotice,
    setErrorNotice,
    createClipboardMessages
  ]);

  return setClipboard;
};
