import { AsyncThunkPayloadCreator, AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import { getResponseError } from 'common/utils';

import { applicationActions } from 'entities/application';

export const createReduxAsyncThunk = <
  Returned,
  ThunkArg = void
> (
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, {}>
): AsyncThunk<Returned, ThunkArg, {}> => {
  return createAsyncThunk<Returned, ThunkArg, {}>(
    typePrefix,
    // @ts-ignore
    async (arg, thunkAPI) => {
      try {
        return await payloadCreator(arg, thunkAPI);
      } catch (e) {
        const error = await getResponseError(e);
        thunkAPI.dispatch(applicationActions.setErrorNotice(error));
        return thunkAPI.rejectWithValue({ error });
      }
    }
  )
}
