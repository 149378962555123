import dataSources from 'dataSources';
import { batch } from 'react-redux';
import { STATUS_LOADING } from 'common/constants';
import { getResponseError } from 'common/utils/index';
import { Country } from 'dataSources/Typicode/common/types/country';
import { RoadsideService } from 'dataSources/Typicode/common/types/roadsideService';
import { StationCategory } from 'common/types/common/stationCategory';
import { ServiceFormat } from 'common/types/common/serviceFormat';
import { DimensionLimitation } from 'dataSources/Typicode/common/types/dimensionLimitation';
import { Service } from 'dataSources/Typicode/common/types/service';
import { AdditionalService } from 'dataSources/Typicode/common/types/additionalService';
import { PaymentType } from 'dataSources/Typicode/common/types/paymentType';
import { CountriesBrands } from 'dataSources/Typicode/common/types';
import Promise from 'core-js-pure/actual/promise';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { statusLoading, initDirectories } = slice.actions;
const { setErrorNotice } = applicationActions;
interface PromiseResultItem<T> {
  value?: T
}

type PromiseResult = [
  PromiseResultItem<Country[]>,
  PromiseResultItem<RoadsideService[]>,
  PromiseResultItem<StationCategory[]>,
  PromiseResultItem<ServiceFormat[]>,
  PromiseResultItem<DimensionLimitation[]>,
  PromiseResultItem<Service[]>,
  PromiseResultItem<AdditionalService[]>,
  PromiseResultItem<CountriesBrands>,
  PromiseResultItem<PaymentType[]>
];

const operation = () => async (dispatch: any) => {
  try {
    dispatch(statusLoading(STATUS_LOADING.load));

    const [
      { value: countries = [] },
      { value: roadsideService = [] },
      { value: stationCategories = [] },
      { value: serviceFormats = [] },
      { value: additionalParams = [] },
      { value: services = [] },
      { value: additionalServices = [] },
      { value: countriesBrands },
      { value: paymentTypes = [] }
    ] = await Promise.allSettled([
      dataSources.common.getCountries(),
      dataSources.common.getRoadsideService(),
      dataSources.common.getStationCategories(),
      dataSources.common.getServiceFormats(),
      dataSources.common.getDimensionsLimitation(),
      dataSources.common.getServices(),
      dataSources.common.getAdditionalServices(),
      dataSources.common.getCountriesBrands({ countryAlpha2Codes: [] }),
      dataSources.common.getPaymentTypes()
    ]) as PromiseResult;

    batch(() => {
      dispatch(statusLoading(STATUS_LOADING.done));
      dispatch(
        initDirectories({
          countries,
          roadsideService,
          stationCategories,
          serviceFormats,
          additionalParams,
          services,
          additionalServices,
          countriesBrands,
          paymentTypes
        })
      );
    });
  } catch (e) {
    const error = await getResponseError(e);
    dispatch(setErrorNotice(error));
    dispatch(statusLoading(STATUS_LOADING.error));
  }
};

export default operation;
