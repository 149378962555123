const copyWithExecCommand = (text: string) => {
  const elem = document.createElement('textarea');
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
}

const copyWithNavigatorClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

const hasSupportNavigatorClipboardWriteText = !!navigator.clipboard.writeText;

export const copyToClipboard = async (text: string) => {
  if (hasSupportNavigatorClipboardWriteText) {
    await copyWithNavigatorClipboard(text);
  } else {
    copyWithExecCommand(text);
  }
};
