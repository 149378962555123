import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import {
  INFO_WINDOW_Z_INDEX,
  MOBILE_INFO_WINDOW_Z_INDEX
} from 'common/constants/styles';
const INFO_WINDOW_WIDTH = 310;

export const useStyles = makeStyles(({
  spacing,
  palette
}) => ({
  root: ({ isMobile }: { isMobile: boolean }) => ({
    width: INFO_WINDOW_WIDTH,
    zIndex: INFO_WINDOW_Z_INDEX,
    ...isMobile && {
      zIndex: MOBILE_INFO_WINDOW_Z_INDEX,
      width: '100%',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      left: '50%',
      bottom: spacing(-1.65),
      border: '6px solid transparent',
      borderTop: `8px solid ${palette.common.white}`,
      marginLeft: spacing(-1.25)
    }
  }),
  paperRoot: {
    padding: spacing(1.5)
  },
  background: {
    pointerEvents: "none",
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 10
  }
}));
