import { createSlice } from '@reduxjs/toolkit';

import { StationsState } from '../../types';

import { STATIONS_STATE } from '../../constants';

import { setStationsWithPrices } from './setStationsWithPrices';

const initialState: StationsState = {
  stationsWithPrices: {}
}

export const stationsSlice = createSlice({
  name: STATIONS_STATE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setStationsWithPrices(builder);
  }
});

export const stationsActions = stationsSlice.actions;
export const stationsReducer = stationsSlice.reducer;
