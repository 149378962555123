import { MAP_NAMES } from '../constants';
import { MapName } from '../types';

export const checkIsGoogleMap = (mapName?: MapName) => (
  mapName === MAP_NAMES.google
);

export const checkIsHereMap = (mapName?: MapName) => (
  mapName === MAP_NAMES.here
);
