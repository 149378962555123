import { useCallback } from 'react';

type TOption = {
  key: string,
  value: string
}

type OnChangeValue = (event: React.KeyboardEvent<HTMLInputElement>, val: any) => void;

const useOnKeyPress = (onChangeValue: OnChangeValue, defaultOptions?: TOption[]) => {
  const onSelectDefaultOption: React.KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
    // @ts-ignore
    const value = defaultOptions?.find(({ value }) => value.toLowerCase().includes(e.target.value.toString().toLowerCase()))
    if (e.key === 'Enter' && value) {
      onChangeValue(e, value);
    }
  }, [onChangeValue, defaultOptions]);

  return onSelectDefaultOption;
};

export default useOnKeyPress;
