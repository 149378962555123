import React from 'react';

import {
  HistoryLocation,
  NavigationHistoryPathType
} from '../../../types';

import { Location } from '../Location/Location';
import { LocationIcon } from './LocationIcon/LocationIcon';

import { useStyles } from './styles';

export const Path = ({
  startLocation,
  endLocation,
  type
}: PathProps) => {
  const classes = useStyles({ type });
  return (
    <div className={classes.container}>
      <Location
        {...startLocation}
        icon={<LocationIcon type={type} />}
      />
      <Location
        {...endLocation}
        icon={<LocationIcon type={type} />}
      />
    </div>
  );
};

export interface PathProps {
  startLocation: HistoryLocation;
  endLocation: HistoryLocation;
  type: NavigationHistoryPathType;
}
