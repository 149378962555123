import { PayloadAction } from "@reduxjs/toolkit";
import { ProblemDetailsError } from 'common/utils/getResponseError';
import {
  ApplicationState,
  Notice,
  NoticeType
} from "../../types";

export interface Data extends Partial<ProblemDetailsError>,
  Pick<Notice, 'text' | 'translateId'> {}

interface Payload {
  data?: Data;
  type: NoticeType;
}

export const setNotice = (
  state: ApplicationState,
  { payload }: PayloadAction<Payload>
) => {
  const { title: text = '', translateId = '', data = {} } = payload.data || {};
  state.notice = { text, translateId, data, type: payload.type };
};
