const REFRESH_TOKEN = 'refresh_token';

export const getResfreshToken = (): string => window
  .localStorage.getItem(REFRESH_TOKEN) as string || '';

export const setRefreshToken = (value: string): void => {
  window.localStorage.setItem(REFRESH_TOKEN, value);
};

export const deleteRefreshToken = () => {
  window.localStorage.removeItem(REFRESH_TOKEN);
};
