import { PayloadAction } from "@reduxjs/toolkit";
import { sortByName } from 'common/utils/sorting';

import { ApplicationState } from "../../types";

interface Action extends Pick<
  ApplicationState,
  'countries' |
  'roadsideService' |
  'stationCategories' |
  'serviceFormats' |
  'additionalParams' |
  'additionalServices' |
  'services'
> {}

export const setDirections = (state: ApplicationState, action: PayloadAction<Action>) => {
  const {
    countries, roadsideService, stationCategories, serviceFormats, additionalParams,
    services, additionalServices
  } = action.payload;
  state.countries = countries.sort(sortByName);
  state.roadsideService = roadsideService.sort(sortByName);
  state.stationCategories = stationCategories.sort(sortByName);
  state.serviceFormats = serviceFormats.sort(sortByName);
  state.additionalParams = additionalParams.sort(sortByName);
  state.additionalServices = additionalServices.sort(sortByName);
  state.services = services;
};
