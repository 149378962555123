import { TAvailableLanguageCode } from 'components/LangSelect/types/availableLanguages';
import { AppThunk } from 'store';

import * as storageLocale from 'i18n/storageLocale';

import { applicationActions } from "../reducer";

export const setLocale = (locale: TAvailableLanguageCode): AppThunk => async (dispatch) => {
  dispatch(applicationActions.setLocale(locale));
  storageLocale.setLocale(locale);
};
