import { RootState } from 'store';

import { STATE } from '../types/state';

import { TIsLoading } from '../types/isLoading';

const selector = (state: RootState): TIsLoading | undefined => {
  const { stationPrices } = state[STATE];
  return stationPrices.isLoading;
};

export default selector;
