export const getButtonElement = ({ target }: React.MouseEvent<HTMLButtonElement, React.MouseEvent<Element, globalThis.MouseEvent>>) => {
  let buttonElement = target;

  // @ts-ignore
  while (buttonElement.nodeName !== 'BUTTON') {
    // @ts-ignore
    buttonElement = buttonElement?.parentNode;
  }

 return buttonElement;
};
