import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({
  breakpoints,
  palette,
  spacing
}) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    lineHeight: 0,
    minWidth: 0,
    [breakpoints.down('sm')]: {
      padding: spacing(),
      '&:hover': {
        background: palette.background.default
      }
    }
  }
}));

export default useStyles;