import { useCallback } from 'react';

import { useMapContext } from 'components/Map/context';

export const useHandlerGoogleClickCluster = () => {
  const { setZoom, setCenter } = useMapContext();

  const handlerGoogleClickCluster = useCallback((props: any) => {
    const { layer, object, coordinate } = props;
    // @ts-ignore
    if (object && object.cluster && map && coordinate) {
      const expansionZoom = layer.state.index.getClusterExpansionZoom(object.cluster_id);
      const cluster = layer.state.index.getCluster(object.cluster_id);
      setZoom(expansionZoom + 1);
      setTimeout(() => {
        setCenter({
          lat: cluster.lat,
          lng: cluster.lng
        });
      });
    }
  }, [
    setZoom,
    setCenter
  ]);

  return handlerGoogleClickCluster;
};
