import React from 'react';
import { Button, Tooltip } from 'e100-react-components';
import { Icon } from 'components';

import useStyles from './useStyles';
import { useTextColor, useButtonText } from '../../../hooks';

import { CLIENT_EBOX_LOCATION_BUTTON_ID } from '../../../constants';

import { LocationButtonProps } from '../EBoxLocationButton';

export const EBoxLocationTabletButton = ({
  onClick
}: LocationTabletButtonProps) => {
  const textColor = useTextColor();
  const classes = useStyles({ textColor });
  const buttonText = useButtonText();

  return (
    <Tooltip title={buttonText}>
      <Button
        classes={{
          root: classes.root
        }}
        // @ts-ignore
        onClick={onClick}
        disableElevation={false}
        id={CLIENT_EBOX_LOCATION_BUTTON_ID}
      >
        <Icon
          kind="auto"
          classes={{ root: classes.iconRoot }}
        />
      </Button>
    </Tooltip>
  );
};

interface LocationTabletButtonProps extends Pick<
  LocationButtonProps,
  'onClick'
> {}
