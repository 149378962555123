import { GoogleMapInterface } from "common/utils/Map";

import { Params } from '../types/params';
import { getIcon } from '../utils/getIcon';

class GoogleWaypointMarker {
  private _map: GoogleMapInterface;
  private _marker: google.maps.Marker;

  constructor (map: GoogleMapInterface, params: Params) {
    const { name, location } = params;
    this._map = map;
    this._marker = new google.maps.Marker({
      icon: getIcon(name),
      position: location,
      clickable: false,
      map: this._map?.getNativeMap()
    });
  }

  public hide() {
    this._marker.setMap(null);
  }
}

export default GoogleWaypointMarker;
