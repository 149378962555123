import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CarsState } from '../../types';

import { getCarEBoxLocations } from '../operations/getCarEBoxLocations';

import { STATUS_LOADING } from 'common/constants';

export const setEBoxLocations = (builder: ActionReducerMapBuilder<CarsState>) => builder
  .addCase(getCarEBoxLocations.pending, (state) => {
    state.eBoxLocations.loadingStatus = STATUS_LOADING.load;
  })
  .addCase(getCarEBoxLocations.fulfilled, (state, { payload }) => {
    state.eBoxLocations.loadingStatus = STATUS_LOADING.done;
    state.eBoxLocations.data = payload;
  })
  .addCase(getCarEBoxLocations.rejected, (state) => {
    state.eBoxLocations.loadingStatus = STATUS_LOADING.error;
  });