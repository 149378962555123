import { PayloadAction } from "@reduxjs/toolkit";
import { TStationCode } from 'dataSources/Typicode/stations/types';

import { IState } from "../types";

const setSelectedStation = (state: IState, action: PayloadAction<TStationCode>) => {
  state.selectedStation = action.payload;
};

export default setSelectedStation;
