import { AppThunk } from 'store';
import { saveAs } from 'file-saver';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import { getResponseError } from 'common/utils/index';
import dataSources from 'dataSources';
import { batch } from 'react-redux';

import { applicationActions } from 'entities/application';
import { reportsActions } from '../reducer';

import { ReportGuid } from 'entities/reports/api';

const {
  setReportDownloadStatus,
  setReportGuid,
  resetReportGuid
} = reportsActions;
const { setErrorNotice } = applicationActions;

export const downloadReport = (reportGuid: ReportGuid, fileName: string): AppThunk => async (dispatch) => {
  try {
    batch(() => {
      dispatch(setReportDownloadStatus(STATUS_LOADING.load));
      dispatch(setReportGuid(reportGuid));
    });

    const report = await dataSources.reports.getReport(reportGuid);
    saveAs(report, fileName);

    dispatch(setReportDownloadStatus(STATUS_LOADING.done));
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setReportDownloadStatus(STATUS_LOADING.error));
    });
  } finally {
    dispatch(resetReportGuid());
  }
};
