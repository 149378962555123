import { AppThunk } from 'store';
import { batch } from 'react-redux';
import dataSources from 'dataSources';
import setAuthData from 'common/utils/auth/setAuthData';
import { USER_TYPES } from 'common/utils/userTypes';
import parseAuthData from 'common/utils/auth/parseAuthData';
import resetAuthData from 'common/utils/auth/resetAuthData';
import { getResponseError } from 'common/utils/index';
import addGTMEvent from 'common/utils/addGTMEvent';
import EVENT_TYPES_GTM from 'common/constants/eventTypesGTM';
import { STATUS_LOADING } from 'common/constants/statusLoading';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { setUserType, setUser, setUserLoadingStatus } = slice.actions;
const { setErrorNotice } = applicationActions;

type TOperation = (identifyToken: string, clientId?: string) => AppThunk;

const operation: TOperation = (identifyToken, clientId) => async (dispatch) => {
  try {
    dispatch(setUserLoadingStatus(STATUS_LOADING.load));

    const { data } = await dataSources.auth.getAuthTokensByIdentityToken(identifyToken, clientId);

    const authData = parseAuthData(data);
    setAuthData(authData);
    addGTMEvent(EVENT_TYPES_GTM.login, { userId: authData.clientCode, userType: authData.userType });

    batch(() => {
      dispatch(setUserLoadingStatus(STATUS_LOADING.done));
      dispatch(setUser(authData));
      dispatch(setUserType(USER_TYPES.autorized));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      resetAuthData();
      dispatch(setUserLoadingStatus(STATUS_LOADING.error));
      dispatch(setUserType(USER_TYPES.unautorized));
    });
  }
};

export default operation;