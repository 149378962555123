import makeStyles from 'e100-react-components/lib/styles/makeStyles';

export const useStyles = makeStyles(({
  breakpoints
}) => ({
  mapTypeControlContainer: {
    [breakpoints.down('sm')]: {
      order: 2
    }
  },
  stationDisplayButtonContainer: {
    [breakpoints.down('sm')]: {
      padding: `${0} !important`,
      order: 1
    }
  }
}));
