import React, { useMemo } from 'react';

import {
  HistoryLocation,
  NavigationHistoryPath
} from '../../types';

import { Location } from './Location/Location';
import { LocationTitle } from './LocationTitle/LocationTitle';
import { Typography } from 'e100-react-components';
import { Icon } from 'components';
import { Path } from './Path/Path';

import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import { useGetPathTitle } from '../../hooks';

import { checkIsTrackedLocationHistoryPath } from '../../utils';

export const NavigationHistoryPathDescription = ({
  startLocation,
  endLocation,
  navigationHistoryPath = []
}: NavigationHistoryPathDescriptionProps) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const getPathTitle = useGetPathTitle();

  const filteredNavigationHistoryPath = useMemo(() => (
    navigationHistoryPath
      .filter(({ type }) => !checkIsTrackedLocationHistoryPath(type))
  ), [navigationHistoryPath]);

  return (
    <div className={classes.container}>
      <br />
      <Typography variant="body1" gutterBottom>
        {formatMessage({ id: 'route' })}
      </Typography>
      <div className={classes.list}>
        {
          startLocation && (
            <>
              <LocationTitle padding={{ top: 2.5 }}>
                {formatMessage({ id: 'start' })}
              </LocationTitle>
              <Location
                {...startLocation}
                icon={<Icon kind={'startLocation'} color="primary" />}
                warningMessage={startLocation.isLate ? formatMessage({ id: 'navigationHistoryStartLocationWarningMessage' }) : null}
              />
            </>
          )
        }
        {
          filteredNavigationHistoryPath
            .map(({ type, locations }, index) => (
              <>
                {
                  (!filteredNavigationHistoryPath[index - 1] || type !== filteredNavigationHistoryPath[index - 1].type) && (
                    <LocationTitle>
                      {getPathTitle(type)}
                    </LocationTitle>
                  )
                }
                <Path
                  startLocation={locations[0]}
                  endLocation={locations[locations.length - 1]}
                  type={type}
                />
              </>
            ))
        }
        {
          endLocation && (
            <>
              <LocationTitle>
                {formatMessage({ id: 'end' })}
              </LocationTitle>
              <Location
                {...endLocation}
                icon={<Icon kind={'endLocation'} color="secondary" />}
                warningMessage={endLocation.isLate ? formatMessage({ id: 'navigationHistoryEndLocationWarningMessage' }) : null}
              />
            </>
          )
        }
      </div>
    </div>
  );
}

interface NavigationHistoryPathDescriptionProps {
  startLocation?: HistoryLocation | null;
  endLocation?: HistoryLocation | null;
  navigationHistoryPath?: NavigationHistoryPath[];
}