export interface ProblemDetailsError {
  data: {
    stack_trace_ui: {
      code: string,
      message: string,
      source: string,
      stack_trace: string,
      status: number,
      type: string,
    },
    exception: {
      message: string,
      stackTrace: string,
    },
    time: string,
    traceId: string
  },
  detail: string,
  instance: string,
  status: number,
  title: string,
  type: string,
  code?: string
}

const getResponseError = async (e: any): Promise< Partial<ProblemDetailsError>> => {
  try {
    return e.responseJSON || {};
  } catch(e) {
    return {};
  }
};

export default getResponseError;
