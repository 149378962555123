import { RootState } from 'store';
import { IBaseDirectory } from 'common/types/common/directory';

import { STATE } from '../types/state';

interface IResult {
  [key: string]: IBaseDirectory;
}

const selector = (state: RootState): IResult => {
  const { roadsideService } = state[STATE];
  return roadsideService.reduce((acc, item) => ({
    ...acc,
    [item.code]: item
  }), {});
};

export default selector;
