import { createSlice } from '@reduxjs/toolkit';

import { State, STATE } from "../types/state";

import setSettings from './setSettings';
import updateSettings from './updateSettings';
import setSettingsStatusLoading from './setSettingsStatusLoading';
import setUpdateSettingsStatusLoading from './setUpdateSettingsStatusLoading';

import setAutosCoordinates from './setAutosCoordinates';
import setAutosCoordinatesStatusLoading from './setAutosCoordinatesStatusLoading';
import resetAutosCoordinates from './resetAutosCoordinates';

const initialState: State = {
  settings: {},
  autosCoordinates: {}
};

const slice = createSlice({
  name: STATE,
  initialState,
  reducers: {
    setSettings,
    updateSettings,
    setSettingsStatusLoading,
    setUpdateSettingsStatusLoading,

    setAutosCoordinates,
    setAutosCoordinatesStatusLoading,
    resetAutosCoordinates
  }
});

export default slice;
