import { makeStyles } from 'e100-react-components';

import { DateTimePickerArrowIconProps } from './DateTimePickerArrowIcon';
interface StyleProps extends Pick<
  DateTimePickerArrowIconProps,
  'variant'
> {}

export const useStyles = makeStyles(({
  spacing
}) => ({
  root: ({ variant }: StyleProps) => ({
    position: 'absolute',
    right: variant === 'default' ? 0 : spacing(2),
    top: '50%',
    marginTop: spacing(-1.5)
  })
}));
