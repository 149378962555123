import getPermissions from './getPermissions';
import loginByIdentityToken from './loginByIdentityToken';
import getRefreshToken from './refreshToken';
import setUserType from './setUserType';
import getUserStateE100Mobility from './getUserStateE100Mobility';
import login from './login';

const operations = {
  loginByIdentityToken,
  getPermissions,
  getRefreshToken,
  setUserType,
  getUserStateE100Mobility,
  login
};

export default operations;

