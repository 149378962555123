import { Report } from './api';
import { REPORT_STATES } from './constants';

export const checkIsReportBeingGenerated = (state?: Report['state']) => (
  state !== REPORT_STATES.endGeneration &&
  state !== REPORT_STATES.sentReport &&
  state !== REPORT_STATES.error
);

export const checkIsReportStateError = (state?: Report['state']) => (
  state === REPORT_STATES.error
);
