import { GoogleMap } from 'common/utils/Map/GoogleMap';

import { waypointMarkerFactory as WaypointMarkerFactory } from './types/waypointMarkerFactory';
import GoogleWaypointMarker from './GoogleWaypointMarker';

const waypointMarkerFactory: WaypointMarkerFactory = (map, params) => {
  if (map instanceof GoogleMap) {
    return new GoogleWaypointMarker(map, params);
  }

  throw Error('There is no a waypoint marker for such a map');
};

export default waypointMarkerFactory;
