import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, transitions }) => ({
  rootIconButton: {
    position: 'absolute',
    right: spacing(4),
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0,
    transition: transitions.create(['opacity'])
  },
  iconButton: {
    lineHeight: 0,
    // color: palette.secondary.main
  },
  icon: {
    fontSize: '1.25rem',
    width: spacing(2.5),
    height: spacing(2.5),
    lineHeight: `${spacing(3)}px`
  }
}));

export default useStyles;
