import React from 'react';
import Grid from 'e100-react-components/lib/components/Grid';
import MapTypeControl from './MapTypeControl';

import { Props as FooterProps } from '../';
import { FooterElements } from 'components/Map/types/footerElements';
import { StationsDisplayButton } from './StationsDisplayButton/StationsDisplayButton';

import { useStyles } from './styles';

const LeftColumn = ({
  showMapTypeControl,
  leftColumnElements,
  showStationsDisplayButton
}: Props) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Grid container alignItems="center"> 
        <Grid item classes={{ root: classes.mapTypeControlContainer }}>
          <MapTypeControl showMapTypeControl={showMapTypeControl} />
        </Grid>
        {
          showStationsDisplayButton && (
            <Grid item classes={{ root: classes.stationDisplayButtonContainer }}>
              <StationsDisplayButton />
            </Grid>
          )
        }
        <Grid item>
          {leftColumnElements}
        </Grid>
      </Grid>
    </Grid>
  );
};

export interface Props extends Pick<FooterProps, 'showMapTypeControl' | 'showStationsDisplayButton'> {
  leftColumnElements: FooterElements['leftColumn'];
}

export default LeftColumn;
