import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import MainLayout from './MainLayout';
const Map = lazy(() => import('./pages/Map'));
const Report = lazy(() => import('./pages/Report'));
const RouteReport = lazy(() => import('./pages/RouteReport'));

const routeConfig = [{
  path: '/',
  element: <MainLayout />,
  children: [{
    path: 'report/:reportGuid',
    element: <Report />
  }, {
    path: 'route-report/:reportGuid',
    element: <RouteReport />
  }, {
    path: '/',
    element: <Map />
  },
  {
    path: '*',
    element: <Map />
  }]
}];

export const Routes = () => {
  // @ts-ignore
  const element = useRoutes(routeConfig);

  return (
    <>{element}</>
  );
};

export default Routes;