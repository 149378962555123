import { useEffect } from 'react';
import { getIdentityToken } from 'common/utils/getIdentityToken';
import { useOperations as useAuthOperations } from 'store/features/auth';
import deleteParamFromUrl from 'common/utils/deleteParamFromUrl';
import deleteClientIdFromUrl from 'common/utils/deleteClientIdFromUrl';
import { getResfreshToken as getLocalRefreshToken } from 'common/utils/auth/refreshToken';
import { getClientId } from 'common/utils/getClientId';
import { USER_TYPES } from 'common/utils/userTypes';

const useAuth = () => {
  const { loginByIdentityToken, getRefreshToken, setUserType } = useAuthOperations();

  useEffect(() => {
    const urlIdentityToken = getIdentityToken();
    const refreshToken = getLocalRefreshToken();
    const urlClientId = getClientId();

    if (urlIdentityToken) {
      deleteParamFromUrl('identityToken');
      deleteClientIdFromUrl();
      loginByIdentityToken(urlIdentityToken, urlClientId);
    }

    if (!urlIdentityToken && refreshToken) {
      getRefreshToken();
    }

    if (!urlIdentityToken && !refreshToken) {
      setUserType(USER_TYPES.unautorized);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAuth;
