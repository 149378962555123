import { RestProvider } from 'dataSources/types/restProvider';
import { BaseResource } from 'e100-data-providers';

import { CarEBoxLocations } from '../types';

export class CarsApi extends BaseResource {
  path: string = '';

  constructor(provider: RestProvider) {
    super(provider);
    this.path = '';
  }

  getCarEBoxLocations(): Promise<CarEBoxLocations> {
    return this.getChildResource('navigation/autosCoordinates').getItems();
  }
}