import { RootState } from 'store';
import { checkIsLoading, checkIsLoadingDone } from 'common/utils/checkLoading';

import { STATE } from '../types/state';

export const stationPrices = (state: RootState) => {
  const { stationPrices } = state[STATE];
  return stationPrices.prices;
};

export const stationPricesStatusLoading  = (state: RootState) => {
  const { stationPrices } = state[STATE];
  return stationPrices.isLoading;
}

export const isStationPricesLoading = (state: RootState) => (
  checkIsLoading(stationPricesStatusLoading(state))
);

export const isStationPricesDone = (state: RootState) => (
  checkIsLoadingDone(stationPricesStatusLoading(state))
);
