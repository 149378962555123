import createMuiTheme from 'e100-react-components/lib/theme/createMuiTheme';
import colors from 'e100-react-components/lib/styles/colors';
import defaultTheme from './defaultTheme';

const PALETTE = {
  secondary: {
    main: '#666'
  }
}

/* cyrillic */
const cyrillicRubikLightFont = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: "url(https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYiFU0U1dYPFkZVOA6w.woff2) format('woff2')",
  unicodeRange: 'U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116'
};

const cyrillicRubikRegularFont = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: "url(https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFU0U1dYPFkZVOA6w.woff2) format('woff2')",
  unicodeRange: 'U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116'
};

const cyrillicRubikMediumFont = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: "url(https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFU0U1dYPFkZVOA6w.woff2) format('woff2')",
  unicodeRange: 'U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116'
};

/* latin */
const latinRubikLightFont = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: "url(https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYiFV0U1dYPFkZVO.woff2) format('woff2')",
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const latinRubikRegularFont = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: "url(https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0U1dYPFkZVO.woff2) format('woff2')",
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const latinRubikMediumFont = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: "url(https://fonts.gstatic.com/s/rubik/v23/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFV0U1dYPFkZVO.woff2) format('woff2')",
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const FONT_FAMILY = 'Rubik, sans-serif';

const eastTheme = createMuiTheme({
  ...defaultTheme,
  typography: {
    ...defaultTheme?.typography,
    fontFamily: FONT_FAMILY,
    body1: { ...defaultTheme?.typography.body1, fontFamily: FONT_FAMILY },
    body2: { ...defaultTheme?.typography.body2, fontFamily: FONT_FAMILY },
    button: { ...defaultTheme?.typography.button, fontFamily: FONT_FAMILY },
    caption: { ...defaultTheme?.typography.caption, fontFamily: FONT_FAMILY, lineHeight: '16px' },
    h1: { ...defaultTheme?.typography.h1, fontFamily: FONT_FAMILY },
    h2: { ...defaultTheme?.typography.h2, fontFamily: FONT_FAMILY },
    h3: { ...defaultTheme?.typography.h3, fontFamily: FONT_FAMILY },
    h4: { ...defaultTheme?.typography.h4, fontFamily: FONT_FAMILY },
    h5: { ...defaultTheme?.typography.h5, fontFamily: FONT_FAMILY },
    h6: { ...defaultTheme?.typography.h6, fontFamily: FONT_FAMILY },
    overline: { ...defaultTheme?.typography.overline, fontFamily: FONT_FAMILY },
    subtitle1: { ...defaultTheme?.typography.subtitle1, fontFamily: FONT_FAMILY },
    subtitle2: { ...defaultTheme?.typography.subtitle2, fontFamily: FONT_FAMILY },
  },
  palette: {
    primary: {
      ...defaultTheme.palette.primary,
      main: '#52AE32',
      light: '#F4FCF0'
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      main: PALETTE.secondary.main
    },
    action: {
      ...defaultTheme.palette.action,
      disabled: '#D1D1D1'
    },
    text: {
      ...defaultTheme.palette.text,
      primary: '#3D3D3D',
      secondary: '#ABABAB'
    },
    warning: {
      ...defaultTheme.palette.warning,
      main: colors.amber['A700'],
      light: colors.orange[50]
    }
  },
  overrides: {
    ...defaultTheme.overrides,
    MuiCheckbox: {
      ...defaultTheme.overrides?.MuiCheckbox,
      root: {
        color: PALETTE.secondary.main
      }
    },
    MuiRadio: {
      ...defaultTheme.overrides?.MuiRadio,
      root: {
        ...defaultTheme.overrides?.MuiRadio?.root,
        color: PALETTE.secondary.main
      }
    },
    MuiCssBaseline: {
      ...defaultTheme.overrides?.MuiCssBaseline,
      '@global': {
        ...defaultTheme.overrides?.MuiCssBaseline?.['@global'],
        '@font-face': [
          // @ts-ignore
          cyrillicRubikLightFont, cyrillicRubikRegularFont, cyrillicRubikMediumFont,
          // @ts-ignore
          latinRubikLightFont, latinRubikRegularFont, latinRubikMediumFont
        ]
      }
    }
  }
});

export default eastTheme;
