import { BaseResource } from 'e100-data-providers';
import { TRestProvider } from 'dataSources/types/restProvider';

import {
  IOrderFirstStepOptions,
  IOrderFirstStepResult,
  IOrderSecondStepOptions,
  IOrderSecondStepResult
} from './types';

class Cards extends BaseResource {
  constructor(provider: TRestProvider) {
    super(provider);
    this.path = 'cards';
  }

  orderFirstStep(data: IOrderFirstStepOptions): Promise<IOrderFirstStepResult> {
    return this.send(this.getChildResource('order').request.put().setBody(data));
  }

  orderSecondStep(data: IOrderSecondStepOptions): Promise<IOrderSecondStepResult> {
    return this.send(this.getChildResource('order').request.post().setBody(data));
  }
};

export default Cards;

