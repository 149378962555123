import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IPropTypes } from 'e100-react-components/lib/components/Icon/index';
import { isEastTerritory } from 'common/utils/checkTerritory';
import {
  AddBlue, Agent, AllFuel, Cluster, Currency, DieselFuel, DirectStart, E100Logo, Road, Roads, Parking, StationActive, Gas,
  Guard, Pos, Tire, Trace, Petrol, ActiveTune, EnvelopePicture, Car, Truck, BigAuto, ShowerOutlined, MobilePayment, Vector,
  Action, E1CardLogo, TruckWash, Auto, AutoOutlined, PointDisable, HistoryCar, StartLocation, EndLocation, LocationPoint
} from './Items';
export interface ISvgIconMap {
  [key: string]: typeof SvgIcon;
}

export const SVG_ICON_MAP = {
  activeTune     : ActiveTune,
  addBlue        : AddBlue,
  agent          : Agent,
  allFuel        : AllFuel,
  bigAuto        : BigAuto,
  car            : Car,
  cluster        : Cluster,
  currency       : Currency,
  dieselFuel     : DieselFuel,
  directStart    : DirectStart,
  gas            : Gas,
  guard          : Guard,
  parking        : Parking,
  petrol         : Petrol,
  pos            : Pos,
  road           : Road,
  roads          : Roads,
  stationActive  : StationActive,
  tire           : Tire,
  trace          : Trace,
  truck          : Truck,
  envelopePicture: EnvelopePicture,
  showerOutlined : ShowerOutlined,
  vector         : Vector,
  mobilePayment  : MobilePayment,
  action         : Action,
  e100Logo       : E100Logo,
  truckWash      : TruckWash,
  auto           : Auto,
  autoOutlined   : AutoOutlined,
  pointDisable   : PointDisable,
  historyCar     : HistoryCar,
  startLocation  : StartLocation,
  endLocation    : EndLocation,
  locationPoint  : LocationPoint,
  ...isEastTerritory && { e1CardLogo : E1CardLogo }
} as const;

export type Kind = IPropTypes['kind'] | keyof typeof SVG_ICON_MAP;

export const getSvgIcon = (kind?: string) => (
  // @ts-ignore
  SVG_ICON_MAP[kind || '']
);