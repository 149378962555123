import React, { createContext, useContext, useMemo, useState } from 'react';

import { Context } from './types/context';

interface Props {
  children?: React.ReactNode;
}

export const ActiveMarkersContext = createContext({} as Context);
export const useActiveMarkersContext = () => useContext(ActiveMarkersContext);

const ActiveMarkersProvider = ({ children }: Props) => {
  const [ hoveredMarker, setHoveredMarker ] = useState<Context['hoveredMarker']>(null);

  const value = useMemo(() => ({
    hoveredMarker,
    setHoveredMarker
  }), [
    hoveredMarker,
    setHoveredMarker
  ]);

  return (
    <ActiveMarkersContext.Provider value={value}>{children}</ActiveMarkersContext.Provider>
  );
};

export default ActiveMarkersProvider;
