import { useEffect, useRef } from 'react';
import mask from 'common/constants/phonesMasks';
import IMask from 'imask';

const usePhoneMask = (inputRef: React.RefObject<HTMLInputElement>, onChange: (a: any) => void) => {
  const maskEl = useRef<IMask.InputMask<any>>();

  useEffect(() => {
    const maskOptions = {
      mask,
      dispatch: function (appended: any, dynamicMasked: any) {
        var number = (dynamicMasked.value + appended).replace(/\D/g,'');

        return dynamicMasked.compiledMasks.find(function (m: any) {
          return number.indexOf(m.startsWith) === 0;
        });
      }
    };

    if (inputRef?.current) {
      maskEl.current = IMask(inputRef.current, maskOptions);
      maskEl.current.on("accept", () => {
        onChange({
          target: {
            value: maskEl?.current?.value,
            name: 'phoneNumber',
          }
        })
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  useEffect(() => () => {
    if (maskEl.current) {
      maskEl.current.destroy();
    }
  }, []);

};

export default usePhoneMask;

