import React from 'react';
import { Outlet } from "react-router-dom";

const MainLayout = () => (
  <>
    <Outlet />
  </>
);

export default MainLayout;
