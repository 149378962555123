import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Cluster = (props: IProps) => (
  <SvgIcon {...props}>
    <path id="XMLID_2_" style={{ opacity:0.24 }} d="M7.4,0.9C8.9,0.3,10.4,0,12,0s3.1,0.3,4.6,0.9
      c1.5,0.6,2.8,1.5,3.9,2.6s2,2.4,2.6,3.9c0.6,1.5,0.9,3,0.9,4.6c0,3.2-1.3,6.2-3.5,8.5C18.2,22.7,15.2,24,12,24
      c-1.6,0-3.1-0.3-4.6-0.9c-1.5-0.6-2.8-1.5-3.9-2.6C1.3,18.2,0,15.2,0,12s1.3-6.2,3.5-8.5C4.6,2.4,6,1.5,7.4,0.9z"/>
    <path id="XMLID_1_" d="M8.6,3.7C9.6,3.2,10.8,3,12,3s2.4,0.2,3.4,0.7c1.1,0.5,2.1,1.1,2.9,2
      c0.8,0.8,1.5,1.8,2,2.9C20.8,9.6,21,10.8,21,12c0,2.4-0.9,4.7-2.6,6.4c-1.7,1.7-4,2.6-6.4,2.6c-1.2,0-2.4-0.2-3.4-0.7
      c-1.1-0.5-2.1-1.1-2.9-2C3.9,16.7,3,14.4,3,12c0-2.4,0.9-4.7,2.6-6.4C6.5,4.8,7.5,4.1,8.6,3.7z"/>
  </SvgIcon>
);

export default Cluster;
