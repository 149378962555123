import { useCallback } from 'react';

import { Map } from 'common/utils/Map/types/map';

type Position = [number, number];

const DESKTOP_VERSION_WIDTH = 720; // px


export const useGoogleInfoWindow = (map: Map | null) => {

  const googleInfoWindow = useCallback((
    coordinates: Position,
    infoWindowRef: HTMLDivElement | null
  ) => {
    if (coordinates && infoWindowRef && map) {
      class Popup extends google.maps.OverlayView {
        position: google.maps.LatLng;
        containerDiv: HTMLDivElement;
      
        constructor(position: Position, content: HTMLDivElement) {
          super();
          this.position = new google.maps.LatLng(position[0], position[1]);
          this.containerDiv = content;
      
          // Optionally stop clicks, etc., from bubbling up to the map.
          Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
        }
      
        /** Called when the popup is added to the map. */
        onAdd() {
        }
      
        /** Called when the popup is removed from the map. */
        onRemove() {
        }
      
        /** Called each frame when the popup needs to draw itself. */
        draw() {
          const divPosition = this.getProjection().fromLatLngToContainerPixel(
            this.position
          )!;
      
          // Hide the popup when it is far out of view.
          const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
              ? "block"
              : "none";
      
          if (display === "block") {
            this.containerDiv.style.position = 'fixed';

            if (window.screen.width >= DESKTOP_VERSION_WIDTH) {
              this.containerDiv.style.left = divPosition.x + "px";
              this.containerDiv.style.top = divPosition.y + "px";
              this.containerDiv.style.bottom = 'inherit';
              this.containerDiv.style.transform = 'translate(-50%, calc(-100% - 30px))';
            } else {
              this.containerDiv.style.left = 0 + "px";
              this.containerDiv.style.bottom = 0 + "px";
              this.containerDiv.style.top = 'inherit';
            }
          }
      
          if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
          }
        }
      }

      const popup = new Popup(coordinates, infoWindowRef);
      popup.setMap(map.getNativeMap() || null);
      return popup;
    }
  }, [map]);

  return googleInfoWindow;
};
