import { MAP_TYPES, MapType } from 'common/utils/Map/constants';

export const checkIsRoadmapMapType = (mapType: MapType) => (
  mapType === MAP_TYPES.ROADMAP
);

export const checkIsHybridMapType = (mapType: MapType) => (
  mapType === MAP_TYPES.HYBRID
);

