import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Parking = (props: IProps) => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16 5H8C6.34457 5 5 6.34457 5 8V16C5 17.6554 6.34457 19 8 19H16C17.6554 19 19 17.6554 19 16V8C19 6.34457 17.6554 5 16 5ZM16 3H8C5.24 3 3 5.24 3 8V16C3 18.76 5.24 21 8 21H16C18.76 21 21 18.76 21 16V8C21 5.24 18.76 3 16 3Z"/>
    <path d="M9 7.5238V17.5238H11V13.5238H13C13.5304 13.5238 14.0391 13.3131 14.4142 12.938C14.7893 12.5629 15 12.0542 15 11.5238V9.5238C15 8.99337 14.7893 8.48466 14.4142 8.10959C14.0391 7.73452 13.5304 7.5238 13 7.5238H9ZM11 9.5238H13V11.5238H11V9.5238Z" />
  </SvgIcon>
);

export default Parking;
