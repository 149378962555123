import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(() => ({
  active: {
    filter: 'blur(3px)',
    select: 'none'
  }
}));

export default useStyles;
