import {
  useEffect,
  useRef,
  useCallback,
  MutableRefObject
} from 'react';

import { useGoogleInfoWindow } from './useGoogleInfoWindow';

import { isGoogleMap } from 'common/constants';

import {
  MapInfoWindowProps,
  RootElementRef
} from '../ui/MapInfoWindow/MapInfoWindow';

import { MAP_INFO_WINDOW_ID } from '../constants';

interface Props extends Pick<
  MapInfoWindowProps,
  'coordinates' | 'markers' | 'map'
> {
  rootRef: MutableRefObject<RootElementRef>;
}

interface Handlers {
  onClose: MapInfoWindowProps['onClose']
}

export const useUpdateInfoWindow = (
  {
    map,
    markers,
    coordinates,
    rootRef
  }: Props,
  {
    onClose
  }: Handlers
) => {
  const infoWindowRef = useRef<any | null>(null);
  const googleInfoWindow = useGoogleInfoWindow(map);

  const deleteInfoWindow = useCallback(() => {
    if (infoWindowRef.current) {
      infoWindowRef.current.setMap(null);
      infoWindowRef.current = null;
    }
  }, [infoWindowRef]);

  useEffect(() => {
    if (infoWindowRef.current) {
      infoWindowRef.current.setMap(null);
    }
    if (coordinates && map && rootRef.current && isGoogleMap(map.name)) {
      infoWindowRef.current = googleInfoWindow(coordinates, rootRef.current);
    }
  // eslint-disable-next-line
  }, [
    coordinates,
    map,
    infoWindowRef,
    googleInfoWindow,
    rootRef
  ]);

  useEffect(() => {
    const clickHandler = ({ object, ...otherProps }: any) => {
      if (!object || (object && object.cluster)) {
        deleteInfoWindow();

        if (onClose) {
          onClose();
        }
      }
    }

    if (markers) {
      markers.addClickHandler(clickHandler)
    }
    

    return () => {
      if (markers) {
        markers.deleteClickHandler(clickHandler);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handlerDocumentClick = (event: MouseEvent) => {
      let targetElement = event.target;
      const popupElement = document.getElementById(MAP_INFO_WINDOW_ID);
  
      do {
        if (targetElement === popupElement) {
          return;
        }
  
        // @ts-ignore
        targetElement = (targetElement || {}).parentNode;
      } while (targetElement);
    
      deleteInfoWindow();
      if(onClose) onClose();
    }

    // @ts-ignore
    window.document.addEventListener('click', handlerDocumentClick);

    return () => {
      // @ts-ignore
      window.document.removeEventListener('click', handlerDocumentClick);
    };
    // eslint-disable-next-line
  }, []);
};
