import { useCallback } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { StationCode } from 'dataSources/Typicode/stations/types';
import { Station } from 'dataSources/Typicode/stations/types/station';

import { STATE, IState } from '../types/state';

export const allStationsMap = (state: RootState): IState['allStations']['dataMap'] => {
  const { allStations: { dataMap } } = state[STATE];
  return dataMap;
};

export const useAllStationsMap = () => {
  const { dataMap } = useSelector((state: RootState) => state[STATE].allStations);
  return dataMap;
};

export const useGetStationByCode = () => {
  const allStations = useSelector(allStationsMap);

  const getStationByCode = useCallback(
    (stationCode?: StationCode) => allStations[stationCode || ''],
    [allStations]
  );

  return getStationByCode;
};

export const useGetStationsByCodes = () => {
  const getStationByCode = useGetStationByCode();

  const getStationsByCodes = useCallback((stationCodes?: StationCode[]) => {
    if (!stationCodes) {
      return [];
    }

    return stationCodes.reduce((acc, stationCode) => {
      const station = getStationByCode(stationCode);
      if (station) {
        acc.push(station);
      }
      return acc;
    }, [] as Station[]);
  }, [getStationByCode]);

  return getStationsByCodes;
}

export const useMappingStationsByCodes = (stationCodes?: StationCode[]) => {
  const getStationsByCodes = useGetStationsByCodes();
  
  return getStationsByCodes(stationCodes);
};

export const useStationByCode = (stationCode?: StationCode) => {
  const getStationByCode = useGetStationByCode();
  return getStationByCode(stationCode);
};
