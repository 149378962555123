import {
  checkIsGoogleMap,
  checkIsHereMap,
  MapName
} from 'entities/application';

import { GoogleMap } from './GoogleMap';
import { HereMap } from './HereMap';
import { ContainerRef, Map } from './types';

export const mapFactory = (mapName: MapName, containerRef: ContainerRef): Map => {
  if (checkIsGoogleMap(mapName)) {
    return new GoogleMap(containerRef);
  }

  if (checkIsHereMap(mapName)) {
    return new HereMap(containerRef);
  }

  throw Error('There is no such a map');
};
