import { PayloadAction } from "@reduxjs/toolkit";
import { StatusLoading } from 'common/constants/statusLoading';
import { UserStateE100Mobility } from 'dataSources/Typicode/common/types/userStateE100Mobility';

import { IState } from "../types/state";

export const setUserStateE100Mobility = (state: IState, action: PayloadAction<UserStateE100Mobility>) => {
  state.userStateE100Mobility.data = action.payload;
}

export const setUserStateE100MobilityStatusLoading = (state: IState, action: PayloadAction<StatusLoading>) => {
  state.userStateE100Mobility.statusLoading = action.payload;
}
