import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Agent = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M18.72 14.76C19.07 13.91 19.26 13 19.26 12C19.26 11.28 19.15 10.59 18.96 9.94998C18.31 10.1 17.63 10.18 16.92 10.18C13.86 10.18 11.15 8.66998 9.5 6.33998C8.61 8.49998 6.91 10.26 4.77 11.22C4.73 11.47 4.73 11.74 4.73 12C4.73 13.9281 5.49594 15.7773 6.85933 17.1406C8.22272 18.504 10.0719 19.27 12 19.27C13.05 19.27 14.06 19.04 14.97 18.63C15.54 19.72 15.8 20.26 15.78 20.26C14.14 20.81 12.87 21.08 12 21.08C9.58 21.08 7.27 20.13 5.57 18.42C4.53 17.38 3.76 16.11 3.33 14.73H2V10.18H3.09C3.93 6.03998 7.6 2.91998 12 2.91998C14.4 2.91998 16.71 3.86998 18.42 5.57998C19.69 6.83998 20.54 8.44998 20.89 10.18H22V14.67V14.69V14.73H21.94L18.38 18L13.08 17.4V15.73H17.91L18.72 14.76ZM9.27 11.77C9.57 11.77 9.86 11.89 10.07 12.11C10.28 12.32 10.4 12.61 10.4 12.91C10.4 13.21 10.28 13.5 10.07 13.71C9.86 13.92 9.57 14.04 9.27 14.04C8.64 14.04 8.13 13.54 8.13 12.91C8.13 12.28 8.64 11.77 9.27 11.77ZM14.72 11.77C15.35 11.77 15.85 12.28 15.85 12.91C15.85 13.54 15.35 14.04 14.72 14.04C14.09 14.04 13.58 13.54 13.58 12.91C13.58 12.6076 13.7001 12.3177 13.9139 12.1039C14.1277 11.8901 14.4177 11.77 14.72 11.77Z" />
  </SvgIcon>
);

export default Agent;
