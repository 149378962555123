import { useState, useCallback, useEffect } from 'react';
import isMobileDevice from 'common/utils/isMobileDevice';

const useControlsStyles = () => {
  const [controlsStyles, setControlsStyles] = useState({});

  const getGmnoprintElement = useCallback(() => (
    document.querySelector('.gmnoprint.gm-bundled-control .gm-svpc')
  ), []);

  const getGmnoprintTopPosition = useCallback(() => {
    const element = getGmnoprintElement();
    return element ? element.getBoundingClientRect().top : 0;
  }, [getGmnoprintElement]);

  const onUpdateControslStyles = useCallback(() => {
    const topPosition = getGmnoprintTopPosition();
    setControlsStyles({
      top: topPosition ? topPosition + 90 : '50%'
    });
  }, [setControlsStyles, getGmnoprintTopPosition]);

  useEffect(() => {
    var observer = new MutationObserver(function(){
      if (getGmnoprintElement() && (getGmnoprintTopPosition() > 50)) {
        onUpdateControslStyles();
        observer.disconnect();
      }
    });
    
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMobileDevice) {
      window.addEventListener("touchstart", () => {
        onUpdateControslStyles();
      });
      window.addEventListener("touchend", () => {
        onUpdateControslStyles();
      });
    }

    window.addEventListener('resize', () => {
      setTimeout(() => {
        onUpdateControslStyles();
      }, 500);
    });
  // eslint-disable-next-line
  }, []);

  return controlsStyles;
};

export default useControlsStyles;
