import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const BigAuto = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M3,4h14v4h3l3,4v5h-2c0,0.8-0.3,1.6-0.9,2.1C19.6,19.7,18.8,20,18,20s-1.6-0.3-2.1-0.9
      C15.3,18.6,15,17.8,15,17H9c0,0.8-0.3,1.6-0.9,2.1S6.8,20,6,20s-1.6-0.3-2.1-0.9S3,17.8,3,17H1V6C1,4.9,1.9,4,3,4z M17,9.5V12h4.5
      l-2-2.5H17z M6,15.5c-0.4,0-0.8,0.2-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1s0.2,0.8,0.4,1.1s0.7,0.4,1.1,0.4s0.8-0.2,1.1-0.4
      s0.4-0.7,0.4-1.1s-0.2-0.8-0.4-1.1C6.8,15.7,6.4,15.5,6,15.5z M18,15.5c-0.4,0-0.8,0.2-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1
      s0.2,0.8,0.4,1.1s0.7,0.4,1.1,0.4s0.8-0.2,1.1-0.4s0.4-0.7,0.4-1.1s-0.2-0.8-0.4-1.1C18.8,15.7,18.4,15.5,18,15.5z"/>
  </SvgIcon>
);

export default BigAuto;
