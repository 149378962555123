import { checkIsCluster } from 'components/Map/utils/checkIconType';
import { CAR_CLUSTER_HEIGHT, CAR_HEIGHT } from 'components/Map/constants/icons';

export const getCarIconSize = (d: any) => {
  if (checkIsCluster(d)) {
    return CAR_CLUSTER_HEIGHT;
  }

  return CAR_HEIGHT;
};
