import React from 'react';
import Button from 'e100-react-components/lib/components/Button';
import classNames from 'classnames';

import Icon, { Props as IconProps } from '../Icon';
import { IPropTypes as IPropsButton } from 'e100-react-components/lib/components/Button';

import useStyles from './useStyles';

const IconButton = (props: IProps) => {
  const { kind, onClick, color, id, classes = {}, component, href } = props;
  const innerClasses = useStyles();

  return (
    <Button
      onClick={onClick}
      color={color}
      id={id}
      classes={{
        root: classNames({
          [innerClasses.button]: true,
          [classes.root || '']: true
        }),
        endIcon: classNames({
          [innerClasses.buttonEndIcon]: true,
          [classes.endIcon || '']: true
        })
      }}
      endIcon={<Icon kind={kind} classes={{ root: innerClasses.icon }}/>}
      component={component}
      href={href}
      disableElevation={false}
    />
  );
};

interface IClasses {
  root?: string;
  endIcon?: string;
};
interface IProps extends Pick<
  IPropsButton,
  'onClick' | 'color' | 'component' | 'href'
> {
  kind: IconProps['kind'];
  id?: string;
  classes?: IClasses;
}

export default IconButton;
