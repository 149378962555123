import { PayloadAction } from "@reduxjs/toolkit";
import { TUserPermissions } from 'dataSources/Typicode/auth/types/permissions';

import { IState } from "../types/state";

type TAction = TUserPermissions;

const setPermissions = (state: IState, action: PayloadAction<TAction>) => {
    state.permissions.data = action.payload;
}

export default setPermissions;
