import { useMemo } from 'react';
import { Notice } from 'entities/application';
import { DEFAULT_ERROR_MESSAGE } from 'common/constants';
import { useIntl } from 'react-intl';

const useMessage = (data: Notice | null) => {
  const { formatMessage } = useIntl();

  const message = useMemo(() => {
    if (data && data.translateId) return formatMessage({ id: data?.translateId });
    if (data && data.text) return data.text;
    return DEFAULT_ERROR_MESSAGE;
  }, [formatMessage, data]);

  return message;
};

export default useMessage;
