import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => {
  return ({
    container: {
      position: 'relative'
    },
    root: {
      '& input': {
        paddingTop: spacing(1.5),
        paddingBottom: spacing(1.5),
        color: palette.text.primary
      },
      '& > div': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderColor: palette.grey[300]
      },
      '& fieldset': {
        borderColor: `${palette.grey[300]} !important`,
        borderBottomWidth: 0
      }
    },
    sliderRoot: {
      position: 'absolute',
      top: spacing(3),
      left: '0'
    },
    markLabel: {
      transform: 'translateX(0)',
      '&[data-index="1"]': {
        left: 'auto !important',
        right: 0
      }
    }
  });
});

export default useStyles;
