import { AnyAction } from "redux";

import slice from "../reducer/slice";
import { IStationFilter } from '../types/stationFilter';

type TOperation = (filter: IStationFilter) => AnyAction;

const operation: TOperation = filter => slice.actions.setFilter(filter);

export default operation;
