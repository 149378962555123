import { lineString } from '@turf/helpers';
import turfBbox from '@turf/bbox';

import { isEastTerritory } from 'common/utils';

const BOUNDS_PADDING = 8;

const EAST_POINT_COORDINATES = {
  lat: 56.3268684,
  lng: 43.9261664
};

export const defaultEastBounds = [
  [EAST_POINT_COORDINATES.lng, EAST_POINT_COORDINATES.lat],
  [EAST_POINT_COORDINATES.lng + BOUNDS_PADDING, EAST_POINT_COORDINATES.lat + BOUNDS_PADDING],
  [EAST_POINT_COORDINATES.lng - BOUNDS_PADDING, EAST_POINT_COORDINATES.lat - BOUNDS_PADDING],
];

const EAST_BBOX = turfBbox(lineString(defaultEastBounds));

export const defaultWestBounds = [
  [-5.6854854, 38.3286307],
  [36.8251381, 55.5815245]
];

const WEST_BBOX = turfBbox(lineString(defaultWestBounds));

export const defaultBounds = isEastTerritory ? defaultEastBounds : defaultWestBounds;
export const defaultBBox = isEastTerritory ? EAST_BBOX : WEST_BBOX;
