import React, { ReactNode } from 'react';
import Typography from 'e100-react-components/lib/components/Typography';
import Grid from 'e100-react-components/lib/components/Grid';

import useStyles from './useStyles';

export const Title = ({
  title,
  marginTop = 0,
  marginBottom = 1.5,
  actions
}: Props) => {
  const classes = useStyles({ marginTop, marginBottom });
  return (
    <Grid container justify={!!actions ? "space-between" : "flex-start"}>
      <Grid item>
        <Typography
          variant="subtitle2"
          classes={{
            root: classes.title
          }}
        >
          {title}
        </Typography>
      </Grid>
      {
        actions && (
          <Grid item>
            {actions}
          </Grid>
        )
      }
    </Grid>
    
  );
};

interface Props {
  title: any;
  marginTop?: number;
  marginBottom?: number;
  actions?: ReactNode;
};

export default Title;
