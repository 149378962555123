import { Map } from 'common/utils/Map';
import { GoogleMap } from 'common/utils/Map/GoogleMap';
import { PolylineOptions } from '../../types';

import { checkIsGoogleMap } from 'common/utils/Map/utils/checkMapType';

import GooglePolyline from './GooglePolyline/GooglePolyline';

export const polylineFactory = (map: Map, options?: PolylineOptions) => {
  if (checkIsGoogleMap(map.name) && map instanceof GoogleMap) {
    return new GooglePolyline(map, options);
  }

  throw Error('There is no a polyline for such a map');
};
