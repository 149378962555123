import dataSources from 'dataSources';
import { AppThunk } from 'store';
import { batch } from 'react-redux';
import{ STATUS_LOADING } from 'common/constants';
import { getResponseError } from 'common/utils/index';
import { OptionsUpdateSettings } from 'dataSources/Typicode/user/types/updateSettings';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { updateSettings, setUpdateSettingsStatusLoading } = slice.actions;
const { setErrorNotice } = applicationActions;

const operation = (form: OptionsUpdateSettings): AppThunk => async (dispatch: any) => {
  try {
    dispatch(setUpdateSettingsStatusLoading(STATUS_LOADING.load));

    await dataSources.user.updateSettings(form);

    batch(() => {
      dispatch(setUpdateSettingsStatusLoading(STATUS_LOADING.done));
      dispatch(updateSettings(form));
    });
  } catch (e) {
    const error = await getResponseError(e);
    dispatch(setErrorNotice(error));
    dispatch(setUpdateSettingsStatusLoading(STATUS_LOADING.error));
  }
};

export default operation;
