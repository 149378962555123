import { createSlice } from '@reduxjs/toolkit';

import { STATE } from "../types/state";

import setFirstStep from './setFirstStep';
import setIsLoadingFirstStep from './setIsLoadingFirstStep';

import setIsLoadingSecondStep from './setIsLoadingSecondStep';
import setSecondStep from './setSecondStep';

import resetState from './resetState';

import { initialState } from './initialState';

const slice = createSlice({
  name: STATE,
  initialState,
  reducers: {
    setFirstStep,
    setIsLoadingFirstStep,
    
    setSecondStep,
    setIsLoadingSecondStep,

    resetState
  }
});

export default slice;
