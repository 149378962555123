import { RootState } from 'store';
import { TIsLoading } from 'common/types/common';
import { StatusLoading } from 'common/constants';

import { STATE } from '../types/state';

export const initStationsLoadingStatus = (state: RootState): TIsLoading => (
  state[STATE].allStations.isLoading
);

export const isInitStationsLoading = (state: RootState) => (
  initStationsLoadingStatus(state) === StatusLoading.Load
);

export const isInitStationsDone = (state: RootState) => (
  initStationsLoadingStatus(state) === StatusLoading.Done
);

export const isInitStationsError = (state: RootState) => (
  initStationsLoadingStatus(state) === StatusLoading.Error
);
