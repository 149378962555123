import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  isAutorizedUser as checkIsAutorizedUser,
  isUnautorizedUser as checkIsUnautorizedUser,
  isUnknownUser as checkIsUnknownUser
} from 'common/utils/userTypes';

import { STATE } from '../types/state';

export const useUserType = () => {
  const userType = useSelector((rootState: RootState) => rootState[STATE].userType);
  return userType;
};

export const useIsAutorizedUser = () => {
  const userType = useUserType();
  return checkIsAutorizedUser(userType);
};

export const useIsUnautorizedUser = () => {
  const userType = useUserType();
  return checkIsUnautorizedUser(userType);
};

export const useIsUnknownUser = () => {
  const userType = useUserType();
  return checkIsUnknownUser(userType);
};
