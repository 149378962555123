import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  checkIsLoading,
  checkIsLoadingDone,
  checkIsLoadingError
} from 'common/utils/checkLoading';

import { STATE } from '../types/state';

const seletorAutosCoordinatesStatusLoading = (state: RootState) => {
  const { autosCoordinates: { statusLoading} } = state[STATE];
  return statusLoading;
};

const useAutosCoordinatesStatusLoading = () => {
  const autosCoordinatesStatusLoading = useSelector(seletorAutosCoordinatesStatusLoading);
  return autosCoordinatesStatusLoading;
};

export const useIsAutosCoordinatesDone = () => {
  const autosCoordinatesStatusLoading = useAutosCoordinatesStatusLoading();
  return checkIsLoadingDone(autosCoordinatesStatusLoading);
};

export const useIsAutosCoordinatesLoading = () => {
  const autosCoordinatesStatusLoading = useAutosCoordinatesStatusLoading();
  return checkIsLoading(autosCoordinatesStatusLoading);
};

export const useIsAutosCoordinatesError = () => {
  const autosCoordinatesStatusLoading = useAutosCoordinatesStatusLoading();
  return checkIsLoadingError(autosCoordinatesStatusLoading);
};

const selectorAutosCoordinates = (state: RootState) => {
  const { autosCoordinates: { data } } = state[STATE];
  return data;
};

export const useAutosCoordinates = () => {
  const autosCoordinates = useSelector(selectorAutosCoordinates);
  return autosCoordinates;
};

export const useAutosCoordinatesCount = () => {
  const autosCoordinates = useAutosCoordinates();
  return (autosCoordinates || []).length;
};

export const useHasAutosCoordinates = () => {
  const autosCoordinatesCount = useAutosCoordinatesCount();
  return !!autosCoordinatesCount;
};
