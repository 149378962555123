import React from 'react';

import Icon from 'components/Icon';

import { IconedDateTimePickerProps } from '../ui';

import { useStyles } from './styles';

export const DateTimePickerArrowIcon = ({
  variant
}: DateTimePickerArrowIconProps) => {
  const innerClasses = useStyles({ variant });
  return (
    <Icon
      kind={'arrowDropDown'}
      color={'secondary'}
      classes={{
        root: innerClasses.root
      }}
    />
  );
};

export interface DateTimePickerArrowIconProps extends Pick<
  IconedDateTimePickerProps,
  'variant'
> {}