import { StatusLoading } from 'common/constants';

import { IState } from "../types/state";

export const initialState: IState = {
  orderFirstStep: {
    isLoading: StatusLoading.Default,
    data: {}
  },
  orderSecondStep: {
    isLoading: StatusLoading.Default,
    data: {}
  }
};
