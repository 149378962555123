import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const AllFuel = (props: IProps) => (
  <SvgIcon {...props}>
    	<path d="M12,21.7c-2,0-4.1-0.8-5.7-2.3l0,0l0,0C4.8,17.8,4,15.8,4,13.7C4,11.5,4.8,9.5,6.3,8L12,2.3
				L17.7,8c1.5,1.5,2.3,3.5,2.3,5.7c0,2.1-0.8,4.1-2.3,5.7C16.1,20.9,14,21.7,12,21.7z M12,5.2L7.8,9.4C6.6,10.5,6,12.1,6,13.7
				c0,1.6,0.6,3.1,1.8,4.2l0,0c2.3,2.3,6.1,2.3,8.5,0c1.1-1.1,1.8-2.6,1.8-4.2c0-1.6-0.6-3.1-1.8-4.2L12,5.2z"/>
  </SvgIcon>
);

export default AllFuel;
