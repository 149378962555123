import React from 'react';

import { useIntl } from 'react-intl';
import { useSelectedCarEBoxLocation } from 'entities/cars';

import CopyingTextToClipboard from 'components/CopyingTextToClipboard';
import { Typography, Grid } from 'e100-react-components';

const AutoNumber = () => {
  const { formatMessage } = useIntl();
  const { autoNumber } = useSelectedCarEBoxLocation() || {};

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item>
          <Typography variant={'h6'} gutterBottom>
            {formatMessage({ id: 'auto' })}:{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h6'} gutterBottom>
            <CopyingTextToClipboard>
              {autoNumber}
            </CopyingTextToClipboard>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AutoNumber;
