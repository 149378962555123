const AUTH_TOKEN_EXPIRY_TIME = 'auth_token_expiry_time';

export const getExpiryTime = (): number => Number(window.localStorage
  .getItem(AUTH_TOKEN_EXPIRY_TIME));

export const setExpiryTime = (value: number) => {
  window.localStorage.setItem(AUTH_TOKEN_EXPIRY_TIME, value.toString());
};

export const isExpiryTime = (authCheckTimeout: number) => (new Date().getTime() + authCheckTimeout
  > getExpiryTime());

export const deleteExpiryTime = () => {
  window.localStorage.removeItem(AUTH_TOKEN_EXPIRY_TIME);
}
