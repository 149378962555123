import { ROADSIDE_SERVICES_CODES } from 'common/constants/index';

import { Station } from 'dataSources/Typicode/stations/types';

export const checkIsStation = (roadsideServiceCode: number) => (
  roadsideServiceCode === ROADSIDE_SERVICES_CODES.gasStation
);

const ROADSIDE_SERVICE_ORDER = [ 1, 2, 4, 11, 12, 3, 9, 5, 10, 6, 7, 8 ];

export const getFirstByOrderRoadsideServiceCode = (
  roadsideServiceCodes: Station['roadsideServiceCodes']
) => {
  const roadsideServiceCodesCopy = [...roadsideServiceCodes];

  const sortedRoadsideServiceCodes = ROADSIDE_SERVICE_ORDER.reduce((acc, roadsideServiceCode) => {
    const foundRoadsideServiceIndex = roadsideServiceCodesCopy.findIndex(item => item === roadsideServiceCode);

    if (foundRoadsideServiceIndex >= 0) {
      acc.push(roadsideServiceCodesCopy[foundRoadsideServiceIndex]);
      roadsideServiceCodesCopy.splice(foundRoadsideServiceIndex, 1);
    }

    return acc;
  }, [] as Station['roadsideServiceCodes']);

  return [...sortedRoadsideServiceCodes, ...roadsideServiceCodesCopy][0];
};

