import { useEffect } from 'react';
import { useStationsOperations } from './useStationsOperations';
import { useIsAutorizedUser } from 'store/features/auth';

export const useInitStationsWithPrices = () => {
  const { getStationsWithPrices } = useStationsOperations();
  const isAutorizedUser = useIsAutorizedUser();

  useEffect(() => {
    if (isAutorizedUser) {
      getStationsWithPrices();
    }
  }, [
    isAutorizedUser,
    getStationsWithPrices
  ]);
};
