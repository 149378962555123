import { PayloadAction } from "@reduxjs/toolkit";

import { ReportsState } from "../../types";

export const setReports = (
  state: ReportsState,
  action: PayloadAction<ReportsState['reports']['data']>
) => {
  state.reports.data = action.payload;
};
