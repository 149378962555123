import { makeStyles } from 'e100-react-components';

export const useStyles = makeStyles(({
  spacing,
  palette
}) => ({
  container: {
    paddingLeft: spacing(2)
  },
  list: {
    paddingLeft: spacing(5),
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: spacing(1.5),
      top: 0,
      bottom: 0,
      borderLeft: `1px solid ${palette.divider}`,
      zIndex: -2
    }
  }
}));
