import { useMemo } from 'react';

import { Props as MapProps } from '../index';

const useMapStyles = (styles: MapProps['styles']) => {
  const mapStyles = useMemo(() => ({
    width: '100%',
    height: '100%',
    ...styles
  }), [styles]);

  return mapStyles;
};

export default useMapStyles;
