import React, { RefObject } from 'react';

import { useActiveMarkersContext } from '../context/activeMarkers';
import useStyles from './useStyles';
// import useOffsetContainer from './hooks/useOffsetContainer';

const Tooltip = (_: Props) => {
  const { hoveredMarker } = useActiveMarkersContext();
  const classes = useStyles();
  // const offsetContainerPosition = useOffsetContainer(containerRef);

  if (!hoveredMarker || !hoveredMarker.station.code) return null;

  return (
    <span
      className={classes.container}
      style={{
        // left: (hoveredMarker.viewportCoords.x || 0) + offsetContainerPosition.left,
        // top: (hoveredMarker.viewportCoords.y || 0) + offsetContainerPosition.top
        left: hoveredMarker.viewportCoords.x || 0,
        top: hoveredMarker.viewportCoords.y || 0
      }}
    >
      {hoveredMarker.station.code}
    </span>
  )
};

interface Props {
  containerRef: RefObject<HTMLDivElement>;
}

export default Tooltip;
