import { useState, useCallback, ChangeEvent, Dispatch, SetStateAction } from 'react';

export type SelectValue = string;
export type SetSelectValue = Dispatch<SetStateAction<string>>;
export type HandleChangeSelectValue = (event: SelectEvent) => void;
type SelectEvent = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;


export const useSelectState = (initValue: SelectValue = '') => {
  const [value, setValue] = useState<SelectValue>(initValue);

  const handleChangeValue: HandleChangeSelectValue = useCallback(({ target: { value: newValue } }: SelectEvent) => {
      setValue(newValue as string);
    },
    [setValue],
  );

  return [value, handleChangeValue, setValue] as const;
};
