import React from 'react';

import Popover, {
  IPropTypes as PopoverProps
} from 'e100-react-components/lib/components/Popover';
import { ModalDialog } from 'e100-react-components';
import { Main } from './Main/Main';

import { useStyles } from './styles';
import { useMediaQuery } from 'common/hooks';

export const SecureEBoxPopover = ({
  id,
  open = false,
  anchorEl,
  onClose,
  children,
  buttonId
}: SecureEBoxPopoverProps) => {
  const classes = useStyles();
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return (
      <ModalDialog
        open={open}
        showCloseButton
        disableEnforceFocus
        onClose={onClose}
        fullScreen
        title={' '}
        classes={{
          title: {
            root: classes.titleRoot
          },
          dialog: {
            root: classes.dialogRoot,
            paper: classes.dialogPaper
          },
          content: {
            root: classes.contentRoot
          }
        }}
      >
        <Main buttonId={buttonId}>
          {children}
        </Main>
      </ModalDialog>
    );
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: classes.popoverRoot,
      }}
    >
      <Main buttonId={buttonId}>
        {children}
      </Main>
    </Popover>
  );
};

export interface SecureEBoxPopoverProps extends Pick<
  PopoverProps,
  'onClose'
> {
  id?: string;
  open?: boolean;
  anchorEl?: Element;
  children?: React.ReactNode;
  buttonId?: string;
}
