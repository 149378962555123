import { AppThunk } from 'store';
import { batch } from 'react-redux';
import dataSources from 'dataSources';
import{ StatusLoading } from 'common/constants';
import { getResponseError } from 'common/utils/index';
import { IOrderSecondStepOptions } from 'dataSources/Typicode/cards/types/orderSecondStep';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { setIsLoadingSecondStep, setSecondStep } = slice.actions;
const { setErrorNotice } = applicationActions;

type TOperation = (data: IOrderSecondStepOptions) => AppThunk;

const operation: TOperation = (data) => async (dispatch: any) => {
  try {
    dispatch(setIsLoadingSecondStep(StatusLoading.Load));

    const result = await dataSources.cards.orderSecondStep(data);

    batch(() => {
      dispatch(setIsLoadingSecondStep(StatusLoading.Done));
      dispatch(setSecondStep({...result, cardCount: data.cardCount, prePay: data.prePay }));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setIsLoadingSecondStep(StatusLoading.Error));
    });
  }
};

export default operation;
