import { Leg } from 'common/utils/Route/types/leg';
import { RoutePoint } from '../../types/routePoints';
import { IntlFormatters } from '@formatjs/intl';

const getLegName = (index: number): string => (
  index === 0 ? 'startPoint' : `waypoint${index}`
);

export const mappingLegs = (
  sections: any[],
  allWaypoints: RoutePoint[],
  formatMessage: IntlFormatters['formatMessage']
) => sections
  .reduce((acc: Leg[], item, index) => {

    const {
      departure: {
        place: {
          location: departureLocation
        }
      },
      arrival: {
        place: {
          location: arrivalLocation
        }
      },
      travelSummary: {
        length
      }
    } = item;
    const { value: address = '' } = allWaypoints[index];

    acc.push({
      address,
      location: departureLocation,
      distance: `${length / 1000} ${formatMessage({ id: 'km' })}`,
      name: getLegName(index)
    });

    if (index === sections.length - 1) {
      const { value: endAddress = '' } = allWaypoints[index + 1];
      acc.push({
        address: endAddress,
        location: arrivalLocation,
        distance: '',
        name: 'endPoint'
      });
    }

    return acc;
  }, [] as Leg[]);
