import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Pos = (props: IProps) => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H13C13.5304 21 14.0391 20.7893 14.4142 20.4142C14.7893 20.0391 15 19.5304 15 19V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3ZM5 9V5H13V9H5ZM5 13V11H7V13H5ZM8 13V11H10V13H8ZM11 13V11H13V13H11ZM5 16V14H7V16H5ZM8 16V14H10V16H8ZM11 16V14H13V16H11ZM11 19V17H13V19H11ZM8 19V17H10V19H8ZM5 19V17H7V19H5ZM17.2409 18.9412C16.9621 19.4242 16.5045 19.7396 16 19.8521V17.2642L20.367 9.70031L19.5386 9.22201L16 15.351V11.5247L17.8817 8.26542L16 7.179V5.01465C16.1205 5.05484 16.2387 5.10768 16.353 5.17366L21.3236 8.04344C22.2387 8.57175 22.5522 9.74184 22.0239 10.6569L17.2409 18.9412Z" />
  </SvgIcon>
);

export default Pos;
