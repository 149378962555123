import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Currency = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M12,2c1.3,0,2.6,0.3,3.8,0.8C17,3.3,18.1,4,19.1,4.9c0.9,0.9,1.7,2,2.2,3.2C21.7,9.4,22,10.7,22,12
      c0,2.7-1.1,5.2-2.9,7.1C17.2,20.9,14.7,22,12,22c-1.3,0-2.6-0.3-3.8-0.8C7,20.7,5.9,20,4.9,19.1C3.1,17.2,2,14.7,2,12
      c0-2.7,1.1-5.2,2.9-7.1C6.8,3.1,9.3,2,12,2z M12,4C9.9,4,7.8,4.8,6.3,6.3C4.8,7.8,4,9.9,4,12c0,2.1,0.8,4.2,2.3,5.7
      C7.8,19.2,9.9,20,12,20c2.1,0,4.2-0.8,5.7-2.3c1.5-1.5,2.3-3.5,2.3-5.7c0-2.1-0.8-4.2-2.3-5.7C16.2,4.8,14.1,4,12,4z M11,17v-1H9v-2
      h4v-1h-3c-0.3,0-0.5-0.1-0.7-0.3C9.1,12.5,9,12.3,9,12V9c0-0.3,0.1-0.5,0.3-0.7C9.5,8.1,9.7,8,10,8h1V7h2v1h2v2h-4v1h3
      c0.3,0,0.5,0.1,0.7,0.3S15,11.7,15,12v3c0,0.3-0.1,0.5-0.3,0.7S14.3,16,14,16h-1v1H11z"/>
  </SvgIcon>
);

export default Currency;
