import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "../../types";

import { ActiveModal } from '../../types';

export const deleteActiveModal = (
  state: ApplicationState,
  { payload }: PayloadAction<ActiveModal>
) => {
  state.activeModals = (state.activeModals || []).filter(activeModal => activeModal !== payload);
}