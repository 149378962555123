import useBindActionCreators from 'common/hooks/useBindActionCreators';

import operations from '../operations';

export const useStationsOperations = (): typeof operations => {
  const bindedActions = useBindActionCreators(operations);
  return bindedActions;
};

export default useStationsOperations;
