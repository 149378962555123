import { AppThunk } from 'store';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import { getResponseError } from 'common/utils/index';
import dataSources from 'dataSources';
import { batch } from 'react-redux';

import { applicationActions } from 'entities/application';
import { reportsActions } from '../reducer';

const { 
  setReportsLoadingStatus,
  setReports
} = reportsActions;
const { setErrorNotice } = applicationActions;

export const getReports = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setReportsLoadingStatus(STATUS_LOADING.load));

    const { reports } = await dataSources.reports.getReports();

    batch(() => {
      dispatch(setReportsLoadingStatus(STATUS_LOADING.done));
      dispatch(setReports(reports));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setReportsLoadingStatus(STATUS_LOADING.error));
    });
  }
};
