import { RootState } from 'store';
import { checkIsLoading, checkIsLoadingDone, checkIsLoadingError } from 'common/utils/checkLoading';

import { STATE } from '../types/state';

export const userStateE100MobilityStatusLoading = (state: RootState) => (
  state[STATE].userStateE100Mobility.statusLoading
);

export const userStateE100MobilityIsLoading = (state: RootState) => (
  checkIsLoading(userStateE100MobilityStatusLoading(state))
);

export const userStateE100MobilityIsLoadingDone = (state: RootState) => (
  checkIsLoadingDone(userStateE100MobilityStatusLoading(state))
);

export const userStateE100MobilityIsLoadingError = (state: RootState) => (
  checkIsLoadingError(userStateE100MobilityStatusLoading(state))
);

export const userStateE100Mobility = (state: RootState) => {
  const { data } = state[STATE].userStateE100Mobility;
  return data ? data.isEnabled : data;
};
