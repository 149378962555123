import { useMemo, useCallback } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { IService, Service } from 'dataSources/Typicode/common/types';
import { WEST_CARD_NAME, CARD_NAME } from 'common/constants/card';

import { STATE } from '../types/state';

import serviceParentIds from './serviceParentIds';

const FUEL_CODE = 163;

export const services = (state: RootState): IService[] => {
  const { services } = state[STATE];
  return services;
};

export const useServices = () => {
  const services = useSelector((state: RootState) => state[STATE].services);

  const result = useMemo(() => {
    const getItem = (item: IService): IService => ({
      ...item,
      children: (item.children || []).map(childrenItem => getItem(childrenItem)),
      name: item.name.replace(WEST_CARD_NAME, CARD_NAME)
    });

    return services.map(item => getItem(item))
  }, [services]);

  return result;
};

type GetParentServiceCodesByCode = (code: string) => string[] | undefined;

export const useGetParentServiceCodesByCode = () => {
  const serviceParentIdsMap = useSelector(serviceParentIds);

  const getParentServiceCodesByCode: GetParentServiceCodesByCode = useCallback((code) => {
    const serviceParentIdMap = serviceParentIdsMap[code];
    return serviceParentIdMap ? Object.keys(serviceParentIdMap) as string[] : serviceParentIdMap;
  }, [serviceParentIdsMap]);

  return getParentServiceCodesByCode;
};

export const useSortedServices = () => {
  const services = useServices();

  const servicesOrder= useMemo(() => ([{
    code: 163, // Fuel
    children: [{
      code: 162, // disel
    }, {
      code: 40, // AdBlue
    }, {
      code: 30, // Petrol
    }, {
      code: 80, // Gas
    }]
  }] as any as Service[]), []);

  const sortedServices = useMemo(() => {
    const sortedArray = (serves: Service[], servOrder: Service[]) => {
      const result = serves.reduce((acc, service) => {
        const serviceIndex = servOrder.findIndex(item => item.code === service.code);

        if (serviceIndex > -1) {
          const { children, ...otherServiceProps } = service;

          acc.sortedServices[serviceIndex] = {
            ...otherServiceProps,
            ...children && {
              children: sortedArray(children, servOrder[serviceIndex].children || [])
            }
          };
        } else {
          acc.unsortedServices.push(service);
        }

        return acc;
      }, {
        sortedServices: [] as Service[],
        unsortedServices: [] as Service[]
      });

      return [...result.sortedServices, ...result.unsortedServices];
    }

    return sortedArray(services, servicesOrder);
  }, [
    services,
    servicesOrder
  ]);

  return sortedServices;
};

export const useFlatTreeSortedServices = () => {
  const sortedServices = useSortedServices();

  const flatTreeSortedServices = useMemo(() => {
    const getSubservices = (subservices: Service[]) => subservices
      .reduce((acc, service) => {
        const { children, ...otherServiceProps } = service;
        acc.push(otherServiceProps);
        if (service.children) {
          acc.push(...getSubservices(service.children))
        }
        return acc;
      }, [] as Service[]);

    return getSubservices(sortedServices);
  }, [
    sortedServices
  ]);

  return  flatTreeSortedServices;
};

export const useSortServices = () => {
  const flatTreeSortedServices = useFlatTreeSortedServices();

  const sortServices = useCallback(<Item extends { code: string }>(items: Item[]) => (
    flatTreeSortedServices.reduce((acc, flatTreeSortedService) => {
      const foundItem = items.find(item => item.code === flatTreeSortedService.code);
  
      if (foundItem) {
        acc.push(foundItem);
      }

      return acc;
    }, [] as Item[])
  ), [
    flatTreeSortedServices
  ]);

  return sortServices;
};

export const useFuelServices = () => {
  const services = useServices();

  const fuelServices = useMemo(() => (
    services.find(({ code }) => `${code}` === `${FUEL_CODE}`)
  ), [services]);

  return fuelServices;
};

export const useFuelServiceLeaves = () => {
  const fuelServices = useFuelServices();

  const fuelServiceLeaves = useMemo(() => {
    const getLeaves = (service?: Service): Service[] => {
      if (!service) {
        return [];
      }

      if (service.children?.length) {
        return service.children.reduce((acc, item) => ([...acc, ...getLeaves(item)]), [] as Service[]);
      }

      return [service];
    };

    return getLeaves(fuelServices);
  }, [fuelServices]);

  return fuelServiceLeaves;
};

export const useFuelServiceLeavesList = () => {
  const fuelServiceLeaves = useFuelServiceLeaves();

  const fuelServiceLeavesList = useMemo(() => (
    fuelServiceLeaves.map(({ code, name }) => ({
      key: code,
      value: name
    }))
    .sort(({ value: firstValue }, { value: secondValue }) => firstValue > secondValue ? 1 : -1)
  ), [fuelServiceLeaves]);

  return fuelServiceLeavesList;
};
