import { createSlice } from '@reduxjs/toolkit';

import { MapState } from '../../types';

import { MAP_STATE } from '../../constants';

import { setInitializedMap } from './setInitializedMap';

const initialState: MapState = {
  initialized: false
};

export const mapSlice = createSlice({
  name: MAP_STATE,
  initialState,
  reducers: {
    setInitializedMap
  }
});

export const mapActions = mapSlice.actions;
export const mapReducer = mapSlice.reducer;
