import { AppThunk } from 'store';
import { batch } from 'react-redux';

import { getResponseError } from 'common/utils';
import dataSources from 'dataSources';
import { navigationActions } from '../reducer';
import { applicationActions } from 'entities/application';

import { LOADING_STATUS } from 'shared/constants';

import { NavigationAutos } from '../../types';

const {
  setAutos,
  setAutosLoadingStatus
} = navigationActions;
const {
  setErrorNotice
} = applicationActions;

export const getNavigationAutos = (
  onSuccess: (autos: NavigationAutos) => void
): AppThunk => async (dispatch) => {
  try {
    dispatch(setAutosLoadingStatus(LOADING_STATUS.load));

    const { autoObuPairs } = await dataSources.navigation.getAutos();

    batch(() => {
      dispatch(setAutos(autoObuPairs));
      dispatch(setAutosLoadingStatus(LOADING_STATUS.done));
    });
    if (onSuccess) {
      onSuccess(autoObuPairs);
    }
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setAutosLoadingStatus(LOADING_STATUS.done));
    });
  }
};
