import { isEastTerritory } from 'common/utils/checkTerritory';

export const WEST_PALETTE = {
  common      : '#4FBC46',
  powerMax    : '#EE4242',
  discountPlus: '#FFAB00',
  economy     : '#00AEEF',
  roadService : '#8484FF'
};

export const EAST_PALETTE = {
  common      : '#52AE32',
  powerMax    : '#EE4242',
  discountPlus: '#FFAB00',
  economy     : '#00AEEF',
  roadService : '#8484FF'
};

export const STATION_COLLOR_PALETTE = isEastTerritory ? EAST_PALETTE : WEST_PALETTE;
