import getCoordinatesFromString from 'common/utils/getCoordinatesFromString';
import { isGasStation, isPlace, isCoordinates } from 'common/utils/checkRoutePointType';

import { RoutePoint } from '../../types/routePoints';

type MappingRoutePointResult = Required<RoutePoint>['value'] | google.maps.LatLng;

const mappingRoutePoint = (item: RoutePoint): MappingRoutePointResult => {
  if (isPlace(item.type) && item.value) return item.value;
  if (isGasStation(item.type) && item.coordinates) return new google.maps.LatLng(item.coordinates);
  if (isCoordinates(item.type) && item.value) {
    const [lat, lng] = getCoordinatesFromString(item.value);
    return new google.maps.LatLng({ lat, lng });
  }
  return '';
};

export default mappingRoutePoint;
