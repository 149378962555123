import defaultTheme from 'e100-react-components/lib/theme/defaultTheme';
import createMuiTheme from 'e100-react-components/lib/theme/createMuiTheme';
import colors from 'e100-react-components/lib/styles/colors';

const theme = createMuiTheme({
  ...defaultTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 720,
      lg: 1023,
      xl: 1440
    }
  },
  palette: {
    primary: {
      ...defaultTheme.palette.primary,
      light: 'rgba(79, 188, 70, 0.12);'
    },
    secondary: {
      main: '#637282',
      100: '#9BA4B0'
    },
    warning: {
      ...defaultTheme.palette.warning,
      main: colors.amber['A700'],
      light: colors.orange[50]
    }
  },
  typography: {
    caption: {
      fontSize: 11
    },
    body2: {
      fontSize: '0.8125rem'
    },
    h5: {
      lineHeight: defaultTheme.typography.h3.lineHeight
    },
    h6: {
      fontSize: '1rem'
    }
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    ...defaultTheme.overrides,
    MuiSelect: {
      ...defaultTheme.overrides?.MuiSelect,
      select: {
        "&:focus": {
          backgroundColor: "$labelcolor",
          borderRadius: defaultTheme.spacing()
        }
      }
    },
    MuiRadio: {
      root: {
        width: `${defaultTheme.spacing(5.25)}px !important`,
        height: `${defaultTheme.spacing(5.25)}px !important`
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: defaultTheme.spacing(5)
      }
    },
    MuiChip: {
      outlined: {
        backgroundColor: defaultTheme.palette.grey[50],
        borderColor: defaultTheme.palette.grey[300]
      }
    },
    MuiButton: {
      ...defaultTheme.overrides?.MuiButton,
      root: {
        padding: `${defaultTheme.spacing()}px ${defaultTheme.spacing(1.5)}px`,
        lineHeight: defaultTheme.typography.body1.lineHeight,
        minHeight: defaultTheme.spacing(5)
      },
      textSizeSmall: {
        minHeight: defaultTheme.spacing(3.75)
      },
      label: {
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
      },
      contained: {
        backgroundColor: defaultTheme.palette.common.white,
        color: '#637282'
      },
      text: {
        color: '#637282',
        padding: `${defaultTheme.spacing()}px ${defaultTheme.spacing(2)}px`
      }
    },
    MuiDialogTitle: {
      root: {
        padding: defaultTheme.spacing(2)
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: defaultTheme.palette.grey[200]
      }
    },
    MuiListItemSecondaryAction: {
      root: {
        position: 'relative',
        top: 'initial',
        right: 0,
        transform: 'initial'
      }
    }
  },
});

export default theme;
