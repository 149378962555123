import { Station } from 'dataSources/Typicode/stations/types';
import { BBox } from '@turf/helpers';
import turfBbox from '@turf/bbox';
import {
  reduceAsync,
  isActionItem
} from 'common/utils';
import {
  defaultBBox,
  turfLineString
} from 'shared/utils';

export interface MappingStationResult {
  stations: Station[];
  actionStations: Station[];
  stationsBBox: BBox;
  actionStationsBBox: BBox;
}

interface ReduceResult extends Pick<
  MappingStationResult,
  'stations' | 'actionStations'
> {
  stationCoords: [
    Station['coordinates']['longitude'],
    Station['coordinates']['latitude']
  ][];
  actionStationCoords: [
    Station['coordinates']['longitude'],
    Station['coordinates']['latitude']
  ][];
}

export const mappingStations = async (stations: Station[]): Promise<MappingStationResult> => {
  const result = await reduceAsync<Station, ReduceResult>(
    stations,
    (acc, station) => {
      if (station.code && station.coordinates) {
        const newItem = {
          ...station,
          roadsideServiceCodes: station.roadsideServiceCodes || []
        };
  
        acc.stations.push(newItem);
        acc.stationCoords.push([
          station.coordinates.longitude,
          station.coordinates.latitude
        ]);
  
        if (isActionItem(newItem)) {
          acc.actionStations.push(newItem);
          acc.actionStationCoords.push([
            station.coordinates.longitude,
            station.coordinates.latitude
          ]);
        }
      }
      return acc;
    },
    {
      stations: [],
      actionStations: [],
      stationCoords: [],
      actionStationCoords: []
    }
  );

  return {
    ...result,
    stationsBBox: turfBbox(turfLineString(result.stationCoords)),
    actionStationsBBox: result.actionStationCoords.length > 2 ? turfBbox(turfLineString(result.actionStationCoords)) : defaultBBox
  }
};
