import { batch } from 'react-redux';
import dataSources from 'dataSources';
import { AppThunk } from 'store';
import { AuthForm } from 'dataSources/Typicode/auth/types/authToken';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import setAuthData from 'common/utils/auth/setAuthData';
import resetAuthData from 'common/utils/auth/resetAuthData';
import parseAuthData from 'common/utils/auth/parseAuthData';
import EVENT_TYPES_GTM from 'common/constants/eventTypesGTM';
import addGTMEvent from 'common/utils/addGTMEvent';
import { USER_TYPES } from 'common/utils/userTypes';
import getTranslateIdFromResponseError from 'common/utils/getTranslateIdFromResponseError';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { setUserType, setUserLoadingStatus } = slice.actions;
const { setErrorNotice } = applicationActions;

const operation = (authForm: AuthForm): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserLoadingStatus(STATUS_LOADING.load));

    const { data } = await dataSources.auth.getAuthTokens(authForm);

    const authData = parseAuthData(data);
    setAuthData(authData);
    addGTMEvent(EVENT_TYPES_GTM.login, { userId: authData.clientCode, userType: authData.userType });

    window.location.reload();
  } catch (e) {
    const translateId = await getTranslateIdFromResponseError(e);
    batch(() => {
      dispatch(setErrorNotice({ translateId }));
      resetAuthData();
      dispatch(setUserLoadingStatus(STATUS_LOADING.error));
      dispatch(setUserType(USER_TYPES.unautorized));
    });
  }
};

export default operation;
