import defaultTheme from 'e100-react-components/lib/theme/defaultTheme';
import makeStyles from 'e100-react-components/lib/styles/makeStyles';
import createStyles from 'e100-react-components/lib/styles/createStyles';

const useGlobalStyles = makeStyles(() => createStyles({
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      fontFamily: defaultTheme.typography.fontFamily,
      touchAction: 'none',
      height: '100%'
    },
    '#root': {
      height: '100%'
    }
  }
}));

export default useGlobalStyles;
