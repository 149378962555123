import { makeStyles } from 'e100-react-components';

interface StylesProps {
  hasWarningMessage?: boolean;
}

export const useStyles = makeStyles(({
  typography,
  spacing,
  palette
}) => ({
  text: {
    fontWeight: typography.body1.fontWeight
  },
  date: {
    fontWeight: typography.body1.fontWeight,
    color: ({ hasWarningMessage }: StylesProps) => hasWarningMessage ? palette.warning.main : palette.secondary.main
  },
  warningIcon: {
    display: 'block',
    fontSize: typography.h5.fontSize,
    marginLeft: spacing(0.5),
    color: palette.warning.main,
    padding: spacing(0.5)
  },
  textContainer: {
    position: 'relative'
  },
  iconContainer: {
    position: 'absolute',
    left: spacing(-5),
    top: '50%',
    marginTop: spacing(-1.5),
    zIndex: 1
  },
  copyIconButtonContainer: {
    marginRight: spacing()
  }
}));
