import { useHasCarEBoxLocations } from 'entities/cars';
import useTheme from 'pages/app/hooks/useTheme';

export type TextColor = ReturnType<typeof useTextColor>;

export const useTextColor = () => {
  const hasCarEBoxLocations = useHasCarEBoxLocations();
  const { palette } = useTheme();

  return hasCarEBoxLocations ? palette.primary.main : palette.secondary.main;
};
