import React from 'react';

import Grid, { IBreakpoints } from 'e100-react-components/lib/components/Grid';
import { IPropTypes as ModalDialogProps } from 'e100-react-components/lib/components/ModalDialog';

import { CancelButton } from './CancelButton/CancelButton';
import { SubmitButton } from './SubmitButton/SubmitButton';

import { useStyles } from './styles';

export const ModalActionButtons = ({
  labelOk,
  onOk,
  labelCancel,
  onClose,
  isLoading,
  isValid = true,
  disabledOkButton = false,
  children,
  onOkContainerSize,
  margin
}: ModalActionButtonsProps) => {
  const classes = useStyles({ margin });

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            wrap={'nowrap'}
          >
            <CancelButton
              labelCancel={labelCancel}
              onClose={onClose}
              isLoading={isLoading}
            />
            <SubmitButton
              labelOk={labelOk}
              onOk={onOk}
              isLoading={isLoading}
              isValid={isValid}
              disabledOkButton={disabledOkButton}
              onOkContainerSize={onOkContainerSize}
            />
            {children}
          </Grid>
        </Grid>
      </Grid>
    </div>

  );
};

interface Margin {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export interface ModalActionButtonsProps extends Pick<
  ModalDialogProps,
  'labelOk' | 'labelCancel' | 'onClose' | 'isLoading' | 'isValid'
> {
  onOk?: React.FormEventHandler<HTMLFormElement>;
  disabledOkButton?: boolean;
  onOkContainerSize?: IBreakpoints;
  children?: any;
  margin?: Margin;
}
