import dataSources from 'dataSources';
import { AppThunk } from 'store';
import { batch } from 'react-redux';
import { getAnonymousUserId } from 'common/utils/anonymousUser';
import { isAutorizedUser } from 'common/utils/userTypes';
import { getResponseError } from 'common/utils/index';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import { IPermissions } from 'dataSources/Typicode/auth/types/permissions';

import slice from '../reducer/slice';
import { TUserType } from '../types';
import { applicationActions } from 'entities/application';

const { setPermissions, setPermissionsLoadingStatus } = slice.actions;
const { setErrorNotice } = applicationActions;

type TOperation = (userType: TUserType) => AppThunk;

const operations: TOperation = (userType) => async (dispatch) => {
  try {
    dispatch(setPermissionsLoadingStatus(STATUS_LOADING.load));

    let data: IPermissions;
    if (isAutorizedUser(userType)) {
      data = await dataSources.auth.getPermissions();
    } else {
      data = await dataSources.auth.getAnonymousPermissions(getAnonymousUserId());
    }
    
    batch(() => {
      dispatch(setPermissionsLoadingStatus(STATUS_LOADING.done));
      // @ts-ignore
      dispatch(setPermissions(data.data.permissions));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setPermissionsLoadingStatus(STATUS_LOADING.error));
    });
  }
};

export default operations;
