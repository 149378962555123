import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Locale } from 'entities/application';

import enLocale from '../locales/en';

type IntlProviderProps = React.ComponentProps<typeof IntlProvider>;
type TMessages = IntlProviderProps['messages'];

type TUseGetTranslates = (currentLocale: Locale) => TMessages;

const useGetTranslates: TUseGetTranslates = (locale) => {
  const [messages, setMessages] = useState({ ...enLocale });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await import(`../locales/${locale}.js`);
        setMessages({ ...enLocale, ...data.default });
      } catch (e) {}
    }
    fetchData();
  }, [setMessages, locale]);

  return messages;
};

export default useGetTranslates;
