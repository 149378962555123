import React from 'react';
import classNames from 'classnames';

import E100Button, {
  IPropTypes
} from 'e100-react-components/lib/components/Button';

import { useStyles } from './styles';

export const Button = ({
  mobileButton = false,
  classes = {},
  ...props
}: ButtonProps) => {
  const innerClasses = useStyles({ mobileButton });

  return (
    <E100Button
      {...props}
      classes={{
        ...classes,
        root: classNames({
          [innerClasses.root]: true,
          [classes.root || '']: true
        })
      }}
    />
  );
};

export interface ButtonProps extends IPropTypes {
  mobileButton?: boolean;
}