import { PayloadAction } from "@reduxjs/toolkit";

import { NavigationState } from "../../types";

export const setAutos = (
  state: NavigationState,
  action: PayloadAction<NavigationState['autos']['data']>
) => {
  state.autos.data = action.payload;
};
