import React, { MouseEvent } from 'react';
import { useMediaQuery } from 'common/hooks';
import { useIsClient } from 'store/features/auth/selectors/user';
import { useIsAutorizedUser } from 'store/features/auth/selectors';

import { isEastTerritory } from 'common/utils/checkTerritory';
import { isFrameUser } from 'common/utils/frameUser';

import { EBoxLocationTabletButton } from './EBoxLocationTabletButton/EBoxLocationTabletButton';
import { EBoxLocationMobileButton } from './EBoxLocationMobileButton/EBoxLocationMobileButton';

export const EBoxLocationButton = ({
  onClick
}: LocationButtonProps) => {
  const isClient = useIsClient();
  const isAutorizedUser = useIsAutorizedUser();
  const { isMobile } = useMediaQuery();

  if (isEastTerritory || isFrameUser || (isAutorizedUser && !isClient)) return null;
  if (isMobile) return <EBoxLocationMobileButton onClick={onClick} />;
  return <EBoxLocationTabletButton onClick={onClick} />;
};

export interface LocationButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}