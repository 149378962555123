export const REPORTS_STATE = "REPORTS";

export const REPORT_STATES = {
  created: 'Created',
  startGeneration: 'StartGeneration',
  endGeneration: 'EndGeneration',
  sentReport: 'SentReport',
  error: 'Error',
  canceled: 'Canceled'
} as const;

export const REPORT_TYPES = {
  sendPrice: 1,
  uiStationsListWithoutPrice: 2,
  uiStationsListWithVatPrice: 3,
  uiStationsListWithoutVatPrice: 4
};

export const REPORT_EXTENSIONS = {
  xlsx: 'xlsx',
  csv: 'csv',
  json: 'json'
} as const;

export const REPORT_NAMES = {
  excel: 'Excel',
  excelWithPrice: 'ExcelWithPrice',
  csv: 'CSV',
  csvWithPrice: 'CSVWithPrice',
  print: 'print',
  printWithPrice: 'printWithPrice'
} as const;

export const REPORT_COLUMN_NAMES = {
  stationId: 'StationId',
  brand: 'Brand',
  address: 'Address',
  comment: 'Comment',
  prices: 'Prices'
};
