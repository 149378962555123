import React from 'react';
import { ButtonGroup as E100ButtonGroup } from 'e100-react-components';
import { IPropTypes as E100ButtonGroupProps } from 'e100-react-components/src/lib/components/ButtonGroup/ButtonGroup';

import { useStyles } from './styles';

export const ButtonGroup = (props: ButtonGroupProps) => {
  const classes = useStyles();
  return (
    <E100ButtonGroup
      {...props}
      classes={{
        root: classes.groupedOutlinedHorizontal
      }}
    />
  );
};

export interface ButtonGroupProps extends E100ButtonGroupProps {}

