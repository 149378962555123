import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { IService } from 'dataSources/Typicode/common/types';
import { IServiceIds } from 'common/utils/services/types/serviceIds';
import { TParentId } from 'common/utils/services/types/parentId';

import { STATE } from '../types/state';

type TTraversalTree = (service: IService, parentIds?: TParentId[]) => void;
type TSetServiceIdByParentIds = (id: string, parentIds: TParentId[]) => void;

const selector = createSelector(
  (state: RootState) => state[STATE].services,
  services => {
    const result: IServiceIds = {};

    const setParentIdsByServiceId: TSetServiceIdByParentIds = (id, parentIds) => parentIds
      .forEach((parentId) => { result[id][parentId] = true; });

    const traversalTree: TTraversalTree = ({ code, children }, parentIds = []) => {
      result[code] = {};
      if (parentIds.length) setParentIdsByServiceId(code, parentIds);
      if (!children) return;

      children.forEach(service => { traversalTree(service, [...parentIds, code]); });
    };

    services.forEach(item => { traversalTree(item); });

    return result;
  }
);

export default selector;
