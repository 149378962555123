import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';

import { IContext } from './types/context';
import { IActiveStation } from './types/activeStation';
import { TSetActiveStations } from './types/setActiveStations';

interface Props {
  children: React.ReactNode
}

export const ActiveStationsContext = createContext({} as IContext);
export const useActiveStationsContext = () => useContext(ActiveStationsContext);

const ActiveStationsProvider = (props: Props) => {
  const { children } = props;

  const [ activeStations, setActiveStations ] = useState<IActiveStation[]>([]);
  const [ isRouteStations, setIsRouteStations ] = useState(false);

  const onSetActiveStations: TSetActiveStations = useCallback((stations, isRouteStations) => {
    setActiveStations(stations);
    setIsRouteStations(!!isRouteStations);
  }, [setActiveStations]);

  const amountActiveStations = useMemo(() => activeStations.length, [activeStations]);

  const value = useMemo(
    () => ({
      activeStations,
      setActiveStations: onSetActiveStations,
      amountActiveStations,
      isRouteStations
    }),
    [
      activeStations,
      onSetActiveStations,
      amountActiveStations,
      isRouteStations
    ]
  );

  return (
    <ActiveStationsContext.Provider value={value}>{children}</ActiveStationsContext.Provider>
  );
};

export default ActiveStationsProvider;
