import createMuiTheme from 'e100-react-components/lib/theme/createMuiTheme';
import defaultTheme from './defaultTheme';

const westTheme = createMuiTheme({
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    caption: { lineHeight: '16px' }
  }
});

export default westTheme;
