import { useMemo } from 'react';
import { useIsActiveGoogleMap } from 'entities/application';
import { useNavigationHistory } from 'entities/navigation';

import {
  getNavigationHistoryRouteIcon,
  getNavigationHistoryRouteIconSize,
  getNavigationHistoryRouteIconPosition
} from '../utils/navigationHistoryRouteIcon';

import { GoogleLayer, HereLayer } from 'common/utils/MapMarker/types';

const useNavigationHistoryMarkers = () => {
  const navigationHistory = useNavigationHistory();

  const navigationHistoryMarkers = useMemo(() => {
    const { startLocation, endLocation } = navigationHistory || {};

    return [
      ...startLocation ? [{
        ...startLocation,
        locationType: 'startLocation'
      }] : [],
      ...endLocation ? [{
        ...endLocation,
        locationType: 'endLocation'
      }] : []
    ];
  }, [
    navigationHistory
  ]);

  return navigationHistoryMarkers;
};

const useGoogleCarHistoryMarkersLayer = () => {
  const navigationHistoryMarkers = useNavigationHistoryMarkers();

  const useGoogleCarHistoryMarkersLayer = useMemo((): GoogleLayer => ({
    type: 'marker',
    // @ts-ignore
    data: navigationHistoryMarkers || [],
    id: 'navigation-history',
    getIcon: getNavigationHistoryRouteIcon,
    // @ts-ignore
    getPosition: getNavigationHistoryRouteIconPosition,
    getSize: getNavigationHistoryRouteIconSize,
    pickable: false
  }), [
    navigationHistoryMarkers
  ]);

  return useGoogleCarHistoryMarkersLayer;
};

const useHereCarHistoryMarkersLayer = () => {
  const navigationHistoryMarkers = useNavigationHistoryMarkers();

  const useGoogleCarHistoryMarkersLayer = useMemo((): HereLayer => ({
    // @ts-ignore
    data: navigationHistoryMarkers || [] as any,
    name: 'navigation-history',
    type: 'cluster',
    getCoordinates: ({ coordinates: {
      latitude,
      longitude
    } }) => ([latitude, longitude]),
    getMarker: ({ station }) => {
      const {
        url,
        height,
        width
      } = getNavigationHistoryRouteIcon({
        properties: {
          cluster: false,
          ...station
        }
      });

      return ({
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4, y: height / 4 }
      })
    },
  }), [
    navigationHistoryMarkers
  ]);

  return useGoogleCarHistoryMarkersLayer;
};

export const useCarHistoryMarkersLayer = () => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const googleCarHistoryMarkersLayer = useGoogleCarHistoryMarkersLayer();
  const hereCarHistoryMarkersLayer = useHereCarHistoryMarkersLayer();

  return isActiveGoogleMap ? googleCarHistoryMarkersLayer : hereCarHistoryMarkersLayer;
};