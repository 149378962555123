import IconClusterLayer from 'common/utils/DeckGl/Layers/IconClusterLayer';
import { GoogleLayer } from '../../types';

import commonLayerSettings from '../utils/commonLayerSettings';

const clusterLayerCreator = ({
  data,
  selectedStation,
  isRouteStations,
  id = 'cluster-icon',
  ...otherProps
}: GoogleLayer<{}>) => new IconClusterLayer({
  ...commonLayerSettings,
  id,
  data,
  // @ts-ignore
  selectedStation,
  isRouteStations,
  ...otherProps
});

export default clusterLayerCreator;
