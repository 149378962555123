import { NavigationAutoCoordinates } from 'entities/navigation';

import {
  checkIsIdleFrom0To4Hours,
  checkIsIdleFrom4To10Hours,
  checkIsIdleFrom10
} from '../../../checkAutoStatus';

const getIconColor = ({ status }: any) => {
  if (checkIsIdleFrom0To4Hours(status)) return '#50BC46';
  if (checkIsIdleFrom4To10Hours(status)) return '#FDC52A';
  if (checkIsIdleFrom10(status)) return '#FF5630';
  return '#fff';
};

export const getCarIcon = (props: NavigationAutoCoordinates) => (
  `
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_894_115819)">
    <path d="M2.00001 10C2.00002 7.79086 3.79088 6 6.00001 6H26C28.2092 6 30 7.79086 30 10L30 28.5279C30 30.043 29.144 31.428 27.7889 32.1056L16.8944 37.5528C16.3314 37.8343 15.6686 37.8343 15.1056 37.5528L4.21115 32.1056C2.85601 31.428 2 30.043 2 28.5279L2.00001 10Z" fill="#4E5864"/>
    <path d="M3.00001 10C3.00002 8.34314 4.34316 7 6.00001 7H26C27.6569 7 29 8.34315 29 10L29 28.5279C29 29.6642 28.358 30.703 27.3416 31.2111L16.4472 36.6584C16.1657 36.7991 15.8343 36.7991 15.5528 36.6584L4.65836 31.2111C3.64201 30.703 3 29.6642 3 28.5279L3.00001 10Z" stroke="#3B424B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 14.25C10 13.4216 10.597 12.75 11.3333 12.75H20.6667C21.403 12.75 22 13.4216 22 14.25V16.5V17.25H22.5C23.0523 17.25 23.5 17.6977 23.5 18.25V19.25C23.5 19.8023 23.0523 20.25 22.5 20.25H22V21V21.75V25.5V26.25V27V27.75C22 28.1642 21.6642 28.5 21.25 28.5H20.5C20.0858 28.5 19.75 28.1642 19.75 27.75V27H12.25V27.75C12.25 28.1642 11.9142 28.5 11.5 28.5H10.75C10.3358 28.5 10 28.1642 10 27.75V27V26.25V25.5V21.75V21V20.25H9.5C8.94772 20.25 8.5 19.8023 8.5 19.25V18.25C8.5 17.6977 8.94772 17.25 9.5 17.25H10V16.5V14.25ZM21.25 21V17.25H10.75V21H21.25ZM13.75 22.5H18.25V23.25H13.75V22.5ZM18.25 24H13.75V24.75H18.25V24ZM13 23.25C13 23.6642 12.6642 24 12.25 24C11.8358 24 11.5 23.6642 11.5 23.25C11.5 22.8358 11.8358 22.5 12.25 22.5C12.6642 22.5 13 22.8358 13 23.25ZM19.75 24C20.1642 24 20.5 23.6642 20.5 23.25C20.5 22.8358 20.1642 22.5 19.75 22.5C19.3358 22.5 19 22.8358 19 23.25C19 23.6642 19.3358 24 19.75 24Z" fill="white"/>
    <path d="M29 14C25.6863 14 23 11.3137 23 8C23 4.68629 25.6863 2 29 2C32.3137 2 35 4.68629 35 8C35 11.3137 32.3137 14 29 14Z" fill="white"/>
    <path d="M29 12C31.2091 12 33 10.2091 33 8C33 5.79086 31.2091 4 29 4C26.7909 4 25 5.79086 25 8C25 10.2091 26.7909 12 29 12Z" fill="${getIconColor(props)}"/>
    <defs>
    <filter id="filter0_d_894_115819" x="0.5" y="5" width="31" height="34.7639" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="0.5"/>
    <feGaussianBlur stdDeviation="0.75"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.105882 0 0 0 0 0.121569 0 0 0 0 0.137255 0 0 0 0.2 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_894_115819"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_894_115819" result="shape"/>
    </filter>
    </defs>
  </svg>  
  `
);
