import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Tire = (props: IProps) => (
  <SvgIcon {...props}>
    <path id="XMLID_2_" d="M12,2.3c-1,0-2,0.2-3,0.5l0.7,1.8L8.8,5L8.1,3.2C6.1,4.1,4.4,5.6,3.4,7.6l1.8,0.8
      L4.7,9.3L3,8.5C2.6,9.6,2.3,10.8,2.3,12c0,1,0.2,2,0.5,3l1.8-0.7L5,15.2l-1.8,0.6c0.9,2.1,2.5,3.7,4.5,4.8l0.8-1.8l0.9,0.4L8.5,21
      c1.1,0.4,2.3,0.7,3.5,0.7c1,0,2-0.2,3-0.5l-0.7-1.8l0.9-0.3l0.6,1.8c2.1-0.9,3.7-2.5,4.8-4.5l-1.8-0.8l0.4-0.9l1.7,0.8
      c0.4-1.1,0.7-2.3,0.7-3.5c0-1-0.2-2-0.5-3l-1.8,0.7L19,8.8l1.8-0.6c-0.9-2.1-2.5-3.7-4.5-4.8l-0.8,1.8l-0.9-0.4L15.5,3
      C14.4,2.6,13.2,2.3,12,2.3z M12,6.2c1.5,0,3,0.6,4.1,1.7c1.1,1.1,1.7,2.6,1.7,4.1s-0.6,3-1.7,4.1c-1.1,1.1-2.6,1.7-4.1,1.7
      c-1.5,0-3-0.6-4.1-1.7C6.8,15,6.2,13.5,6.2,12s0.6-3,1.7-4.1C9,6.8,10.5,6.2,12,6.2z"/>
  </SvgIcon>
);

export default Tire;
