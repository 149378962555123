import makeStyles from 'e100-react-components/lib/styles/makeStyles';

export const useStyles = makeStyles(({
  spacing
}) => ({
  icon: {
    position: 'absolute',
    left: spacing(2),
    top: '50%',
    marginTop: spacing(-1.5)
  }
}));
