import React from 'react';
import Grid from 'e100-react-components/lib/components/Grid';
import IconButton from 'e100-react-components/lib/components/IconButton';

import { IProps as IModalProps, Classes } from './index';

const Title = ({
  children,
  onClickExpandContent,
  bottomPosition,
  showTitleArrow,
  classes = {}
}: Props) => (
  <Grid container wrap={'nowrap'}>
    {
      showTitleArrow && (
        <IconButton
          kind={bottomPosition ? 'expandLess' : 'expandMore'}
          onClick={onClickExpandContent}
          classes={{ button: { root: classes.titleArrowButton } }}
        />
      )
    }
    <Grid item xs={12}>
      { children }
    </Grid>
  </Grid>
);

interface Props {
  children?: React.ReactNode;
  onClickExpandContent: IModalProps['onClickExpandContent'];
  bottomPosition?: IModalProps['bottomPosition'];
  showTitleArrow: IModalProps['showTitleArrow'];
  classes?: Classes;
}

export default Title;
