import useBindActionCreators from 'common/hooks/useBindActionCreators';

import { stationsOperations } from '../store';

type StationsOperations = typeof stationsOperations;

export const useStationsOperations = (): StationsOperations => {
  const bindedActions = useBindActionCreators<StationsOperations>(stationsOperations);
  return bindedActions;
};
