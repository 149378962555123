import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    position: 'absolute',
    left: spacing(1.2),
    top: '50%',
    transform: 'translateY(-50%)',
    lineHeight: 0,
    color: palette.secondary.main
  },
  circularProgressRoot: {
    width: `${spacing(2.75)}px !important`,
    height: `${spacing(2.75)}px !important`
  }
}));

export default useStyles;
