import { LOCAL_STORAGE_LOCALE_KEY, DEFAULT_LOCALE } from 'common/constants/locales';

export const setLocale = (locale: string) => {
  window.localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
}

export const getLocale = (): string => {
  return window.localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || DEFAULT_LOCALE;
}


