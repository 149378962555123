import { makeStyles } from 'e100-react-components';

import { DatePickerClearButtonProps } from './DatePickerClearButton';
interface StyleProps extends Pick<
  DatePickerClearButtonProps,
  'variant'
> {}

export const useStyles = makeStyles(({
  spacing
}) => ({
  iconButton: ({ variant }: StyleProps) => ({
    position: 'absolute',
    right: variant === 'default' ? spacing(2.5) : spacing(5.5),
    top: '50%',
    marginTop: spacing(-2)
  }),
  icon: {
    width: spacing(2.5),
    height: spacing(2.5)
  }
}));
