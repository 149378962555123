import am from 'assests/img/flags/AM.svg';
import at from 'assests/img/flags/AT.svg';
import az from 'assests/img/flags/AZ.svg';
import ba from 'assests/img/flags/BA.svg';
import be from 'assests/img/flags/BE.svg';
import bg from 'assests/img/flags/BG.svg';
import by from 'assests/img/flags/BY.svg';
import ch from 'assests/img/flags/CH.svg';
import cz from 'assests/img/flags/CZ.svg';
import de from 'assests/img/flags/DE.svg';
import dk from 'assests/img/flags/DK.svg';
import ee from 'assests/img/flags/EE.svg';
import en from 'assests/img/flags/EN.svg';
import es from 'assests/img/flags/ES.svg';
import fi from 'assests/img/flags/FI.svg';
import fr from 'assests/img/flags/FR.svg';
import gb from 'assests/img/flags/GB.svg';
import ge from 'assests/img/flags/GE.svg';
import gr from 'assests/img/flags/GR.svg';
import hu from 'assests/img/flags/HU.svg';
import ie from 'assests/img/flags/IE.svg';
import it from 'assests/img/flags/IT.svg';
import kg from 'assests/img/flags/KG.svg';
import kz from 'assests/img/flags/KZ.svg';
import lt from 'assests/img/flags/LT.svg';
import lu from 'assests/img/flags/LU.svg';
import lv from 'assests/img/flags/LV.svg';
import ma from 'assests/img/flags/MA.svg';
import md from 'assests/img/flags/MD.svg';
import mn from 'assests/img/flags/MN.svg';
import mt from 'assests/img/flags/MT.svg';
import nl from 'assests/img/flags/NL.svg';
import no from 'assests/img/flags/NO.svg';
import pl from 'assests/img/flags/PL.svg';
import pt from 'assests/img/flags/PT.svg';
import ro from 'assests/img/flags/RO.svg';
import rs from 'assests/img/flags/RS.svg';
import ru from 'assests/img/flags/RU.svg';
import se from 'assests/img/flags/SE.svg';
import si from 'assests/img/flags/SI.svg';
import sk from 'assests/img/flags/SK.svg';
import tj from 'assests/img/flags/TJ.svg';
import tn from 'assests/img/flags/TN.svg';
import tr from 'assests/img/flags/TR.svg';
import ua from 'assests/img/flags/UA.svg';
import uk from 'assests/img/flags/UK.svg';
import uz from 'assests/img/flags/UZ.svg';

const flags = {
  am, at, az, ba, be, bg, by, ch, cz, de, dk, ee, en,
  es, fi, fr, gb, ge, gr, hu, ie, it, kg, kz, lt, lu,
  lv, ma, md, mn, mt, nl, no, pl, pt, ro, rs, ru, se,
  si, sk, tj, tn, tr, ua, uk, uz
};

export type Flags = keyof typeof flags;

export default flags;
