import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Truck = (props: IProps) => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 0C2.44772 0 2 0.447715 2 1V7H3L3.31213 5.43934C3.42495 4.87526 3.87825 4.44181 4.44681 4.35434C8.12729 3.78811 11.8727 3.78811 15.5532 4.35434C16.1218 4.44181 16.5751 4.87526 16.6879 5.43934L17 7H18V1C18 0.447715 17.5523 0 17 0H3ZM0 8C0 7.44772 0.447715 7 1 7C1.55228 7 2 7.44772 2 8V10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10V8ZM1 18H2H4H16H18H19V20C19 20.5523 18.5523 21 18 21H17C16.4477 21 16 20.5523 16 20V19.5H4V20C4 20.5523 3.55228 21 3 21H2C1.44772 21 1 20.5523 1 20V18ZM1 13.1206V17H19V13.1206C19 12.462 18.5518 11.888 17.9129 11.7282C17.3586 11.5896 16.9392 11.1354 16.8453 10.5718L16.1174 6.20457C16.0472 5.7831 15.7169 5.45282 15.2954 5.38258L13.9456 5.1576C11.3332 4.72221 8.66679 4.72221 6.05442 5.1576L4.70457 5.38258C4.28309 5.45282 3.95282 5.7831 3.88257 6.20457L3.1547 10.5718C3.06076 11.1354 2.64144 11.5896 2.08711 11.7282C1.44821 11.888 1 12.462 1 13.1206ZM6.51343 12.4606C6.51343 11.9083 6.96114 11.4606 7.51343 11.4606H12.4866C13.0389 11.4606 13.4866 11.9083 13.4866 12.4606V14.4606C13.4866 15.0129 13.0389 15.4606 12.4866 15.4606H7.51343C6.96114 15.4606 6.51343 15.0129 6.51343 14.4606V12.4606ZM2.50342 14.4606C2.50342 13.9083 2.95113 13.4606 3.50342 13.4606H4.50342C5.0557 13.4606 5.50342 13.9083 5.50342 14.4606C5.50342 15.0129 5.0557 15.4606 4.50342 15.4606H3.50342C2.95113 15.4606 2.50342 15.0129 2.50342 14.4606ZM15.4966 13.4606C14.9443 13.4606 14.4966 13.9083 14.4966 14.4606C14.4966 15.0129 14.9443 15.4606 15.4966 15.4606H16.4966C17.0489 15.4606 17.4966 15.0129 17.4966 14.4606C17.4966 13.9083 17.0489 13.4606 16.4966 13.4606H15.4966ZM5.64959 6.18669C5.18188 6.22451 4.80337 6.58244 4.73949 7.0473L4.33374 10H15.6663L15.2605 7.0473C15.1967 6.58244 14.8182 6.22451 14.3505 6.18669L11.9343 5.99133C10.6469 5.88723 9.35316 5.88723 8.06576 5.99133L5.64959 6.18669ZM19 7C18.4477 7 18 7.44772 18 8V10C18 10.5523 18.4477 11 19 11C19.5523 11 20 10.5523 20 10V8C20 7.44772 19.5523 7 19 7Z"/>
  </SvgIcon>
);

export default Truck;
