import React, { 
  useState,
  useEffect
} from 'react';

export const LazyLoadImage = ({
  src = '',
  previewSrc,
  alt,
  classes = {}
}: Props) => {
  const [currentImage, setCurrentImage] = useState(previewSrc);

  useEffect(() => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <img
      src={currentImage}
      alt={alt}
      className={classes.root}
    />
  );
};

interface Classes {
  root?: string;
}

interface Props {
  previewSrc: string;
  src: string;
  alt: string;
  classes?: Classes;
}
