import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { STATE, IState } from '../types/state';

export const selectedStationCode = (state: RootState): IState['selectedStation'] => {
  const { selectedStation } = state[STATE];
  return selectedStation;
};

export const useSelectedStationCode = () => {
  const result = useSelector((state: RootState) => state[STATE].selectedStation);
  return result;
};
