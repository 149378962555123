import React from 'react';

import useStyles from './useStyles';
import Buttons from './Buttons';
import useControlsStyles from './hooks/useControlsStyles';
import { Props as ControlProps } from '../index';

const Controls = ({ showZoomControl }: Props) => {
  const controlsStyles = useControlsStyles();
  const classes = useStyles({ controlsStyles });
  if (!showZoomControl) return null;
  return (
    <div className={classes.container}>
      <Buttons />
    </div>
  );
};

interface Props extends Pick<ControlProps, 'showZoomControl'> {}

export default Controls;