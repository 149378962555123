import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Roads = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M7.8,4c-0.9,0-1.8,0.7-2,1.6L5,9.5h2L7.8,6H11v1h2V6h3.2l0.8,3.5h2l-0.8-3.9c-0.2-0.9-1-1.6-2-1.6H7.8z M19.4,11.5h-2l1.4,6.5H13v-3h-2v3H5.2l1.4-6.5h-2l-1.3,6.1C3,18.8,4,20,5.2,20h13.5c1.3,0,2.2-1.2,2-2.4L19.4,11.5z M11,11.5V13h2 v-1.5H11z" />
    <path id="XMLID_20_" d="M1.5,11.2c0-1.1,0.9-2,2-2h17c1.1,0,2,0.9,2,2v2.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2.5h-17v2.5c0,0.6-0.4,1-1,1s-1-0.4-1-1V11.2z" />
  </SvgIcon>
);

export default Roads;
