const AUTO_NUMBER_ICON_PADDING = 44;
const AUTO_NUMBER_ICON_MINIMUM_WIDTH = 24;
const AUTO_NUMBER_ICON_DEFAULT_WIDTH = 100;

const autoNumberCalculatedContainerRef = document.getElementById('auto-number-calculated-container');

export const getCarNumberIconWidth = (autoNumber: string) => {
  if (autoNumberCalculatedContainerRef) {
    autoNumberCalculatedContainerRef.innerHTML = autoNumber;
    const width = autoNumberCalculatedContainerRef.offsetWidth + AUTO_NUMBER_ICON_PADDING;
    return width < AUTO_NUMBER_ICON_MINIMUM_WIDTH ? AUTO_NUMBER_ICON_MINIMUM_WIDTH : width;
  }

  return AUTO_NUMBER_ICON_DEFAULT_WIDTH;
};
