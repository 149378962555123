import React from 'react';

import dayjs from 'dayjs';

import { DateTimePickerProps } from '../ui';

import { useStyles } from './styles';

export const CalendarDay = ({
  day,
  dayComponent,
  dayInCurrentMonth,
  dateRange
}: CalendarDayProps) => {
  const isStartDay = day.isSame(dateRange[0]);
  const isEndDay = day.isSame(dateRange[1], 'day');
  const isActiveDay = day.isBetween(
    dayjs(dateRange[0]).subtract(1, 'D').format(),
    dateRange[1]
  );

  const classes = useStyles({
    isActiveDay,
    isStartDay,
    isEndDay
  });

  if (!dayInCurrentMonth) {
    return dayComponent;
  }

  return (
    <span className={classes.container}>
      {dayComponent}
    </span>
  );
};

interface CalendarDayProps extends Pick<
  Required<DateTimePickerProps>,
  'dateRange'
> {
  day: any;
  selectedDay: any;
  dayInCurrentMonth: boolean;
  dayComponent: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}