import { TUserPermissions } from 'dataSources/Typicode/auth/types/permissions';
import { StatusLoading } from 'common/constants/statusLoading';
import { UserStateE100Mobility } from 'dataSources/Typicode/common/types/userStateE100Mobility';
import { User } from './user';

import { TUserType } from './userType';

export const STATE = 'AUTH';

export interface IState {
  userType: TUserType;
  permissions: {
    loadingStatus?: StatusLoading;
    data?: TUserPermissions;
  },
  user: {
    loadingStatus?: StatusLoading;
    data?: User;
  },
  userStateE100Mobility: {
    statusLoading?: StatusLoading;
    data?: UserStateE100Mobility;
  }
}