import { FeedbackForm } from '../types/feedback';

const mappingFeedbackForm = (feedbackForm: FeedbackForm) => {
  const { files, ...data } = feedbackForm;
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    // @ts-ignore
    const value = data[key];
    if (value) formData.append(key, value);
  });

  formData.append('applicationId', 'locator');

  if (files) files.forEach(file => formData.append('files', file));

  return formData;
};

export default mappingFeedbackForm;
