let accessToken: string = '';

export const getAccessToken = () => accessToken;

export const setAccessToken = (value: string) => {
  accessToken = value;
};

export const deleteAccessToken = () => {
  accessToken = '';
}