import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { markerFactory } from 'common/utils/MapMarker';
import { Map } from 'common/utils/Map/types';

import { MarkersContext as Context } from './types/context';
import useMarkersState from './hooks/useMarkersState';

interface Props {
  children?: React.ReactNode;
}

export const MarkersContext = createContext({} as Context);
export const useMarkersContext = () => useContext(MarkersContext);

const MarkersProvider = ({ children }: Props) => {
  const { markersRef, markers, setMarkers } = useMarkersState();

  const initMarkers = useCallback((map: Map | null) => {
    if (map) {
      const newMarkers = markerFactory(map);
      setMarkers(newMarkers);
    }
  }, [setMarkers]);

  const setOverlayProps: Context['setOverlayProps'] = useCallback((...overlayProps) => {
    if (markers) markers.setOverlayProps(...overlayProps);
  }, [markers]);

  const updateLayers: Context['updateLayers'] = useCallback((updateLayersProps) => {
    if (markers) {
      // @ts-ignore
      markers.updateLayers(updateLayersProps);
    }
  }, [markers]);

  const value = useMemo(
    () => ({
      markersRef,
      markers,
      initMarkers,
      setOverlayProps,
      updateLayers
    }),
    [
      markersRef,
      markers,
      initMarkers,
      setOverlayProps,
      updateLayers
    ]
  );

  return (
    <MarkersContext.Provider value={value}>{children}</MarkersContext.Provider>
  );
};

export default MarkersProvider;
