import { PayloadAction } from "@reduxjs/toolkit";
import { IOrderSecondStepData } from 'dataSources/Typicode/cards/types/orderSecondStep';

import { IState } from "../types/state";

const action = (state: IState, payloadAction: PayloadAction<IOrderSecondStepData>) => {
  state.orderSecondStep.data = payloadAction.payload;
};

export default action;
