import React from 'react';

import MapProvider from '../context/map';
import MarkersProvider from '../context/markers';
import ActiveMarkersProvider from '../context/activeMarkers';
import ActiveAutosProvider from '../context/activeAutos';
import { MapSettingsProvider } from '../context';

import { Props } from '../index';

const withContext = (
  Component: (componentProps: Props) => JSX.Element
) => ({ ...props }: Props) => (
  <MapProvider>
    <MarkersProvider>
      <ActiveMarkersProvider>
        <ActiveAutosProvider>
          <MapSettingsProvider>
            <Component {...props} />
          </MapSettingsProvider>
        </ActiveAutosProvider>
      </ActiveMarkersProvider>
    </MarkersProvider>
  </MapProvider>
);

export default withContext;