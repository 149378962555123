import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useReport } from '../store';

import { REPORT_COLUMN_NAMES } from '../constants';

interface ColumnHeader {
  title: string;
  id: string;
}

export interface Item {
  Prices?: { title: string; value: string; }[];
  [key: string]: any;
}

interface MappedReport {
  columnNames?: ColumnHeader[];
  items?: Item[];
  hasAddressColumn?: boolean;
  hasCommentColumn?: boolean;
}

export const useMappedReport = (): MappedReport => {
  const { formatMessage } = useIntl();
  const report = useReport();

  const hasReportPrices = useMemo(() => {
    if (report && report.ColumnNames) {
      const hasCommentColumn = report.ColumnNames.find(columnName => columnName === REPORT_COLUMN_NAMES.comment);
      return hasCommentColumn;
    }

    return false;
  }, [report]);

  const mappedReport = useMemo(() => {
    if (report) {
      return report.ColumnNames.reduce((acc, columnName, columnIndex) => {

        if (hasReportPrices && columnName === REPORT_COLUMN_NAMES.comment) {
          acc.hasCommentColumn = true;
        }

        if (acc.hasAddressColumn && !acc.hasCommentColumn && hasReportPrices) {
          report.Rows.slice(1).forEach((row, rowIndex) => {
            if (row[columnIndex].trim()) {
              acc.items[rowIndex] = {
                ...acc.items[rowIndex] || {},
                [REPORT_COLUMN_NAMES.prices]: [
                  ...(acc.items[rowIndex][REPORT_COLUMN_NAMES.prices] || []),
                  { title: columnName, value: row[columnIndex] }
                ]
              };
            }
          });
        } else {
          report.Rows.slice(1).forEach((row, rowIndex) => {
            acc.items[rowIndex] = {
              ...acc.items[rowIndex] || {},
              [columnName]: row[columnIndex]
            };
          });
        }

        if (!hasReportPrices || (!acc.hasAddressColumn && !acc.hasCommentColumn) || (acc.hasAddressColumn && acc.hasCommentColumn)) {
          acc.columnNames.push({
            title: report.Rows[0][columnIndex],
            id: columnName
          });
        }

        if (hasReportPrices && columnName === REPORT_COLUMN_NAMES.address) {
          acc.hasAddressColumn = true;
          acc.columnNames.push({
            title: formatMessage({ id: 'prices' }),
            id: REPORT_COLUMN_NAMES.prices
          });
        }

        return acc;
      }, {
        columnNames: [],
        items: [],
        hasAddressColumn: false,
        hasCommentColumn: false
      } as Required<MappedReport>);
    }

    return {};
  }, [
    formatMessage,
    hasReportPrices,
    report
  ]);

  return mappedReport;
};
