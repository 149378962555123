import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, zIndex }) => ({
  // @ts-ignore
  container: ({ controlsStyles }) => ({
    position: 'absolute',
    top: `calc(50% + ${spacing(9)}px)`,
    right: spacing(2),
    transform: 'translateY(-50%)',
    zIndex: `${zIndex.mobileStepper} !important`,
    ...controlsStyles
  })
}));

export default useStyles;
