import { GoogleMap, HereMap } from 'common/utils/Map';

import { routeFactory as RouteFactory } from './types/routeFactory';
import { GoogleRoute } from './GoogleRoute';
import { HereRoute } from './HereRoute';

export * from './types';

export const routeFactory: RouteFactory = (map, params) => {
  if (map instanceof GoogleMap) {
    return new GoogleRoute(map, params);
  }

  if (map instanceof HereMap) {
    return new HereRoute(map, params);
  }

  throw Error('There is no a route for such a map');
};
