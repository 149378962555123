import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { NOTICE_TYPES } from 'common/constants/notice';

const useStyles = makeStyles(({ palette, spacing, transitions }) => ({
  iconButton: {
    // @ts-ignore
    color: ({ type }) => type === NOTICE_TYPES.error ? palette.error.main : palette.primary.main
  },
  button: {
    marginRight: -spacing(),
    transition: transitions.create(['opacity']),
    '&:hover': {
      opacity: 0.7
    }
  },
  iconCopy: {
    fontSize: spacing(2.3),
    transform: 'rotate(0) !important'
  },
  buttonCopy: {
    top: spacing(0.25),
    transition: transitions.create(['opacity']),
    '&:hover': {
      opacity: 0.7
    }
  },
  inputErrorMessage: {
    width: 50,
    height: 50,
    opacity: 1
  }
}));

export default useStyles;
