import makeStyles from 'e100-react-components/lib/styles/makeStyles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  avator: {
    backgroundColor: '#FAFAFA',
    border: `1px solid ${palette.grey[300]}`
  },
  avatorSmall: {
    width: spacing(3.75),
    height: spacing(3.75)
  }
}));
