import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback = (props: Props) => {
  const { error } = props;
  return (
    <div>{error.message}</div>
  );
};

interface Props extends FallbackProps {}

export default ErrorBoundaryFallback;
