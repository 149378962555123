import React from 'react';
import Icon from 'e100-react-components/lib/components/Icon';
import Button from 'e100-react-components/lib/components/Button';
import Paper from 'e100-react-components/lib/components/Paper';
import classNames from 'classnames';
import { useMediaQuery } from 'common/hooks';

import useStyles from './useStyles';
import useHandlers from './useHandlers';


const ZoomControl = () => {
  const { isMobile } = useMediaQuery();
  const classes = useStyles();
  const { onZoomIn, onZoomOut } = useHandlers();
  return (
    <Paper
      classes={{
        root: classNames({
          [classes.paper]: true,
          [classes.mobilePaper]: isMobile
        })
      }}
      elevation={3}
    >
      <Button
        onClick={onZoomIn}
        classes={{
          root: classNames({
            [classes.button]: true,
            [classes.mobileButton]: isMobile,
            [classes.topButton]: true
          })
        }}
        id="map-zoom-in"
      >
        <Icon kind={'add'} />
      </Button>
      <Button
        onClick={onZoomOut}
        classes={{
          root: classNames({
            [classes.button]: true,
            [classes.mobileButton]: isMobile,
            [classes.bottomButton]: true
          })
        }}
        id="map-zoom-out"
      >
        <Icon kind={'remove'} />
      </Button>
    </Paper>
  );
};

export default ZoomControl;
