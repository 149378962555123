import { isFrameUser } from './frameUser';

const ANONYMOUS_USER_IDS = {
  unauthorized: 'maps-unauthorized',
  frameUnauthorized: 'maps-frame-unauthorized'
};

export const getAnonymousUserId = () => {
  if (isFrameUser) return ANONYMOUS_USER_IDS.frameUnauthorized;
  return ANONYMOUS_USER_IDS.unauthorized;
};
