import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { IS_BROWSER_FIREFOX } from 'common/constants/browserType';

export const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    width: breakpoints.values.lg,
    paddingLeft: 0,
    paddingRight: 0
  },
  spacing: {
    '@media print': {
      display: IS_BROWSER_FIREFOX ? 'none' : undefined
    }
  },
  headerCell: {
    fontSize: 12,
    padding: spacing(),
    fontWeight: 800,
    lineHeight: '1.2',
    '@media print': {
      fontSize: 14
    },
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  },
  bodyCell: {
    fontSize: 14,
    padding: spacing(),
    '@media print': {
      fontSize: 16
    }
  },
  item: {
    marginBottom: spacing(0.5)
  }
}));
