// @ts-nocheck
import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Trace = (props: IProps) => (
  <SvgIcon {...props}>
    <path fill="#627180" d="M12,22.1c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S13.4,22.1,12,22.1z M12,18.3
      c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S12.7,18.3,12,18.3z"/>
    <circle fill="#627180" cx="12" cy="13.9" r="0.6"/>
      <path fill="#57AD62" d="M12,7.1c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S13.4,7.1,12,7.1z M12,3.3
        c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S12.7,3.3,12,3.3z"/>
    <circle fill="#627180" cx="12" cy="9.6" r="0.6"/>
  </SvgIcon>
);

export default Trace;
