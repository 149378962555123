import React from 'react';
import { BottomNavigationAction as E100BottomNavigationAction } from 'e100-react-components';
import { IProps } from 'e100-react-components/lib/components/BottomNavigationAction';

import useStyles from './styles';

export const BottomNavigationAction = (props: BottomNavigationActionProps) => {
  const classes = useStyles();
  return (
    <E100BottomNavigationAction
      {...props}
      classes={{
        root: classes.root,
        label: classes.label
      }}
    />
  );
};

export interface BottomNavigationActionProps extends IProps {}
