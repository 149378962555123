import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { NavigationHistoryPathType } from '../types';

import { LOCATION_HISTORY_PATH_TYPES } from '../constants';

export const useGetPathTitle = () => {
  const { formatMessage } = useIntl();

  const getPathTitle = useCallback((navigationHistoryPathType: NavigationHistoryPathType) => ({
    [LOCATION_HISTORY_PATH_TYPES.untracked]: formatMessage({ id: 'communicationLackWithOBU' }),
    [LOCATION_HISTORY_PATH_TYPES.parking]: formatMessage({ id: 'trafficStop' }),
    [LOCATION_HISTORY_PATH_TYPES.tracked]: ''
  }[navigationHistoryPathType] || formatMessage({ id: 'communicationLackWithOBU' })), [
    formatMessage
  ]);

  return getPathTitle;
};
