import { MARKER_TYPES, MarkerType as MType} from '../../constants/markerTypes';
import { MARKER_GROUP_TYPES, MarkerGroupType } from '../../constants/markerGroupTypes';
import { checkIsPlace } from './checkIconType';
import { checkIsCluster } from '../checkIconType';
import { ROADSIDE_SERVICES_CODES } from 'common/constants/index';
import {
  checkIsStation,
  getFirstByOrderRoadsideServiceCode
} from 'common/utils/index';

export interface MarkerType {
  markerType?: MType;
  markerGroupType?: MarkerGroupType;
  roadsideServiceCode?: number;
}

const createMarkerType = (
  markerType?: MType,
  markerGroupType?: MarkerGroupType,
  roadsideServiceCode?: number
): MarkerType => ({
  markerType,
  markerGroupType,
  roadsideServiceCode
});



const getStationType = (categoryCode: number) => ({
  2: MARKER_TYPES.discountPlusPetrolStation,
  4: MARKER_TYPES.powerMaxPetrolStation,
  5: MARKER_TYPES.economyPetrolStation
}[categoryCode] || MARKER_TYPES.petrolStation);

const getStationServiceType = (roadsideServiceCode: number) => ({
  [ROADSIDE_SERVICES_CODES.roadPay]: MARKER_TYPES.roadPaymentPoint,
  [ROADSIDE_SERVICES_CODES.parking]: MARKER_TYPES.parking,
  [ROADSIDE_SERVICES_CODES.carWash]: MARKER_TYPES.carWash,
  [ROADSIDE_SERVICES_CODES.autoService]: MARKER_TYPES.carService,
  [ROADSIDE_SERVICES_CODES.guard]: MARKER_TYPES.convoy,
  [ROADSIDE_SERVICES_CODES.boat]: MARKER_TYPES.ferry,
  [ROADSIDE_SERVICES_CODES.railway]: MARKER_TYPES.railwayPlatform,
  [ROADSIDE_SERVICES_CODES.store]: MARKER_TYPES.autoGoodsStore,
  [ROADSIDE_SERVICES_CODES.cafe]: MARKER_TYPES.cafe,
  [ROADSIDE_SERVICES_CODES.truckWash]: MARKER_TYPES.truckWash,
  [ROADSIDE_SERVICES_CODES.automobileWash]: MARKER_TYPES.carWash
}[roadsideServiceCode]);

const getMarker = (
  roadsideServiceCode: number,
  categoryCode: number
) => {
  if (checkIsStation(roadsideServiceCode)) {
    return createMarkerType(
      getStationType(categoryCode),
      MARKER_GROUP_TYPES.station,
      roadsideServiceCode
    );
  }

  return createMarkerType(
    getStationServiceType(roadsideServiceCode),
    MARKER_GROUP_TYPES.roadsideService,
    roadsideServiceCode
  );
};



export const getMarkerType = (
  d: any
): MarkerType => {

  if (checkIsCluster(d))
    return createMarkerType(MARKER_TYPES.cluster);

  if (checkIsPlace(d))
    return createMarkerType(MARKER_TYPES.place);

  if (!d.properties.roadsideServiceCodes.length) {
    return createMarkerType(
      MARKER_TYPES.petrolStation,
      MARKER_GROUP_TYPES.station
    );
  }

  const firstByOrderRoadsideServiceCode = getFirstByOrderRoadsideServiceCode(d.properties.roadsideServiceCodes);
  return getMarker(firstByOrderRoadsideServiceCode, d.properties.categoryCode);
};
