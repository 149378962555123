import { RoutePoint } from '../../types/routePoints';
import getCoordinatesFromString from 'common/utils/getCoordinatesFromString';
import { isGasStation, isPlace, isCoordinates } from 'common/utils/checkRoutePointType';

export const mappingRoutePoint = (item: RoutePoint): string => {
  if ((isPlace(item.type) || isGasStation(item.type)) && item.coordinates) {
    return `${item.coordinates.lat},${item.coordinates.lng}`;
  };
  if (isCoordinates(item.type) && item.value) {
    const [lat, lng] = getCoordinatesFromString(item.value);
    return `${lat},${lng}`;
  }

  return '';
};
