import React from 'react';
import Grid from 'e100-react-components/lib/components/Grid';
import LeftColumn from './LeftColumn';

import useStyles from './useStyles';
import { useMediaQuery } from 'common/hooks';

import { Props as MapProps } from '../index';

const Footer = ({
  footerElements,
  showMapTypeControl,
  showStationsDisplayButton
}: Props) => {
  const { leftColumn, rightColumn } = footerElements || {};
  const { isMobile } = useMediaQuery();
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.container}>
      <Grid container justify="space-between">
        <LeftColumn
          leftColumnElements={leftColumn}
          showMapTypeControl={showMapTypeControl}
          showStationsDisplayButton={showStationsDisplayButton}
        />
        <Grid item>{rightColumn}</Grid>
      </Grid>
    </div>
  );
};

export interface Props extends Pick<
  MapProps,
  'footerElements' | 'showMapTypeControl' | 'showStationsDisplayButton'
> { }

export default Footer;
