import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette, zIndex, typography }) => ({
  container: {
    display: 'inline-block',
    fontSize: typography.caption.fontSize,
    padding: `${spacing(0.5)}px ${spacing(0.75)}px`,
    borderRadius: 5,
    backgroundColor: palette.action.active,
    color: palette.common.white,
    position: 'fixed',
    zIndex: zIndex.drawer,
    transform: 'translate(-50%, -150%)'
  }
}));

export default useStyles;
