import { useEffect, useCallback } from 'react';
import usePreviousState from 'common/hooks/usePreviousState';

import { Props as MapProps } from '../index';

const useShowRoutes = (routes: MapProps['routes']) => {
  const prevRoutes = usePreviousState<MapProps['routes']>(routes);

  const deletePreviousRoutes = useCallback(() => {
    (prevRoutes || []).forEach(route => {
      route.delete();
    });
  }, [prevRoutes]);

  const addRoutes = useCallback(() => {
    (routes || []).forEach(route => {
      route.show();
    });
  }, [routes]);

  useEffect(() => {
    deletePreviousRoutes();
    addRoutes();
    // eslint-disable-next-line
  }, [addRoutes]);

};

export default useShowRoutes;
