import { AppThunk } from 'store';
import dataServices from 'dataServices';
import { batch } from 'react-redux';
import { StatusLoading } from 'common/constants';
import { DEFAULT_BBOX } from 'shared/constants'; 
import { getResponseError } from 'common/utils/index';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';
import { IStationFilter } from '../types/stationFilter';
import {
  mappingStations,
  MappingStationResult,
  mappingStationCodes
} from '../utils';

const {
  setAllStations,
  setIsLoadingAllStations,
  setStations,
  setActionStations,
  setActionStationsFitBounds,
  setStationsFitBounds,
  setFilter
} = slice.actions;
const { setErrorNotice } = applicationActions;

const operation = (
  urlFilter?: IStationFilter,
  isSetBounds: boolean = true
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingAllStations(StatusLoading.Load));
    let mappedData: MappingStationResult | null = null;
    
    const [{
      value: allStations = []
    }, {
      value: stationCodes
    }] = await dataServices.stations.initStations(urlFilter);

    if (stationCodes) {
      mappedData = await mappingStationCodes(stationCodes, allStations);
    } else {
      mappedData = await mappingStations(allStations);
    }

    batch(() => {
      dispatch(setIsLoadingAllStations(StatusLoading.Done));      
      dispatch(setAllStations(allStations));
      if (isSetBounds) {
        const latLngBounds = urlFilter && mappedData ? mappedData.stationsBBox : DEFAULT_BBOX;
        dispatch(setStationsFitBounds(latLngBounds));
      }
      if (urlFilter) {
        dispatch(setFilter(urlFilter));
      }
      if (mappedData) {
        dispatch(setActionStationsFitBounds(mappedData.actionStationsBBox));
        dispatch(setStations(mappedData.stations));
        dispatch(setActionStations(mappedData.actionStations));
      }
    });
  } catch(e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setIsLoadingAllStations(StatusLoading.Error));
    });
  }
};

export default operation;
