import React from 'react';
import {
  Container,
  Typography,
  Table,
  Grid
} from 'e100-react-components';

import { useStyles } from './styles';
import {
  useReportTableColumns,
  useMappedReport
} from '../../hooks';

export const ReportTable = ({
  title,
  isDocumentPrint
}: ReportTableProps) => {
  const classes = useStyles({ isDocumentPrint });
  const reportTableColumns = useReportTableColumns();
  const { items = [] } = useMappedReport();

  return (
    <Container classes={{ root: classes.container }}>
      <br className={classes.spacing} />
      <br className={classes.spacing} />
      {title && <Typography align="center" variant="h5">{title}</Typography>}
      <br />
      <Grid container>
        <Grid item xs={12}>
          <Table
            data={items}
            columns={reportTableColumns}
            translate={() => ''}
            classes={{
              header: {
                cell: classes.headerCell
              },
              body: {
                cell: classes.bodyCell
              }
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

interface ReportTableProps {
  title?: string;
  isDocumentPrint?: boolean;
}