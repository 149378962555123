import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const AddBlue = (props: IProps) => (
  <SvgIcon {...props}>
    <path id="XMLID_13_" fill="#627180" d="M10,21.7c-2,0-4.1-0.8-5.7-2.3l0,0c-3.1-3.1-3.1-8.2,0-11.3L10,2.3L15.7,8
      c3.1,3.1,3.1,8.2,0,11.3C14.1,20.9,12,21.7,10,21.7z M5.8,17.9c2.3,2.3,6.1,2.3,8.5,0c2.3-2.3,2.3-6.1,0-8.5L10,5.2L5.8,9.4
      C3.4,11.8,3.4,15.6,5.8,17.9L5.8,17.9z"/>
    <path id="XMLID_11_" fill="#57AD62" d="M9,7.3h11c1.1,0,2,0.9,2,2v8c0,1.1-0.9,2-2,2H9c-1.1,0-2-0.9-2-2v-8
      C7,8.2,7.9,7.3,9,7.3z"/>
    <path id="XMLID_8_" fill="#FFFFFF" d="M10.6,9.3c-0.4,0-0.8,0.2-1.1,0.5C9.2,10.1,9,10.5,9,10.9v6.4h1.6v-3.2h1.6v3.2h1.6
      v-6.4c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.5-1.1-0.5H10.6z M10.6,10.9h1.6v1.6h-1.6V10.9z"/>
    <path id="XMLID_4_" fill="#FFFFFF" d="M20.2,12.1v-1.2c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.5-1.1-0.5h-3.2v8h3.2
      c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1v-1.2c0-0.6-0.6-1.2-1.2-1.2C19.6,13.3,20.2,12.8,20.2,12.1z M18.6,15.7H17v-1.6
      h1.6V15.7z M18.6,12.5H17v-1.6h1.6V12.5z"/>
  </SvgIcon>
);

export default AddBlue;
