import makeStyles from 'e100-react-components/lib/styles/makeStyles';
import { searchFieldWidth } from 'pages/Map/Controls/DesktopControls/useStyles';

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '50vh',
    borderBottom: 'none'
  },
  contentRoot: {
    padding: `${theme.spacing()}px 0`,
    position: 'relative'
  },
  scrollPaper: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  fullScreenPaper: {
    width: searchFieldWidth(theme),
    margin: theme.spacing(2),
    pointerEvents: 'all',
    position: 'relative',
    height: `calc(100% - ${theme.spacing(4)}px)`,
    maxHeight: '100%'
  },
  paper: {
    overflowY: 'visible',
    transform: 'translateX(0)',
    transition: theme.transitions.create(['transform'])
  },
  root: {
    pointerEvents: 'none'
  },
  titleRoot: {
    backgroundColor: 'transparent',
    position: 'relative'
  },
  mobileTitleRoot: {
    boxShadow: theme.shadows[2]
  },
  bottomPositionScrollPaper: {
    height: 'auto',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    pointerEvents: 'all'
  },
  bottomPositionPaper: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  contentContainer: {
    overflow: 'auto',
    height: '100%'
  }
}));

export default useStyles;