import { PayloadAction } from "@reduxjs/toolkit";
import { Data } from './setNotice';
import { ApplicationState } from "../../types";

import { NOTICE_TYPES } from '../../constants';

export const setErrorNotice = (
  state: ApplicationState,
  { payload }: PayloadAction<Data>
) => {
  const { title: text = '', translateId = '', data = {} } = payload || {};
  state.notice = { text, translateId, data, type: NOTICE_TYPES.error };
};
