import React, { useRef } from 'react';
import FilledTextField from 'components/FilledTextFiled';
import { IPropTypes } from 'e100-react-components/lib/components/TextField';

import usePhoneMask from './usePhoneMask';


const PhoneTextField = (props: IPropTypes) => {
  const { label, value, name = '', onChange = () => {}, InputProps = {}, error, helperText } = props;
  const inp = useRef<HTMLInputElement>(null);

  usePhoneMask(inp, onChange);

  return (
    <FilledTextField
      label={label}
      value={value}
      name={name}
      fullWidth
      inputRef={inp}
      InputProps={InputProps}
      error={error}
      helperText={helperText}
    />
  )
};


export default PhoneTextField;
