import { PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_BBOX } from 'shared/constants';
import { BBox } from "@turf/helpers";
import { getValidFitBounds } from '../utils';

import { IState } from '../types/state';

const setStationsFitBounds = (state: IState, action: PayloadAction<BBox>) => {
  const fitBounds = action.payload.length ? action.payload : DEFAULT_BBOX;
  state.stations.fitBounds = getValidFitBounds(fitBounds);
};

export default setStationsFitBounds;
