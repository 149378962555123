import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { checkIsLoading, checkIsLoadingDone, checkIsLoadingError } from 'common/utils/checkLoading';
import { checkIsMapsClient } from 'common/utils/checkUserId';

import { STATE } from '../types/state';

export const useLoadingStatus = () => {
  const loadingStatus = useSelector((rootState: RootState) => rootState[STATE].user.loadingStatus);
  return loadingStatus;
};

export const useIsUserLoading = () => {
  const loadingStatus = useLoadingStatus();
  return checkIsLoading(loadingStatus);
};

export const useIsUserLoadingDone = () => {
  const loadingStatus = useLoadingStatus();
  return checkIsLoadingDone(loadingStatus);
};

export const useIsUserLoadingError = () => {
  const loadingStatus = useLoadingStatus();
  return checkIsLoadingError(loadingStatus);
};

export const useUser = () => {
  const user = useSelector((rootState: RootState) => rootState[STATE].user.data);
  return user;
};

export const useIsMapsClient = () => {
  const { clientId } = useUser() || {};
  return checkIsMapsClient(clientId);
};

export const useIsClient = () => {
  const { userType = '' } = useUser() || {};
  return userType.toString().toLowerCase() === 'client';
};

export const useIsRegionalClient = () => {
  const { userType = '' } = useUser() || {};
  return userType.toString().toLowerCase() === 'regional';
};
