import { useEffect } from 'react';
import { useMapContext } from '../context/map';
import { FitBounds } from 'common/types/map/fitBounds';
import { MapPadding } from 'common/utils/Map';

type UseFitBounds = (fitBounds?: FitBounds, fitBoundsPadding?: MapPadding) => void;

const useFitBounds: UseFitBounds = (fitBounds, fitBoundsPadding) => {
  const { setFitBounds } = useMapContext();

  useEffect(() => {
    if (fitBounds && fitBounds.ne !== undefined && fitBounds.sw !== undefined) {
      setFitBounds(fitBounds, fitBoundsPadding);
    }
    // eslint-disable-next-line 
  }, [fitBounds, setFitBounds]);
};

export default useFitBounds;