import { combineReducers } from 'redux';

import auth from './features/auth';
import { STATE as AUTH_STATE } from './features/auth/types/state';

import station from './features/station';
import { STATE as STATION_STATE } from './features/station/types/state';

import directories from './features/directories';
import { STATE as DIRECTORIES_STATE } from './features/directories/types/state';

import stations from './features/stations';
import { STATE as STATIONS_STATE } from './features/stations/types/state';

import cards from './features/cards';
import { STATE as CARDS } from './features/cards/types/state';

import user from './features/user';
import { STATE as USER } from './features/user/types/state';

import {
  APPLICATION_STATE,
  applicationReducer
} from 'entities/application';

import {
  REPORTS_STATE,
  reportsReducer
} from 'entities/reports';

import {
  NAVIGATION_STATE,
  navigationReducer
} from 'entities/navigation';

import {
  MAP_STATE,
  mapReducer
} from 'entities/map';

import {
  CARS_STATE,
  carsReducer
} from 'entities/cars';

import {
  STATIONS_STATE as STATIONS_WITH_PRICES_STATE,
  stationsReducer
} from 'entities/stations';

const reducer = combineReducers({
  [APPLICATION_STATE]: applicationReducer,
  [AUTH_STATE]: auth,
  [STATION_STATE]: station,
  [DIRECTORIES_STATE]: directories,
  [STATIONS_STATE]: stations,
  [CARDS]: cards,
  [USER]: user,
  [REPORTS_STATE]: reportsReducer,
  [NAVIGATION_STATE]: navigationReducer,
  [MAP_STATE]: mapReducer,
  [CARS_STATE]: carsReducer,
  [STATIONS_WITH_PRICES_STATE]: stationsReducer
});

export default reducer;
