import { makeStyles } from 'e100-react-components';
import { ITheme } from 'types/theme';
import { CONTROLS_Z_INDEX } from 'common/constants/styles';

const useStyles = makeStyles((theme) => ({
  col: {
    position: 'absolute',
    top: 0,
    margin: theme.spacing(2),
    display: 'block',
    zIndex: CONTROLS_Z_INDEX
  },
  leftCol: {
    left: 0
  },
  rightCol: {
    right: 0
  },
  search: {
    width: searchFieldWidth(theme)
  },
}));

export const searchFieldWidth = ({ spacing }: ITheme) => spacing(47);

export default useStyles;

