import { Leg } from 'common/utils/Route/types/leg';
import { RoutePoint } from '../../types/routePoints';

type MappingLegs = (legs: google.maps.DirectionsLeg[], allWaypoints: RoutePoint[]) => Leg[];

const getLegName = (index: number): string => (
  index === 0 ? 'startPoint' : `waypoint${index}`
);

const mappingLegs: MappingLegs = (legs, allWaypoints) => legs
  .reduce((acc: Leg[], item, index) => {
    const { start_location: location, distance } = item;
    const { value: address = '' } = allWaypoints[index];

    acc.push({ address, location: location.toJSON(), distance: distance?.text || '', name: getLegName(index) });

    if (index === legs.length - 1) {
      const { value: endAddress = '' } = allWaypoints[index + 1];
      acc.push({ address: endAddress, location: item.end_location.toJSON(), distance: '', name: 'endPoint' });
    }

    return acc;
  }, []);

export default mappingLegs;
