import React from 'react';

import { Typography } from 'e100-react-components';

import { useStyles } from './styles';

export const LocationTitle = ({
  children,
  padding = {}
}: LocationTitleProps) => {
  const classes = useStyles({ padding });
  return (
    <Typography
      variant="subtitle2"
      classes={{ root: classes.title }}
    >
      {children}
    </Typography>
  )
};

interface Padding {
  top?: number;
  bottom?: number;
}

export interface LocationTitleProps {
  children: string;
  padding?: Padding;
}