import useBindActionCreators from 'common/hooks/useBindActionCreators';

import { carOperations } from '../store';

type CarOperations = typeof carOperations;

export const useCarOperations = (): CarOperations => {
  const bindedActions = useBindActionCreators<CarOperations>(carOperations);
  return bindedActions;
};
