import React, { FC } from 'react';
import makeStyles from 'e100-react-components/lib/styles/makeStyles';
import InputAdornment from 'e100-react-components/lib/components/InputAdornment';
import { IPropTypes } from 'e100-react-components/lib/components/InputAdornment';
import IconComponent, { Props as IconProps } from 'components/Icon';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0 !important'
  }
}));

const TextFieldIcon: FC<IProps> = (props) => {
  const { kind, position = "start" } = props;
  const classes = useStyles();
  return (
    <InputAdornment position={position} classes={{ root: classes.root }}>
      <IconComponent kind={kind} color="action" />
    </InputAdornment>
  );
};

interface IProps extends Pick<IconProps, 'kind'>{
  position?: IPropTypes['position'];
};

export default TextFieldIcon;
