import { useEffect, useCallback } from 'react';
import { directionsServiceFactory } from 'common/utils/directionsService';
import { useOperations as useStationOperations } from 'store/features/station';
import { useIntl } from 'react-intl';

import { Props as MapProps } from '../index';
import { useMapContext } from '../context/map';

type UseInitRoutes = (
  routesSettings: Required<MapProps>['routesSettings'],
  routesPoints: MapProps['routesPoints'],
  onChangeRoutes: MapProps['onChangeRoutes'],
  routeModifiers: MapProps['routeModifiers']
) => void;

const useInitRoutes: UseInitRoutes = (routesSettings, routesPoints, onChangeRoutes, routeModifiers) => {
  const { resetSelectedPlace, updateSelectedCoordinates } = useStationOperations();
  const { map } = useMapContext();
  const { formatMessage } = useIntl();

  const initRoutes = useCallback(async () => {
    try {
      if (routesPoints) {
        const directionsService = directionsServiceFactory(map);
        const routes = await directionsService.getRoutes(routesPoints, formatMessage, routeModifiers, routesSettings);
        console.log('routes: ', routes);
        console.log('');
        if (routesSettings.showInfoWindow) routes?.forEach(route => route.addInfoWindow());
        if (onChangeRoutes) onChangeRoutes(routes);
        updateSelectedCoordinates();
        resetSelectedPlace();
      }
    } catch (e) {
      if (onChangeRoutes) onChangeRoutes(undefined, e as string);
    }
    // eslint-disable-next-line
  }, [map, routesPoints, formatMessage, onChangeRoutes, resetSelectedPlace, updateSelectedCoordinates, routesSettings]);

  useEffect(() => {
    initRoutes();
  }, [initRoutes]);
};

export default useInitRoutes;
