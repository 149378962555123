import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

import { STATE } from '../types/state';

const selector = createSelector(
  (state: RootState) => state[STATE].selectedStation || {},
  selectedStation => !!Object.keys(selectedStation).length
);

export default selector;