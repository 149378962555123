import { RestProvider } from 'e100-data-providers';
import { IConfig } from 'config';
import * as authStorageProvider from 'dataSources/authStorageProvider';
import getCustomHeaders from 'dataSources/utils/getCustomHeaders';

import Common from './common';
import Auth from './auth';
import Stations from './stations';
import { ReportsApi } from 'entities/reports';
import { NavigationApi } from 'entities/navigation';
import { CarsApi } from 'entities/cars';
import { StationsApi } from 'entities/stations';
import Cards from './cards';
import User from './user';

const getApiTypicode = (config: IConfig) => {
  const provider = new RestProvider({ ...config, getCustomHeaders }, authStorageProvider);
  const authProvider = config.authServiceName
    ? new RestProvider({ ...config, serviceName: config.authServiceName, getCustomHeaders }, authStorageProvider)
    : provider;

  return {
    getProvider: () => provider,
    common: new Common(provider),
    auth: new Auth(authProvider),
    stations: new Stations(provider),
    reports: new ReportsApi(provider),
    cards: new Cards(provider),
    user: new User(provider),
    navigation: new NavigationApi(provider),
    cars: new CarsApi(provider),
    stationsWithPrices: new StationsApi(provider)
  };
};

export default getApiTypicode;

