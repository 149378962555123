import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ zIndex }) => ({
  root: {
    zIndex: zIndex.snackbar,
    '& > div': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& > div > div': {
      width: '100%'
    }
  }
}));

export default useStyles;
