export const PERMISSIONS = {
  optionsLangSelect: 'options-lang-select',
  gotoSiteMapVersionSelect: 'goto-site-map-version-select',
  gotoSiteMapUserRegional: 'goto-site-map-user-regional',
  gotoSiteE100: 'goto-site-e100',
  gotoSiteClient: 'goto-site-client',
  loginSiteClient: 'login-site-client',
  logoutSiteClient: 'logout-site-client',
  supportCallRequest: 'support-call-request',
  supportCardRequest: 'support-card-request',
  mapDataView: 'map-data-view',
  mapServicePointView: 'map-service-point-view',
  mapServicePointGroupView: 'map-service-point-group-view',
  mapRouteView: 'map-route-view',
  mapLegendView: 'map-legend-view',
  servicePointView: 'service-point-view',
  servicePointSearchE100Code: 'service-point-search-e100-code',
  // TODO: refact in the feature (LOCATOR-824)
  servicePointFilterPromoSelect: 'service-point-filter-promo-select-disabled',
  servicePointFilterCountrySelect: 'service-point-filter-country-select',
  servicePointFilterCountryRegionSelect: 'service-point-filter-country-region-select',
  servicePointFilterBrandSelect: 'service-point-filter-brand-select',
  servicePointFilterMapTrackSelect: 'service-point-filter-map-track-select',
  servicePointFilterCategorySelect: 'service-point-filter-category-select',
  servicePointFilterRoadsideTypeSelect: 'service-point-filter-roadside-type-select',
  servicePointFilterServiceSelect: 'service-point-filter-service-select',
  servicePointFilterAdditionalServiceSelect: 'service-point-filter-additional-service-select',
  servicePointFilterVehicleTypeSelect: 'service-point-filter-vehicle-type-select',
  servicePointFilterServiceFormatSelect: 'service-point-filter-service-format-select',
  servicePointFilterConfirm: 'service-point-filter-confirm',
  servicePointFilterClear: 'service-point-filter-clear',
  buildingRouteBrandSelect: 'building-route-brand-select',
  buildingRouteServiceSelect: 'building-route-service-select',
  buildingRouteServicePointCategorySelect: 'building-route-service-point-category-select',
  buildingRouteServicePointServiceFormatSelect: 'building-route-service-point-service-format-select',
  buildingRouteServicePointRoadsideTypeSelect: 'building-route-service-point-roadside-type-select',
  buildingRouteAdditionalServiceSelect: 'building-route-additional-service-select',
  buildingRouteVehicleTypeSelect: 'building-route-vehicle-type-select',
  buildingRouteBuild: 'building-route-build',
  buildingRouteClear: 'building-route-clear',
  servicePointTrackRouteSectionPrint: 'service-point-track-route-section-print',
  servicePointPriceTrackRouteSectionPrint: 'service-point-price-track-route-section-print',
  servicePointRouteSectionPrint: 'service-point-route-section-print',
  servicePointPriceRouteSectionPrint: 'service-point-price-route-section-print',
  servicePointExportExcel: 'service-point-export-excel',
  servicePointExportCsv: 'service-point-export-csv',
  servicePointPriceExportExcel: 'service-point-price-export-excel',
  servicePointPriceExportCsv: 'service-point-price-export-csv',
  servicePointTrackExportExcel: 'service-point-track-export-excel',
  servicePointTrackExportCsv: 'service-point-track-export-csv',
  servicePointPriceTrackExportExcel: 'service-point-price-track-export-excel',
  servicePointPriceTrackExportCsv: 'service-point-price-track-export-csv',
  servicePointViewAction: 'service-point-view-action',
  servicePointViewBrand: 'service-point-view-brand',
  servicePointViewServiceFormat: 'service-point-view-service-format',
  servicePointViewLocaltion: 'service-point-view-location',
  servicePointViewRoadsideType: 'service-point-view-roadside-type',
  servicePointViewServices: 'service-point-view-services',
  servicePointViewAdditionalServices: 'service-point-view-additional-services',
  servicePointViewServicePrices: 'service-point-view-services-prices',
  buildingRouteAvoidSomeTypesRoad: 'building-route-avoid-some-types-road',
  servicePointViewPaymentMethods: 'service-point-view-payment-methods',
  servicePointFilterPaymentMethodsSelect: 'service-point-filter-payment-methods-select',
  buildingRoutePaymentMethodsSelect: 'building-route-payment-methods-select'
} as const;

export type TPermissions = typeof PERMISSIONS;