interface NewUrlParams {
  [key: string]: string;
}

const addURLParams = (newUrlParams: NewUrlParams | string) => {
  const url = new URL(window.location.href);
  if (typeof newUrlParams === 'string') {
    const oldParams = url.searchParams.toString();
    const newUrl = `${url.origin}${url.pathname}?${oldParams}${oldParams ? '&' : ''}${newUrlParams}`;
    window.history.replaceState({}, 'new params', newUrl);
  } else {
    Object.keys(newUrlParams).forEach((urlParamName) => {
      url.searchParams.append(urlParamName as string, newUrlParams[urlParamName]);
    });
    window.history.replaceState({}, 'new params', url.toString());
  }
};

export default addURLParams;
