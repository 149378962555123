import { useMemo } from 'react';
import classNames from 'classnames';

import useInternalClasses from '../useStyles';
import { IProps as IModalProps } from '../index';

interface IProps extends Pick<
  IModalProps,
  'classes' | 'fullScreen' | 'bottomPosition'
> {}

const useModalDialogClasses = ({
  classes,
  fullScreen,
  bottomPosition
}: IProps) => {
  const {
    dialog: dialogClasses = {},
    content: contentClasses = {},
    title: titleClasses = {}
  } = classes || {};
  const internalClasses = useInternalClasses();

  const modalDialogClasses = useMemo(() => ({
    content: {
      root: classNames({
        [contentClasses.root || '']: true,
        [internalClasses.contentRoot]: true,
        [internalClasses.content]: !fullScreen
      })
    },
    dialog: {
      scrollPaper: classNames({
        [internalClasses.scrollPaper]: !fullScreen,
        [internalClasses.bottomPositionScrollPaper]: bottomPosition
      }),
      paper: classNames({
        [dialogClasses.paper || '']: !!dialogClasses.paper,
        [internalClasses.paper]: true,
        [internalClasses.fullScreenPaper]: !fullScreen,
        [internalClasses.bottomPositionPaper]: bottomPosition
      }),
      root: classNames({
        [internalClasses.root]: !fullScreen,
        [dialogClasses.root || '']: true
      })
    },
    title: {
      root: classNames({
        [internalClasses.titleRoot]: true,
        [internalClasses.mobileTitleRoot]: fullScreen,
        [titleClasses.root || '']: !!titleClasses.root
      })
    }
  }), [
    dialogClasses,
    contentClasses,
    internalClasses,
    fullScreen,
    bottomPosition,
    titleClasses.root
  ]);

  return modalDialogClasses;
};

export default useModalDialogClasses;
