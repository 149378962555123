import React from 'react';
import { Tooltip } from 'e100-react-components';

import { copyToClipboard } from 'common/utils/index';

import { useApplicationOperations } from 'entities/application';
import { useIntl } from 'react-intl';
import { useStyles } from './useStyles';

const CopyingTextToClipboard = ({
  children
}: CopyingTextToClipboardProps) => {
  const { setErrorNotice, setSuccessNotice } = useApplicationOperations();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  if (!children) return null;
  return (
    <Tooltip
      title={formatMessage({ id: 'replicate' })}
      enterDelay={1000}
      enterNextDelay={1000}
      enterTouchDelay={0}
    >
      <div
        className={classes.root}
        role='button'
        onClick={async (event) => {
          event.stopPropagation();
          event.preventDefault();
          try {
            await copyToClipboard(children);
            setSuccessNotice({ translateId: 'copied' });
          } catch (e) {
            setErrorNotice({ translateId: 'somethingGoWrong' });
          }
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export interface CopyingTextToClipboardProps {
  children?: string;
}

export default CopyingTextToClipboard;
