import { TOperation } from 'common/types/store/operation';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import { batch } from 'react-redux';
import dataSources from 'dataSources';
import { getResponseError } from 'common/utils/index';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { setUserStateE100MobilityStatusLoading, setUserStateE100Mobility } = slice.actions;
const { setErrorNotice } = applicationActions;

const operation: TOperation = () => async (dispatch) => {
  try {
    dispatch(setUserStateE100MobilityStatusLoading(STATUS_LOADING.load));

    const data = await dataSources.common.getUserStateE100Mobility();

    batch(() => {
      dispatch(setUserStateE100MobilityStatusLoading(STATUS_LOADING.done));
      dispatch(setUserStateE100Mobility(data));
    });
  } catch (e) {
    const error = await getResponseError(e);

    batch(() => {      
      dispatch(setErrorNotice(error));
      dispatch(setUserStateE100MobilityStatusLoading(STATUS_LOADING.error));
    });
  }
};

export default operation;