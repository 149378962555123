import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Action = (props: IProps) => (
  <SvgIcon {...props}>
    <mask id="path-1-inside-1_3517_39737" fill="white">
    <path d="M23 9.75V5.25C23 4.0125 22.01 3 20.8 3H3.2C1.99 3 1.011 4.0125 1.011 5.25V9.75C2.221 9.75 3.2 10.7625 3.2 12C3.2 13.2375 2.221 14.25 1 14.25V18.75C1 19.9875 1.99 21 3.2 21H20.8C22.01 21 23 19.9875 23 18.75V14.25C21.79 14.25 20.8 13.2375 20.8 12C20.8 10.7625 21.79 9.75 23 9.75Z"/>
    </mask>
    <path d="M23 9.75V5.25C23 4.0125 22.01 3 20.8 3H3.2C1.99 3 1.011 4.0125 1.011 5.25V9.75C2.221 9.75 3.2 10.7625 3.2 12C3.2 13.2375 2.221 14.25 1 14.25V18.75C1 19.9875 1.99 21 3.2 21H20.8C22.01 21 23 19.9875 23 18.75V14.25C21.79 14.25 20.8 13.2375 20.8 12C20.8 10.7625 21.79 9.75 23 9.75Z" fill="url(#paint0_linear_3517_39737)"/>
    <path d="M23 9.75V10.35C23.3314 10.35 23.6 10.0814 23.6 9.75H23ZM1.011 9.75H0.411C0.411 10.0814 0.679629 10.35 1.011 10.35V9.75ZM1 14.25V13.65C0.668629 13.65 0.4 13.9186 0.4 14.25H1ZM23 14.25H23.6C23.6 13.9186 23.3314 13.65 23 13.65V14.25ZM23.6 9.75V5.25H22.4V9.75H23.6ZM23.6 5.25C23.6 3.69391 22.354 2.4 20.8 2.4V3.6C21.666 3.6 22.4 4.33109 22.4 5.25H23.6ZM20.8 2.4H3.2V3.6H20.8V2.4ZM3.2 2.4C1.64213 2.4 0.411 3.69788 0.411 5.25H1.611C1.611 4.32712 2.33787 3.6 3.2 3.6V2.4ZM0.411 5.25V9.75H1.611V5.25H0.411ZM1.011 10.35C1.87313 10.35 2.6 11.0771 2.6 12H3.8C3.8 10.4479 2.56887 9.15 1.011 9.15V10.35ZM2.6 12C2.6 12.9209 1.87506 13.65 1 13.65V14.85C2.56694 14.85 3.8 13.5541 3.8 12H2.6ZM0.4 14.25V18.75H1.6V14.25H0.4ZM0.4 18.75C0.4 20.3061 1.64599 21.6 3.2 21.6V20.4C2.33401 20.4 1.6 19.6689 1.6 18.75H0.4ZM3.2 21.6H20.8V20.4H3.2V21.6ZM20.8 21.6C22.354 21.6 23.6 20.3061 23.6 18.75H22.4C22.4 19.6689 21.666 20.4 20.8 20.4V21.6ZM23.6 18.75V14.25H22.4V18.75H23.6ZM23 13.65C22.134 13.65 21.4 12.9189 21.4 12H20.2C20.2 13.5561 21.446 14.85 23 14.85V13.65ZM21.4 12C21.4 11.0811 22.134 10.35 23 10.35V9.15C21.446 9.15 20.2 10.4439 20.2 12H21.4Z" fill="#795100" fill-opacity="0.52" mask="url(#path-1-inside-1_3517_39737)"/>
    <path d="M8.29356 17L12.0063 14.7022L15.7191 17L14.5913 12.8933L18 10.2044L13.603 9.94778L12.0063 6L10.397 9.93556L6 10.1922L9.40866 12.8811L8.29356 17Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_3517_39737" x1="12" y1="4" x2="12" y2="20" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFD970"/>
    <stop offset="1" stop-color="#E9AD35"/>
    </linearGradient>
    </defs>
  </SvgIcon>
);

export default Action;
