/* eslint-disable */
// @ts-nocheck
import { useEffect } from 'react';
import { useUser } from 'store/features/auth/selectors/user';
import { useLocale } from 'entities/application';

const isExistUserCode = (code) => {
  if (!window.stonlyLocatorUserCodes) return true;
  const userCodes = window.stonlyLocatorUserCodes.toString().split(',').map(item => item.trim());
  return userCodes.find(userCode => userCode === code);
};

const useStonly = () => {
  const { clientCode = '' } = useUser() || {};
  const locale = useLocale();
  const stonlyKey = process.env.REACT_APP_STONLY_KEY;

  useEffect(() => {
    if ((stonlyKey && clientCode && !!isExistUserCode(clientCode))) {
      try {
        window.STONLY_WID = stonlyKey;
        !function ( s, t, o, n, l, y, w, g ) {
          s.StonlyWidget || (
            (w = s.StonlyWidget = function () {
              w._api
              ? w._api.apply( w, arguments )
              : w.queue.push( arguments )
            }).scriptPath = n,
            w.queue = [],
            (y = t.createElement(o)).async = !0,
            (g = new XMLHttpRequest).open('GET', n + 'version?v=' + Date.now(), !0),
            g.onreadystatechange = function(){
            4 === g.readyState && (y.src = n + 'stonly-widget.js?v=' + (200 === g.status ? g.responseText : Date.now()),
            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l))
          },
          g.send())
        }(window, document, 'script', 'https://stonly.com/js/widget/v2/');
  
        !function(s,t,o,n,l,y,_){s.stonlyTrack||((_=s.stonlyTrack=function(){
          _._api?_._api.apply(_,arguments):_.queue.push(arguments)
          }).queue=[],(y=t.createElement(o)).async=!0,
          y.src=n,(l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))
          }(window,document,"script","https://stonly.com/js/tracker/stn.js");
  
          stonlyTrack('init', stonlyKey);
      } catch (e) {
        console.error('stonly error: ', e);
      }
    }
  }, [
    clientCode,
    stonlyKey
  ]);

  useEffect(() => {
    if (window.STONLY_WID && clientCode) {
      stonlyTrack('identify', clientCode, {
        'locator_locale': locale,
        'locator_client_code': clientCode
      });
    }
  }, [locale, clientCode])
};

export default useStonly;
