import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const DirectStart = (props: IProps) => (
  <SvgIcon {...props}>
    <path id="XMLID_2_" style={{ opacity: 0.24 }} d="M8.6,3.7C9.6,3.2,10.8,3,12,3s2.4,0.2,3.4,0.7
    c1.1,0.5,2.1,1.1,2.9,2c0.8,0.8,1.5,1.8,2,2.9C20.8,9.6,21,10.8,21,12c0,2.4-0.9,4.7-2.6,6.4c-1.7,1.7-4,2.6-6.4,2.6
    c-1.2,0-2.4-0.2-3.4-0.7c-1.1-0.5-2.1-1.1-2.9-2C3.9,16.7,3,14.4,3,12c0-2.4,0.9-4.7,2.6-6.4C6.5,4.8,7.5,4.1,8.6,3.7z"/>
    <path id="XMLID_1_" d="M10.1,7.4C10.7,7.1,11.3,7,12,7s1.3,0.1,1.9,0.4c0.6,0.3,1.2,0.6,1.6,1.1
      c0.5,0.5,0.8,1,1.1,1.6c0.3,0.6,0.4,1.3,0.4,1.9c0,1.3-0.5,2.6-1.5,3.5C14.6,16.5,13.3,17,12,17c-0.7,0-1.3-0.1-1.9-0.4
      c-0.6-0.3-1.2-0.6-1.6-1.1C7.5,14.6,7,13.3,7,12s0.5-2.6,1.5-3.5C8.9,8,9.5,7.6,10.1,7.4z"/>
  </SvgIcon>
);

export default DirectStart;
