import { IntlFormatters } from '@formatjs/intl';
import { Distance } from 'common/utils/Route/types/distance';

type GetDistance = (legs: google.maps.DirectionsLeg[], formatMessage: IntlFormatters['formatMessage']) => Distance;

const getDistance: GetDistance = (legs, formatMessage) => {
  const distance = Math
    .round(legs.reduce((acc, { distance }) => (distance ? acc + distance.value : acc), 0) / 1000);

  return `${distance} ${formatMessage({ id: 'km' })}`
}

export default getDistance;
