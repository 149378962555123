import { AppThunk } from 'store';

import { APPLICATION_STATE } from '../..//constants';

import { applicationActions } from '../reducer';

const { setShowOrderCardsModal } = applicationActions;

export const toggleShowOrderCardsModal = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const { showOrderCardsModal } = state[APPLICATION_STATE];
  dispatch(setShowOrderCardsModal(!showOrderCardsModal));
}