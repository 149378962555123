import { useMemo } from 'react';
import { useIsActiveGoogleMap } from 'entities/application';
import { useSelectedStationCode } from 'store/features/station';

import { Stations } from 'dataSources/Typicode/stations';
import {
  GoogleLayer,
  HereLayer
} from 'common/utils/MapMarker/types';

import {
  getIcon,
  getPosition,
  getSize
} from '../utils';
import getLargestClusterIcon from '../assets/mapIcons/cluster/largest';
import getDefaultClusterIcon from '../assets/mapIcons/cluster/default';

import {
  MIN_LARGE_CLUSTER_WEIGHT,
  CLUSTER_HEIGHT,
  CLUSTER_WIDTH,
  LARGEST_CLUSTER_HEIGHT,
  LARGET_CLUSTER_WIDTH
} from '../constants';

const useExtraGoogleStationMarkersLayer = ({
  extraStations
}: ExtraStationMarkersLayerProps) => {
  const selectedStationCode = useSelectedStationCode();

  const extraGoogleStationMarkersLayer = useMemo((): GoogleLayer => ({
    type: 'cluster',
    // @ts-ignore
    data: extraStations || [],
    id: 'extra-stations',
    getIcon,
    // @ts-ignore
    getPosition,
    getSize,
    selectedStation: selectedStationCode
  }), [
    extraStations,
    selectedStationCode
  ]);

  return extraGoogleStationMarkersLayer;
};

const useExtraHereStationMarkersLayer = ({
  extraStations
}: ExtraStationMarkersLayerProps) => {
  const selectedStationCode = useSelectedStationCode();

  const extraHereStationMarkersLayer = useMemo((): HereLayer => ({
    data: extraStations || [],
    name: 'extra-stations',
    type: 'cluster',
    selectedStation: selectedStationCode,
    getCoordinates: ({ coordinates: {
      latitude,
      longitude
    } }: any) => ([latitude, longitude]),
    getCluster: (clusterWeight: any) => ({
      icon: clusterWeight < MIN_LARGE_CLUSTER_WEIGHT
        ? getDefaultClusterIcon(clusterWeight)
        : getLargestClusterIcon(clusterWeight),
      size: clusterWeight < MIN_LARGE_CLUSTER_WEIGHT
        ? { w: CLUSTER_WIDTH / 2, h: CLUSTER_HEIGHT / 2 }
        : { w: LARGET_CLUSTER_WIDTH / 2, h: LARGEST_CLUSTER_HEIGHT / 2 },
      anchor: clusterWeight < MIN_LARGE_CLUSTER_WEIGHT
        ? { x: CLUSTER_WIDTH / 4, y: CLUSTER_HEIGHT / 4 }
        : { x: LARGET_CLUSTER_WIDTH / 4, y: LARGEST_CLUSTER_HEIGHT / 4 }
    }),
    getMarker: ({ station, selectedStation }) => {
      const iconProps = {
        properties: {
          cluster: false,
          ...station
        },
        selectedStation
      };

      const {
        url,
        height,
        width
      } = getIcon(iconProps);

      return ({
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4, y: height / 4 }
      })
    },
  }), [
    extraStations,
    selectedStationCode
  ]);

  return extraHereStationMarkersLayer;
};


interface ExtraStationMarkersLayerProps {
  extraStations?: Stations;
}

export const useExtraStationMarkersLayer = (props: ExtraStationMarkersLayerProps) => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const extraGoogleStationMarkersLayer = useExtraGoogleStationMarkersLayer(props);
  const extraHereStationMarkersLayer = useExtraHereStationMarkersLayer(props);

  return isActiveGoogleMap ? extraGoogleStationMarkersLayer : extraHereStationMarkersLayer;
};