import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { mapFactory } from 'common/utils/Map';
import {
  checkIsGoogleMap,
  checkIsHereMap
} from 'entities/application';
import { useIntl } from 'react-intl';

import {
  TMap,
  InitMap,
  IContext,
  TFitBounds,
  SetCenter,
  TSetZoom,
  TAddListenerDragStart,
  TAddListenerBoundsChanged,
  TAddListenerStreetViewChange,
  setMapType
} from './types';
import { IMap } from 'common/utils/Map/types';

interface Props {
  children?: React.ReactNode;
}

export const MapContext = createContext({} as IContext);
export const useMapContext = () => useContext(MapContext);

const MapProvider = ({ children }: Props) => {
  const { locale } = useIntl();
  const [map, setMap] = useState<TMap>(null);

  const initMap: InitMap = useCallback((mapName, containerRef) => {
    if ((checkIsGoogleMap(mapName) && window.google) || checkIsHereMap(mapName)) {
      const newMap = mapFactory(mapName, containerRef.current);
      newMap.initMap({ lang: locale });
      setMap(newMap);
    }
  }, [
    setMap,
    locale
  ]);

  const setFitBounds: TFitBounds = useCallback((value, padding) => {
    if (map) map.fitBounds(value, padding);
  }, [map]);

  const setCenter: SetCenter = useCallback((coords) => {
    if (map) {
      map.setCenter(coords);
    }
  }, [map]);

  const setZoom: TSetZoom = useCallback((value) => {
    if (map) map.setZoom(value);
  }, [map]);

  const addListenerDragStart: TAddListenerDragStart = useCallback((onDragStart) => {
    if (map) return map.addListenerDragStart(onDragStart);
  }, [map]);

  const addListenerBoundsChanged: TAddListenerBoundsChanged = useCallback((onBoundsChanged) => {
    if (map) return map.addListenerBoundsChanged(onBoundsChanged);
  }, [map]);

  const addListenerStreetViewChange: TAddListenerStreetViewChange = useCallback((onStreetViewChanged) => {
    if (map && onStreetViewChanged) return map.addListenerStreetViewChange(onStreetViewChanged);
  }, [map]);

  const addListenerClick: IMap['addListenerClick'] = useCallback((onClick) => {
    if (map) return map.addListenerClick(onClick);
  }, [map]);

  const setMapType: setMapType = useCallback((mapType) => {
    if (map) map.setMapType(mapType);
  }, [map]);

  const value = useMemo(
    () => ({
      map,
      initMap,
      setFitBounds,
      setCenter,
      setZoom,
      addListenerDragStart,
      addListenerBoundsChanged,
      addListenerStreetViewChange,
      addListenerClick,
      setMapType
    }),
    [
      map,
      initMap,
      setFitBounds,
      setCenter,
      setZoom,
      addListenerDragStart,
      addListenerBoundsChanged,
      addListenerStreetViewChange,
      addListenerClick,
      setMapType
    ]
  );

  return (
    <MapContext.Provider value={value}>{children}</MapContext.Provider>
  );
};

export default MapProvider;
