import React, { useMemo } from 'react';
import Icon from 'e100-react-components/lib/components/Icon';
import { IPropTypes } from 'e100-react-components/lib/components/Icon/index';

import { getSvgIcon } from './svgIcons';

export interface Props extends Omit<IPropTypes, 'kind'> {
  kind?: string;
}

const IconComponent = (props: Props) => {
  const { kind, color, classes, style } = props;
  const SvgIcon = useMemo(() => getSvgIcon(kind), [kind]);
  if (!kind) return null;
  return (SvgIcon
    ? <SvgIcon color={color} classes={classes} htmlColor={style?.color || ''} />
    : <Icon {...props} kind={kind as IPropTypes['kind']} />
  );
};

export default IconComponent;