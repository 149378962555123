import { useEffect, useRef } from 'react';
import { useLocale } from 'entities/application';

const useReloadPage = () => {
  const locale = useLocale();
  const countUpdateLocale = useRef(0);

  useEffect(() => {
    if (countUpdateLocale.current > 0) window.document.location.reload();
    countUpdateLocale.current++;
  }, [locale, countUpdateLocale]);
};

export default useReloadPage;
