import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const StationActive = (props: IProps) => (
  <SvgIcon {...props}>
    <path id="XMLID_25_" fill="#4FBC46" d="M12,22.1c5.6,0,10.1-4.5,10.1-10.1S17.6,1.9,12,1.9S1.9,6.4,1.9,12
      S6.4,22.1,12,22.1z"/>
    <path fill="#3DA554" d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,2.8c-5.1,0-9.2,4.1-9.2,9.2
      c0,5.1,4.1,9.2,9.2,9.2c5.1,0,9.2-4.1,9.2-9.2C21.2,6.9,17.1,2.8,12,2.8z"/>
    <path fill="#FFFFFF" d="M16.5,8.9L15,7.3c-0.2-0.2-0.5-0.2-0.7,0C14,7.5,14,7.8,14.2,8L15,8.8C14.5,9,14.2,9.5,14.2,10
      c0,0.8,0.6,1.4,1.4,1.4c0.1,0,0.3,0,0.4-0.1v4.1c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.3v-2.1c0-0.9-0.7-1.6-1.6-1.6V8.6
      c0-1.2-1-2.2-2.2-2.2H9.2C8,6.4,7,7.4,7,8.6v6.7c0,1.2,1,2.2,2.2,2.2h2.2c1.2,0,2.2-1,2.2-2.2v-2.6c0.3,0,0.6,0.3,0.6,0.6v2.1
      c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-5.4C17,9.6,16.9,9.2,16.5,8.9z M15.6,10.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4
      c0.2,0,0.4,0.2,0.4,0.4S15.8,10.4,15.6,10.4z M12.6,10.3c0,0.6-0.5,1.1-1.1,1.1H9.2c-0.6,0-1.1-0.5-1.1-1.1V8.6
      c0-0.6,0.5-1.1,1.1-1.1h2.2c0.6,0,1.1,0.5,1.1,1.1V10.3z"/>
  </SvgIcon>
);

export default StationActive;
