import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeProvider from 'e100-react-components/lib/theme/themeProvider';
import useMuiGlobalStyles from 'e100-react-components/lib/styles/useGlobalStyles';
import useAuth from 'common/hooks/useAuth';
import useKeepAliveSession from 'common/hooks/useKeepAliveSession';
import AppRoutes from 'AppRoutes';
import CssBaseline from 'e100-react-components/lib/styles/CssBaseline';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';

import useTheme from './hooks/useTheme';
import useGlobalStyles from './hooks/useGlobalStyles';
import useInitPermissions from './hooks/useInitPermissions';
import useReloadPage from './hooks/useReloadPage';
import useGTM from './hooks/useGTM';
import useSendYandexEvents from './hooks/useSendYandexEvents';
import Notice from './Notice';
import useStonly from 'common/hooks/useStonly';

const App = () => {
  useMuiGlobalStyles();
  useReloadPage();
  useGlobalStyles();
  useAuth();
  useInitPermissions();
  useKeepAliveSession();
  useGTM();
  useStonly();
  useSendYandexEvents();

  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
      >
        <Router>
          <AppRoutes />
        </Router>
      </ErrorBoundary>

      <Notice />
    </ThemeProvider>
  );
};

export default App;
