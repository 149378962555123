import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { checkIsLoadingError } from 'common/utils/checkLoading';
import { StatusLoading } from 'common/constants/statusLoading';

import { STATE } from '../types/state';
import { useIsSelectedStationCodeEqualToReceivedStationCode } from './stationInfo';


export const isLoading = (state: RootState): StatusLoading | undefined => {
  const { isLoading } = state[STATE];
  return isLoading;
};

const useStationLoadingStatus = () => {
  const stationLoadingStatus = useSelector((state: RootState) => state[STATE].isLoading);
  return stationLoadingStatus;
};

export const useIsStationLoading = () => {
  const isSelectedStationCodeEqualToReceivedStationCode = useIsSelectedStationCodeEqualToReceivedStationCode();
  return !isSelectedStationCodeEqualToReceivedStationCode;
};

export const useIsStationDone = () => {
  const isSelectedStationCodeEqualToReceivedStationCode = useIsSelectedStationCodeEqualToReceivedStationCode();
  return isSelectedStationCodeEqualToReceivedStationCode;
};

export const useIsStationError = () => {
  const stationLoadingStatus = useStationLoadingStatus();
  const isSelectedStationCodeEqualToReceivedStationCode = useIsSelectedStationCodeEqualToReceivedStationCode();

  return isSelectedStationCodeEqualToReceivedStationCode && checkIsLoadingError(stationLoadingStatus);
};
