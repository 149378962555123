import { useCallback } from 'react';
import { MAP_TYPES } from 'common/utils/Map/constants';

import { useMapContext } from 'components/Map/context/map';
import { setMapType } from '../../index';

const useOnClick = (setStateMapType: setMapType) => {
  const { setMapType } = useMapContext();

  const onClick = useCallback(() => {
    setMapType(MAP_TYPES.ROADMAP);
    setStateMapType(MAP_TYPES.ROADMAP);
  }, [
    setMapType,
    setStateMapType
  ]);

  return onClick;
};

export default useOnClick;
