import { AppThunk } from 'store';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import { getResponseError } from 'common/utils/index';
import dataSources from 'dataSources';
import { batch } from 'react-redux';

import { applicationActions } from 'entities/application';
import { reportsActions } from '../reducer';

import { ReportGuid, JSONReport } from 'entities/reports/api';

const { 
  setReportLoadingStatus,
  setReport
} = reportsActions;
const { setErrorNotice } = applicationActions;


export const getReport = (reportGuid: ReportGuid): AppThunk => async (dispatch) => {
  try {
    dispatch(setReportLoadingStatus(STATUS_LOADING.load));

    const result = await dataSources.reports.getReport(reportGuid);
    const base64 = await result.text();
    const report = JSON.parse(atob(base64)) as JSONReport;

    batch(() => {
      dispatch(setReportLoadingStatus(STATUS_LOADING.done));
      dispatch(setReport(report));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setReportLoadingStatus(STATUS_LOADING.error));
    });
  }
};
