import { RootState } from 'store';
import { IListItem } from 'common/types/common';

import { STATE } from '../types/state';

const selector = (state: RootState): IListItem[] => {
  const { countries } = state[STATE];
  return countries.map(({ name, code }) => ({ key: code, value: name, code }));
};

export default selector;
