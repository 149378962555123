import { createSlice } from '@reduxjs/toolkit';

import { IState, STATE } from "../types/state";

import initDirectories from './initDirectories';
import statusLoading from './statusLoading';

const initialState: IState = {
  countries: [],
  roadsideService: [],
  stationCategories: [],
  serviceFormats: [],
  additionalParams: [],
  additionalServices: [],
  services: [],
  paymentTypes: []
};

const slice = createSlice({
  name: STATE,
  initialState,
  reducers: {
    initDirectories,
    statusLoading
  }
});

export default slice;
