import { LatLng } from 'common/utils/Route';

export const polylineToLatLngAray = (polyline: string) => {
  const lineString = window.H.geo.LineString.fromFlexiblePolyline(polyline);
  const latLngArray: LatLng[] = [];
  lineString.eachLatLngAlt((lat: string, lng: string) => {
    latLngArray.push({ lat: Number(lat), lng: Number(lng) });
  });
  return latLngArray;
};
