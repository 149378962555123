import React from 'react';

import classNames from 'classnames';

import {
  DateTimePicker,
  DateTimePickerProps
} from '../../DateTimePicker';

import { useStyles } from './styles';

import { FormHelperText } from 'e100-react-components';
import { DateTimePickerIcon } from './DateTimePickerIcon/DateTimePickerIcon';
import { DateTimePickerArrowIcon } from './DateTimePickerArrowIcon/DateTimePickerArrowIcon';
import { DatePickerClearButton } from './DatePickerClearButton/DatePickerClearButton';

export const IconedDateTimePicker = ({
  icon,
  hideBorderBottom = false,
  error,
  classes = {},
  variant = 'outlined',
  ...props
}: IconedDateTimePickerProps) => {
  const { onChange, value, defaultValue } = props;
  const innerClasses = useStyles({ hideBorderBottom, variant });

  return (
    <>
      <div className={innerClasses.container}>
        <DateTimePickerIcon
          icon={icon}
        />
        <DateTimePicker
          {...props}
          classes={{
            datePicker: classNames(innerClasses.datePicker, classes.datePicker)
          }}
        />
        <DatePickerClearButton
          show={defaultValue !== value}
          onClick={() => {
            if (onChange && defaultValue) {
              onChange(defaultValue);
            }
          }}
          variant={variant}
        />
        <DateTimePickerArrowIcon
          variant={variant}
        />
      </div>
      {
        error && (
          <FormHelperText
            error
            classes={{
              root: innerClasses.errorMessage
            }}
          >
            {error}
          </FormHelperText>
        )
      }
    </>
  )
};

type Variants = 'outlined' | 'default';
export interface IconedDateTimePickerProps extends DateTimePickerProps {
  icon?: string;
  hideBorderBottom?: boolean;
  error?: string;
  variant?: Variants;
}