import React from 'react';
import { makeStyles, Select as E100Select } from 'e100-react-components';
import { IPropTypes } from 'e100-react-components/lib/components/Select';

const useStyles = makeStyles(({ palette, spacing, shape, transitions }) => ({
  formControl: {
    width: 'auto',
    '& > *:before, & > *:after': {
      display: 'none!important'
    }
  },
  select: {
    backgroundColor: palette.common.white,
    padding: spacing(),
    borderRadius: shape.borderRadius,
    height: 24,
    transition: transitions.create(['background-color', 'box-shadow', 'border']),
    '&:hover': {
      backgroundColor: palette.grey['A100']
    }
  }
}));

const Select = ({
  classes: componentClasses = {},
  ...otherProps
}: IPropTypes) => {
  const classes = useStyles();

  return (
    <E100Select
      MenuProps={{
        anchorOrigin:{
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        getContentAnchorEl: null
      }}
      classes={{
        formControl: {
          root: classes.formControl
        },
        select: {
          root: `${classes.select} ${componentClasses?.select?.root}`,
          icon: componentClasses?.select?.icon
        }
      }}
      fullWidth
      {...otherProps}
    />
  );
};

export default Select;