import {
  IStationCategory,
  IServiceFormat
} from 'common/types/common';
import {
  IAdditionalService,
  IDimensionLimitation,
  ICountry,
  IRoadsideService,
  IService,
  PaymentType,
  CountriesBrands
} from 'dataSources/Typicode/common/types';
import { StatusLoading } from 'common/constants/statusLoading';

export const STATE = "DIRECTORIES";

export interface IState {
  isLoading?: StatusLoading;
  countries: ICountry[];
  roadsideService: IRoadsideService[];
  stationCategories: IStationCategory[];
  serviceFormats: IServiceFormat[];
  additionalParams: IDimensionLimitation[];
  additionalServices: IAdditionalService[];
  services: IService[];
  countriesBrands?: CountriesBrands;
  paymentTypes: PaymentType[];
};