import { CAR_NUMBER_ICON_HEIGHT } from 'components/Map/constants/icons';

import { svgToDataURL } from 'common/utils/index';
import { getIcon } from './getIcon';
import { checkIsCluster } from 'components/Map/utils/checkIconType';
import { getCarNumberIconWidth } from '../../utils/carNumberIcon';

export const getCarNumberIcon = (d: any) => {
  const { properties: { autoNumber } } = d;

  if (checkIsCluster(d)) {
    return ({
      url: svgToDataURL('<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1" viewBox="0 0 0 0"></svg>'),
      width: 1,
      height: 1,
      anchorY: 1,
      anchorX: 1,
      id: 'epmty-car-number'
    });
  }
  const carNumberIconWidth = getCarNumberIconWidth(autoNumber);
  return ({
    url: svgToDataURL(getIcon(autoNumber, carNumberIconWidth)),
    width: carNumberIconWidth,
    height: CAR_NUMBER_ICON_HEIGHT,
    anchorY: CAR_NUMBER_ICON_HEIGHT / 2,
    anchorX: -45,
    id: `car-number-${autoNumber}`
  });
};
