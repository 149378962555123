import { useEffect } from 'react';

import { FocusPoint } from '../types/focusPoint';
import { useMapContext } from '../context/map';

const useFocusPoint = (focusPoint?: FocusPoint) => {
  const { setZoom, setCenter } = useMapContext();

  useEffect(() => {
    if (focusPoint) {
      const { longitude, latitude, zoom } = focusPoint;

      setCenter({
        lng: longitude,
        lat: latitude
      });
      if (zoom) setZoom(zoom);
    }
  }, [
    focusPoint,
    setCenter,
    setZoom
  ]);
};

export default useFocusPoint;
