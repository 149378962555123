import makeStyles from 'e100-react-components/lib/styles/makeStyles';

export const useStyles = makeStyles(({
  spacing,
  palette,
  shape
}) => ({
  button: {
    marginLeft: spacing(-1.5),
    marginBottom: spacing(-1.5),
    width: `calc(100% + ${spacing(3)}px)`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: `1px solid ${palette.grey[300]}`
  },
  image: {
    width: '100%',
    maxWidth: spacing(40),
    marginBottom: spacing(),
    borderRadius: shape.borderRadius
  }
}));
