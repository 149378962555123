export const loadScriptFromDocument = (url: string, sourceElement: HTMLElement | null) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;    
  if (sourceElement) sourceElement.appendChild(script);

  return new Promise(resolve => {
    script.onload = resolve;
  });
};
