import { useEffect } from 'react';
import addGTMEvent from 'common/utils/addGTMEvent';
import EVENT_TYPES_GTM from 'common/constants/eventTypesGTM';
import { useUser } from 'store/features/auth/selectors/user';

const useGTM = () => {
  const { clientCode = '', userType = ''  } = useUser() || {};

  useEffect(() => {
    if (clientCode) addGTMEvent(EVENT_TYPES_GTM.initRegisteredSession, { userId: clientCode, userType });
  }, [clientCode, userType]);
};

export default useGTM;
