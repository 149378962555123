import { GoogleMapsOverlayProps } from '@deck.gl/google-maps/typed';
import { HereMapInterface } from 'common/utils/Map';
import {
  HereMarkerInterface,
  OverlayProps,
  HereLayers,
  HereLayer
} from '../types';

export class HereMarker implements HereMarkerInterface {
  private _map?: HereMapInterface;
  private _mapLayers: { [layer: string]: any } = {};
  private _handleClick?: (event: any) => void;

  constructor(map?: HereMapInterface) {
    this._map = map;
  }

  updateLayers(layers: HereLayers) {
    layers.forEach((layer) => {
      if (layer.type === 'cluster') {
        const mapLayer = this._mapLayers[layer.name];
        if (mapLayer) {
          this._updateClusterLayer(layer, mapLayer);
          mapLayer.onClick = layer.onClick;
        } else {
          const clusterLayer = this._createClusterLayer(layer);
          this._map?.getNativeMap().addLayer(clusterLayer.objectLayer, 10);
          this._mapLayers[layer.name] = clusterLayer;
        }
      }
    });
  }

  _updateClusterLayer(
    {
      data,
      getCoordinates,
      selectedStation
    }: HereLayer,
    {
      objectLayer
    }: any
  ) {
    const layerProvider = objectLayer.getProvider();
    const dataPoints = data
      .reduce((acc, item) => {
        const [latitude, longtitude] = getCoordinates(item);
        acc.push(new window.H.clustering.DataPoint(
          latitude,
          longtitude,
          1,
          {
            station: item,
            selectedStation
          }
        )
      );
        return acc;
      }, [] as any[]);

    layerProvider.setDataPoints(dataPoints);
  }

  _createClusterLayer(layer: HereLayer) {
    const {
      data,
      selectedStation,
      getCoordinates,
      getCluster,
      getMarker,
      onClick,
      onPointermove,
      onPointerenter,
      onDragStart
    } = layer;

    const dataPoints = data
      .reduce((acc, item) => {
        const [latitude, longtitude] = getCoordinates(item);
        // acc.push(new window.H.clustering.DataPoint(latitude, longtitude, 1, item));
        acc.push(new window.H.clustering.DataPoint(
          latitude,
          longtitude,
          1,
          {
            station: item,
            selectedStation
          }
        )
      );
        return acc;
      }, [] as any[]);

    // Create a clustered data provider and a theme implementation
    const clusteredDataProvider = new window.H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 60,
        strategy: window.H.clustering.Provider.Strategy.DYNAMICGRID
      },
      pixelRatio: 0.5,
      theme: {
        getClusterPresentation: (cluster: any) => {
          if (getCluster) {
            // Calculate circle size
            const {
              icon,
              size,
              anchor
            } = getCluster(cluster.getWeight());

            var clusterIcon = new window.H.map.Icon(icon, {
                size,
                anchor
            });

            var clusterMarker = new window.H.map.Marker(cluster.getPosition(), {
                icon: clusterIcon,
                // Set min/max zoom with values from the cluster, otherwise
                // clusters will be shown at all zoom levels
                min: cluster.getMinZoom(), // 0 - min value
                max: cluster.getMaxZoom(), // 22 - max value
                zIndex: 10,
                data: {
                  type: 'cluster'
                }
            });

            clusterMarker.addEventListener('tap', () => {
              const boundingBox = cluster.getBoundingBox();
              this._map?.fitBounds({
                sw: {
                  lat: boundingBox.getBottom(),
                  lng: boundingBox.getLeft()
                },
                ne: {
                  lat: boundingBox.getTop(),
                  lng: boundingBox.getRight()
                }
              });
            });


            return clusterMarker;
          }

          return undefined;
        },
        getNoisePresentation: function(noisePoint: any) {
          const {
            icon,
            size,
            anchor
          } = getMarker(noisePoint.getData());

          const noiseIcon = new window.H.map.Icon(icon, {
            size,
            anchor
          });

          const noiseMarker = new window.H.map.Marker(noisePoint.getPosition(), {
            icon: noiseIcon,
            // Use min zoom from a noise point to show it correctly at certain zoom levels
            min: 0,
            data: {
              type: 'marker',
              data: noisePoint.getData().station
            }
          });

          return noiseMarker;
        }
      }
    });

    this._addClusterLayerPointermoveListener(clusteredDataProvider, onPointermove);
    this._addClusterLayerPointerenterListener(onPointerenter);
    this._addDragStartListener(onDragStart);
    this._addClusterLayerTapListener(clusteredDataProvider);

    return {
      objectLayer: new window.H.map.layer.ObjectLayer(clusteredDataProvider),
      onClick
    };
  }

  _addClusterLayerPointermoveListener(clusteredDataProvider: any, onPointermove: HereLayer['onPointermove']) {
    if (onPointermove) {
      clusteredDataProvider.addEventListener('pointermove', (event: any) => {
        onPointermove(event);
      }, false);
    }
  }

  _addClusterLayerPointerenterListener(onPointerenter: HereLayer['onPointerenter']) {
    if (onPointerenter) {
      this._map?.getNativeMap().addEventListener('pointerenter', (event: any) => {
        onPointerenter(event);
      });
    }
  }

  _addDragStartListener(onDragStart: HereLayer['onDragStart']) {
    if (onDragStart) {
      this._map?.getNativeMap().addEventListener('dragstart', (event: any) => {
        onDragStart(event);
      });
    }
  }

  _addClusterLayerTapListener(clusteredDataProvider: any) {
    this._handleClick = (event: any) => {
      Object.values(this._mapLayers).forEach(({ onClick }) => {
        if (onClick) {
          onClick(event.target.getData());
        }
      });
    };

    clusteredDataProvider.addEventListener('tap', this._handleClick, false);
  }

  _removeClusterLayerTapListener(clusteredDataProvider: any) {
    if (this._handleClick) {
      clusteredDataProvider.removeEventListener('tap', this._handleClick);
    }
  }

  _updateMarkerLayer(layer: HereLayer) {

  }

  deleteMarker() {}

  setOverlayProps(props: OverlayProps) {}

  addClickHandler(handler: GoogleMapsOverlayProps['onClick']) {
  }

  deleteClickHandler(handler: GoogleMapsOverlayProps['onClick']) {

  };
}