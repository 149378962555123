import dataSources from 'dataSources';

import {
  CountriesRegionsFilter
} from 'dataSources/Typicode/common/types';

import { ListItem } from 'common/types/common';

export const getCountriesRegionList = async(filter: CountriesRegionsFilter) => {
  const { countries = [] } = await dataSources.common.getCountriesRegions(filter) || {};

  return countries.reduce((acc, item) => {
    item.regions.forEach(({ billingId, title }) => {
      acc.push({
        key: billingId,
        value: title
      });
    });

    return acc;
  }, [] as ListItem[]);
}

interface GetCountriesRoadListFilter {
  countryCodes?: string[];
  trackCodes?: string[];
}

export const getCountriesRoadList = async ({ countryCodes, trackCodes }: GetCountriesRoadListFilter): Promise<ListItem[]> => {
  if (countryCodes && countryCodes.length && trackCodes && trackCodes.length) {
    try {
      const { countries } = await dataSources.common.getCountriesRoads({ countryAlpha2Codes: countryCodes });

      const countriesRoadMap = countries.reduce((acc, item) => {
        item.roads.forEach(({ billingId, code }) => {
          acc[billingId] = {
            key: billingId,
            value: code
          };
        });

        return acc;
      }, {} as Record<string, ListItem>);

      return trackCodes.reduce((acc, trackCode) => {
        if (countriesRoadMap[trackCode]) {
          acc.push(countriesRoadMap[trackCode]);
        }
        return acc;
      }, [] as ListItem[]);
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  return [];
};

