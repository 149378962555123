import {
  useState,
  useCallback
} from 'react';

import {
  CardNumber,
  AutoNumber,
  ObuNumber
} from 'entities/navigation';

import dataSources from 'dataSources';

export const useGetObuCards = () => {
  const [ isObuCardsLoading, setIsObuCardsLoading ] = useState(false);
  const [ obuCards, setObuCards ] = useState<CardNumber[] | undefined>();

  const getObuCards = useCallback(async (autoNumber?: AutoNumber, obuNumber?: ObuNumber) => {
    if (autoNumber && obuNumber) {
      try {
        setIsObuCardsLoading(true);

        const { cards } = await dataSources.navigation.getObuCards({
          autoNumber,
          obuNumber
        });

        setObuCards(cards);
        setIsObuCardsLoading(false);
      } catch (e) {
        setIsObuCardsLoading(false);
      }
    }
  }, []);

  return { getObuCards, obuCards, isObuCardsLoading };
};
