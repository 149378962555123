import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState
} from 'react';

import { Context } from './types';

interface Props {
  children?: React.ReactNode;
}

export const ActiveAutosContext = createContext({} as Context);
export const useActiveAutosContext = () => useContext(ActiveAutosContext);

const ActiveAutosProvider = ({ children }: Props) => {
  const [ selectedAuto, setSelectedAuto ] = useState<Context['selectedAuto']>();
  const resetSelectedAuto = useCallback(() => {
    setSelectedAuto(undefined);
  }, [setSelectedAuto]);

  const value = useMemo(() => ({
    selectedAuto,
    setSelectedAuto,
    resetSelectedAuto
  }), [
    selectedAuto,
    setSelectedAuto,
    resetSelectedAuto
  ]);

  return (
    <ActiveAutosContext.Provider value={value}>{children}</ActiveAutosContext.Provider>
  );
};

export default ActiveAutosProvider;
