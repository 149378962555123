export const CAR_NUMBER_ICON_WIDTH = 600;
export const CAR_NUMBER_ICON_HEIGHT = 48;

export const MARKER_HEIGHT = 72;
export const MARKER_WIDTH = 72;

export const ACTIVE_MARKER_HEIGHT = 152;
export const ACTIVE_MARKER_WIDTH = 152;

export const CLUSTER_HEIGHT = 128;
export const CLUSTER_WIDTH = 128;

export const LARGEST_CLUSTER_HEIGHT = 128;
export const LARGET_CLUSTER_WIDTH = 186;

export const CAR_CLUSTER_HEIGHT = 128;
export const CAR_CLUSTER_WIDTH = 128;

export const CAR_LARGEST_CLUSTER_HEIGHT = 128;
export const CAR_LARGET_CLUSTER_WIDTH = 186;

export const CAR_WIDTH = 80;
export const CAR_HEIGHT = 80;

export const NAVIGATION_HISTORY_ROUTE_MARKER_WIDTH = 72;
export const NAVIGATION_HISTORY_ROUTE_MARKER_HEIGHT = 72;
