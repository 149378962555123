import { useEffect } from 'react';
import dataSources from 'dataSources';
import useOnLogout from 'common/hooks/useOnLogout';

const useCheckResponseCodeFromAPI = () => {
  const onLogout = useOnLogout();

  useEffect(() => {
    const provider = dataSources.getProvider();
    // @ts-ignore
    provider.addInterceptor('keepAlive', ({ name }) => {
      if (name === 'NotAuthorizedException') onLogout();
    });
    return () => {
      provider.removeInterceptor('keepAlive');
    };
  }, [onLogout]);
};

export default useCheckResponseCodeFromAPI;
