import { useMemo } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { PaymentType } from 'dataSources/Typicode/common/types';
import { WEST_CARD_NAME, CARD_NAME } from 'common/constants/card';

import { STATE } from '../types/state';

export const selectPaymentTypes = (state: RootState): PaymentType[] => {
  const { paymentTypes } = state[STATE];
  return paymentTypes;
};

export const usePaymentTypes = () => {
  const value = useSelector(selectPaymentTypes);

  const result = useMemo(() => value.map(item => ({
    ...item,
    name: item.name.replace(WEST_CARD_NAME, CARD_NAME)
  })), [value]);

  return result;
};

export const usePaymentTypesList = () => {
  const paymentTypes = usePaymentTypes();

  const result = useMemo(() => (
    paymentTypes.map(({ code, name }) => ({ key: code, value: name }))
  ), [paymentTypes]);

  return result;
};

export const usePaymentTypeByCode = (paymentTypeCode: PaymentType['code']) => {
  const paymentTypes = usePaymentTypes();

  const result = useMemo(() => (
    paymentTypes.find(({ code }) => code === paymentTypeCode)
  ), [paymentTypes, paymentTypeCode]);

  return result;
};
