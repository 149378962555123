import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

export const EndLocation = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M12.0007 22.6666C17.8917 22.6666 22.6673 17.891 22.6673 11.9999C22.6673 6.10888 17.8917 1.33325 12.0007 1.33325C6.10961 1.33325 1.33398 6.10888 1.33398 11.9999C1.33398 17.891 6.10961 22.6666 12.0007 22.6666Z" />
    <path d="M8.86588 16V7.54492H12.5456C14.1393 7.54492 15.1413 8.36523 15.1413 9.66602V9.67773C15.1413 10.6035 14.4382 11.3945 13.5065 11.5176V11.5527C14.6842 11.6406 15.5339 12.4727 15.5339 13.5801V13.5918C15.5339 15.0742 14.4147 16 12.61 16H8.86588ZM12.0885 8.85742H10.6354V11.0781H11.8717C12.8678 11.0781 13.401 10.6621 13.401 9.92969V9.91797C13.401 9.24414 12.9147 8.85742 12.0885 8.85742ZM12.0827 12.2793H10.6354V14.6816H12.1589C13.1725 14.6816 13.7292 14.2656 13.7292 13.4805V13.4688C13.7292 12.6953 13.1667 12.2793 12.0827 12.2793Z" fill="white"/>
  </SvgIcon>
);
