import { isEastTerritory } from 'common/utils/checkTerritory';

export const COMMON_LOCALES = {
  en: { key: 'en', translateId: 'languages.en' },
  pl: { key: 'pl', translateId: 'languages.pl' },
  lt: { key: 'lt', translateId: 'languages.lt' },
  tr: { key: 'tr', translateId: 'languages.tr' },
  es: { key: 'es', translateId: 'languages.es' },
  ru: { key: 'ru', translateId: 'languages.ru' }
} as const;
export const WEST_LOCALES = { ...COMMON_LOCALES, uk: { key: 'uk', translateId: 'languages.uk' } } as const;
export const EAST_LOCALES = { ...COMMON_LOCALES } as const;

export type WestLocales = keyof typeof WEST_LOCALES;
export type EastLocales = keyof typeof EAST_LOCALES;
export type AllLocales = WestLocales | EastLocales;

export const WEST_LOCALES_ORDER = ['en', 'pl', 'lt', 'tr', 'es', 'ru', 'uk'] as AllLocales[];
export const EAST_LOCALES_ORDER = ['ru', 'en', 'pl', 'lt', 'tr', 'es'] as AllLocales[];
export const LOCALES_ORDER = isEastTerritory ? EAST_LOCALES_ORDER : WEST_LOCALES_ORDER;

export const LOCALES = isEastTerritory ? EAST_LOCALES : WEST_LOCALES;
export type LOCALE_KEYS = keyof typeof LOCALES;

export const LOCAL_STORAGE_LOCALE_KEY = 'locale';

export const DEFAULT_LOCALE_WEST = COMMON_LOCALES.en.key;
export const DEFAULT_LOCALE_EAST = COMMON_LOCALES.ru.key;
export const DEFAULT_LOCALE = isEastTerritory ? DEFAULT_LOCALE_EAST : DEFAULT_LOCALE_WEST;