type TFn<T> = (value: T, index: number) => any;

const NUMBER_ITEMS_ITERATE = 100;

const forEachAsync = <T, D = any> (items: T[], fn: TFn<T>): Promise<D[]> => new Promise((resolve) => {
  const newItems: any = [];

  const iteratePartArray = (startIndex: number) => {
    if (startIndex > items.length) {
      resolve(newItems);
      return;
    }

    const maxItems = startIndex + NUMBER_ITEMS_ITERATE > items.length
      ? items.length : startIndex + NUMBER_ITEMS_ITERATE;

    for(let i = startIndex; i < maxItems; i++) {
      const value = fn(items[i], i);
      if ((value !== undefined) && (value !== null)) newItems.push(value);
    }

    setTimeout(() => { iteratePartArray(startIndex + NUMBER_ITEMS_ITERATE); }, 10);
  };

  iteratePartArray(0);
});

export default forEachAsync;
