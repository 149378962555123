import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { StationsState } from '../../types';

import { getStationsWithPrices } from '../operations/getStationsWithPrices';

import { STATUS_LOADING } from 'common/constants';

export const setStationsWithPrices = (builder: ActionReducerMapBuilder<StationsState>) => builder
  .addCase(getStationsWithPrices.pending, (state) => {
    state.stationsWithPrices.loadingStatus = STATUS_LOADING.load;
  })
  .addCase(getStationsWithPrices.fulfilled, (state, { payload }) => {
    state.stationsWithPrices.loadingStatus = STATUS_LOADING.done;
    state.stationsWithPrices.data = payload.stationsWithPrices;
    state.stationsWithPrices.dataMap = payload.stationsWithPricesMap;
  })
  .addCase(getStationsWithPrices.rejected, (state) => {
    state.stationsWithPrices.loadingStatus = STATUS_LOADING.error;
  });