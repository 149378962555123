import React, { FC, ReactNode } from 'react';
import { Divider, Typography } from 'e100-react-components';

import Title from '../Title';
import Description from './Description';
import useStyles from  './useStyles';

const TitledSection: FC<Props> = ({
  title,
  subtitle,
  children,
  bordered = true,
  paddingBottom = 2,
  paddingTop = 2,
  titleActions,
  description
}) => {
  const classes = useStyles({ paddingBottom, paddingTop });
  return (
    <>
      <div className={classes.content}>
        { title && <Title title={title} marginBottom={subtitle ? 0 : 1.5} actions={titleActions} /> }
        {
          subtitle && (
            <Typography
              classes={{ root: classes.subtitle }}
              variant="caption"
              color="secondary"
            >
              {subtitle}
            </Typography>
          )
        }
        {children}
        <Description description={description} />
      </div>
      {bordered && <Divider classes={{ root: classes.divider }} />}
    </>
  );
};

export default TitledSection;

export interface Props {
  title?: any,
  subtitle?: string,
  children: ReactNode,
  bordered?: boolean,
  paddingBottom?: number;
  paddingTop?: number;
  titleActions?: ReactNode;
  description?: string;
};