import { TStationCode, IStation } from 'dataSources/Typicode/stations/types';
import { checkIsSelectedStation } from './checkIconType';
import { getMarkerType } from './getMarkerType';
import { checkIsStationMarkerGroupType } from './checkMarkerGroupTypes';

import { MARKER_TYPES } from 'components/Map/constants/markerTypes';
import { MIN_LARGE_CLUSTER_WEIGHT } from '../constants';

const getActionStatus = (actions: any[]) => (
  actions && actions.length ? 'Action' : ''
);

const getActiveStatus = (
    stationCode: TStationCode,
    selectedStationCode: TStationCode
) => (
  checkIsSelectedStation(stationCode, selectedStationCode) 
  ? 'Active' : ''
);

const getStationMarkerName = (
  { categoryCode, code, actions }: IStation,
  selectedStationCode: TStationCode
) => (categoryCode ?
  'station'
  + getActiveStatus(code, selectedStationCode)
  + getActionStatus(actions)
  + categoryCode
  : 'station1'
);

const getRoadsideServiceMarkerName = (
  { code, actions }: IStation,
  selectedStation: TStationCode,
  roadsideServiceCode?: number
) => (
  'roadsideService'
  + getActiveStatus(code, selectedStation)
  + getActionStatus(actions)
  + roadsideServiceCode
);

const getStationWithEmptyRoadsideServicesMarkerName = (
  { code }: IStation,
  selectedStationCode: TStationCode
) => (
  'station'
  + getActiveStatus(code, selectedStationCode)
  + '1'
);

const getClusterMarkerName = (clusterWeight: number) => (
  clusterWeight < MIN_LARGE_CLUSTER_WEIGHT ? 'cluster' : 'largeCluster'
);

export const getIconName = (d: any) => {
  const { markerType, markerGroupType, roadsideServiceCode } = getMarkerType(d);
  const { properties, selectedStation } = d;

  if (markerType === MARKER_TYPES.cluster)
    return getClusterMarkerName(properties.point_count);
  if (markerType === MARKER_TYPES.place)
    return 'place';
  if (markerType === MARKER_TYPES.petrolStation)
    return getStationWithEmptyRoadsideServicesMarkerName(properties, selectedStation);
  if (checkIsStationMarkerGroupType(markerGroupType))
    return getStationMarkerName(properties, selectedStation);
  return getRoadsideServiceMarkerName(properties, selectedStation, roadsideServiceCode);
};
