import { IStationPrice, IStationInfo } from 'dataSources/Typicode/stations/types';
import { StatusLoading } from 'common/constants/statusLoading';
import { StationCode } from 'dataSources/Typicode/stations/types';

import { ISelectedPlace } from './selectedPlace';

export const STATE = "STATION";

export type TStation = Partial<IStationInfo>
export interface IState {
  isLoading?: StatusLoading;
  stationInfo: TStation;
  selectedStation: StationCode;
  selectedPlace: ISelectedPlace | null;
  stationPrices: {
    isLoading?: StatusLoading;
    stationCode?: StationCode;
    prices: IStationPrice[];
  },
  selectedCoordinates: string
};
