import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Guard = (props: IProps) => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.201 4.5C11.201 5.05228 11.6488 5.5 12.201 5.5C12.7533 5.5 13.201 5.05228 13.201 4.5V3.5C13.201 2.94772 12.7533 2.5 12.201 2.5C11.6488 2.5 11.201 2.94772 11.201 3.5V4.5ZM15.201 15.5V11.5C15.201 9.84315 13.8579 8.5 12.201 8.5C10.5442 8.5 9.20104 9.84315 9.20104 11.5V15.5H15.201ZM12.201 6.5C9.43961 6.5 7.20104 8.73858 7.20104 11.5V17.5H17.201V11.5C17.201 8.73858 14.9625 6.5 12.201 6.5ZM5.20104 20.5C5.20104 19.9477 5.64875 19.5 6.20104 19.5H18.201C18.7533 19.5 19.201 19.9477 19.201 20.5C19.201 21.0523 18.7533 21.5 18.201 21.5H6.20104C5.64875 21.5 5.20104 21.0523 5.20104 20.5ZM6.29912 7.86603C6.02298 8.34432 5.41139 8.5082 4.93309 8.23206L4.06707 7.73206C3.58878 7.45591 3.4249 6.84432 3.70104 6.36603C3.97719 5.88774 4.58878 5.72386 5.06707 6L5.93309 6.5C6.41139 6.77615 6.57526 7.38774 6.29912 7.86603ZM19.0671 8.23206C18.5888 8.5082 17.9772 8.34432 17.701 7.86603C17.4249 7.38774 17.5888 6.77615 18.0671 6.5L18.9331 6C19.4114 5.72386 20.023 5.88774 20.2991 6.36603C20.5753 6.84432 20.4114 7.45591 19.9331 7.73206L19.0671 8.23206ZM21.2991 14.866C21.023 15.3443 20.4114 15.5082 19.9331 15.2321L19.0671 14.7321C18.5888 14.4559 18.4249 13.8443 18.701 13.366C18.9772 12.8877 19.5888 12.7239 20.0671 13L20.9331 13.5C21.4114 13.7761 21.5753 14.3877 21.2991 14.866ZM4.06706 15.2321C3.58877 15.5082 2.97718 15.3443 2.70104 14.866C2.42489 14.3877 2.58877 13.7761 3.06706 13.5L3.93309 13C4.41138 12.7239 5.02297 12.8877 5.29911 13.366C5.57525 13.8443 5.41138 14.4559 4.93309 14.7321L4.06706 15.2321Z" />
  </SvgIcon>
);

export default Guard;
