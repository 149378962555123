import { IAuthToken } from 'dataSources/Typicode/auth/types/authToken';
import { APP_LOG } from '../../constants';
import parseJWT from './parseJWT'; 

export interface IGetAuthDataResult {
  accessToken: string;
  refreshToken: string;
  expiryTime: number;
  login: string;
  userType: string;
  clientCode: string;
  clientId: string;
}
type TGetAuthData = (authData: IAuthToken['data']) => IGetAuthDataResult;

export interface TokenPayload {
  login: string;
  userType: string;
  clientCode: string;
  clientId: string;
  accessToken: string;
  refreshToken: string;
  expiryTime: number;
}

const parseAuthData: TGetAuthData = (authData: IAuthToken['data']): TokenPayload => {
  const {
    access_token,
    refresh_token
  } = authData;
  const tokenPayload: TokenPayload = {} as TokenPayload;

  tokenPayload.accessToken = access_token
  tokenPayload.refreshToken = refresh_token

  try {
    const {
      exp,
      'http://schemas.zgs.loc/e100/identity/claims/login': login,
      'http://schemas.zgs.loc/e100/identity/claims/usertype': userType,
      'http://schemas.zgs.loc/e100/identity/claims/clientcode': clientCode,
      'http://schemas.zgs.loc/e100/identity/claims/clientid': clientId
    } = parseJWT(tokenPayload.accessToken);

    tokenPayload.login = login;
    tokenPayload.userType = userType;
    tokenPayload.clientCode = clientCode;
    tokenPayload.clientId = clientId;
    tokenPayload.expiryTime = Number(exp);
  } catch (error) {
    console.info(APP_LOG.JWT.ERROR_PARSE, error);
  }

  return tokenPayload;
};

export default parseAuthData;
