import { useMemo } from 'react';
import { useIsActiveGoogleMap } from 'entities/application';
import { useAutosCoordinates } from 'store/features/user';
import { useCarOperations } from 'entities/cars';

import {
  getCarNumberIcon,
  getCarNumberIconSize
} from '../utils/carNumberIcon';
import { getCarPosition } from '../utils/carIcon';

import { CAR_CLUSTER_MIN_POINTS } from '../constants';

import { GoogleLayer, HereLayer } from 'common/utils/MapMarker/types';
import { CarEBoxLocation } from 'entities/cars';

const useGoogleCarNumberMarkersLayer = () => {
  const autosCoordinates = useAutosCoordinates();
  const { setSelectedCarEBoxLocation } = useCarOperations();

  const googleCarNumberMarkersLayer = useMemo((): GoogleLayer => ({
    type: 'cluster',
    data: autosCoordinates || [] as any,
    id: 'car-numbers',
    getIcon: getCarNumberIcon,
    getSize: getCarNumberIconSize,
    // @ts-ignore
    getPosition: getCarPosition,
    sizeScale: 0.45,
    clusterProps: {
      minPoints: CAR_CLUSTER_MIN_POINTS
    },
    onClick: (props: any) => {
      setSelectedCarEBoxLocation(props.object);
    }
  }), [
    autosCoordinates,
    setSelectedCarEBoxLocation
  ]);

  return googleCarNumberMarkersLayer;
};

const useHereCarNumberMarkersLayer = () => {
  const autosCoordinates = useAutosCoordinates();
  const { setSelectedCarEBoxLocation } = useCarOperations();

  const hereCarNumberMarkersLayer = useMemo((): HereLayer => ({
    data: autosCoordinates || [] as any,
    name: 'cars',
    type: 'cluster',
    getCoordinates: ({ coordinates: {
      latitude,
      longitude
    } }) => ([latitude, longitude]),
    getCluster: (clusterWeight) => {
      const { url, width, height } = getCarNumberIcon({
        properties: {
          cluster: true,
          point_count: clusterWeight
        }
      });

      return {
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4 , y: height / 4 }
      };
    },
    getMarker: ({ station }) => {
      const {
        url,
        height,
        width
      } = getCarNumberIcon({
        properties: {
          cluster: false,
          ...station
        }
      });

      return ({
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4, y: height / 4 }
      })
    },
    onClick: ({ data }) => {
      setSelectedCarEBoxLocation(data as any as CarEBoxLocation);
    }
  }), [
    autosCoordinates,
    setSelectedCarEBoxLocation
  ]);

  return hereCarNumberMarkersLayer;
};

export const useCarNumberMarkersLayer = () => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const googleCarNumberMarkersLayer = useGoogleCarNumberMarkersLayer();
  const hereCarNumberMarkersLayer = useHereCarNumberMarkersLayer();

  return isActiveGoogleMap ? googleCarNumberMarkersLayer : hereCarNumberMarkersLayer;
};