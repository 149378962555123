import dataSources from 'dataSources';
import { batch } from 'react-redux';
import{ STATUS_LOADING } from 'common/constants';
import { Operation } from 'common/types/store/operation';
import { getResponseError } from 'common/utils/index';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const {
  setAutosCoordinates,
  setAutosCoordinatesStatusLoading
} = slice.actions;
const { setErrorNotice } = applicationActions;

const operation: Operation = () => async (dispatch: any) => {
  try {
    dispatch(setAutosCoordinatesStatusLoading(STATUS_LOADING.load));

    const autosCoordinates = await dataSources.navigation.getAutosCoordinates();

    batch(() => {
      dispatch(setAutosCoordinatesStatusLoading(STATUS_LOADING.done));
      dispatch(setAutosCoordinates(autosCoordinates));
    });
  } catch (e) {
    const error = await getResponseError(e);
    dispatch(setErrorNotice(error));
    dispatch(setAutosCoordinatesStatusLoading(STATUS_LOADING.error));
  }
};

export default operation;
