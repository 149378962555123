import { useState, useCallback, Dispatch, SetStateAction } from 'react';

export type BoolState = boolean;
export type SetBoolState = Dispatch<SetStateAction<boolean>>;
export type ToggleBoolState = () => void;
export type SetBoolStateFalse = () => void;
export type SetBoolStateTrue = () => void;
export type ResetBoolState = () => void;

type UseBoolState = (initValue?: boolean) => [
  BoolState,
  ToggleBoolState,
  SetBoolState,
  SetBoolStateFalse,
  SetBoolStateTrue,
  ResetBoolState
];

export const useBoolState: UseBoolState = (initBoolState) => {
  const [boolState, setBoolState] = useState<BoolState>(initBoolState || false);

  const toggleBoolState = useCallback(() => {
    setBoolState(oldValue => !oldValue);
  }, [setBoolState]);

  const setBoolStateFalse = useCallback(() => {
    setBoolState(false);
  }, [setBoolState]);

  const setBoolStateTrue = useCallback(() => {
    setBoolState(true);
  }, [setBoolState]);

  const resetBoolState = useCallback(() => {
    setBoolState(initBoolState || false);
  }, [
    setBoolState,
    initBoolState
  ]);

  return [
    boolState,
    toggleBoolState,
    setBoolState,
    setBoolStateFalse,
    setBoolStateTrue,
    resetBoolState
  ];
};
