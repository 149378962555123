import { PayloadAction } from "@reduxjs/toolkit";

import { IState } from "../types/state";
import { IStationFilter } from '../types/stationFilter';

const setFilter = (state: IState, action: PayloadAction<IStationFilter>) => {
  state.filter.data = { ...action.payload };
};

export default setFilter;
