import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  formControlRoot: {
    paddingRight: spacing(),
    paddingLeft: spacing(),
    borderBottom: `1px solid ${palette.grey[200]}`,
    '&:hover .rootButtonClear': {
      opacity: 1
    },
    '& > div:after': {
      display: 'none'
    },
    '& > div:before': {
      display: 'none'
    }
  },
  selectRoot: {
    paddingTop: spacing(2.25),
    paddingBottom: spacing(2.25),
    paddingLeft: spacing(6),
    paddingRight: `${spacing(8)}px !important`,
    fontSize: typography.body2.fontSize
  },
  selectMenu: {
    // @ts-ignore
    marginLeft: ({ isMobile }) => `${isMobile ? -8 : 0}px`
  },
  menuItemRoot: {
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5)
  },
  menuItemText: {
    fontSize: typography.body1.fontSize
  },
  menuHeaderSearch: {
    paddingRight: spacing(),
    '& input': {
      fontSize: typography.body2.fontSize,
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    },
    '& > div:after': {
      display: 'none'
    },
    '& > div:before': {
      display: 'none'
    }
  },
  searchIcon: {
    fontSize: typography.h5.fontSize,
    color: palette.secondary.main
  },
  selectIcon: {
    right: spacing()
  },
  checkboxContainer: {
    paddingLeft: '0 !important'
  }
}));

export default useStyles;
