import { isIosUnder13Version } from 'common/utils/checkBrowserType';
import { svgToDataURL } from 'common/utils/index';

import getDefaultClusterIcon from '../assets/mapIcons/cluster/default';
import defaultClusterIcon from '../assets/mapIcons/cluster/default/default.png';
import getLargestClusterIcon from '../assets/mapIcons/cluster/largest';
import largestClusterIcon from '../assets/mapIcons/cluster/largest/largest.png';

import placeActiveIcon from '../assets/mapIcons/common/place/active.png';

import commonStationIcon from '../assets/mapIcons/stations/common/default.png';
import commonActionStationIcon from '../assets/mapIcons/stations/common/action.png';
import commonActiveStationIcon from '../assets/mapIcons/stations/common/active.png';
import commonActiveActionStationIcon from '../assets/mapIcons/stations/common/activeAction.png';

import discountStationIcon from '../assets/mapIcons/stations/discount/default.png';
import discountActionStationIcon from '../assets/mapIcons/stations/discount/action.png';
import discountActiveStationIcon from '../assets/mapIcons/stations/discount/active.png';
import discountActiveActionStationIcon from '../assets/mapIcons/stations/discount/activeAction.png';

import powerMaxStationIcon from '../assets/mapIcons/stations/powerMax/default.png';
import powerMaxActionStationIcon from '../assets/mapIcons/stations/powerMax/action.png';
import powerMaxActiveStationIcon from '../assets/mapIcons/stations/powerMax/active.png';
import powerMaxActiveActionStationIcon from '../assets/mapIcons/stations/powerMax/activeAction.png';

import economyStationIcon from '../assets/mapIcons/stations/economy/default.png';
import economyActionStationIcon from '../assets/mapIcons/stations/economy/action.png';
import economyActiveStationIconfrom from  '../assets/mapIcons/stations/economy/active.png';
import economyActiveActionStationIconfrom from  '../assets/mapIcons/stations/economy/activeAction.png';

// Roadside services
import roadPaymentPointServiceIcon from '../assets/mapIcons/roadsideServices/roadPaymentPoint/default.png';
import roadPaymentPointActionServiceIcon from '../assets/mapIcons/roadsideServices/roadPaymentPoint/action.png';
import roadPaymentPointActiveServiceIcon from  '../assets/mapIcons/roadsideServices/roadPaymentPoint/active.png';
import roadPaymentPointActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/roadPaymentPoint/activeAction.png';

import parkingServiceIcon from '../assets/mapIcons/roadsideServices/parking/default.png';
import parkingActionServiceIcon from '../assets/mapIcons/roadsideServices/parking/action.png';
import parkingActiveServiceIcon from  '../assets/mapIcons/roadsideServices/parking/active.png';
import parkingActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/parking/activeAction.png';

import carWashServiceIcon from '../assets/mapIcons/roadsideServices/carWash/default.png';
import carWashActionServiceIcon from '../assets/mapIcons/roadsideServices/carWash/action.png';
import carWashActiveServiceIcon from  '../assets/mapIcons/roadsideServices/carWash/active.png';
import carWashActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/carWash/activeAction.png';

import carServiceServiceIcon from '../assets/mapIcons/roadsideServices/carService/default.png';
import carServiceActionServiceIcon from '../assets/mapIcons/roadsideServices/carService/action.png';
import carServiceActiveServiceIcon from  '../assets/mapIcons/roadsideServices/carService/active.png';
import carServiceActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/carService/activeAction.png';

import convoyServiceIcon from '../assets/mapIcons/roadsideServices/convoy/default.png';
import convoyActionServiceIcon from '../assets/mapIcons/roadsideServices/convoy/action.png';
import convoyActiveServiceIcon from  '../assets/mapIcons/roadsideServices/convoy/active.png';
import convoyActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/convoy/activeAction.png';

import ferryboatServiceIcon from '../assets/mapIcons/roadsideServices/ferryboat/default.png';
import ferryboatActionServiceIcon from '../assets/mapIcons/roadsideServices/ferryboat/action.png';
import ferryboatActiveServiceIcon from  '../assets/mapIcons/roadsideServices/ferryboat/active.png';
import ferryboatActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/ferryboat/activeAction.png';

import railwayServiceIcon from '../assets/mapIcons/roadsideServices/railway/default.png';
import railwayActionServiceIcon from '../assets/mapIcons/roadsideServices/railway/action.png';
import railwayActiveServiceIcon from  '../assets/mapIcons/roadsideServices/railway/active.png';
import railwayActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/railway/activeAction.png';

import autoPartsStoreServiceIcon from '../assets/mapIcons/roadsideServices/autoPartsStore/default.png';
import autoPartsStoreActionServiceIcon from '../assets/mapIcons/roadsideServices/autoPartsStore/action.png';
import autoPartsStoreActiveServiceIcon from  '../assets/mapIcons/roadsideServices/autoPartsStore/active.png';
import autoPartsStoreActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/autoPartsStore/activeAction.png';

import cafeServiceIcon from '../assets/mapIcons/roadsideServices/cafe/default.png';
import cafeActionServiceIcon from '../assets/mapIcons/roadsideServices/cafe/action.png';
import cafeActiveServiceIcon from  '../assets/mapIcons/roadsideServices/cafe/active.png';
import cafeActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/cafe/activeAction.png';

import truckWashServiceIcon from '../assets/mapIcons/roadsideServices/truckWash/default.png';
import truckWashActionServiceIcon from '../assets/mapIcons/roadsideServices/truckWash/action.png';
import truckWashActiveServiceIcon from  '../assets/mapIcons/roadsideServices/truckWash/active.png';
import truckWashActiveActionServiceIcon from  '../assets/mapIcons/roadsideServices/truckWash/activeAction.png';

import {
  MARKER_HEIGHT,
  ACTIVE_MARKER_HEIGHT,
  ACTIVE_MARKER_WIDTH,
  CLUSTER_HEIGHT,
  CLUSTER_WIDTH,
  LARGEST_CLUSTER_HEIGHT,
  LARGET_CLUSTER_WIDTH,
  MARKER_WIDTH
} from '../constants';


export const getMarkerSettings = (iconURL: string, id?: string) => () => ({
  url: iconURL,
  height: MARKER_HEIGHT,
  width: MARKER_WIDTH,
  anchorY: MARKER_HEIGHT / 2,
  id
});

export const getActiveMarkerSettings = (iconURL: string, id?: string) => () => ({
  url: iconURL,
  height: ACTIVE_MARKER_HEIGHT,
  width: ACTIVE_MARKER_WIDTH,
  anchorY: ACTIVE_MARKER_HEIGHT / 1.1,
  id
});

export const getClusterSettings = () => (d: any) => ({
  url: isIosUnder13Version ? defaultClusterIcon : svgToDataURL(getDefaultClusterIcon(d.properties.point_count)),
  width: CLUSTER_WIDTH,
  height: CLUSTER_HEIGHT,
  anchorY: CLUSTER_HEIGHT / 2,
  id: `cluster-${d.properties.point_count}`
});

export const getLargestClusterSettings = () => (d: any) => ({
  url: isIosUnder13Version ? largestClusterIcon : svgToDataURL(getLargestClusterIcon(d.properties.point_count)),
  width: LARGET_CLUSTER_WIDTH,
  height: LARGEST_CLUSTER_HEIGHT,
  anchorY: LARGEST_CLUSTER_HEIGHT / 2,
  id: `large-cluster-${d.properties.point_count}`
});

const ICONS = {
  cluster: getClusterSettings(),
  largeCluster: getLargestClusterSettings(),
  place: getActiveMarkerSettings(placeActiveIcon, 'place'),
  station1: getMarkerSettings(commonStationIcon, 'station1'),
  station2: getMarkerSettings(discountStationIcon, 'station2'),
  station4: getMarkerSettings(powerMaxStationIcon, 'station4'),
  station5: getMarkerSettings(economyStationIcon, 'station5'),
  stationAction1: getMarkerSettings(commonActionStationIcon, 'stationAction1'),
  stationAction2: getMarkerSettings(discountActionStationIcon, 'stationAction2'),
  stationAction4: getMarkerSettings(powerMaxActionStationIcon, 'stationAction4'),
  stationAction5: getMarkerSettings(economyActionStationIcon, 'stationAction5'),
  stationActive1: getActiveMarkerSettings(commonActiveStationIcon, 'stationActive1'),
  stationActive2: getActiveMarkerSettings(discountActiveStationIcon, 'stationActive2'),
  stationActive4: getActiveMarkerSettings(powerMaxActiveStationIcon, 'stationActive4'),
  stationActive5: getActiveMarkerSettings(economyActiveStationIconfrom, 'stationActive5'),
  stationActiveAction1: getActiveMarkerSettings(commonActiveActionStationIcon, 'stationActiveAction1'),
  stationActiveAction2: getActiveMarkerSettings(discountActiveActionStationIcon, 'stationActiveAction2'),
  stationActiveAction4: getActiveMarkerSettings(powerMaxActiveActionStationIcon, 'stationActiveAction4'),
  stationActiveAction5: getActiveMarkerSettings(economyActiveActionStationIconfrom, 'stationActiveAction5'),
  roadsideService2: getMarkerSettings(roadPaymentPointServiceIcon, 'roadsideService2'),
  roadsideService3: getMarkerSettings(parkingServiceIcon, 'roadsideService3'),
  roadsideService4: getMarkerSettings(carWashServiceIcon, 'roadsideService4'),
  roadsideService5: getMarkerSettings(carServiceServiceIcon, 'roadsideService5'),
  roadsideService6: getMarkerSettings(convoyServiceIcon, 'roadsideService6'),
  roadsideService7: getMarkerSettings(ferryboatServiceIcon, 'roadsideService7'),
  roadsideService8: getMarkerSettings(railwayServiceIcon, 'roadsideService8'),
  roadsideService9: getMarkerSettings(autoPartsStoreServiceIcon, 'roadsideService9'),
  roadsideService10: getMarkerSettings(cafeServiceIcon, 'roadsideService10'),
  roadsideService11: getMarkerSettings(truckWashServiceIcon, 'roadsideService11'),
  roadsideService12: getMarkerSettings(carWashServiceIcon, 'roadsideService12'),
  roadsideServiceAction2: getMarkerSettings(roadPaymentPointActionServiceIcon, 'roadsideServiceAction2'),
  roadsideServiceAction3: getMarkerSettings(parkingActionServiceIcon, 'roadsideServiceAction3'),
  roadsideServiceAction4: getMarkerSettings(carWashActionServiceIcon, 'roadsideServiceAction4'),
  roadsideServiceAction5: getMarkerSettings(carServiceActionServiceIcon, 'roadsideServiceAction5'),
  roadsideServiceAction6: getMarkerSettings(convoyActionServiceIcon, 'roadsideServiceAction6'),
  roadsideServiceAction7: getMarkerSettings(ferryboatActionServiceIcon, 'roadsideServiceAction7'),
  roadsideServiceAction8: getMarkerSettings(railwayActionServiceIcon, 'roadsideServiceAction8'),
  roadsideServiceAction9: getMarkerSettings(autoPartsStoreActionServiceIcon, 'roadsideServiceAction9'),
  roadsideServiceAction10: getMarkerSettings(cafeActionServiceIcon, 'roadsideServiceAction10'),
  roadsideServiceAction11: getMarkerSettings(truckWashActionServiceIcon, 'roadsideServiceAction11'),
  roadsideServiceAction12: getMarkerSettings(carWashActionServiceIcon, 'roadsideServiceAction12'),
  roadsideServiceActive2: getActiveMarkerSettings(roadPaymentPointActiveServiceIcon, 'roadsideServiceActive2'),
  roadsideServiceActive3: getActiveMarkerSettings(parkingActiveServiceIcon, 'roadsideServiceActive3'),
  roadsideServiceActive4: getActiveMarkerSettings(carWashActiveServiceIcon, 'roadsideServiceActive4'),
  roadsideServiceActive5: getActiveMarkerSettings(carServiceActiveServiceIcon, 'roadsideServiceActive5'),
  roadsideServiceActive6: getActiveMarkerSettings(convoyActiveServiceIcon, 'roadsideServiceActive6'),
  roadsideServiceActive7: getActiveMarkerSettings(ferryboatActiveServiceIcon, 'roadsideServiceActive7'),
  roadsideServiceActive8: getActiveMarkerSettings(railwayActiveServiceIcon, 'roadsideServiceActive8'),
  roadsideServiceActive9: getActiveMarkerSettings(autoPartsStoreActiveServiceIcon, 'roadsideServiceActive9'),
  roadsideServiceActive10: getActiveMarkerSettings(cafeActiveServiceIcon, 'roadsideServiceActive10'),
  roadsideServiceActive11: getActiveMarkerSettings(truckWashActiveServiceIcon, 'roadsideServiceActive11'),
  roadsideServiceActive12: getActiveMarkerSettings(carWashActiveServiceIcon, 'roadsideServiceActive12'),
  roadsideServiceActiveAction2: getActiveMarkerSettings(roadPaymentPointActiveActionServiceIcon, 'roadsideServiceActiveAction2'),
  roadsideServiceActiveAction3: getActiveMarkerSettings(parkingActiveActionServiceIcon, 'roadsideServiceActiveAction3'),
  roadsideServiceActiveAction4: getActiveMarkerSettings(carWashActiveActionServiceIcon, 'roadsideServiceActiveAction4'),
  roadsideServiceActiveAction5: getActiveMarkerSettings(carServiceActiveActionServiceIcon, 'roadsideServiceActiveAction5'),
  roadsideServiceActiveAction6: getActiveMarkerSettings(convoyActiveActionServiceIcon, 'roadsideServiceActiveAction6'),
  roadsideServiceActiveAction7: getActiveMarkerSettings(ferryboatActiveActionServiceIcon, 'roadsideServiceActiveAction7'),
  roadsideServiceActiveAction8: getActiveMarkerSettings(railwayActiveActionServiceIcon, 'roadsideServiceActiveAction8'),
  roadsideServiceActiveAction9: getActiveMarkerSettings(autoPartsStoreActiveActionServiceIcon, 'roadsideServiceActiveAction9'),
  roadsideServiceActiveAction10: getActiveMarkerSettings(cafeActiveActionServiceIcon, 'roadsideServiceActiveAction10'),
  roadsideServiceActiveAction11: getActiveMarkerSettings(truckWashActiveActionServiceIcon, 'roadsideServiceActiveAction11'),
  roadsideServiceActiveAction12: getActiveMarkerSettings(carWashActiveActionServiceIcon, 'roadsideServiceActiveAction12')
} as Record<string, any>;

export const getIconSettings = (markerName: string, d: any) => (
  ICONS[markerName] ? ICONS[markerName](d) : ICONS.station1(d)
);
