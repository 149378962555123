// @ts-nocheck
import { IconLayer as DeckIconLayer } from '@deck.gl/layers';
import IconManager from './IconManager';

class IconLayer extends DeckIconLayer {
  static layerName = 'IconLayer';
  initializeState() {
    super.initializeState();

    this.state = {
      iconManager: new IconManager(this.context.gl, {
        onUpdate: this._onUpdate.bind(this),
        onError: this._onError.bind(this)
      })
    };
  }
}

export default IconLayer;
