import React, { useMemo, useCallback } from 'react';
import Grid from 'e100-react-components/lib/components/Grid';
import IconButton from 'e100-react-components/lib/components/IconButton';
import { SetBoolStateFalse } from 'common/hooks/useBoolState';
import { useNotice } from 'entities/application';
import { copyToClipboard } from 'common/utils/index';
import { isBuildProduction } from 'common/utils/checkBuild';

import useStyles from './useStyles';

const Actions = ({ onHide }: IProps) => {
  const notice = useNotice();
  const classes = useStyles({ type: notice?.type });
  const errorData = useMemo(() => JSON.stringify((notice && notice?.data) || '', undefined, 2), [notice]);

  const onCopyError = useCallback(() => {
    copyToClipboard(errorData);
  }, [errorData]);

  return (
    <>
      <Grid container spacing={0}>
        {
          !isBuildProduction && (
            <Grid item>
              <IconButton
                kind={'fileCopyOutlined'}
                onClick={onCopyError}
                color="inherit"
                classes={{
                  icon: { root: classes.iconCopy },
                  button: { root: classes.buttonCopy }
                }}
              />
            </Grid>
          )
        }
        <Grid item>
          <IconButton
            kind={'close'}
            onClick={onHide}
            color="secondary"
            classes={{ 
              icon: { root: classes.iconButton },
              button: { root: classes.button }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface IProps {
  onHide: SetBoolStateFalse
}

export default Actions;
