import * as countries from './countries';
import * as loadingStatus from './loadingStatus';
import countryList from './countryList';
import roadsideServiceList from './roadsideServiceList';
import roadsideServicesMap from './roadsideServicesMap';
import stationCategoriesList from './stationCategoriesList';
import serviceFormatsList from './serviceFormatsList';
import additionalParamsList from './additionalParamsList';
import * as services from './services';
import additionalServiceList from './additionalServiceList';
import serviceParentIds from './serviceParentIds';
import * as brands from './brands';
import stationCatgoriesMap from './stationCatgoriesMap';
import stationCategories from './stationCategories';
import * as paymentTypes from "./paymentTypes";

export * from './services';

const selectors = {
  ...countries,
  ...loadingStatus,
  countryList,
  roadsideServiceList,
  stationCategoriesList,
  serviceFormatsList,
  serviceParentIds,
  additionalParamsList,
  additionalServiceList,
  ...services,
  ...brands,
  stationCatgoriesMap,
  stationCategories,
  roadsideServicesMap,
  ...paymentTypes
};

export default selectors;
