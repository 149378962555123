import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

const useBindActionCreators = <T extends {}>(actions: T) => {
  const dispatch = useDispatch();
  const bindedActions = useMemo(() => bindActionCreators(actions, dispatch),
    [actions, dispatch]);
  return bindedActions;
};

export default useBindActionCreators;
