import { makeStyles } from "@material-ui/core";

interface StyleProps {
  hideBorderBottom?: boolean;
}

const useStyles = makeStyles(theme => ({
  field: ({ hideBorderBottom }: StyleProps) => ({
    position: 'relative',
    ...!hideBorderBottom && {
      borderBottom: `1px solid #F1F3F6`
    }
  }),
  popper: {
    width: '100% !important',
  },
  paper: {
    boxShadow: '0px 20px 24px rgba(99, 114, 130, 0.1), 0px 20px 30px rgba(99, 114, 130, 0.08), 0px 20px 10px rgba(36, 38, 44, 0.06);',
    margin: 0,
    borderRadius: `0 0 ${theme.spacing()}px ${theme.spacing()}px`
  },
  focused: {
    boxShadow: '0px 16px 24px rgba(99, 114, 130, 0.1), 0px 6px 30px rgba(99, 114, 130, 0.08), 0px 8px 10px rgba(36, 38, 44, 0.06);',
    borderBottom: `1px solid #F1F3F6`,
    borderRadius: `${theme.spacing()}px ${theme.spacing()}px 0 0`,
    '& input::placeholder': {
      opacity: '0.5 !important',
    }
  },
  input: {
    '& div:before, & div:after': {
      border: 'none !important'
    },
    '& > div, & > label, & input': {
      fontSize: '0.9rem'
    },
    '& input': {
      zIndex: theme.zIndex.drawer
    }
  },
  inputIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  search: {
    minHeight: 55,
    padding: `${theme.spacing(1.8)}px ${theme.spacing(2)}px`,
    '& input::placeholder': {
      opacity: 1
    }
  },
  divider: {
    height: theme.spacing(3),
    margin: theme.spacing(.5),
  },
  tagsContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: `calc(100% - ${theme.spacing(4.5)}px)`,
    position: 'absolute',
    left: theme.spacing(5),
    right: theme.spacing(6.5)
  },
  endAdornment: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: theme.spacing(7)
  },
  errorMessage: {
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25)
  }
}));

export default useStyles;
