import startLocationIcon from './icons/startLocation.svg';
import endLocationIcon from './icons/endLocation.svg';

import {
  NAVIGATION_HISTORY_ROUTE_MARKER_WIDTH,
  NAVIGATION_HISTORY_ROUTE_MARKER_HEIGHT
} from 'components/Map/constants';

export const getNavigationHistoryRouteIcon = ({ locationType }: any) => ({
  url: locationType === 'startLocation' ? startLocationIcon : endLocationIcon,
  width: NAVIGATION_HISTORY_ROUTE_MARKER_WIDTH,
  height: NAVIGATION_HISTORY_ROUTE_MARKER_HEIGHT,
  anchorY: NAVIGATION_HISTORY_ROUTE_MARKER_HEIGHT / 2,
  id: `navigation-history-route-icon-${locationType}`
});
