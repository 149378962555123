import { makeStyles } from 'e100-react-components';

interface StyleProps {
  isActiveDay: boolean;
  isStartDay: boolean;
  isEndDay: boolean;
}

export const useStyles = makeStyles(({
  palette
}) => ({
  container: ({
    isActiveDay,
    isStartDay,
    isEndDay
  }: StyleProps) => ({
    display: 'inline-block',
    backgroundColor: isActiveDay ? '#eee' : undefined,
    ...isStartDay && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    ...isEndDay && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    }
  })
}));
