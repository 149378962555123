import { useEffect, useRef } from 'react';
import { useUserType } from 'store/features/auth/selectors';

import { isExpiryTime } from 'common/utils/auth/expiryTime';
import { useOperations as useAuthOperations } from 'store/features/auth';
import { isAutorizedUser } from 'common/utils/userTypes';

const useCheckSessionByTimeout = (authCheckTimeout: number) => {
  const timeIntervalId = useRef<null | ReturnType<typeof setTimeout>>(null);
  const { getRefreshToken } = useAuthOperations();
  const userType = useUserType();

  useEffect(() => {
    if (isAutorizedUser(userType)) {
      timeIntervalId.current = setInterval(
        () => {
          if(isExpiryTime(authCheckTimeout)) getRefreshToken();
        },
        authCheckTimeout
      );
    } else {
      if (timeIntervalId.current) clearInterval(timeIntervalId.current);
    }
    return () => {
      if (timeIntervalId.current) clearInterval(timeIntervalId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);
};

export default useCheckSessionByTimeout;
