
import { RootState } from 'store';
import { FitBounds } from 'common/types/map/fitBounds';

import { STATE } from '../types/state';

const selector = (state: RootState): FitBounds => {
  const { fitBounds } = state[STATE].actionStations;
  return fitBounds;
};

export default selector;
