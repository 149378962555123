import React from 'react';

import {
  Button,
  Grid
} from 'e100-react-components';
import { Icon } from 'components';

import { useStyles } from './styles';
import { useIsAutorizedUser } from 'store/features/auth/selectors';

const STATION_LINK_BUTTON_ID = 'share-station-show';

export const ShareStationLinkButton = ({
  onClick
}: ShareStationLinkButtonProps) => {
  const classes = useStyles();
  const isAutorizedUser = useIsAutorizedUser();

  if (!isAutorizedUser) {
    return null;
  }

  return (
    <Grid item>
      <Button
        variant="outlined"
        classes={{
          root: classes.buttonRoot
        }}
        onClick={onClick}
        id={STATION_LINK_BUTTON_ID}
      >
        <Icon kind="shareOutlined" color="primary" />
      </Button>
    </Grid>
  );
};

export interface ShareStationLinkButtonProps {
  onClick?: () => void;
}