import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getLocalDate } from 'common/utils/index';

import {
  Grid,
  Typography
} from 'e100-react-components';
import CopyingTextToClipboard from 'components/CopyingTextToClipboard';

import { useIntl } from 'react-intl';
import { useSelectedCarEBoxLocation } from 'entities/cars';

dayjs.extend(duration);

const UpdateTime = () => {
  const { formatMessage } = useIntl();
  const { timestampUtc } = useSelectedCarEBoxLocation() || {};

  return (
    <Grid item xs={12}>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant='caption' gutterBottom color="error">
            {formatMessage({ id: 'updateTime' })}:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='caption' color="error">
            <CopyingTextToClipboard>
              {getLocalDate(timestampUtc)}
            </CopyingTextToClipboard>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateTime;
