import { AppThunk } from 'store';

import slice from '../reducer/slice';

const { resetState } = slice.actions;

type TOperation = () => AppThunk;

const operation: TOperation = () => async (dispatch: any) => {
  dispatch(resetState());
};

export default operation;
