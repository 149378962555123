import { IStation } from 'dataSources/Typicode/stations/types';
import { TIsLoading } from 'common/types/common';
import { StatusLoading } from 'common/constants/statusLoading';

import { IStationFilter } from './stationFilter';
import { IStationMap } from './stationMap';
import { FitBounds } from 'common/types/map/fitBounds';

export const STATE = "STATIONS";
export interface IState {
  filter: {
    statusLoading?: StatusLoading;
    data: IStationFilter;
  },
  stations: {
    isLoading: TIsLoading;
    fitBounds: FitBounds;
    data: IStation[];
  },
  actionStations: {
    isLoading: TIsLoading;
    fitBounds: FitBounds;
    data: IStation[];
  },
  allStations: {
    isLoading: TIsLoading;
    data: IStation[];
    dataMap: IStationMap;
  }
};
