import React, { ReactNode, useCallback } from 'react';
import {
  TextField,
  InputAdornment,
  Typography
} from 'e100-react-components';
import { IPropTypes } from 'e100-react-components/lib/components/Slider';
import { Slider as E100Slider } from 'e100-react-components';
import { onChangeField } from 'common/hooks/useForm';
import { Props as IconProps } from 'components/Icon';

import useStyles from './useStyles';
import TextFieldIcon from '../TextFieldIcon';

const Slider = ({
  label,
  iconKind,
  valueLabel,
  value = 0,
  maxValue = 100,
  minValue = 0,
  name = 'slider',
  step = 1,
  onChange,
  helperText,
  postfixValue = '',
  marks,
  disabled
}: Props) => {
  const classes = useStyles();

  const onChangeSlider: Required<IPropTypes>['onChange'] = useCallback((_, value) => {
    if (onChange) onChange({ target: { name, value: value.toString() } });
  }, [name, onChange]);

  return (
    <div className={classes.container}>
      <TextField
        value={`${value} ${postfixValue}`}
        label={label}
        variant="outlined"
        fullWidth
        disabled
        classes={{ root: classes.root }}
        { ...helperText && { helperText: <Typography variant="caption" color="primary">{helperText}</Typography> } }
        {
          ...(iconKind || valueLabel) && {
            InputProps: {
              ...iconKind && { startAdornment: <TextFieldIcon kind={iconKind} /> },
              ...valueLabel && {
                endAdornment: <InputAdornment><Typography variant="caption">{valueLabel}</Typography></InputAdornment>
              }
            }
          }
        }
      />
      <E100Slider
        value={value}
        max={maxValue}
        min={minValue}
        name={name}
        step={step}
        onChange={onChangeSlider}
        classes={{
          root: classes.sliderRoot,
          markLabel: classes.markLabel
        }}
        marks={marks}
        disabled={disabled}
      />
    </div>
  );
};

export interface Props extends Pick<IPropTypes, 'marks' | 'disabled'> {
  label?: string;
  iconKind?: IconProps['kind'];
  valueLabel?: string;
  value?: number;
  maxValue?: number;
  minValue?: number;
  name?: string;
  step?: number;
  onChange?: onChangeField;
  helperText?: string;
  customIcon?: string;
  postfixValue?: string;
  children?: ReactNode;
};

export default Slider;
