import { createSlice } from '@reduxjs/toolkit';
import { StatusLoading } from 'common/constants';

import { IState, STATE } from "../types/state";

import setAllStations from './setAllStations';
import setIsLoadingAllStations from './setIsLoadingAllStations';

import setStations from './setStations';
import setStationsFitBounds from './setStationsFitBounds';
import setIsLoadingStations from './setIsLoadingStations';

import setActionStationsFitBounds from './setActionStationsFitBounds';
import setActionStations from './setActionStations';

import setFilter from './setFilter';
import setFilterStatusLoading from './setFilterStatusLoading';
import setHasActions from './setHasActions';

const initialState: IState = {
  filter: {
    data: {}
  },
  stations: {
    fitBounds: {
      sw: undefined,
      ne: undefined
    },
    isLoading: StatusLoading.Default,
    data: []
  },
  actionStations: {
    fitBounds: {
      sw: undefined,
      ne: undefined
    },
    isLoading: StatusLoading.Default,
    data: []
  },
  allStations: {
    isLoading: StatusLoading.Load,
    data: [],
    dataMap: {}
  }
};

const slice = createSlice({
  name: STATE,
  initialState,
  reducers: {
    setIsLoadingAllStations,
    setAllStations,

    setIsLoadingStations,
    setStations,
    setStationsFitBounds,

    setActionStations,
    setActionStationsFitBounds,

    setFilter,
    setFilterStatusLoading,
    setHasActions
  }
});

export default slice;
