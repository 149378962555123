import { PayloadAction } from "@reduxjs/toolkit";
import { StatusLoading } from 'common/constants/statusLoading';
import { StationCode } from "dataSources/Typicode/stations/types";

import { IState } from "../types";

interface Payload {
  statusLoading: StatusLoading;
  stationCode?: StationCode;
}

const setPricesStatusLoading = (state: IState, action: PayloadAction<Payload>) => {
  if (
    state.stationPrices.stationCode === action.payload.stationCode ||
    action.payload.stationCode === undefined
  ) {
    state.stationPrices.isLoading = action.payload.statusLoading;
  }
};

export default setPricesStatusLoading;
