import * as storageLocale from 'i18n/storageLocale';
import getLangFromURLParam from 'common/utils/getLangFromURLParam';
import { checkIsExistLocale } from 'common/utils/checkIsExistLocale';
import { DEFAULT_LOCALE } from 'common/constants/locales';
import { TAvailableLanguageCode } from 'components/LangSelect/types/availableLanguages';

export const getLocale = () => {
  const langFromURLParam = getLangFromURLParam();
  const lang = langFromURLParam || storageLocale.getLocale();
  const result = checkIsExistLocale(lang) ? lang : DEFAULT_LOCALE;

  return result as TAvailableLanguageCode;
};
