type TFn<T> = (acc: any, value: T, index: number) => any;

const NUMBER_ITEMS_ITERATE = 100;

const reduceAsync = <T, D = any> (items: T[], fn: TFn<T>, accValue: any): Promise<D> => new Promise((resolve) => {
  let acc: any = accValue;

  const iteratePartArray = (startIndex: number) => {
    if (startIndex > items.length) {
      resolve(acc);
      return;
    }

    const maxItems = startIndex + NUMBER_ITEMS_ITERATE > items.length
      ? items.length : startIndex + NUMBER_ITEMS_ITERATE;

    for(let i = startIndex; i < maxItems; i++) {
      acc = fn(acc, items[i], i);
    }

    setTimeout(() => { iteratePartArray(startIndex + NUMBER_ITEMS_ITERATE); }, 2);
  };

  iteratePartArray(0);
});

export default reduceAsync;
