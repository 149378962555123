// @ts-nocheck
import { CompositeLayer } from '@deck.gl/core';
import IconLayer from './IconLayer';
import Supercluster from 'common/utils/Cluster/Cluster';
import { UpdateStateInfo } from '@deck.gl/core/lib/layer';

const MAX_ZOOMS = {
  route: 5,
  map: 8
};
class IconClusterLayer extends CompositeLayer<{}> {
  static layerName = 'IconClusterLayer';

  shouldUpdateState({changeFlags}: UpdateStateInfo<{}>) {
    return changeFlags.somethingChanged;
  }

  updateState({props, oldProps, changeFlags}: UpdateStateInfo<{}>) {
    const rebuildIndex = changeFlags.dataChanged
      || props.sizeScale !== oldProps.sizeScale
      || props.isRouteStations !== oldProps.isRouteStations
      || props.selectedStation !== oldProps.selectedStation;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: props.isRouteStations ? MAX_ZOOMS.route : MAX_ZOOMS.map,
        radius: 75,
        minPoints: 5,
        ...props.clusterProps || {}
      });
      index.load(
        props.data.map(d => ({
          geometry: {
            coordinates: [
              d.longitude || d.coordinates.longitude,
              d.latitude || d.coordinates.latitude
            ] },
          selectedStation: props.selectedStation || '',
          properties: d
        }))
      );
      this.setState({index});
    }

    const z = Math.round(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  getPickingInfo({info, mode}) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== 'hover') {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map(f => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const {data, z} = this.state;
    const {
      id, sizeScale, getIcon, pickable, loadOptions, getSize, getPosition, onClick
    } = this.props;

    return [
      new IconLayer(
        this.getSubLayerProps({
          id,
          pickable,
          sizeScale,
          data,
          getPosition,
          getIcon: d => getIcon(d, z),
          getSize: d => getSize(d, z),
          loadOptions,
          onClick,
          onIconError: (error) => {
            console.error('IconLayer error', error);
          }
        })
      )
    ];
  }
}

export default IconClusterLayer;
