import { RestProvider } from 'dataSources/types/restProvider';
import { BaseResource } from 'e100-data-providers';

import {
  NavigationAutos,
  NavigationAutosCoordinates,
  CardNumber,
  GetObuCardsForm,
  NavigationHistory,
  GetLocationsHistoryForm
} from '../types';

export class NavigationApi extends BaseResource {
  path: string = '';

  constructor(provider: RestProvider) {
    super(provider);
    this.path = 'navigation';
  }

  getAutos(): Promise<{ autoObuPairs: NavigationAutos; }> {
    return this.getChildResource('autoObuPairs').getItems();
  }

  getLocationsHistory(form: GetLocationsHistoryForm): Promise<NavigationHistory> {
    return this.send(this.getChildResource('LocationsHistory').request.post().setBody(form));
  }

  getAutosCoordinates(): Promise<NavigationAutosCoordinates> {
    return this.getChildResource('/autosCoordinates').getItems();
  }

  getObuCards(form: GetObuCardsForm): Promise<{ cards: CardNumber[] }> {
    return this.send(this.getChildResource('getClientObuAutoNumberCards').request.post().setBody(form));
  }
}