import { RootState } from 'store';
import { IStationCategory } from 'common/types/common';

import { STATE } from '../types/state';

const selector = (state: RootState): IStationCategory[] => {
  const { stationCategories } = state[STATE];
  return stationCategories;
};

export default selector;
