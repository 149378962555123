import { createReduxAsyncThunk } from 'shared/utils';

import dataSources from 'dataSources';

import { CARS_STATE } from '../../constants';

import { CarEBoxLocations } from '../../types';

export const getCarEBoxLocations = createReduxAsyncThunk<CarEBoxLocations>(
  `${CARS_STATE}/getClientEBoxLocations`,
  async () => {
    return await dataSources.cars.getCarEBoxLocations();
  }
);