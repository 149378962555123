import makeStyles from 'e100-react-components/lib/styles/makeStyles';

interface Props {
  mobileButton: boolean;
}

export const useStyles = makeStyles(({
  spacing,
  palette
}) => ({
  root: ({ mobileButton }: Props) => ({
    ...mobileButton && {
      minWidth: 40,
      padding: spacing()
    },
    '&:hover': {
      background: palette.background.default
    }
  })
}));
