import { PayloadAction } from "@reduxjs/toolkit";

import { NavigationState } from "../../types";

export const setAutosLoadingStatus = (
  state: NavigationState,
  action: PayloadAction<NavigationState['autos']['loadingStatus']>
) => {
  state.autos.loadingStatus = action.payload;
};
