import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { Country } from 'dataSources/Typicode/common/types/country';

import { STATE } from '../types/state';

export const countries = (state: RootState) => (
  state[STATE].countries
);

export const getCountryByCode = (countryCode: Country['code']) => createSelector(
  (state: RootState) => countries(state),
  countries => countries.find(({ code }) => code === countryCode)
);

export const useCountryMap = () => {
  const items = useSelector(countries);

  return useMemo(() => items.reduce((acc, item) => {
    acc[item.code] = item;
    return acc;
  }, {} as {[key: Country['code']]: Country}), [items]);
};

export const useGetCountyByCode = () => {
  const countryMap = useCountryMap();

  const getCountyByCode = useCallback((countryCode: Country['code']) => (
    countryMap[countryCode]
  ), [countryMap]);

  return getCountyByCode;
};

export const useGetCountriesByCodes = () => {
  const getCountyByCode = useGetCountyByCode();

  const getCountriesByCodes = useCallback((countryCodes?: Country['code'][]) => (
    countryCodes ? countryCodes.reduce((acc, countryCode) => {
      const country = getCountyByCode(countryCode);
      if (country) acc.push(country);
      return acc;
    }, [] as Country[]) : []
  ), [getCountyByCode]);

  return getCountriesByCodes;
};
