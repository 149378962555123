import { useEffect } from 'react';
import { useOperations as useAuthOperations } from 'store/features/auth';
import { isUnknownUser } from 'common/utils/userTypes';
import { useUserType } from 'store/features/auth/selectors';

const useInitPermissions = () => {
  const { getPermissions } = useAuthOperations();
  const userType = useUserType();

  useEffect(() => {
    if (!isUnknownUser(userType)) {
      getPermissions(userType);
    }
  }, [getPermissions, userType]);
};

export default useInitPermissions;
