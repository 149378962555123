import { useEffect } from 'react';

import { Props as MapProps } from '../index';

const useChangeOptionsForActiveRoute = (routes: MapProps['routes'], activeRoute: MapProps['activeRoute']) => {
  useEffect(() => {
    (routes || []).forEach(route => {
      route.setOptions({
        isActive: !!(route.id === (activeRoute && activeRoute.id))
      });
    });
  }, [routes, activeRoute]);
};

export default useChangeOptionsForActiveRoute;
