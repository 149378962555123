import { useIntl } from 'react-intl';

import { useHasCarEBoxLocations } from 'entities/cars';

export const useButtonText = () => {
  const { formatMessage } = useIntl();
  const hasCarEBoxLocations = useHasCarEBoxLocations();

  return hasCarEBoxLocations
    ? formatMessage({ id: 'hideCars' })
    : formatMessage({ id: 'showCars' });
};

