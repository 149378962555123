import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    width: spacing(5)
  },
  mobilePaper: {
    width: spacing(4.5)
  },
  button: {
    padding: spacing(),
    minWidth: 0
  },
  mobileButton: {
    padding: spacing(0.75),
    '&:hover': {
      background: 'none'
    }
  },
  topButton: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  bottomButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
}));

export default useStyles;
