import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { Props as ActiveItemProps } from './index';

interface Props extends Pick<ActiveItemProps, 'isActive'> {}

const useStyles = makeStyles(({ palette }) => ({
  text: {
    color: ({ isActive }: Props) => isActive ? palette.primary.main : palette.text.secondary
  }
}));

export default useStyles;