import { RootState } from 'store';
import { TIsLoading } from 'common/types/common';

import { STATE } from '../types/state';

const selector = (state: RootState): TIsLoading => {
  const { allStations: { isLoading } } = state[STATE];
  return isLoading;
};

export default selector;
