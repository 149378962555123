import { deleteAccessToken } from './accessToken';
import { deleteRefreshToken } from './refreshToken';
import { deleteExpiryTime } from './expiryTime';

const resetAuthData = () => {
  deleteAccessToken();
  deleteRefreshToken();
  deleteExpiryTime();
};

export default resetAuthData;
