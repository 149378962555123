import makeStyles from 'e100-react-components/lib/styles/makeStyles';

interface Props {
  showStations: boolean;
}

export const useStyles = makeStyles(({
  spacing,
  palette,
  breakpoints
}) => ({
  icon: () => ({
    [breakpoints.up('md')]: {
      marginRight: spacing()
    }
  }),
  button: ({ showStations }: Props) => ({
    ...!showStations && { color: palette.primary.main },
    [breakpoints.down('sm')]: {
      position: 'absolute',
      top: `calc(-100% - ${spacing(2)}px)`,
      left: spacing(0.25)
    }
  })
}));
