import React, { FC } from 'react';
import classNames from 'classnames';
import TextField from 'e100-react-components/lib/components/TextField';
import { IPropTypes } from 'e100-react-components/lib/components/TextField';

import useStyles from './useStyles';

const FilledTextFiled: FC<IPropTypes> = (props) => {
  const classes = useStyles();
  const { InputProps = {}, ...otherProps } = props;

  return (
    <div className={classes.container}>
      {
        InputProps.startAdornment && (
          <div className={classes.iconContainer}>
            <>{InputProps.startAdornment}</>
          </div>
        )
      }
      <TextField
        {...otherProps}
        variant="filled"
        classes={{
          root: classNames({
            [classes.root]: true,
            [classes.rootWithIcon]: InputProps.startAdornment
          })
        }}
      />
    </div>
  );
};

export default FilledTextFiled;
