import React from 'react';

import {
  Grid,
  Typography
} from 'e100-react-components';
import CopyingTextToClipboard from 'components/CopyingTextToClipboard';

import { useIntl } from 'react-intl';
import { useSelectedCarEBoxLocation } from 'entities/cars';

const Coordinates = () => {
  const { formatMessage } = useIntl();
  const { latitude, longitude } = useSelectedCarEBoxLocation() || {};

  return (
    <Grid item xs={12}>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant='caption' gutterBottom>
            {formatMessage({ id: 'coordinates' })}:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='caption'>
            <CopyingTextToClipboard>
              {`${latitude} ${longitude}`}
            </CopyingTextToClipboard>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Coordinates;
