import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { MARKERS_LAYER_Z_INDEX } from 'common/constants/styles';

import { Props as MapProps } from './index';

interface Props extends Pick<
  Required<MapProps>,
  'isStreetView'
> { }

const useStyles = makeStyles(({
  zIndex,
  spacing,
  shadows
}) => ({
  map: {
    '& .gmnoprint.gm-bundled-control': {
      right: '45px !important',
      zIndex: zIndex.mobileStepper,
      '& button.gm-svpc': {
        borderRadius: `50% !important`,
        boxShadow: `${shadows[4]} !important`,
        '& img': {
          height: `${spacing(3)}px !important`,
          width: `${spacing(3)}px !important`
        }
      }
    },
    '& #deckgl-overlay': ({ isStreetView }: Props) => ({
      zIndex: MARKERS_LAYER_Z_INDEX,
      ...isStreetView && { display: 'none' }
    })
  },
  gmStyle: {
    '& .gm-style': {
      zIndex: 'initial !important'
    }
  },
  '@global': {
    '#map > div > .gm-style > div:nth-last-child(3) > div': {
      zIndex: '1 !important'
    },
    '.gm-style > div:last-child .gmnoprint:nth-child(5)': {
      zIndex: `1000 !important`
    },
    '.gm-style > div:last-child .gmnoprint:nth-child(2)': {
      zIndex: '1000 !important'
    }
  }
}));

export default useStyles;