import { createSlice } from '@reduxjs/toolkit';
import { USER_TYPES } from 'common/utils/userTypes';

import { IState, STATE } from "../types";

import setUser from './setUser';
import deleteUser from './deleteUser';
import setUserLoadingStatus from './setUserLoadingStatus';
import setUserType from './setUserType';
import setPermissions from './setPermissions';
import {
  setUserStateE100Mobility,
  setUserStateE100MobilityStatusLoading
} from './getUserStateE100Mobility';
import setPermissionsLoadingStatus from './setPermissionsLoadingStatus';

const initialState: IState = {
  userType: USER_TYPES.unknown,
  permissions: {
    data: []
  },
  user: {},
  userStateE100Mobility: {}
};

const slice = createSlice({
  name: STATE,
  initialState,
  reducers: {
    setUser,
    deleteUser,
    setUserLoadingStatus,
    setUserType,
    setPermissions,
    setUserStateE100Mobility,
    setUserStateE100MobilityStatusLoading,
    setPermissionsLoadingStatus
  }
});

export default slice;
