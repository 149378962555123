import { PayloadAction } from "@reduxjs/toolkit";

import { NavigationState } from "../../types";

export const setHistoryLoadingStatus = (
  state: NavigationState,
  action: PayloadAction<NavigationState['history']['loadingStatus']>
) => {
  state.history.loadingStatus = action.payload;
};
