import { RoutePoints } from '../../types/routePoints';
import mappingRoutePoint from './mappingRoutePoint';

type MappingWaypoints = (waypoints: RoutePoints['waypoints']) => google.maps.DirectionsWaypoint[] | undefined;

const mappingWaypoints: MappingWaypoints = (waypoints) => (
  waypoints.map(item => ({ location: mappingRoutePoint(item), stopover: true }))
);

export default mappingWaypoints;
