import { createSlice } from '@reduxjs/toolkit';

import { NAVIGATION_STATE } from '../../constants';

import { NavigationState } from '../../types';

import { setAutos } from './setAutos';
import { setAutosLoadingStatus } from './setAutosLoadingStatus';

import { setHistory } from './setHistory';
import { resetHistory } from './resetHistory';
import { setHistoryLoadingStatus } from './setHistoryLoadingStatus';

const initialState: NavigationState = {
  autos: {},
  history: {}
};

export const navigationSlice = createSlice({
  name: NAVIGATION_STATE,
  initialState,
  reducers: {
    setAutos,
    setAutosLoadingStatus,

    setHistory,
    resetHistory,
    setHistoryLoadingStatus
  }
});

export const navigationActions = navigationSlice.actions;
export const navigationReducer = navigationSlice.reducer;
