import React from 'react';

import { ModalActionButtonsProps } from '../ModalActionButtons';

import Grid from 'e100-react-components/lib/components/Grid';
import Button from 'e100-react-components/lib/components/Button';

export const SubmitButton = ({
  labelOk,
  onOk,
  isLoading,
  isValid,
  disabledOkButton,
  onOkContainerSize = {}
}: SubmitButtonProps) => {
  if (!labelOk) return null;
  return (
    <Grid item {...onOkContainerSize}>
      <Button
        color="primary"
        onClick={onOk as ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined}
        disabled={!isValid || disabledOkButton}
        isLoading={isLoading}
        fullWidth
      >
        {labelOk}
      </Button>
    </Grid>
  );
};

interface SubmitButtonProps extends Pick<
  ModalActionButtonsProps,
  'labelOk' | 'onOk' | 'isLoading' | 'isValid' | 'disabledOkButton' | 'onOkContainerSize'
> {}
