import { useSelector } from 'react-redux';

import { RootState } from 'store';

import { MAP_STATE } from '../../constants';

export const useInitializedMap = () => {
  const initializingMap = useSelector((state: RootState) => state[MAP_STATE].initialized);
  return initializingMap;
};
