import { getMarkerType } from './getMarkerType';
import { MARKER_TYPES } from '../../constants/markerTypes';

export const Z_COORDS = {
  [MARKER_TYPES.cluster]: 20,
  [MARKER_TYPES.place]: 1,
  [MARKER_TYPES.petrolStation]: 15,
  [MARKER_TYPES.economyPetrolStation]: 16,
  [MARKER_TYPES.discountPlusPetrolStation]: 17,
  [MARKER_TYPES.powerMaxPetrolStation]: 18,
  [MARKER_TYPES.railwayPlatform]: 5,
  [MARKER_TYPES.ferry]: 6,
  [MARKER_TYPES.convoy]: 7,
  [MARKER_TYPES.cafe]: 8,
  [MARKER_TYPES.carService]: 9,
  [MARKER_TYPES.autoGoodsStore]: 10,
  [MARKER_TYPES.parking]: 11,
  [MARKER_TYPES.carWash]: 12,
  [MARKER_TYPES.roadPaymentPoint]: 13
};

const getZCoord = (
  d: any
) => {
  const { markerType = '' } = getMarkerType(d);
  // @ts-ignore
  return Z_COORDS[markerType] || 1;
};

export default getZCoord;
