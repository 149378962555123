import { TAddress, TPlaceId, TPlaceCoordinates } from '../types/selectedPlace';

import slice from '../reducer/slice';
const { setSelectedPlace } = slice.actions;

type TOperation = (data: { address: TAddress, placeId: TPlaceId, coordinates: TPlaceCoordinates }) => void;

const operation: TOperation = (data) =>
  setSelectedPlace(data);

export default operation;
