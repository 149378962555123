import orderFirstStep from './orderFirstStep';
import orderSecondStep from './orderSecondStep';
import resetState from './resetState';

const operations = {
  orderFirstStep,
  orderSecondStep,
  resetState
};

export default operations;

