import { useMemo } from 'react';
import { useIsActiveGoogleMap } from 'entities/application';
import { useAutosCoordinates } from 'store/features/user';
import { useCarOperations } from 'entities/cars';

import {
  getCarIcon,
  getCarIconSize,
  getCarPosition
} from '../utils';

import { GoogleLayer, HereLayer } from 'common/utils/MapMarker/types';
import { CarEBoxLocation } from 'entities/cars';

import { CAR_CLUSTER_MIN_POINTS } from '../constants';

const useGoogleCarMarkersLayer = () => {
  const autosCoordinates = useAutosCoordinates();
  const { setSelectedCarEBoxLocation } = useCarOperations();

  const googleCarMarkersLayer = useMemo((): GoogleLayer => ({
    type: 'cluster',
    data: autosCoordinates || [] as any,
    id: 'cars',
    getIcon: getCarIcon,
    // @ts-ignore
    getPosition: getCarPosition,
    getSize: getCarIconSize,
    clusterProps: {
      minPoints: CAR_CLUSTER_MIN_POINTS
    },
    onClick: (props: any) => {
      setSelectedCarEBoxLocation(props.object);
    }
  }), [
    autosCoordinates,
    setSelectedCarEBoxLocation
  ]);

  return googleCarMarkersLayer;
};

const useHereCarMarkersLayer = () => {
  const autosCoordinates = useAutosCoordinates();
  const { setSelectedCarEBoxLocation } = useCarOperations();

  const hereCarMarkersLayer = useMemo((): HereLayer => ({
    data: autosCoordinates || [] as any,
    name: 'cars',
    type: 'cluster',
    getCoordinates: ({ coordinates: {
      latitude,
      longitude
    } }) => ([latitude, longitude]),
    getCluster: (clusterWeight) => {
      const { url, width, height } = getCarIcon({
        properties: {
          cluster: true,
          point_count: clusterWeight
        }
      });

      return {
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4 , y: height / 4 }
      };
    },
    getMarker: ({ station }) => {
      const {
        url,
        height,
        width
      } = getCarIcon({
        properties: {
          cluster: false,
          ...station
        }
      });

      return ({
        icon: url,
        size: { w: width / 2, h: height / 2 },
        anchor: { x: width / 4, y: height / 4 }
      })
    },
    onClick: ({ data }) => {
      setSelectedCarEBoxLocation(data as any as CarEBoxLocation);
    }
  }), [
    autosCoordinates,
    setSelectedCarEBoxLocation
  ]);

  return hereCarMarkersLayer;
};

export const useCarMarkersLayer = () => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const googleCarMarkersLayer = useGoogleCarMarkersLayer();
  const hereCarMarkersLayer = useHereCarMarkersLayer();

  return isActiveGoogleMap ? googleCarMarkersLayer : hereCarMarkersLayer;
};
