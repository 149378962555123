import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const TruckWash = (props: IProps) => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.5 2.5999C18.5 3.4299 17.83 4.0999 17 4.0999C16.17 4.0999 15.5 3.4299 15.5 2.5999C15.5 1.5999 17 -0.100098 17 -0.100098C17 -0.100098 18.5 1.5999 18.5 2.5999ZM13.5 2.5999C13.5 3.4299 12.83 4.0999 12 4.0999C11.17 4.0999 10.5 3.4299 10.5 2.5999C10.5 1.5999 12 -0.100098 12 -0.100098C12 -0.100098 13.5 1.5999 13.5 2.5999ZM8.5 2.5999C8.5 3.4299 7.83 4.0999 7 4.0999C6.17 4.0999 5.5 3.4299 5.5 2.5999C5.5 1.5999 7 -0.100098 7 -0.100098C7 -0.100098 8.5 1.5999 8.5 2.5999ZM18.2222 8V7H5.77778V8H18.2222ZM5.77778 5C4.79594 5 4 5.89543 4 7V10H3C2.44772 10 2 10.4477 2 11V13C2 13.5523 2.44772 14 3 14H4V20V21V22V23C4 23.5523 4.44772 24 5 24H6C6.55228 24 7 23.5523 7 23V22H17V23C17 23.5523 17.4477 24 18 24H19C19.5523 24 20 23.5523 20 23V22V21V20V14H21C21.5523 14 22 13.5523 22 13V11C22 10.4477 21.5523 10 21 10H20V7C20 5.89543 19.2041 5 18.2222 5H5.77778ZM6 16H18V20H6V16ZM19 9.5V14H5V9.5H19ZM8 19C8.55228 19 9 18.5523 9 18C9 17.4477 8.55228 17 8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19ZM17 18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17C16.5523 17 17 17.4477 17 18ZM14 17.5H10V18.5H14V17.5Z" />
  </SvgIcon>
);

export default TruckWash;
