import { useEffect } from 'react';

import {
  getNavigationHistoryIconColor
} from 'entities/navigation';
import { Props } from '../index';

import { useMapContext } from '../context/map';
import usePreviousState from 'common/hooks/usePreviousState';

import { polylineFactory } from 'entities/map';

import {
  NavigationHistoryPathType,
  LOCATION_HISTORY_PATH_TYPES
} from 'entities/navigation';

const getNavigationHistoryPathZIndex = (value: NavigationHistoryPathType) => ({
  [LOCATION_HISTORY_PATH_TYPES.tracked]: 1,
  [LOCATION_HISTORY_PATH_TYPES.parking]: 2,
  [LOCATION_HISTORY_PATH_TYPES.untracked]: 3
}[value] || 1);

export const useNavigationHistoryRoutes = (
  navigationHistoryPath: Props['navigationHistoryPath'],
  navigationHistoryRoutes: Props['navigationHistoryRoutes'],
  onChangeNavigationHistoryRoutes?: Props['onChangeNavigationHistoryRoutes']
) => {
  const { map } = useMapContext();
  const prevRoutes = usePreviousState<Props['navigationHistoryRoutes']>(navigationHistoryRoutes);

  useEffect(() => {
    if (prevRoutes) {
      prevRoutes.forEach((prevRoute) => {
        prevRoute.delete();
      });
    }
    // eslint-disable-next-line
  }, [navigationHistoryRoutes]);

  useEffect(() => {
    if (map) {
      const routes = (navigationHistoryPath || []).map(({ locations, type }) => {
        const path = locations.map(({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude
        }));

        const route = polylineFactory(map, {
          path,
          geodesic: true,
          strokeColor: getNavigationHistoryIconColor(type),
          strokeOpacity: 1.0,
          strokeWeight: 5,
          zIndex: getNavigationHistoryPathZIndex(type),
          clickable: false
        });

        return route;
      });

      if (onChangeNavigationHistoryRoutes) {
        onChangeNavigationHistoryRoutes(routes);
      }
    }
  }, [
    map,
    navigationHistoryPath,
    onChangeNavigationHistoryRoutes
  ]);
};
