import { RestProvider } from 'dataSources/types/restProvider';
import { BaseResource } from 'e100-data-providers';

import { StationWithPrices } from '../types';

export class StationsApi extends BaseResource {
  path: string = '';

  constructor(provider: RestProvider) {
    super(provider);
    this.path = '';
  }

  async getStationsWithPrices(): Promise<StationWithPrices[]> {
    const autocompleteResponse = await fetch('/assets/data/stationsWithPrices.json') as any;
    const data = await autocompleteResponse.json();
    return data;
  }
}