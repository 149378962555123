import startPoint from '../assets/img/startPoint.svg';
import endPoint from '../assets/img/endPoint.svg';
import waypoint1 from '../assets/img/waypoint1.svg';
import waypoint2 from '../assets/img/waypoint2.svg';
import waypoint3 from '../assets/img/waypoint3.svg';
import waypoint4 from '../assets/img/waypoint4.svg';
import waypoint5 from '../assets/img/waypoint5.svg';
import waypoint6 from '../assets/img/waypoint6.svg';
import waypoint7 from '../assets/img/waypoint7.svg';
import waypoint8 from '../assets/img/waypoint8.svg';

interface Icons {
  [key: string]: string
}

const ROUTE_ICONS: Icons = {
  startPoint,
  endPoint,
  waypoint1,
  waypoint2,
  waypoint3,
  waypoint4,
  waypoint5,
  waypoint6,
  waypoint7,
  waypoint8,
};

export const getIcon = (name: string): string => ROUTE_ICONS[name] || '';
