import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Road = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M7.80852 4C6.86544 4 6.05052 4.6588 5.85292 5.58094L5.01312 9.5H7.05852L7.80852 6L11.0001 6V7H13.0001V6H16.1917L16.9417 9.5H18.9871L18.1473 5.58094C17.9497 4.6588 17.1348 4 16.1917 4H7.80852ZM19.4157 11.5H17.3703L18.7631 18H13.0001V15H11.0001V18H5.23709L6.62995 11.5H4.58455L3.28149 17.5809C3.0147 18.826 3.9638 20 5.23709 20H18.7631C20.0364 20 20.9855 18.826 20.7187 17.5809L19.4157 11.5ZM11.0001 11.5V13H13.0001V11.5H11.0001Z" />
    <path d="M1.5 10.5C1.5 9.39543 2.39543 8.5 3.5 8.5H20.5C21.6046 8.5 22.5 9.39543 22.5 10.5V13C22.5 13.5523 22.0523 14 21.5 14C20.9477 14 20.5 13.5523 20.5 13V10.5H3.5V13C3.5 13.5523 3.05228 14 2.5 14C1.94772 14 1.5 13.5523 1.5 13V10.5Z" />
  </SvgIcon>
);

export default Road;
