export const GOOGLE_MARKER_SIGNATURE = 'Google marker';

export const DEFAULT_MAP_CENTER = { lat: 37.444585, lng: 65.607399 };
export const DEFAULT_MAP_ZOOM = 4;

export const MAP_TYPES = {
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain'
} as const;

export type MapTypeKeys = keyof typeof MAP_TYPES;
export type MapType = typeof MAP_TYPES[MapTypeKeys];
