import { BaseResource } from 'e100-data-providers';
import { RestProvider } from 'dataSources/types/restProvider';

import {
  Report,
  CreateReportForm,
  ReportGuid
} from './types';

export class ReportsApi extends BaseResource {
  path: string = '';

  constructor(provider: RestProvider) {
    super(provider);
    this.path = 'reports';
  }

  createReport(form: CreateReportForm): Promise<{ reportGuid: ReportGuid }> {
    return this.send(this.getChildResource('uiStationList').request.post().setBody(form));
  }

  getReports(): Promise<{ reports: Report[] }> {
    return this.getChildResource('list').getItems();
  }

  getReport(reportGuid: ReportGuid): Blob {
    return this.send(this.getChildResource(`download/${reportGuid}`).request.get().setOptions({ isBinary: true }));
  }
}