import { PayloadAction } from "@reduxjs/toolkit";
import { TIsLoading } from 'common/types/common';

import { IState } from "../types/state";

const action = (state: IState, actionData: PayloadAction<TIsLoading>) => {
  state.stations.isLoading = actionData.payload;
};

export default action;
