import React from 'react';
import { useIntl } from 'react-intl';
import Icon from 'e100-react-components/lib/components/Icon';
import Button from 'e100-react-components/lib/components/Button';
import { MapType } from 'common/utils/Map/constants';
import { checkIsHybridMapType } from 'common/utils/checkMapType';
import { useMediaQuery } from 'common/hooks';

import useStyles from './useStyles';
import ActiveItem from '../ActiveItem';
import useOnClick from './hooks/useOnClick';
import { setMapType } from '../index';

const SatelliteButton = ({ mapType, setMapType }: Props) => {
  const { isMobile } = useMediaQuery();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const onClick = useOnClick(setMapType);

  return (
    <Button
      onClick={onClick}
      classes={{ root: classes.button }}
    >
      <ActiveItem
        isActive={checkIsHybridMapType(mapType)}
      >
        {
          isMobile ? (
            <Icon kind={'publicOutlined'} />
          ) : (
            <>{formatMessage({ id: 'satellite' })}</>
          )
        }
      </ActiveItem>
    </Button>
  );
};

interface Props {
  mapType: MapType;
  setMapType: setMapType;
}

export default SatelliteButton;
