import React from 'react';

import {
  Tooltip,
  IconButton
} from 'e100-react-components';

import { copyToClipboard } from 'common/utils/index';

import { useIntl } from 'react-intl';
import { useApplicationOperations } from '../../hooks';

export const CopyIconButton = ({
  title,
  message
}: CopyIconButtonProps) => {
  const { formatMessage } = useIntl();
  const { setErrorNotice, setSuccessNotice } = useApplicationOperations();

  return (
    <Tooltip
      title={title || formatMessage({ id: 'replicate' })}
      enterDelay={1000}
      enterNextDelay={1000}
      enterTouchDelay={0}
    >
      <IconButton
        kind="fileCopyOutlined"
        onClick={async (event) => {
          event.stopPropagation();
          event.preventDefault();

          try {
            await copyToClipboard(message);
            setSuccessNotice({ translateId: 'copied' });
          } catch (e) {
            setErrorNotice({ translateId: 'somethingGoWrong' });
          }
        }}
      />
    </Tooltip>
  );
};

interface CopyIconButtonProps {
  title?: string;
  message: string;
}