import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import Loading from 'components/Loading';
import 'e100-react-components/lib/fonts/fonts.css';

import store from './store';
import App from './pages/app';
import Translations from './i18n/Translations';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Suspense fallback={<Loading position={'fixed'} align={'center'} />}>
    <Provider store={store}>
      <Translations>
        <App />
      </Translations>
    </Provider>
  </Suspense>
);
