import { createSlice } from '@reduxjs/toolkit';
import { getLocale } from '../../utils';

import { ApplicationState } from '../../types';

import {
  APPLICATION_STATE,
  MAP_NAMES
} from '../../constants';

import { setDirections } from './setDirectories';
import { setLocale } from './setLocale';
import { deleteNotice } from './deleteNotice';
import { setNotice } from './setNotice';
import { setSuccessNotice } from './setSuccessNotice';
import { setErrorNotice } from './setErrorNotice';
import { setShowOrderCardsModal } from './setShowOrderCardsModal';
import { setActiveModals } from './setActiveModals';
import { addActiveModal } from './addActiveModal';
import { deleteActiveModal } from './deleteActiveModal';

const initialState: ApplicationState = {
  i18n: {
    locale: getLocale()
  },
  isLoading: '',
  showOrderCardsModal: false,
  countries: [],
  roadsideService: [],
  stationCategories: [],
  serviceFormats: [],
  additionalParams: [],
  additionalServices: [],
  services: [],
  notice: null,
  activeModals: [],
  map: {
    name: MAP_NAMES.here
  }
};

export const applicationSlice = createSlice({
  name: APPLICATION_STATE,
  initialState,
  reducers: {
    setDirections,
    setLocale,
    deleteNotice,
    setNotice,
    setSuccessNotice,
    setErrorNotice,
    setShowOrderCardsModal,
    setActiveModals,
    addActiveModal,
    deleteActiveModal
  }
});

export const applicationActions = applicationSlice.actions;
export const applicationReducer = applicationSlice.reducer;
