import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { StatusLoading } from 'common/constants/statusLoading';
import { checkIsLoading, checkIsLoadingDone, checkIsLoadingError } from 'common/utils/checkLoading';
import config from 'config';

import { STATE, State } from '../types/state';

export const selectorUserSettingsStatusLoading = (state: RootState): StatusLoading | undefined => {
  const { settings: { statusLoading} } = state[STATE];
  return statusLoading;
};

const useUserSettingsStatusLoading = () => {
  const userSettingsStatusLoading = useSelector(selectorUserSettingsStatusLoading);
  return userSettingsStatusLoading;
};

export const useIsUserSettingsDone = () => {
  const userSettingsStatusLoading = useUserSettingsStatusLoading();
  return checkIsLoadingDone(userSettingsStatusLoading);
};

export const useIsUserSettingsLoading = () => {
  const userSettingsStatusLoading = useUserSettingsStatusLoading();
  return checkIsLoading(userSettingsStatusLoading);
};

export const useIsUserSettingsError = () => {
  const userSettingsStatusLoading = useUserSettingsStatusLoading();
  return checkIsLoadingError(userSettingsStatusLoading);
};

const selectorUserUpdateSettingsStatusLoading = (state: RootState): StatusLoading | undefined => {
  const { settings: { updateStatusLoading} } = state[STATE];
  return updateStatusLoading;
};

const useUpdateUserSettingsStatusLoading = () => {
  const updateUserSettingsStatusLoading = useSelector(selectorUserUpdateSettingsStatusLoading);
  return updateUserSettingsStatusLoading;
};

export const useIsUpdateUserSettingsDone = () => {
  const updateUserSettingsStatusLoading = useUpdateUserSettingsStatusLoading();
  return checkIsLoadingDone(updateUserSettingsStatusLoading);
};

export const useIsUpdateUserSettingsLoading = () => {
  const updateUserSettingsStatusLoading = useUpdateUserSettingsStatusLoading();
  return checkIsLoading(updateUserSettingsStatusLoading);
};

export const useIsUpdateUserSettingsError = () => {
  const updateUserSettingsStatusLoading = useUpdateUserSettingsStatusLoading();
  return checkIsLoadingError(updateUserSettingsStatusLoading);
};

export const selectorUserSettings = (state: RootState): State['settings']['data'] => {
  const { settings: { data} } = state[STATE];
  return data;
};

export const useUserSettings = () => {
  const userSettings = useSelector(selectorUserSettings);
  return userSettings;
};

export const useUserSettingsAutoscaling = () => {
  const { autoscaling = true } = useSelector(selectorUserSettings) || {};
  return autoscaling;
};

export const useUserSettingsDistanceFromRoute = () => {
  const { distanceFromRoute } = useUserSettings() || {};
  return distanceFromRoute ? Number(distanceFromRoute) : Number(config.routeStationsDistance);
};
