import { NavigationAutoCoordinates } from 'entities/navigation';

export const checkIsIdleFrom0To4Hours = (status: NavigationAutoCoordinates['status']) => (
  status === 1
);

export const checkIsIdleFrom4To10Hours = (status: NavigationAutoCoordinates['status']) => (
  status === 2
);

export const checkIsIdleFrom10 = (status: NavigationAutoCoordinates['status']) => (
  status === 3
);