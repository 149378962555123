import React from 'react';
import {
  SecureEBoxPopover,
  SecureEBoxPopoverProps
} from 'entities/secureEBox';
import { Grid, Typography } from 'e100-react-components';

import { useIntl } from 'react-intl';
import { useIsAutorizedUser } from 'store/features/auth/selectors';

import { SECURE_EBOX_POPOVER_ID, CLIENT_EBOX_LOCATION_MORE_DETAILS_BUTTON_ID } from '../../../constants';

export const EBoxLocationsPopover = ({
  anchorEl,
  onClose
}: EBoxLocationsPopoverProps) => {
  const { formatMessage } = useIntl();
  const isAutorizedUser = useIsAutorizedUser();

  return (
    <SecureEBoxPopover
      open={!!anchorEl}
      id={SECURE_EBOX_POPOVER_ID}
      buttonId={CLIENT_EBOX_LOCATION_MORE_DETAILS_BUTTON_ID}
      anchorEl={anchorEl as Element}
      onClose={onClose}
    >
      <Grid container>
        <Grid item>
          <Typography variant='body2'>
            {formatMessage({ id: 'clientCars.modal.text1' })}
            {' '}
            {
              !isAutorizedUser
              ? formatMessage({ id: 'clientCars.modal.text2' })
              : formatMessage({ id: 'clientCars.modal.text3' })
            }
          </Typography>
        </Grid>
      </Grid> 
    </SecureEBoxPopover>
  )
};

interface EBoxLocationsPopoverProps extends Pick<
  SecureEBoxPopoverProps,
  'onClose'
>{
  anchorEl?: EventTarget | null;
}
