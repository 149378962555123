import makeStyles from 'e100-react-components/lib/styles/makeStyles';

const useStyles = makeStyles(({ spacing }) => ({
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  fixed: {
    position: 'fixed'
  },
  center: {
    left: '50%',
    top: '50%',
    marginLeft: spacing(-0.5),
    marginTop: spacing(-0.5)
  }
}));

export default useStyles;
