import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useSelectedStationCode } from './selectedStationCode';

import { PAYMENT_TYPES } from 'common/constants/paymentTypes';

import { STATE, TStation } from '../types/state';

export const stationInfo = (state: RootState): TStation => {
  const { stationInfo } = state[STATE];
  return stationInfo;
};

export const useStationInfo = () => {
  const result = useSelector((state: RootState) => state[STATE].stationInfo);
  return result;
};

export const useIsOnlyE100MobilityPayment = () => {
  const { paymentTypes = [] } = useSelector(stationInfo);

  return !!(
      paymentTypes.length === 1
      && paymentTypes[0].code === PAYMENT_TYPES.E100Mobility
  );
};

export const useIsSelectedStationCodeEqualToReceivedStationCode = (): boolean => {
  const { code } = useStationInfo();
  const selectedStationCode = useSelectedStationCode();
  return code === selectedStationCode;
};
