import React, { useState } from 'react';
import {
  ButtonGroup,
  Paper
} from 'e100-react-components';
import { MAP_TYPES, MapType } from 'common/utils/Map/constants';

import MapButton from './MapButton';
import SatelliteButton from './SatelliteButton';

import { Props as LeftColumnProps } from '../index';

const MapTypeControl = ({ showMapTypeControl }: Props) => {
  const [mapType, setMapType] = useState<MapType>(() => MAP_TYPES.ROADMAP);

  if (!showMapTypeControl) return null;
  return (
    <Paper
      elevation={2}
    >
      <ButtonGroup variant="contained" disableElevation>
        <MapButton
          mapType={mapType}
          setMapType={setMapType}
        />
        <SatelliteButton
          mapType={mapType}
          setMapType={setMapType}
        />
      </ButtonGroup>
    </Paper>
  );
};

export type setMapType = React.Dispatch<React.SetStateAction<MapType>>;

interface Props extends Pick<LeftColumnProps, 'showMapTypeControl'> {}

export default MapTypeControl;
