import React from 'react';
import Icon, { Props as IconProps } from 'components/Icon';

import useStyles from './styles';

const InputIcon = ({ isActive, icon }: IProps) => {
  const classes = useStyles({});
  const currentIcon = isActive ? 'search' : icon;
  return (
    <Icon
      kind={currentIcon}
      classes={{ root: classes.inputIcon }}
    />
  );
};

interface IProps {
  isActive: boolean;
  icon: IconProps['kind']
};

export default InputIcon;
