import { useCallback } from 'react';

import { REPORT_COLUMN_NAMES } from '../constants';

export const useGetReportTableColumnSettings = () => {
  const getReportTableColumnSettings = useCallback((columnName: string) => {
    switch (columnName) {
      case REPORT_COLUMN_NAMES.brand: {
        return {
          width: 50
        };
      }
      case REPORT_COLUMN_NAMES.prices: {
        return {
          width: 150
        };
      }
      case REPORT_COLUMN_NAMES.address: {
        return {
          width: 120
        }
      }
    }

    return {}
  }, []);

  return getReportTableColumnSettings;
};
