import { stationCategoryCode } from './../../constants/index';
import { STATION_COLLOR_PALETTE } from 'common/constants/stationCollorPalette';

import { IStationCategoriesSettingsMap } from './types';

export const STATION_CATEGORIES_SETTINGS_MAP: IStationCategoriesSettingsMap = {
  [stationCategoryCode.common]: { iconColor: STATION_COLLOR_PALETTE.common, icon: 'localGasStationOutlined' },
  [stationCategoryCode.discountPlus]: { iconColor: STATION_COLLOR_PALETTE.discountPlus, icon: 'localGasStationOutlined' },
  [stationCategoryCode.powerMax]: { iconColor: STATION_COLLOR_PALETTE.powerMax, icon: 'localGasStationOutlined' },
  [stationCategoryCode.economy]: { iconColor: STATION_COLLOR_PALETTE.economy, icon: 'localGasStationOutlined' },
};

export const getStationCategoryIconSettings = (id?: number) => (
  (id && STATION_CATEGORIES_SETTINGS_MAP[id]) || {}
);
