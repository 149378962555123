import { GoogleMapInterface } from "common/utils/Map";
import {
  Polyline,
  PolylineOptions
} from '../../../types';

class GooglePolyline implements Polyline {
  private _map: GoogleMapInterface;
  private _polyline?: google.maps.Polyline;

  constructor(map: GoogleMapInterface, options?: PolylineOptions) {
    this._map = map;
    this._createPolyline(options);
    this._setMap(map);
  }

  _createPolyline(options?: PolylineOptions) {
    this._polyline = new google.maps.Polyline(options);
  }

  _setMap(map: GoogleMapInterface) {
    const nativeMap = map.getNativeMap();

    if (this._polyline && nativeMap) {
      this._polyline.setMap(nativeMap);
    }
  }

  delete() {
    if (this._polyline) {
      this._polyline.setMap(null);
    }
  }
}

export default GooglePolyline;
