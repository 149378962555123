import { StatusLoading } from 'common/constants';

export const checkIsLoadStatus = (value: number) => (
  value === StatusLoading.Load
);

export const checkIsErrorStatus = (value: number) => (
  value === StatusLoading.Error
);

export const checkIsDoneStatus = (value: number) => (
  value === StatusLoading.Done
);