import React from 'react';

import {
  Link,
  Grid,
  Typography
} from 'e100-react-components';

import { useApplicationOperations, MODALS } from 'entities/application';
import { useIntl } from 'react-intl';

export const ObuCardsButton = () => {
  const { formatMessage } = useIntl();
  const { addActiveModal } = useApplicationOperations();

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='caption' gutterBottom>
            <Link
              underline="always"
              href="#"
              onClick={() => {
                addActiveModal(MODALS.carEBoxLocationsClientCards);
              }}
            >
              {formatMessage({ id: 'showLinkedCards' })}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
