import { BaseResource } from 'e100-data-providers';
import { TRestProvider } from 'dataSources/types/restProvider';
import { IStationInfo, IStation, IStationPrice, TStationCode } from './types';
import { IStationFilter } from 'store/features/stations/types/stationFilter';

import mappingFilter from './utils/mappingFilter';

export * from './types';

class Stations extends BaseResource {
  path: string = '';

  constructor(provider: TRestProvider) {
    super(provider);
    this.path = 'stations';
  }

  getStations(): Promise<IStation[]> {
    return this.getItems();
  }

  getStationCodes(filter: IStationFilter): TStationCode[] {
    return this.send(this.request.post('search').setBody(mappingFilter(filter)));
  }

  getStationInfo(code: string): IStationInfo {
    return this.getChildResource(code).getItems();
  }

  getStationPrices(code: string): IStationPrice[] {
    return this.getChildResource(`${code}/prices`).getItems();
  }
};

export default Stations;

