const PAYMENT_TYPE_CODE_MAP = {
  E100Card: 'PlasticCard',
  E100Mobility: 'OnlineRefuling',

  PlasticCard: 'E100Card',
  OnlineRefuling: 'E100Mobility'
} as const;

type PaymenetTypeCodes = keyof typeof PAYMENT_TYPE_CODE_MAP;

export const mappingPaymentTypeCodesToURL = (paymentTypeCodes: (string | number)[]) => paymentTypeCodes
.map(paymentTypeCode => PAYMENT_TYPE_CODE_MAP[paymentTypeCode as PaymenetTypeCodes] || '');
