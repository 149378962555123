import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Petrol = (props: IProps) => (
  <SvgIcon {...props}>
    <path fill="#637282" d="M11,21.7c-2,0-4.1-0.8-5.7-2.3l0,0c-3.1-3.1-3.1-8.2,0-11.3L11,2.3L16.7,8c3.1,3.1,3.1,8.2,0,11.3
      C15.1,20.9,13,21.7,11,21.7z M6.8,17.9c2.3,2.3,6.1,2.3,8.5,0c2.3-2.3,2.3-6.1,0-8.5L11,5.2L6.8,9.4C4.4,11.8,4.4,15.6,6.8,17.9
      L6.8,17.9z"/>
    <path id="XMLID_3_" fill="#4FBC46" d="M12,7.3h7c1.1,0,2,0.9,2,2v8c0,1.1-0.9,2-2,2h-7c-1.1,0-2-0.9-2-2v-8
      C10,8.2,10.9,7.3,12,7.3z"/>
    <path fill="#FFFFFF" d="M13.1,9.3v8h1.6v-3.2h1.6c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1v-1.6
      c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.5-1.1-0.5H13.1z M14.7,10.9h1.6v1.6h-1.6V10.9z"/>
  </SvgIcon>
);

export default Petrol;
