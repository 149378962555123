import { useCallback } from 'react';
import { isWestTerritory } from 'common/utils/checkTerritory';

import { Station } from 'dataSources/Typicode/stations';

export const useGetStationMobileAppLinks = () => {
  const getStationMobileAppLinks = useCallback((stationCode: Station['code']) => {
    const iosURL = isWestTerritory ? 'e100mobile://open' : 'e1card://open';
    const androidURL = isWestTerritory ? 'https://e100.eu' : 'https://e1-card.ru';
    const base64UrlParams = window.btoa(JSON.stringify({
      screen: "stationDetails",
      stationID: stationCode
    }));

    return {
      androidUrl: `${androidURL}?params=${base64UrlParams}`,
      iosUrl: `${iosURL}?params=${base64UrlParams}`
    };
  }, []);

  return getStationMobileAppLinks;
};
