import {
  useCallback,
  useMemo
} from 'react';
import {
  useLocale,
  useIsActiveGoogleMap
} from 'entities/application';
import config from 'config';
import { loadScriptFromDocument } from 'shared/utils';

export const useLoadGoogleMapLibraries = () => {
  const locale = useLocale();

  const loadGoogleMapLibraries = useCallback(() => {
    const googleMapApiURL = `https://maps.googleapis.com/maps/api/js?&key=${config.googleAppKey}&libraries=places&v=quarterly&language=${locale}&callback=initMap`;
    return loadScriptFromDocument(googleMapApiURL, document.querySelector('head'));
  }, [locale]);

  return loadGoogleMapLibraries;
};

export const useLoadHereMapLibraries = () => {
  const loadHereMapLibraries = useCallback(async () => {
    const headElement = document.querySelector('head');
    await loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-core.js', headElement);
    return Promise.all([
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-harp.js', headElement),
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-core-legacy.js', headElement),
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-service.js', headElement),
      // loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-service-legacy.js', headElement),
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js', headElement),
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-ui.js', headElement),
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-clustering.js', headElement),
      loadScriptFromDocument('https://js.api.here.com/v3/3.1/mapsjs-data.js', headElement),
    ]);
  }, []);

  return loadHereMapLibraries;
};

export const useLoadMapLibraries = () => {
  const isActiveGoogleMap = useIsActiveGoogleMap();
  const loadGoogleMapLibraries = useLoadGoogleMapLibraries();
  const loadHereMapLibraries = useLoadHereMapLibraries();

  const loadMapLibraries = useMemo(() => (
    isActiveGoogleMap
      ? loadGoogleMapLibraries
      : loadHereMapLibraries
  ), [
    isActiveGoogleMap,
    loadGoogleMapLibraries,
    loadHereMapLibraries
  ]);

  return loadMapLibraries;
};
