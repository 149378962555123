import React, { Dispatch, SetStateAction, useRef } from 'react';

import { Props as DatePickerProps } from 'e100-react-components/lib/components/DatePicker/types';

import { DatePicker } from 'e100-react-components';
import { Toolbar } from './Toolbar/Toolbar';
import { CalendarDay } from './CalendarDay/CalendarDay';

import { useLocale } from 'entities/application';
import { useParseValue } from '../hooks';

import dayjs from 'dayjs';

export const DateTimePicker = ({
  value,
  minDate,
  maxDate,
  onChange,
  classes = {},
  shouldDisableDate,
  dateRange
}: DateTimePickerProps) => {
  const locale = useLocale();
  const { time } = useParseValue(value);
  const innerTimeRef = useRef<string | undefined>();

  return (
    <DatePicker
      value={value as string}
      shouldDisableDate={shouldDisableDate}
      className={classes.datePicker}
      format={'YYYY.MM.DD - HH:mm'}
      minDate={minDate}
      maxDate={maxDate}
      {
        ...dateRange && {
          renderDay: (
            day,
            selectedDay,
            dayInCurrentMonth,
            dayComponent
          ) => (
            <CalendarDay
              day={day}
              selectedDay={selectedDay}
              dayInCurrentMonth={dayInCurrentMonth}
              dayComponent={dayComponent}
              dateRange={dateRange}
            />
          )
        }
      }
      onChange={(newDate) => {
        if (onChange) {
          const dateDyjs = dayjs(newDate as string);
          const day = +dateDyjs.format('D');
          const month = +dateDyjs.format('M') - 1;
          const year = +dateDyjs.format('YYYY');
          const result = dayjs(value)
            .set('date', day)
            .set('month', month)
            .set('year', year)
            .format();

          onChange(result);
        }
      }}
      locale={locale}
      fullWidth
      variant='inline'
      onClose={() => {
        if (onChange && innerTimeRef.current) {
          const [hour, minute] = innerTimeRef.current.split(':');
          const result = dayjs(value)
            .set('hour', +hour)
            .set('minute', +minute)
            .format();
          onChange(result);
        }
      }}
      toolbarComponent={() => (
        <Toolbar
          time={time}
          innerTimeRef={innerTimeRef}
        />
      )}
    />
  );
};

export type Time = string;
export type SetTime = Dispatch<SetStateAction<string>>;
export interface Classes {
  datePicker?: DatePickerProps['className'];
}
type Value = string;

export interface DateTimePickerProps extends Pick<
  DatePickerProps,
  'shouldDisableDate' | 'minDate' | 'maxDate'
> {
  value?: Value; // UTC date
  defaultValue?: Value; // UTC date
  onChange?: (date: string) => void;
  classes?: Classes;
  dateRange?: [Value, Value];
}