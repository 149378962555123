import React, { memo } from 'react';
import { TOnChangeCheckbox } from 'common/types/common/onChangeCheckbox';
import { IListItem } from 'common/types/common/listItem';
import { IFilterSettings } from 'common/constants/types/filterSettings';

import Item from './Item';

const CheckboxList = (props: IProps) => {
  const { items, onChange, state } = props;
  return (
    <>
      {items.map(({ key, value, icon, iconColor }) => (
        <Item
          key={key}
          itemKey={key}
          title={value}
          icon={icon}
          iconColor={iconColor}
          onChange={onChange}
          state={state}
        />
      ))}
    </>
  );
};

interface IState {
  [key: string]: boolean;
};

export interface IItem extends IListItem, IFilterSettings {}

export interface IProps {
  items: IItem[];
  onChange?: TOnChangeCheckbox;
  state: IState;
};

export default memo(CheckboxList);
