import { isEastTerritory } from 'common/utils/checkTerritory';

import eastTheme from "../theme/eastTheme";
import westTheme from "../theme/westTheme";

const useTheme = () => {
  return isEastTerritory ? eastTheme : westTheme;
};

export default useTheme;
