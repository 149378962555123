import { BaseResource } from 'e100-data-providers';
import { TRestProvider } from 'dataSources/types/restProvider';

// Types
import { IStationCategory } from 'common/types/common/stationCategory';
import { IServiceFormat } from 'common/types/common/serviceFormat';
import { IService } from 'dataSources/Typicode/common/types';
import {
  IAdditionalService,
  ICountry,
  IDimensionLimitation,
  IRoadsideService,
  PaymentType,
  CountriesRegions,
  CountriesRegionsFilter,
  CountriesRoads,
  CountriesRoadsFilter,
  CountriesBrandsFilter,
  CountriesBrands
} from './types';
import { UserStateE100Mobility } from './types/userStateE100Mobility';
import { FeedbackForm } from './types/feedback';

import mappingFeedbackForm from './utils/mappingFeedbackForm';

class Common extends BaseResource {
  path: string = '';

  constructor(provider: TRestProvider) {
    super(provider);
    this.path = '';
  }

  getTranslates(locale: string) {
    return this.getChildResource(`/locales/${locale}`).getItems();
  }

  getCountries(): ICountry[]  {
    return this.getChildResource('countries').getItems();
  }

  getCountriesRegions(filter: CountriesRegionsFilter): CountriesRegions {
    return this.send(this.getChildResource('/countries/regions').request.post()
      .setBody(filter));
  }

  getCountriesRoads(filter: CountriesRoadsFilter): CountriesRoads {
    return this.send(this.getChildResource('/countries/roads').request.post()
      .setBody(filter));
  }

  getCountriesBrands(filter: CountriesBrandsFilter): CountriesBrands {
    return this.send(this.getChildResource('/countries/brands').request.post()
      .setBody(filter));
  }

  getRoadsideService(): IRoadsideService[] {
    return this.getChildResource('/roadside-services').getItems();
  }

  getStationCategories(): IStationCategory[] {
    return this.getChildResource('/station-categories').getItems();
  }

  getServiceFormats(): IServiceFormat[] {
    return this.getChildResource('/service-formats').getItems();
  }

  getDimensionsLimitation(): IDimensionLimitation[] {
    return this.getChildResource('/dimensions-limitation').getItems();
  }

  getAdditionalServices(): IAdditionalService[] {
    return this.getChildResource('/additional-services').getItems();
  }

  getServices(): IService[] {
    return this.getChildResource('/services').getItems();
  }

  getPaymentTypes(): PaymentType[] {
    return this.getChildResource('/payment-types').getItems();
  }

  getUserStateE100Mobility(): UserStateE100Mobility {
    return this.getChildResource(`/user/e100Mobility/state`).getItems();
  }

  createFeedback(form: FeedbackForm): void {
    const mappedForm = mappingFeedbackForm(form);

    return this.send(this.getChildResource('/feedbacks').request.post()
      .setOptions({ sendRawData: true })
      .setBody(mappedForm));
  }
};

export default Common;

