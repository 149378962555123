import React from 'react';

import useStyles from './useStyles';

const ActiveItem = ({ children, isActive = false }: Props) => {
  const classes = useStyles({ isActive });
  return (
    <span className={classes.text}>{children}</span>
  );
};

export interface Props {
  children: any;
  isActive?: boolean;
}

export default ActiveItem;
