import { MARKER_TYPES } from 'components/Map/constants/markerTypes';

export const MARKER_HEIGHT = 72;
export const MARKER_WIDTH = 72;

export const ACTIVE_MARKER_HEIGHT = 152;
export const ACTIVE_MARKER_WIDTH = 152;

export const CLUSTER_HEIGHT = 128;
export const CLUSTER_WIDTH = 128;

export const LARGEST_CLUSTER_HEIGHT = 128;
export const LARGET_CLUSTER_WIDTH = 186;

export const STATION_ICONS_Z_COORDS = {
  [MARKER_TYPES.cluster]: 20,
  [MARKER_TYPES.place]: 1,
  [MARKER_TYPES.petrolStation]: 15,
  [MARKER_TYPES.economyPetrolStation]: 16,
  [MARKER_TYPES.discountPlusPetrolStation]: 17,
  [MARKER_TYPES.powerMaxPetrolStation]: 18,
  [MARKER_TYPES.railwayPlatform]: 5,
  [MARKER_TYPES.ferry]: 6,
  [MARKER_TYPES.convoy]: 7,
  [MARKER_TYPES.cafe]: 8,
  [MARKER_TYPES.carService]: 9,
  [MARKER_TYPES.autoGoodsStore]: 10,
  [MARKER_TYPES.parking]: 11,
  [MARKER_TYPES.carWash]: 12,
  [MARKER_TYPES.roadPaymentPoint]: 13
} as const;

export const MIN_LARGE_CLUSTER_WEIGHT = 1000;
