import * as common from './common';
import * as stations from './stations';
import * as auth from './auth';

const dataServices = {
  common,
  stations,
  auth
};

export default dataServices;
