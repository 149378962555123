import { NavigationAutoCoordinates } from 'entities/navigation';

import { isIosUnder13Version } from 'common/utils/checkBrowserType';
import { svgToDataURL } from 'common/utils/index';

import clusterIcon from './icons/cluster/default/default.png';
import { getClusterIcon } from './icons/cluster/default';

import largestClusterIcon from './icons/cluster/largest/largest.png';
import { getLargeClusterIcon } from './icons/cluster/largest/index';

import { getCarIcon as getCarIconImage } from './icons/car/index';

import { checkIsCluster } from 'components/Map/utils/checkIconType';
import {
  CAR_CLUSTER_HEIGHT,
  CAR_HEIGHT,
  CAR_CLUSTER_WIDTH,
  CAR_LARGET_CLUSTER_WIDTH,
  CAR_LARGEST_CLUSTER_HEIGHT,
  CAR_WIDTH
} from 'components/Map/constants/icons';


const ICONS = {
  cluster: (d: any) => ({
    url: isIosUnder13Version ? clusterIcon : svgToDataURL(getClusterIcon(d)),
    width: CAR_CLUSTER_WIDTH,
    height: CAR_CLUSTER_HEIGHT,
    anchorY: CAR_CLUSTER_HEIGHT / 2
  }),
  largeCluster: (d: any) => ({
    url: isIosUnder13Version ? largestClusterIcon : svgToDataURL(getLargeClusterIcon(d)),
    width: CAR_LARGET_CLUSTER_WIDTH,
    height: CAR_LARGEST_CLUSTER_HEIGHT,
    anchorY: CAR_LARGEST_CLUSTER_HEIGHT / 2
  }),
  car: (props: NavigationAutoCoordinates) => ({
    url: svgToDataURL(getCarIconImage(props)),
    width: CAR_WIDTH,
    height: CAR_HEIGHT,
    anchorY: CAR_HEIGHT / 2
  })
};

const getClusterIconName = ({ properties }: any) => (
  properties.point_count < 1000 ? 'cluster' : 'largeCluster'
);

export const getCarIcon = (d: any) => {
  if (checkIsCluster(d)) {
    return ICONS[getClusterIconName(d)](d);
  }

  return ICONS['car'](d.properties)
};
