import { useCallback } from "react";
import { useIntl } from 'react-intl';

import { isWestTerritory } from 'common/utils/checkTerritory';

import { Station } from 'dataSources/Typicode/stations';
import { useGetStationMobileAppLinks } from './useStationMobileAppLinks';

const MOBILE_APP_DONWLOAD_LINK_EAST = 'https://e1card.page.link/VTgt';
const MOBILE_APP_DONWLOAD_LINK_WEST = 'https://e100app.page.link/RMiv';

export const useCreateClipboardMessages = () => {
  const { formatMessage } = useIntl();
  const getStationMobileAppLinks = useGetStationMobileAppLinks();

  const createClipboardMessages = useCallback((
      message: string,
      stationCode: Station['code']
    ) => {
      const { androidUrl, iosUrl } = getStationMobileAppLinks(stationCode);
      const text1 = formatMessage({ id: 'shareStationLinkMessage.text1' });
      const text2 = `**${stationCode}** ${formatMessage({ id: 'shareStationLinkMessage.text2' })}`;
      const text3 = formatMessage({ id: 'shareStationLinkMessage.text3' });
      const text4 = formatMessage({ id: 'shareStationLinkMessage.text4' });
      const mobileAppDownloadLink = isWestTerritory ? MOBILE_APP_DONWLOAD_LINK_WEST : MOBILE_APP_DONWLOAD_LINK_EAST;

      return {
        textHtml: `
          <p>${text1}.</p>
          <br />${message.replace(/\n/g, '<br />')}
          ${message.trim() ? '<br /><br />' : ''}
          <p>❗️${text3}: <a href="${mobileAppDownloadLink}">${mobileAppDownloadLink}</a></p>
          <br />
          <p>${text4} 👇</p>
          <br />
          <p>${text2}:</p>
          <p>**Android**: <a href="${androidUrl}">${androidUrl}</a></p>
          <br />
          <p>**IOS**: <a href="${iosUrl}">${iosUrl}</a></p>
        `,
        textPlain: `${text1}. ${!!message ? '\n\n' : ''}${message} \n\n${text3.replaceAll("<br/>", "")}: ${mobileAppDownloadLink} \n\n${text4} \n\n${text2}: \n**Android**: ${androidUrl} \n\n**IOS**: ${iosUrl}`
      }
    }, [
    formatMessage,
    getStationMobileAppLinks
  ]);

  return createClipboardMessages;
};
