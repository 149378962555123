import { PayloadAction } from "@reduxjs/toolkit";
import { TIsLoading } from 'common/types/common';

import { IState } from "../types/state";

const action = (state: IState, payloadAction: PayloadAction<TIsLoading>) => {
  state.orderFirstStep.isLoading = payloadAction.payload;
};

export default action;
