import { TOperation } from 'common/types/store/operation';
import { batch } from 'react-redux';
import dataSources from 'dataSources';
import setAuthData from 'common/utils/auth/setAuthData';
import { USER_TYPES } from 'common/utils/userTypes';
import parseAuthData from 'common/utils/auth/parseAuthData';
import { getResfreshToken } from 'common/utils/auth/refreshToken';
import resetAuthData from 'common/utils/auth/resetAuthData';
import { STATUS_LOADING } from 'common/constants/statusLoading';
import { getResponseError } from 'common/utils/index';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { setUserType, setUser, setUserLoadingStatus, deleteUser } = slice.actions;
const { setErrorNotice } = applicationActions;

const operation: TOperation = () => async (dispatch) => {
  try {
    dispatch(setUserLoadingStatus(STATUS_LOADING.load));

    const { data } = await dataSources.auth.refreshToken(getResfreshToken());

    const authData = parseAuthData(data);
    setAuthData(authData);

    batch(() => {
      dispatch(setUser(authData));
      dispatch(setUserType(USER_TYPES.autorized));
      dispatch(setUserLoadingStatus(STATUS_LOADING.load));
    });
  } catch (e) {
    const error = await getResponseError(e);

    batch(() => {      
      dispatch(setErrorNotice(error));
      resetAuthData();
      dispatch(deleteUser());
      dispatch(setUserType(USER_TYPES.unautorized));
      dispatch(setUserLoadingStatus(STATUS_LOADING.error));
    });
  }
};

export default operation;