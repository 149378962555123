import makeStyles from 'e100-react-components/lib/styles/makeStyles';

import { Props as TitledSectionProps } from './index';

interface Props extends Required<Pick<
  TitledSectionProps, 'paddingTop' | 'paddingBottom'
>> {}

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    padding: spacing(2),
    paddingBottom: ({ paddingBottom }: Props) => spacing(paddingBottom),
    paddingTop: ({ paddingTop }: Props) => spacing(paddingTop)
  },
  subtitle: {
    marginBottom: spacing(1.5)
  },
  divider: {
    margin: 0
  }
}));

export default useStyles;
