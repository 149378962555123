import initStations from './initStations';
import setActionFilter from './setActionFilter';
import getStations from './getStations';
import resetFilter from './resetFilter';
import setStationsFitBounds from './setStationsFitBounds';
import setFilter from './setFilter';
import resetFilterStatusLoading from './resetFilterStatusLoading';

const operations = {
  initStations,
  getStations,
  setActionFilter,
  setFilter,
  resetFilter,
  setStationsFitBounds,
  resetFilterStatusLoading
};

export default operations;

