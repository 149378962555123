import { useCallback } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  checkIsLoading,
  checkIsLoadingDone
} from 'common/utils/checkLoading';

import { STATIONS_STATE } from '../../constants';
import { StationWithPrices } from '../../types';

const useStationsWithPricesLoadingStatus = () => {
  const stationsWithPricesLoadingStatus = useSelector((state: RootState) => state[STATIONS_STATE].stationsWithPrices.loadingStatus);
  return stationsWithPricesLoadingStatus;
};

export const useIsStationsWithPricesLoading = () => {
  const stationsWithPricesLoadingStatus = useStationsWithPricesLoadingStatus();
  return checkIsLoading(stationsWithPricesLoadingStatus);
};

export const useIsStationsWithPricesDone = () => {
  const stationsWithPricesLoadingStatus = useStationsWithPricesLoadingStatus();
  return checkIsLoadingDone(stationsWithPricesLoadingStatus);
};

export const useStationsWithPrices = () => {
  const stationsWithPrices = useSelector((state: RootState) => state[STATIONS_STATE].stationsWithPrices.data);
  return stationsWithPrices;
};

export const useStationsWithPricesMap = () => {
  const stationsWithPricesMap = useSelector((state: RootState) => state[STATIONS_STATE].stationsWithPrices.dataMap);
  return stationsWithPricesMap;
};

export const useGetStationWithPricesByCode = () => {
  const stationsWithPricesMap = useStationsWithPricesMap();

  const getStationWithPricesByCode = useCallback((stationCode: StationWithPrices['code']) => (
    (stationsWithPricesMap || {})[stationCode]
  ), [stationsWithPricesMap]);

  return getStationWithPricesByCode;
};
