import React from 'react';

import { Button } from 'shared/components';
import Icon from 'components/Icon';

import { useIntl } from 'react-intl';
import { useMapSettingsContext } from '../../../context';
import { useStyles } from './styles';
import { useMediaQuery } from 'common/hooks';

import { STATIONS_DISPLAY_BUTTON_ID } from './constants';

export const StationsDisplayButton = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useMediaQuery();
  const { showStations, toggleShowStations } = useMapSettingsContext();
  const classes = useStyles({ showStations });

  return (
    <Button
      onClick={toggleShowStations}
      disableElevation={false}
      id={STATIONS_DISPLAY_BUTTON_ID}
      variant="contained"
      classes={{ root: classes.button }}
      mobileButton={isMobile}
    >
      <Icon
        kind="pointDisable"
        classes={{
          root: classes.icon
        }}
      />
      {
        !isMobile && (
          formatMessage({ id: showStations ? 'hideStations' : 'showStations' })
        )
      }
    </Button>
  );
};
