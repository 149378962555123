import { LatLng } from 'common/utils/Route';
import { polylineToLatLngAray } from './polylineToLatLngAray';
import { getFuelForRoute } from '../../utils';

export const getPointCompletionFuel = (
  sections: any[],
  {
    averageFuelConsumption,
    fuelInTankAmount,
    tanksTotalVolume,
    fuelRemainingPercentageInTank
  }: {
    averageFuelConsumption: string;
    fuelInTankAmount: string;
    tanksTotalVolume: string;
    fuelRemainingPercentageInTank: string;
  }
): LatLng | undefined => {
  if (
    averageFuelConsumption &&
    fuelInTankAmount &&
    tanksTotalVolume &&
    fuelRemainingPercentageInTank
  ) {
    let fuelForRoute = getFuelForRoute(fuelInTankAmount, tanksTotalVolume, fuelRemainingPercentageInTank);

    for(let i = 0; i < sections.length; i++) {
      const { spans, polyline } = sections[i];

      for(let j = 0; j < spans.length; j++) {
        if (fuelForRoute - spans[j].consumption <= 0) {
          const { offset } = spans[j -1];
          const latLngArray = polylineToLatLngAray(polyline);
          return latLngArray[offset];
        } else {
          fuelForRoute -= spans[j].consumption;
        }
      }
    }
  }
};
