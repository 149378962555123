import React from 'react';

import useStyles from './useStyles';

const LoadingContent = ({ children, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <div className={`${isLoading ? classes.active : ''}`}>{children}</div>
  )
};

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

export default LoadingContent;
