import { roadsideServiceCode } from './../../constants/index';
import { STATION_COLLOR_PALETTE } from 'common/constants/stationCollorPalette';
import { IRoadsideServiceIconsMap } from './types';

export const ROADSIDE_SERVICE_ICONS_MAP: IRoadsideServiceIconsMap = {
  [roadsideServiceCode.gasStation]:  { icon: 'localGasStationOutlined' },
  [roadsideServiceCode.roadPay]:     { icon: 'road' },
  [roadsideServiceCode.parking]:     { icon: 'parking' },
  [roadsideServiceCode.carWash]:     { icon: 'localCarWashOutlined' },
  [roadsideServiceCode.autoService]: { icon: 'buildOutlined' },
  [roadsideServiceCode.guard]:       { icon: 'guard' },
  [roadsideServiceCode.boat]:        { icon: 'directionsBoatOutlined' },
  [roadsideServiceCode.railway]:     { icon: 'directionsRailwayOutlined' },
  [roadsideServiceCode.store]:       { icon: 'storeMallDirectoryOutlined' },
  [roadsideServiceCode.cafe]:        { icon: 'localCafeOutlined' },
  [roadsideServiceCode.truckWash]:   { icon: 'truckWash' },
  [roadsideServiceCode.automobileWash]: { icon: 'localCarWashOutlined' },
};

export const getRoadsideServiceIcon = (id: number) => {
  const { icon = '' } = ROADSIDE_SERVICE_ICONS_MAP[id] || {};

  if (icon) return { icon };

  return {};
};

export const getRoadsideServiceIconSettings = (id: number) => {
  const icon = getRoadsideServiceIcon(id);
  return { iconColor: STATION_COLLOR_PALETTE.roadService, ...icon };
};
