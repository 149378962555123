import React from 'react';
import classNames from 'classnames';
import CircularProgress from 'e100-react-components/lib/components/CircularProgress';

import useStyles from './useStyles';

const Loading = (props: IProps) => {
  const {
    marginLeft = 4, marginRight = 4, position = 'relative', align = 'left', size = 24
  } = props;
  const classes = useStyles();

  return (
    <span
      style={{ marginLeft, marginRight }}
      className={classNames({
        [classes.relative]: position === 'relative',
        [classes.absolute]: position === 'absolute',
        [classes.fixed]: position === 'fixed',
        [classes.center]: align === 'center'
      })}
    >
      <CircularProgress size={size} />
    </span>
  );
}

interface IProps {
  marginLeft?: number;
  marginRight?: number;
  position?: 'relative' | 'absolute' | 'fixed';
  align?: 'left' | 'center' | 'right';
  size?: number;
}

export default Loading;
