import React from 'react';
import classNames from 'classnames';
import Avatar, { IPropTypes as IAvatarProps } from 'e100-react-components/lib/components/Avatar';
import Icon from 'components/Icon';

import { useStyles } from './styles';

export const UserAvatar = ({ size = 'medium' }: UserAvatarProps) => {
  const classes = useStyles();
  return (
    <Avatar
      variant="rounded"
      size={size}
      classes={{
        root: classNames({
          [classes.avator]: true,
          [classes.avatorSmall]: size === 'small'
        })
      }}
    >
      <Icon color={'primary'} kind={'accountCircle'} />
    </Avatar>
  );
};

interface UserAvatarProps {
  size?: IAvatarProps['size'];
}
