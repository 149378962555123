import { IStationFilter } from 'store/features/stations/types/stationFilter';
import getKey from 'common/utils/getKey';
import { ServicesFormats as SF } from 'common/constants';

const getServiceFormats = (serviceFormats: IStationFilter['serviceFormats']): (string | number)[] => {
  if (serviceFormats && serviceFormats[SF.automatic] && serviceFormats[SF.operator]) return [SF.halfautomatic];
  return Object.keys(serviceFormats);
}

const getStringValue = (value: any) => `${value}`;

const mappingFilter = ({
  countries, countryRegions, tracks, brands, roadsideServices,
  stationCategories, services, additionalServices, serviceFormats,
  additionalParams, viewPort, stationCode, paymentTypes
}: IStationFilter) => ({
  ...countries && { countryAlpha2Codes: countries.map(getKey) },
  ...countryRegions && { regionBillingIds: countryRegions.map(getKey) },
  ...tracks && { roadBillingIds: tracks.map(getKey) },
  ...brands && { brandBillingIds: brands.map(getKey) },
  ...roadsideServices && { roadsideServiceCodes: Object.keys(roadsideServices).map(getStringValue) }, 
  ...stationCategories && { stationCategoryCodes: Object.keys(stationCategories).map(getStringValue) },
  ...services && { serviceCodes: Object.keys(services).map(getStringValue) },
  ...additionalServices && { additionalServiceCodes: Object.keys(additionalServices).map(getStringValue) }, 
  ...serviceFormats && { serviceFormats: getServiceFormats(serviceFormats) },
  ...additionalParams && { dimensionsLimitation: [ additionalParams ] },
  ...viewPort && { viewPort },
  ...stationCode && { stationCode: stationCode.toUpperCase() },
  ...paymentTypes && { paymentTypeCodes: Object.keys(paymentTypes).map(getStringValue) }
});

export default mappingFilter;
