import { GoogleMapInterface } from "common/utils/Map";
import { Params } from './types/constructor';

import { getInfoWindow } from '../../utils';

class InfoWindow {
  private _map: GoogleMapInterface;
  private _overviewPath: Params['overviewPath'];
  private _distance: Params['distance'];
  private _time: Params['time'];
  private _infoWindow?: google.maps.InfoWindow;

  constructor(map: GoogleMapInterface, params: Params) {
    const { overviewPath, distance, time } = params;
    this._map = map;
    this._overviewPath = overviewPath;
    this._distance = distance;
    this._time = time;
    this._init();
  }

  _init() {
    const middlePathPoint = this._overviewPath[Math.floor(this._overviewPath.length / 2)];

    this._infoWindow = new google.maps.InfoWindow({
      content: getInfoWindow({ distance: this._distance, time: this._time }),
      ariaLabel: "Route info window",
      position: middlePathPoint
    });
  }

  add() {
    if (this._infoWindow) {
      this._infoWindow.open({
        map: this._map.getNativeMap(),
        shouldFocus: false
      });
    }
  }

  delete() {
    if (this._infoWindow) {
      this._infoWindow.close();
    }
  }
}

export default InfoWindow;
