import { isFirefoxBrowser } from 'common/utils/checkBrowserType';

const getIcon = (clusterWeight: any) => (
  `<svg version="1.1" height="128" width="186" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="-29 -52 186 128" style="enable-background:new -29 -52 186 128;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:#4FBC46;fill-opacity:${isFirefoxBrowser ? 1 : 0.48};}
        .st1{fill:#4FBC46;}
        .st2{fill:none;stroke:#379544;stroke-width:4.75;stroke-linecap:round;stroke-linejoin:round;}
        .st3{fill:#FFFFFF;}
        .st4{font-family:'Roboto-Regular', Arial, Helvetica, sans-serif;}
        .st5{font-size:33.7346px; text-anchor: middle;}
      </style>
      <path class="st0" d="M-26.2,12c0-34.1,27.6-61.8,61.8-61.8h57c34.1,0,61.8,27.6,61.8,61.8s-27.6,61.8-61.8,61.8h-57
        C1.4,73.8-26.2,46.1-26.2,12z"/>
      <g>
        <path class="st1" d="M35.5-35.5h57c26.2,0,47.5,21.3,47.5,47.5l0,0c0,26.2-21.3,47.5-47.5,47.5h-57C9.3,59.5-12,38.2-12,12l0,0
          C-12-14.2,9.3-35.5,35.5-35.5z"/>
        <path class="st2" d="M35.5-33.1h57c24.9,0,45.1,20.2,45.1,45.1l0,0c0,24.9-20.2,45.1-45.1,45.1h-57C10.6,57.1-9.6,36.9-9.6,12l0,0
          C-9.6-12.9,10.6-33.1,35.5-33.1z"/>
      </g>
      <text id="XMLID_44_" transform="matrix(1 0 0 1 63 21.8555)" class="st3 st4 st5">${clusterWeight}</text>
    </svg>`
);

export default getIcon;
