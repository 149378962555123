import React from 'react';

import clientAutosMapImage2x from 'assests/clientAutos/client-autos-map-x2.png';
import clientAutosMapImage2xPreview from 'assests/clientAutos/client-autos-map-x2-preview.jpg';

import { Grid, Button } from 'e100-react-components';
import { SecureEBoxPopoverProps } from '../SecureEBoxPopover';
import { LazyLoadImage } from 'components';

import { useIntl } from 'react-intl';
import { useSecureEBoxURL } from '../../../hooks';
import { useStyles } from './styles';
import { useMediaQuery } from 'common/hooks';

export const Main = ({
  children,
  buttonId
}: MainProps) => {
  const secureEBoxURL = useSecureEBoxURL();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { isMobile } = useMediaQuery();

  return (
    <>
      <Grid container justify='center'>
        <Grid item>
          <LazyLoadImage
            previewSrc={clientAutosMapImage2xPreview}
            src={clientAutosMapImage2x}
            alt="Client cars map"
            classes={{
              root: classes.image
            }}
          />
        </Grid>
      </Grid>
      {children}
      <Grid container>
        <Grid item xs={12}>
          <Button
            component="a"
            href={secureEBoxURL}
            color={'primary'}
            variant={isMobile ? 'contained' : 'text'}
            fullWidth
            classes={{
              root: !isMobile ? classes.button : undefined
            }}
            id={buttonId}
          >
            {formatMessage({ id: 'moreDetailed' })}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

interface MainProps extends Pick<
  SecureEBoxPopoverProps,
  'children' | 'buttonId'
> {}
