import { PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "../../types";

import { ActiveModal } from '../../types';

export const addActiveModal = (
  state: ApplicationState,
  { payload }: PayloadAction<ActiveModal>
) => {
  state.activeModals = [...state.activeModals, payload];
}