import { useEffect } from 'react';
import { useBoolState, BoolState, ToggleBoolState } from './useBoolState';

type TResult = [ BoolState, ToggleBoolState ];
type TUseShowPartModalContent = (showDep: boolean) => TResult;

const useShowPartModalContent: TUseShowPartModalContent = (showDep) => {
  const [ showPartContent, onTogleShowPartContent, setShowPartContent ] = useBoolState(false);

  useEffect(() => {
    if (showDep) {
      setShowPartContent(false);
    } else {
      setShowPartContent(true);
    }
    // eslint-disable-next-line
  }, [showDep]);

  return [ showPartContent, onTogleShowPartContent ];
};

export default useShowPartModalContent;
