import { TERRITORIES } from 'common/constants/territories';

export interface IConfig {
  serviceName: string;
  authCheckTimeout: number;
  authClientId: string;
  getAuthHeaders: (token: string) => Partial<{ Authorization: string; }>;
  companySiteLink: string;
  authServiceName: string;
  clientSiteLink: string;
  routeStationsDistance: number;
  mapId: string;
  appStage: string;
  googleAppKey: string;
  hereAppKey: string;
  territory: number;
};

const {
  serviceName,
  authServiceName,
  authCheckTimeout,
  authClientId,
  clientSiteLink,
  companySiteLink,
  routeStationsDistance,
  mapId,
  appStage,
  googleAppKey,
  hereAppKey,
  territory = TERRITORIES.west
} = window.fuelStationsLocator || {};

const config: IConfig = {
  serviceName,
  authServiceName,
  authCheckTimeout,
  authClientId,
  clientSiteLink,
  companySiteLink,
  routeStationsDistance,
  mapId,
  appStage,
  googleAppKey,
  hereAppKey,
  territory,
  getAuthHeaders: (token) => (token ? { Authorization: token } : {})
};

export default config;
