import { getStationCategoryIconSettings } from './stationCategories';
import { getRoadsideServiceIconSettings } from './roadsideServices';
import { stationCategoryCode } from 'common/constants/index';

import {
  checkIsStation,
  getFirstByOrderRoadsideServiceCode
} from 'common/utils/index';

const getStationIconSettings = (categoryCode: number, roadsideServiceCodes: number[] = []) => {
  const firstByOrderRoadsideServiceCode = getFirstByOrderRoadsideServiceCode(roadsideServiceCodes);

  if (!firstByOrderRoadsideServiceCode) {
    return getStationCategoryIconSettings(stationCategoryCode.common);
  }

  if (checkIsStation(firstByOrderRoadsideServiceCode)) {
    return getStationCategoryIconSettings(categoryCode);
  }

  return getRoadsideServiceIconSettings(firstByOrderRoadsideServiceCode);
}

export default getStationIconSettings;
