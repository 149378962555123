import {
  MARKER_HEIGHT,
  CLUSTER_HEIGHT,
  ACTIVE_MARKER_HEIGHT
} from '../constants';

import {
  checkIsPlace,
  checkIsSelectedStation,
  checkIsCluster
} from './checkIconType';

export const getSize = (d: any) => {
  if (checkIsCluster(d)) return CLUSTER_HEIGHT;
  if (checkIsSelectedStation(d.properties.code, d.selectedStation)) return ACTIVE_MARKER_HEIGHT;
  if (checkIsPlace(d)) return ACTIVE_MARKER_HEIGHT;

  return MARKER_HEIGHT;
};
