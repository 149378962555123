import * as isLoading from './isLoading';
import * as stationInfo from './stationInfo';
import isLoadingPrices from './isLoadingPrices';
import * as stationPrices from './stationPrices';
import selectedStation from './selectedStation';
import * as selectedStationCode from './selectedStationCode';
import stationPriceMap from './stationPriceMap';
import isSelectedStation from './isSelectedStation';
import selectedPlace from './selectedPlace';
import selectedCoordinates from './selectedCoordinates';

const selectors = {
  ...isLoading,
  ...stationInfo,
  isLoadingPrices,
  ...stationPrices,
  selectedStation,
  ...selectedStationCode,
  stationPriceMap,
  isSelectedStation,
  selectedPlace,
  selectedCoordinates
};

export * from './selectedStationCode';

export default selectors;

