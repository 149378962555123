import resetURLParams from 'common/utils/url/resetURLParams';
import addURLParams from 'common/utils/url/addURLParams';

import { URL_PARAMS } from '../constants';

export const deleteURLParamActiveStation = () => {
  resetURLParams([URL_PARAMS.activeStation]);
}

export const addURLParamActiveStation = (value: string) => {
  addURLParams({ [URL_PARAMS.activeStation]: value });
};