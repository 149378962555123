import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    minWidth: 0,
    padding: `${spacing(1.25)}px ${spacing(1.5)}px`
  },
  buttonEndIcon: {
    marginLeft: 0,
    marginRight: 0
  },
  icon: {}
}));

export default useStyles;
