import { useMemo } from 'react';

import { ReportGuid } from '../api';
import { reportsMetadataLocalStore } from '../store';

export const useReportMetadata = (reportGuid: ReportGuid) => {

  const reportMetadata = useMemo(() => (
    reportsMetadataLocalStore.getReportMetadata(reportGuid)
  ), [reportGuid]);

  return reportMetadata;
};