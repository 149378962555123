import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const URLs = {
  pl: 'https://e100.eu/pl/products/secure-ebox',
  en: 'https://e100.eu/en/products/secure-ebox',
  uk: 'https://e100.eu/ua/products/secure-ebox',
  lt: 'https://e100.eu/lt/produktai/secure-ebox',
  es: 'https://e100.eu/en/products/secure-ebox',
  tr: 'https://e100.eu/en/products/secure-ebox'
} as { [key: string]: string };

export const useSecureEBoxURL = () => {
  const { locale } = useIntl();

  const secureEBoxURL = useMemo(() => (
    URLs[locale] || URLs.en
  ), [locale]);

  return secureEBoxURL;
};
