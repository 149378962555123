import React from 'react';

import { Grid } from 'e100-react-components';

import { ICellComponentProps } from 'e100-react-components/lib/components/DataGrid/types/props/columns';
import { Item } from '../../../../hooks';

export const PricesCell = ({
  rowData
}: PricesCellProps) => {

  if (!rowData.Prices) {
    return null;
  }

  return (
    <Grid container>
      {rowData.Prices.map(({ title, value }) => (
        <>
          <Grid item xs={12}>
            <b>{title}:</b><br />
            {value}
          </Grid>
          <Grid item xs={12} />
        </>
      ))}
    </Grid>
  );
};

interface PricesCellProps extends Omit<
  ICellComponentProps,
  'rowData'
> {
  rowData: Item;
}