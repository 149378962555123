import { NoticeType } from 'entities/application';

type INoticeTypes = {
  [key in NoticeType]: key;
}

export const NOTICE_TYPES: INoticeTypes = {
  error: 'error',
  warning: 'warning',
  success: 'success'
};
