type Value = string;

const getSeparator = (value: Value) => {
  const modifiedValue = value.toString().trim();

  if (modifiedValue.includes(',')) return ', ';
  if (modifiedValue.includes('\t')) return '\t';
  return ' ';
};

const getCoordinatesFromString = (value: Value) => {
  const separator = getSeparator(value);
  const separatedValue = value.split(separator);

  const latitude = separatedValue.shift() || '';
  const longitude = separatedValue.pop() || '';

  return [+latitude, +longitude];
};

export default getCoordinatesFromString;
