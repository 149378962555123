import React, {
  useRef,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import classNames from 'classnames';

import { EMPTY_COMPONENT } from 'common/constants';

import { Polyline } from 'entities/map';
import { NavigationHistoryPath } from 'entities/navigation';
import { FitBounds } from 'common/types/map/fitBounds';
import { MapPadding } from 'common/utils/Map/types';
import { IStation } from 'dataSources/Typicode/stations/types';
import { RouteSettings } from 'common/utils/Route/types/routeSettings';
import { RoutePoints } from 'common/utils/directionsService/types/routePoints';
import { Route, Routes } from 'common/utils/Route/types/routes';
import { Params as RouteConstructorParams } from 'common/utils/Route/types/params';
import { RoutesSettings } from './types/routesSettings';
import { OnMarkerClick } from './hooks/useOnStationMarkerClick';

import Controls from './Controls';
import Tooltip from './Tooltip';
import Footer from './Footer';

import useMarkers from './hooks/useMarkers';
import useFitBounds from './hooks/useFitBounds';
import useOnMapClick from './hooks/useOnMapClick';
import useFocusPoint from './hooks/useFocusPoint';
import useShowRoutes from './hooks/useShowRoutes';
import useMapStyles from './hooks/useMapStyles';
import useGlobalStyles from './useGlobalStyles';
import {
  useInitMap,
  useNavigationHistoryRoutes
} from './hooks';
import { useIsActiveGoogleMap } from 'entities/application';

import withContext from './hoc/withContext';
import { useMapContext } from './context/map';
import { useMarkersContext } from './context'

import { Map as MapInterface } from 'common/utils/Map';
import { Marker } from 'common/utils/MapMarker/types';
import { FocusPoint } from './types/focusPoint';
import { FooterElements } from './types/footerElements';
import { Layers } from 'common/utils/MapMarker/types';

// Routes
import { onChangeRoutes } from './types/onChangeRoutes';
import useInitRoutes from './hooks/useInitRoutes';
import useChangeOptionsForActiveRoute from './hooks/useChangeOptionsForActiveRoute';

import useStyles from './useStyles';
export interface Props {
  onMarkerClick?: OnMarkerClick;
  stations: IStation[];
  extraStations?: IStation[];
  fitBounds?: FitBounds;
  fitBoundsPadding?: MapPadding;
  showZoomControl?: boolean;
  setIsStreetView?: Dispatch<SetStateAction<boolean>>;
  isStreetView?: boolean;
  onInitializedMap?: () => void;
  focusPoint?: FocusPoint;
  showMapTypeControl?: boolean;
  routes?: Routes;
  activeRoute?: Route;
  routesPoints?: RoutePoints;
  routeInitData?: RouteConstructorParams;
  onChangeRoutes?: onChangeRoutes;
  routeModifiers?: RouteSettings;
  styles?: React.CSSProperties;
  footerElements?: FooterElements;
  routesSettings?: RoutesSettings;
  showStationsDisplayButton?: boolean;
  navigationHistoryPath?: NavigationHistoryPath[];
  navigationHistoryRoutes?: Polyline[];
  onChangeNavigationHistoryRoutes?: (value?: Polyline[]) => void;
  layers?: Layers;
  renderElements?: (props: {
    map: MapInterface | null,
    markers: Marker | null
  }) => JSX.Element | null;
};

const Map = ({
  onMarkerClick,
  fitBounds,
  fitBoundsPadding,
  stations,
  extraStations,
  showZoomControl = true,
  styles,
  isStreetView = false,
  setIsStreetView,
  focusPoint,
  showMapTypeControl = true,
  onInitializedMap,
  footerElements,
  routes,
  activeRoute,
  routesPoints,
  onChangeRoutes,
  routeModifiers,
  showStationsDisplayButton = false,
  navigationHistoryPath,
  navigationHistoryRoutes,
  onChangeNavigationHistoryRoutes,
  renderElements = EMPTY_COMPONENT,
  ...otherProps
}: Props) => {
  const routesSettings = useMemo(() => ({
    showInfoWindow: true,
    ...(otherProps.routesSettings || {})
  }), [otherProps.routesSettings]);

  const classes = useStyles({ isStreetView });
  const containerRef = useRef<HTMLDivElement>(null);
  const mapStyles = useMapStyles(styles);
  const isActiveGoogleMap = useIsActiveGoogleMap();

  useOnMapClick();
  useInitMap(containerRef, onInitializedMap);
  useMarkers({
    stations,
    extraStations
  }, {
    onMarkerClick
  });
  useFitBounds(fitBounds, fitBoundsPadding);
  useFocusPoint(focusPoint);
  useGlobalStyles();

  const {
    addListenerStreetViewChange,
    map
  } = useMapContext();
  const { markers } = useMarkersContext();
  
  // routes
  useInitRoutes(routesSettings, routesPoints, onChangeRoutes, routeModifiers);
  useShowRoutes(routes);
  useChangeOptionsForActiveRoute(routes, activeRoute);

  useNavigationHistoryRoutes(
    navigationHistoryPath,
    navigationHistoryRoutes,
    onChangeNavigationHistoryRoutes
  );

  useEffect(() => {
    if (isActiveGoogleMap) {
      addListenerStreetViewChange(setIsStreetView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isActiveGoogleMap]);

  return (
    <>
      <Controls
        showZoomControl={showZoomControl}
      />
      <Tooltip
        containerRef={containerRef}
      />
      <Footer
        showMapTypeControl={showMapTypeControl}
        footerElements={footerElements}
        showStationsDisplayButton={showStationsDisplayButton}
      />
      <div
        id="map"
        ref={containerRef}
        style={mapStyles}
        className={classNames({
          [classes.map]: true,
          [classes.gmStyle]: showZoomControl
        })}
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      />
      {renderElements({ map, markers })}
    </>
  );
};

export default withContext(Map);
