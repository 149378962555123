import { useState, MouseEvent, useCallback } from 'react';

export type TOnShowMenu = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
export type TOnCloseMenu = () => void;
export type TAnchorEl = HTMLButtonElement | HTMLDivElement | null;
export type TShowMenu = boolean;
export interface IResult {
  showMenu: TShowMenu;
  anchorEl: TAnchorEl;
  onShowMenu: TOnShowMenu;
  onCloseMenu: TOnCloseMenu;
}
type TUseShowMenu = () => IResult;

const useShowMenu: TUseShowMenu = () => {
  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);

  const onShowMenu: TOnShowMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const showMenu = Boolean(anchorEl);

  return { showMenu, anchorEl, onShowMenu, onCloseMenu };
};

export default useShowMenu;
