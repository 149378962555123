import { makeStyles } from 'e100-react-components';

const useGlobalStyles = makeStyles(() => ({
  '@global': {
    '#map': {
      cursor: 'grab'
    }
  }
}));

export default useGlobalStyles;
