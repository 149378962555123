import useBindActionCreators from 'common/hooks/useBindActionCreators';

import operations from '../operations';

export const useOperations = () => {
  const bindedActions = useBindActionCreators(operations);
  return bindedActions;
};

export default useOperations;
