import { BBox } from "@turf/helpers";

const MIN_LAT_LNG_SPACING = 10;

export const getValidFitBounds = (
  fitBounds: BBox,
  spacing: number = MIN_LAT_LNG_SPACING
) => {
  const result = {
    sw: {
      lat: fitBounds[1],
      lng: fitBounds[0]
    },
    ne: {
      lat: fitBounds[3],
      lng: fitBounds[2]
    }
  };

  const latSpacing = Math.abs(result.ne.lat - result.sw.lat);
  const lngSpacing = Math.abs(result.ne.lng - result.sw.lng);

  if (latSpacing < spacing) {
    result.sw.lat -= (spacing - latSpacing) / 2;
    result.ne.lat += (spacing - latSpacing) / 2;
  }

  if (lngSpacing < spacing) {
    result.sw.lng -= (spacing - latSpacing) / 2;
    result.ne.lng += (spacing - latSpacing) / 2;
  }

  return result;
};
