import { PayloadAction } from "@reduxjs/toolkit";

import { State } from "../types/state";
import { Settings } from '../types/settings';

const setSettings = (state: State, action: PayloadAction<Settings['data']>) => {
  state.settings.data = action.payload;
};

export default setSettings;
