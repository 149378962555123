import React from 'react';

import { Grid } from 'e100-react-components';
import { MapInfoWindow } from 'entities/map';
import AutoNumber from './AutoNumber';
import { ObuCardsButton } from './ObuCardsButton';
import ObuNumber from './ObuNumber';
import Coordinates from './Coordinates';
import UpdateTime from './UpdateTime';

import { Map } from 'common/utils/Map/types/map';
import { Marker } from 'common/utils/MapMarker/types';

import { useCarOperations, useSelectedCarEBoxLocation } from 'entities/cars';

export const CarEBoxLocationsInfoWindow = ({
  map,
  markers
}: CarEBoxLocationsInfoWindowProps) => {
  const selectedCarEBoxLocation = useSelectedCarEBoxLocation();
  const { resetSelectedCarEBoxLocation } = useCarOperations();

  if (!selectedCarEBoxLocation) {
    return null;
  }

  return (
    <MapInfoWindow
      coordinates={[selectedCarEBoxLocation.latitude, selectedCarEBoxLocation.longitude]}
      map={map}
      markers={markers}
      onClose={() => {
        resetSelectedCarEBoxLocation();
      }}
    >
      <Grid container spacing={0}>
        <AutoNumber />
        <ObuCardsButton />
        <ObuNumber />
        <Coordinates />
        <UpdateTime />
      </Grid>
    </MapInfoWindow>
  );
};

interface CarEBoxLocationsInfoWindowProps {
  map: Map | null;
  markers: Marker | null;
}
