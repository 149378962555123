import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { IStationPrice } from 'dataSources/Typicode/stations/types/stationPrice';

import { STATE, IStationPriceMap } from '../types';

const selector = createSelector(
  (state: RootState) => state[STATE].stationPrices.prices || [],
  stationPrices => stationPrices.reduce((acc: IStationPriceMap, item: IStationPrice) => {
    acc[item.service.code] = item;
    return acc;
  }, {})
);

export default selector;
