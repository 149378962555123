import { useMemo } from 'react';
import { Report } from '../api';
import { checkIsReportBeingGenerated } from '../utils';

export const useIsReportBeingGenerated = (state?: Report['state']) => {
  const isReportBeingGenerated = useMemo(() => (
    checkIsReportBeingGenerated(state)
  ), [state]);

  return isReportBeingGenerated;
};
