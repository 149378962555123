export const NAVIGATION_STATE = 'NAVIGATION';

export const LOCATION_HISTORY_PATH_TYPES = {
  tracked: 'Tracked',
  untracked: 'Untracked',
  parking: 'Parking'
} as const;

export const NAVIGATION_HISTORY_LOCATION_TYPES = {
  startLocation: 'startLocation',
  endLocation: 'endLocation'
} as const;