import defaultTheme from 'e100-react-components/lib/theme/defaultTheme';
import useMediaQuery from 'e100-react-components/lib/hooks/useMediaQuery';

type TTheme = typeof defaultTheme;

interface IResult {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isFullDesktop: boolean;
}
type TUseHookMediaQuery = () => IResult;

const useHookMediaQuery: TUseHookMediaQuery = () => {
  const isMobile = useMediaQuery((theme: TTheme) => (theme.breakpoints.between('xs', 'sm')));
  const isTablet = useMediaQuery((theme: TTheme) => theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery((theme: TTheme) => theme.breakpoints.only('lg'));
  const isFullDesktop = useMediaQuery((theme: TTheme) => theme.breakpoints.up('xl'));

  return { isMobile, isTablet, isDesktop, isFullDesktop };
};

export default useHookMediaQuery;
