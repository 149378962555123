import { IntlFormatters } from '@formatjs/intl';
import { Time } from 'common/utils/Route/types/time';

type GetRouteTime = (sections: any, formatMessage: IntlFormatters['formatMessage']) => Time;

export const getRouteTime: GetRouteTime = (sections, formatMessage) => {
  const timestamp = sections.reduce((acc: number, { travelSummary: { baseDuration } }: any) => (baseDuration ? acc + baseDuration : acc), 0);
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) % 60;
  return `${hours} ${formatMessage({ id: 'hours' })} ${minutes} ${formatMessage({ id: 'min' })}`;
};
