import makeStyles from 'e100-react-components/lib/styles/makeStyles';

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  contentRoot: {
    padding: 0,
    [breakpoints.up('md')]: {
      maxHeight: spacing(39)
    }
  }
}));
