import React from 'react';

import { ModalDialog } from 'e100-react-components';
import { CardList } from 'entities/cards';

import { useMediaQuery } from 'common/hooks';
import { useIntl } from 'react-intl';
import {
  useGetObuCards,
  useSelectedCarEBoxLocation
} from 'entities/cars';
import {
  useActiveModals,
  useApplicationOperations,
  MODALS
} from 'entities/application';
import { useStyles } from './styles';

import { fakeClientCards } from '../../fakeData';

export const CarEBoxLocationsClientCardsModal = () => {
  const classes = useStyles();
  const { isMobile } = useMediaQuery();
  const { formatMessage } = useIntl();
  const { showCarEBoxLocationsClientCards } = useActiveModals();
  const { deleteActiveModal } = useApplicationOperations();
  const { autoNumber, obuNumber } = useSelectedCarEBoxLocation() || {};
  const { isObuCardsLoading, obuCards, getObuCards } = useGetObuCards();

  return (
    <ModalDialog
      open={showCarEBoxLocationsClientCards}
      maxWidth={'sm'}
      title={formatMessage({ id: 'cardsLinkedToOBU' })}
      onClose={() => {
        deleteActiveModal(MODALS.carEBoxLocationsClientCards);
      }}
      onEnter={() => {
        getObuCards(autoNumber, obuNumber);
      }}
      showCloseButton
      fullScreen={isMobile}
      classes={{
        content: {
          root: classes.contentRoot
        }
      }}
    >
      <CardList
        items={isObuCardsLoading ? fakeClientCards : obuCards}
        isLoading={isObuCardsLoading}
      />
    </ModalDialog>
  );
};
