import { PayloadAction } from "@reduxjs/toolkit";

import { IState } from "../types/state";
import { TUserType } from '../types';

const setUserType = (state: IState, action: PayloadAction<TUserType>) => {
    state.userType = action.payload;
}

export default setUserType;
