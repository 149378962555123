import dataSources from 'dataSources';
import Promise from 'core-js-pure/actual/promise';
import {
  FilterParams as InitStationsFilterParams,
  Result as InitStationsResult
} from './types/initStations';

export const initStations = async (
  urlFilter?: InitStationsFilterParams
) => {
  const [
    allStations,
    stationCodes
  ] = await Promise.allSettled([
    dataSources.stations.getStations(),
    urlFilter ? dataSources.stations.getStationCodes(urlFilter) : undefined
  ]);

  return [allStations, stationCodes] as InitStationsResult;
};
