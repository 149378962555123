import { useEffect, useCallback, RefObject } from 'react';
import { useMapName } from 'entities/application';
import { useApplicationOperations } from 'entities/application';
import { useLoadMapLibraries } from './useLoadMapLibraries';
import { useMapContext } from '../context/map';
import { useMapOperations } from 'entities/map';

import { Props as MapProps } from '../index';

type TUseInitMap = (
  containerRef: RefObject<HTMLDivElement>,
  onInitializedMap: MapProps['onInitializedMap']
) => void;


export const useInitMap: TUseInitMap = (containerRef, onInitializedMap) => {
  const { initMap } = useMapContext();
  const mapName = useMapName();
  const loadMapLibraries = useLoadMapLibraries();
  const { setErrorNotice } = useApplicationOperations();
  const { setInitializedMap } = useMapOperations();

  const initMapHandler = useCallback(async () => {
    try {
      await loadMapLibraries();
      initMap(mapName, containerRef);
      setInitializedMap(true);
      if (onInitializedMap) {
        onInitializedMap();
      }
    } catch (e) {
      console.log('e: ', e);
      setErrorNotice({ title: 'Error loading the map' });
    }
  }, [
    loadMapLibraries,
    initMap,
    containerRef,
    setErrorNotice,
    onInitializedMap,
    setInitializedMap,
    mapName
  ]);

  useEffect(() => {
    initMapHandler();
    // eslint-disable-next-line
  }, []);
};
