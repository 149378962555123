import { PayloadAction } from "@reduxjs/toolkit";

import { CarsState } from '../../types';

export const setSelectedEBoxLocation = (
  state: CarsState,
  action: PayloadAction<CarsState['selectedEBoxLocation']>
) => {
  state.selectedEBoxLocation = action.payload;
}
