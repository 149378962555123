import { useMemo } from 'react';

import { URL_PARAMS } from '../constants';

import getUrlParam from 'common/utils/url/getURLParam';

export const useURLParamBoundCountries = () => {
  const boundCountries = getUrlParam(URL_PARAMS.boundCountries);
  return useMemo(() => boundCountries ? boundCountries.split(',') : null, [boundCountries]);
};
