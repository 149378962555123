import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const ActiveTune = (props: IProps) => (
  <SvgIcon {...props}>
    <polygon id="XMLID_93_" points="7.2,10 7.2,12 1.2,12 1.2,10 3.2,10 3.2,6 5.2,6 5.2,10 "/>
    <rect id="XMLID_92_" x="3.2" y="14" width="2" height="10"/>
    <rect id="XMLID_91_" x="15.2" y="18" width="2" height="6"/>
    <polygon id="XMLID_90_" points="19.2,14 17.2,14 17.2,6 15.2,6 15.2,14 13.2,14 13.2,16 19.2,16 "/>
    <polygon id="XMLID_89_" points="9.2,20 7.2,20 7.2,18 13.2,18 13.2,20 11.2,20 11.2,24 9.2,24 "/>
    <rect id="XMLID_88_" x="9.2" y="6" width="2" height="10"/>
    <path id="XMLID_24_" fill="#4FBC46" d="M17.2,12.4c-2.8,0-5-2.2-5-5c0-2.8,2.2-5,5-5c2.8,0,5,2.2,5,5C22.2,10.1,20,12.4,17.2,12.4z"/>
    <path id="XMLID_21_" fill="#FFFFFF" d="M17.2,3.4c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S15,3.4,17.2,3.4 M17.2,1.4c-3.3,0-6,2.7-6,6
      s2.7,6,6,6s6-2.7,6-6S20.5,1.4,17.2,1.4L17.2,1.4z"/>
  </SvgIcon>
);

export default ActiveTune;
