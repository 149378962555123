import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const Car = (props: IProps) => (
  <SvgIcon {...props}>
    <path d="M19.8,4.1C19.6,3.5,18.9,3,18.2,3H5.8C5.1,3,4.5,3.5,4.2,4.1l-2.3,6.7v9C1.9,20.5,2.4,21,3,21h1.1
      c0.6,0,1.1-0.5,1.1-1.1v-1.1h13.5v1.1c0,0.6,0.5,1.1,1.1,1.1H21c0.6,0,1.1-0.5,1.1-1.1v-9L19.8,4.1z M5.8,15.4
      c-0.9,0-1.7-0.8-1.7-1.7S4.9,12,5.8,12s1.7,0.8,1.7,1.7S6.8,15.4,5.8,15.4z M18.2,15.4c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7
      s1.7,0.8,1.7,1.7S19.1,15.4,18.2,15.4z M4.1,9.8l1.7-5.1h12.4l1.7,5.1H4.1z"/>
  </SvgIcon>
);

export default Car;
