import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { RootState } from 'store';

import {
  APPLICATION_STATE,
  MAP_NAMES,
  MODALS,
} from '../../constants';

import { ActiveModal } from '../../types';

export const useIsApplicationLoading = () => {
  const isApplicationLoading = useSelector((state: RootState) => state[APPLICATION_STATE].isLoading);
  return isApplicationLoading;
};

export const useNotice = () => {
  const notice = useSelector((state: RootState) => state[APPLICATION_STATE].notice);
  return notice;
}

export const useShowOrderCardsModal = () => {
  const showOrderCardsModal = useSelector((state: RootState) => state[APPLICATION_STATE].showOrderCardsModal);
  return showOrderCardsModal;
};

export const useI18n = () => {
  const i18n = useSelector((state: RootState) => state[APPLICATION_STATE].i18n);
  return i18n;
};

export const useLocale = () => {
  const { locale } = useI18n();

  return locale ;
}

export const useActiveModals = () => {
  const activeModals = useSelector((state: RootState) => state[APPLICATION_STATE].activeModals);

  const activeModalsMap = useMemo(() => Object.values(MODALS).reduce((acc, activeModal) => ({
    ...acc,
    [`show${activeModal.charAt(0).toUpperCase() + activeModal.slice(1)}`]: !!activeModals.find(item => item === activeModal)
  }), {} as { [key in `show${Capitalize<ActiveModal>}`]: boolean; }), [
    activeModals
  ]);

  return activeModalsMap;
};

export const useMapName = () => {
  const { name } = useSelector((state: RootState) => state[APPLICATION_STATE].map);
  return name;
};

export const useIsActiveGoogleMap = () => {
  const mapName = useMapName();

  return mapName === MAP_NAMES.google;
};

export const useIsActiveHereMap = () => {
  const mapName = useMapName();

  return mapName === MAP_NAMES.here;
};

