import { useCallback } from 'react';
import { TOnChangeValue } from 'common/hooks/useMultipleSelectState';
import { IPropTypes } from 'e100-react-components/lib/components/MultiSelect/types';

type TUseChange = (onChange: TOnChangeValue) => Required<IPropTypes>['onChange'];

const useChange: TUseChange = (onChange) => {
  const handlerChange: Required<IPropTypes>['onChange'] = useCallback(({ target }) => {
    onChange(null, target?.value);
  }, [onChange]);

  return handlerChange;
};

export default useChange;