import React from 'react';

import { CardNumber } from 'entities/navigation';
import CopyingTextToClipboard from 'components/CopyingTextToClipboard';

import Icon from 'components/Icon';
import LoadingContent from 'components/LoadingContent';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from 'e100-react-components';

import { useStyles } from './styles';

export const CardList = ({
  items = [],
  isLoading = false
}: CardListProps) => {
  const classes = useStyles();

  return (
    <List>
      {
        items.map(item => (
          <ListItem>
            <ListItemIcon>
              <Icon kind="creditCard" />
            </ListItemIcon>
            <ListItemText
              primary={
                <span className={classes.container}>
                  <LoadingContent isLoading={isLoading}>
                    <CopyingTextToClipboard>
                      {item}
                    </CopyingTextToClipboard>
                  </LoadingContent>
                </span>
              }
            />
          </ListItem>
        ))
      }
    </List>
  );
};

export interface CardListProps {
  items?: CardNumber[];
  isLoading?: boolean;
}