import { AppThunk } from 'store';
import { batch } from 'react-redux';
import dataSources from 'dataSources';
import{ StatusLoading } from 'common/constants';
import { getResponseError } from 'common/utils/index';
import { IOrderFirstStepOptions } from 'dataSources/Typicode/cards/types/orderFirstStep';

import slice from '../reducer/slice';
import { applicationActions } from 'entities/application';

const { setIsLoadingFirstStep, setFirstStep } = slice.actions;
const { setErrorNotice, setSuccessNotice } = applicationActions;

type TOperation = (data: IOrderFirstStepOptions) => AppThunk;

const operation: TOperation = (data) => async (dispatch: any) => {
  try {
    dispatch(setIsLoadingFirstStep(StatusLoading.Load));

    const result = await dataSources.cards.orderFirstStep(data);

    batch(() => {
      if (data.nip) dispatch(setSuccessNotice({ translateId: 'orderCardsModal.formSentSuccess' }));
      dispatch(setFirstStep(result));
      dispatch(setIsLoadingFirstStep(StatusLoading.Done));
    });
  } catch (e) {
    const error = await getResponseError(e);
    batch(() => {
      dispatch(setErrorNotice(error));
      dispatch(setIsLoadingFirstStep(StatusLoading.Error));
    });
  }
};

export default operation;
