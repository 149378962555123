export const STATION_TYPES = {
  petrolStation: 'petrolStation',
  powerMaxPetrolStation: 'powerMaxPetrolStation',
  discountPlusPetrolStation: 'discountPlusPetrolStation',
  economyPetrolStation: 'economyPetrolStation',
  roadPaymentPoint: 'roadPaymentPoint',
  parking: 'parking',
  autoGoodsStore: 'autoGoodsStore',
  carService: 'carService',
  cafe: 'cafe',
  convoy: 'convoy',
  ferry: 'ferry',
  railwayPlatform: 'railwayPlatform',
  carWash: 'carWash',
  truckWash: 'truckWash'
} as const;

