import { Time } from '../types/time';
import { Distance } from '../types/distance';

interface Props {
  distance?: Distance;
  time?: Time;
}

export const getInfoWindow = ({ distance, time }: Props): string => {
  return `
    <div style="text-align: center;">
      <div style="font-size: 13px; line-height: 1.5;"><b>${distance}</b></div>
      <div style="font-size: 11px; color: #637282;">${time}</div>
    </div>
  `;
};
