import { useState, useCallback, ChangeEvent } from 'react';

import { setErrors } from './useErrors';
interface IForm {
  [key: string]: any;
};

const isNumber = (value: string | number) => !/\D/.test(value.toString());
interface Event {
  target: {
    value?: string;
    name?: string;
  }
}

export type onChangeField = (event: Event) => void;

const useForm = <T extends IForm>(defaultForm: T = {} as T, setErrors?: setErrors<keyof T>) => {
  const [form, onChangeForm] = useState<T>(() => defaultForm);

  const onChangeField = useCallback((event: Event) => {
    const { target: { value = '', name = '' } } = event;
    onChangeForm(formData => ({ ...formData, [name]: value }));
    // @ts-ignore
    if (setErrors) setErrors(event);
  }, [onChangeForm, setErrors]);

  const onChangeBoolField = useCallback((event: ChangeEvent<Element>) => {
    const { target: { value = '', name = '' } } = event as Event;
    onChangeForm(formData => ({ ...formData, [name]: value === "true" ? true : false }));
    // @ts-ignore
    if (setErrors) setErrors(event);
  }, [onChangeForm, setErrors]);

  const onChangeNumberField = useCallback((event: Event) => {
    const { target: { value = '', name = '' } } = event;
    onChangeForm(formData => ({ ...formData, [name]: isNumber(value) ? value : formData[name] }));
    // @ts-ignore
    if (setErrors) setErrors(event);
  }, [onChangeForm, setErrors]);

  return { form, onChangeForm, onChangeField, onChangeNumberField, onChangeBoolField };
};

export default useForm;
