import React from 'react';

import { IconButton } from 'e100-react-components';

import { IconedDateTimePickerProps } from '../ui';

import { useStyles } from './styles';

export const DatePickerClearButton = ({
  onClick,
  show,
  variant
}: DatePickerClearButtonProps) => {
  const classes = useStyles({ variant });

  if (!show) {
    return null;
  }

  return (
    <IconButton
      kind={'close'}
      onClick={onClick}
      classes={{
        button: {
          root: classes.iconButton
        },
        icon: {
          root: classes.icon
        }
      }}
    />
  );
};

export interface DatePickerClearButtonProps extends Pick<
  IconedDateTimePickerProps,
  'variant'
> {
  show?: boolean;
  onClick: () => void;
}
