import React from 'react';
import { DIALOG_CONTENT_OBJECT } from 'pages/Map/components/FilterModal/constants';

import { IProps as IModalProps } from '../index';
import useStyles from './useStyles';

const Content = ({ children }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container} id={DIALOG_CONTENT_OBJECT}>
      {children}
    </div>
  );
};

interface IProps extends Pick<IModalProps, 'children'> {}

export default Content;
