import { useEffect } from 'react';
import { sendYandexEvent } from 'common/utils/index';
import { useUser } from 'store/features/auth/selectors/user';

const useSendYandexEvents = () => {
  const { clientCode = '', userType = ''  } = useUser() || {};

  useEffect(() => {
    if (clientCode) {
      sendYandexEvent('setUserID', clientCode);
      sendYandexEvent('userParams', { userType });
    };
  }, [clientCode, userType]);
};

export default useSendYandexEvents;
