import isLoadingAllStations from './isLoadingAllStations';
import * as allStationsMap from './allStationsMap';

import * as stations from './stations';
import actionStations from './actionStations';
import hasActions from './hasActions';
import * as filter from './filter';
import actionStationsFitBounds from './actionStationsFitBounds';
import amountStations from './amountStations';
import * as allStations from './allStations';
import * as initStations from './initStations';

export * from './stations';

const selectors = {
  isLoadingAllStations,
  ...allStationsMap,
  ...allStations,

  ...filter,
  hasActions,
  actionStationsFitBounds,

  ...stations,
  amountStations,
  actionStations,
  ...initStations
};

export default selectors;
