import { useCallback } from 'react';
import { Station } from 'dataSources/Typicode/stations/types';

import { useHandlerGoogleClickCluster } from './useHandlerGoogleClickCluster';

export type OnMarkerClick = (station: Station) => void;

export const useOnStationGoogleMarkerClick = (onMarkerClick?: OnMarkerClick) => {
  const handlerGoogleClickCluster = useHandlerGoogleClickCluster();

  const onStationGoogleMarkerClick = useCallback((props: any) => {
    if (onMarkerClick && !props.object?.cluster) {
      onMarkerClick(props.object as Station);
    }

    handlerGoogleClickCluster(props);
  }, [
    onMarkerClick,
    handlerGoogleClickCluster
  ]);

  return onStationGoogleMarkerClick;
};
