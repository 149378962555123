import React, { useState } from 'react';

import { Time } from '../ui';
import {
  Grid,
  TextField
} from 'e100-react-components';

import { useStyles } from './styles';

export const Toolbar = ({
  time,
  innerTimeRef
}: ToolbarProps) => {
  const classes = useStyles();
  const [innerTime, setInnerTime] = useState(time);
  return (
    <Grid
      container
      justify="center"
      spacing={0}
      classes={{
        root: classes.container
      }}
    >
      <Grid item>
        <TextField
          value={innerTime}
          onChange={({ target: { value }}) => {
            setInnerTime(value);
            innerTimeRef.current = value;
          }}
          type="time"
          defaultValue={time}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60
          }}
        />
      </Grid>
    </Grid>
  );
};

export interface ToolbarProps {
  time: Time;
  innerTimeRef: React.MutableRefObject<string | undefined>;
}
