import { RootState } from 'store';
import { IStation } from 'dataSources/Typicode/stations/types';

import { STATE } from '../types/state';
import { STATE as STATIONS_STATE } from '../../stations/types/state';

const selector = (state: RootState): Partial<IStation> => {
  const { selectedStation } = state[STATE];
  const { allStations: { dataMap } } = state[STATIONS_STATE];
  return dataMap[selectedStation] || {};
};

export default selector;