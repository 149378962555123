import { TOperation } from 'common/types/store/operation';
import slice from '../reducer/slice';
import { STATE } from '../types';

const { setSelectedStation } = slice.actions;

const operation: TOperation = () => (dispatch: any, getState) => {
  const state = getState();
  const { selectedStation } = state[STATE];

  if (selectedStation) dispatch(setSelectedStation(''));
};

export default operation;
