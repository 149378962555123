import React from 'react';
import MuiMultiSelect from 'e100-react-components/lib/components/MultiSelect';
import { IPropTypes } from 'e100-react-components/lib/components/MultiSelect/types';
import { TOnChangeValue } from 'common/hooks/useMultipleSelectState';

import { useIntl } from 'react-intl';

import StartAdornment from './StartAdornment';
import EndAdornment from './EndAdornment';
import useChange from './hooks/useChange';
import useClasses from './hooks/useClasses';


const MultiSelect = (props: IProps) => {
  const { value, items, onChange, startAdornment, placeholder, disabled, id, renderItem, progress } = props;
  const { formatMessage } = useIntl();
  const { classes, multiSelectClasses } = useClasses();
  const handlerChange = useChange(onChange);

  return (
    <MuiMultiSelect
      fullWidth
      placeholder={placeholder}
      value={value}
      defaultValue={value}
      items={items}
      onChange={handlerChange}
      showCheckbox
      formatMessage={formatMessage}
      showApplyButton
      disabled={disabled || progress}
      startAdornment={<StartAdornment progress={progress}><>{startAdornment}</></StartAdornment>}
      endAdornment={<EndAdornment value={value} onChange={onChange} />}
      id={id}
      renderItem={renderItem}
      classes={multiSelectClasses}
      MenuProps={{
        classes: {
          paper: classes.selectMenu
        },
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: -124,
          horizontal: 0
        }
      }}
    />
  )
}

export interface IProps
  extends Pick<IPropTypes, 'value' | 'items' | 'placeholder' | 'startAdornment' | 'renderItem' | 'progress'> {
  onChange: TOnChangeValue;
  disabled?: boolean;
  id?: string;
  startAdornment?: any;
}

export default MultiSelect;
