import { useMemo } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  checkIsLoading,
  checkIsLoadingDone,
  checkIsLoadingError
} from 'common/utils/checkLoading';

import { REPORT_COLUMN_NAMES } from '../../constants';

import { ReportGuid } from '../../api';

import {
  REPORTS_STATE
} from '../../constants';

const useReportState = () => {
  const report = useSelector((state: RootState) => state[REPORTS_STATE].report);
  return report;
};

export const useReportGuid = () => {
  const reportGuid = useSelector((state: RootState) => state[REPORTS_STATE].report.guid);
  return reportGuid;
};

export const useReportDownloadStatus = () => {
  const reportDownloadStatus = useSelector((state: RootState) => state[REPORTS_STATE].report.downloadStatus);
  return reportDownloadStatus;
};

export const useIsReportDownloading = () => {
  const reportDownloadStatus = useReportDownloadStatus();
  return checkIsLoading(reportDownloadStatus);
};

export const useIsReportDownloadDone = () => {
  const reportDownloadStatus = useReportDownloadStatus();
  return checkIsLoadingDone(reportDownloadStatus);
};

export const useIsReportDownloadError = () => {
  const reportDownloadStatus = useReportDownloadStatus();
  return checkIsLoadingError(reportDownloadStatus);
};

export const useReport = () => {
  const { data } = useReportState();
  return data;
};

export const useIsReportDownload = (reportGuid?: ReportGuid) => {
  const isReportDownloading = useIsReportDownloading();
  const storeReportGuid = useReportGuid();

  return (isReportDownloading && storeReportGuid === reportGuid);
};

const useReportColumnIndex = (reportColumnName: string) => {
  const report = useReport();

  const reportColumnIndex = useMemo(() => (
    report && report.ColumnNames
      ? report.ColumnNames.findIndex(item => item === reportColumnName)
      : null
  ), [
    report,
    reportColumnName
  ]);

  return reportColumnIndex;
};

export const useReportRows = () => {
  const report = useReport();

  const reportRows = useMemo(() => (
    report && report.Rows
      ? report.Rows.slice(1)
      : []
  ), [report]);

  return reportRows;
};

export const useReportStationCodes = () => {
  const reportRows = useReportRows();
  const reportStationIdColumnIndex = useReportColumnIndex(REPORT_COLUMN_NAMES.stationId);

  const reportStationCodes = useMemo(() => (
    reportStationIdColumnIndex !== null ? reportRows.reduce((acc, item) => {
      const value = item[reportStationIdColumnIndex];
      if (value) {
        acc.push(value);
      }

      return acc;
    }, [] as string[])
    : []
  ), [
    reportStationIdColumnIndex,
    reportRows
  ]);

  return reportStationCodes;
};

