import { turfLineString } from './lineString';
import turfBbox from '@turf/bbox';
import { Position } from '@turf/helpers';

export const turfBounds = (value: Position[]) => {
  const bBox = turfBbox(turfLineString(value));

  return {
    sw: {
      lat: bBox[1],
      lng: bBox[0]
    },
    ne: {
      lat: bBox[3],
      lng: bBox[2]
    }
  };
};
