const commonLayerSettings = {
  pickable: true,
  sizeScale: 0.5,
  loadOptions: {
    image: {
      type: 'image'
    }
  }
};

export default commonLayerSettings;
