import { NavigationAutoCoordinates } from 'entities/navigation';

import { CAR_NUMBER_ICON_HEIGHT } from 'components/Map/constants/icons';

export const getIcon = (
  autoNumber: NavigationAutoCoordinates['autoNumber'],
  carNumberIconWidth: number
) => (`
  <svg xmlns="http://www.w3.org/2000/svg" width="${carNumberIconWidth}" height="${CAR_NUMBER_ICON_HEIGHT}" viewBox="0 0 ${carNumberIconWidth} ${CAR_NUMBER_ICON_HEIGHT}" fill="none">
    <rect x="0.3" y="0.3" width="${carNumberIconWidth}" height="${CAR_NUMBER_ICON_HEIGHT}" rx="25" fill="white"/>
    <rect x="0.3" y="0.3" width="${carNumberIconWidth}" height="${CAR_NUMBER_ICON_HEIGHT}" rx="25" stroke="#E1E5E8" stroke-width="0.6"/>
    <text xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="text-align: left;white-space: pre" font-family="Helvetica" font-size="28" font-weight="500" letter-spacing="0.01em" fill="#30343B">
      <tspan x="23" y="34">${autoNumber}</tspan>
    </text>
  </svg>
`);