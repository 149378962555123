import { RootState } from 'store';

import { STATE } from '../types/state';
import { ISecondStep } from '../types/secondStep';

const selector = (state: RootState): ISecondStep => {
  const { orderSecondStep: { data } } = state[STATE];
  return data;
};

export default selector;
