import React from 'react';

import { useStyles } from './styles';

import { PathProps } from '../Path';

export const LocationIcon = ({
  type
}: LocationIconProps) => {
  const classes = useStyles({ type });

  return (
    <span className={classes.container}>
      <span className={classes.icon}  />
    </span>
  )
};

export interface LocationIconProps extends Pick<
  PathProps,
  'type'
> {}
