import { GoogleMapInterface } from "common/utils/Map";
import { WaypointMarker } from 'common/utils/waypointMarker/types/waypointMarker';
import { waypointMarkerFactory } from 'common/utils/waypointMarker';
import getGUID from 'common/utils/getGUID';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { point as createTurfPoint, LineString, lineString, Position } from '@turf/helpers';
import { DEFAULT_SERVICE_POINT_DISPLAY_RADIUS } from 'common/constants/servicePointDisplayRadius';

import {
  Params,
  Route,
  OverviewPolyline
} from '../types';
import { IGeoCoords } from 'dataSources/Typicode/stations/types/station';
import { Params as SetOptionsParams } from '../types/setOptions';

import InfoWindow from './InfoWindow';
export class GoogleRoute implements Route {
  private _map: GoogleMapInterface;
  private _waypointMarkers: WaypointMarker[] = [];
  private _id: string;
  private _infoWindow?: InfoWindow;
  private _lineString?: LineString;
  private _directionResult?: Params['directionResult'];
  private _directionsRenderer?: google.maps.DirectionsRenderer;
  private _polylineOptions = {
    strokeColor: '#008DC9',
    strokeWeight: 5,
    zIndex: 0,
    clickable: false
  };
  public time: Params['time'];
  public startAddress: Params['startAddress'];
  public endAddress: Params['endAddress'];
  public distance: Params['distance'];
  public bounds: Params['bounds'];
  public nativeBounds: Params['nativeBounds'];
  public legs: Params['legs'];
  public overviewPath: Params['overviewPath'];

  constructor(map: GoogleMapInterface, params: Params) {
    const {
      time,
      startAddress,
      endAddress,
      distance,
      bounds,
      nativeBounds,
      legs,
      overviewPath,
      directionResult
    } = params;

    this._map = map;
    this._id = getGUID();
    this._directionResult = directionResult;
    this.time = time;
    this.startAddress = startAddress;
    this.endAddress = endAddress;
    this.distance = distance;
    this.bounds = bounds;
    this.nativeBounds = nativeBounds;
    this.legs = legs;
    this.overviewPath = overviewPath;
    this._createLineString(overviewPath);
    this._createDirectionRenderer();
  }

  public get id() {
    return this._id;
  }

  _createDirectionRenderer(
    map?: google.maps.Map,
    polylineOptions?: google.maps.PolylineOptions
  ) {
    if (this._directionResult) {
      this._directionsRenderer = new google.maps.DirectionsRenderer({
        map,
        markerOptions: { visible: false },
        polylineOptions: { ...this._polylineOptions, ...polylineOptions },
        preserveViewport: true,
        directions: this._directionResult
      });
    }
  }

  _createLineString(overviewPath?: Params['overviewPath']) {
    if (overviewPath) {
      // @ts-ignore
      this._lineString = lineString(overviewPath
        .reduce((acc, item) => {
          acc.push([item.lng, item.lat]);
          return acc;
      }, [] as Position[]));
    }
  }

  _addWaypointMarkers() {
    if (this.legs) {
      this._waypointMarkers = this.legs.map(({ location, name }) => waypointMarkerFactory(this._map, { location, name }));
    }
  }

  _deleteWaypointMarkers() {
    this._waypointMarkers.forEach(waypointMarker => {
      waypointMarker.hide();
    });
    this._waypointMarkers = [];
  }

  _showRoute() {
    if (this._directionsRenderer && this._map) {
      // @ts-ignore
      this._directionsRenderer.setMap(this._map.getNativeMap());
    }
  }

  _deleteRoute() {
    if (this._directionsRenderer) {
      this._directionsRenderer.setMap(null);
    }
  }

  get overviewPolyline(): OverviewPolyline {
    if (this._directionResult) {
      return this._directionResult.routes[0].overview_polyline;
    }
    return undefined;
  }

  addInfoWindow() {
    if (this.overviewPath) {
      this._infoWindow = new InfoWindow(
        this._map,
        {
          overviewPath: this.overviewPath,
          distance: this.distance,
          time: this.time
        }
      );
      this._infoWindow.add();
    }
  }


  deleteInfoWindow() {
    if (this._infoWindow) this._infoWindow.delete();
  }

  setOptions(params: SetOptionsParams = {}) {
    this._deleteRoute();

    const polylineOptions = {
      strokeColor: params.isActive ? '#4FBC46' : this._polylineOptions.strokeColor,
      zIndex: params.isActive ? 5 : 0,
    };

    this._createDirectionRenderer(this._map.getNativeMap(), polylineOptions);
  }

  show() {
    this._showRoute();
    this._addWaypointMarkers();
  }

  delete() {
    this._deleteRoute();
    this._deleteWaypointMarkers();
    this.deleteInfoWindow();
  }

  checkIsLocationOnRoute(point: IGeoCoords, tolerance: number = DEFAULT_SERVICE_POINT_DISPLAY_RADIUS): boolean {
    if (this._lineString) {
      const turfPoint = createTurfPoint([point.longitude, point.latitude]);
      const { properties: { dist } } = nearestPointOnLine(this._lineString, turfPoint, { units: 'kilometers' });
      return dist ? dist <= tolerance : true;
    }
    return false;
  }
}
