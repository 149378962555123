import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import { useSetClipboard } from '../../hooks';
import { useMediaQuery } from 'common/hooks';

import { Station } from 'dataSources/Typicode/stations';

import {
  ModalDialog,
  Grid,
  Typography,
  TextField,
  Button
} from 'e100-react-components';

const MAX_COMMENT_LENGTH = 100;
const COPY_STATION_LINK_BUTTON_ID = 'share-station-copy';

export const SharedStationLinkModal = ({
  onClose,
  stationCode = ''
}: SharedStationLinkModalProps) => {
  const classes = useStyles();
  const [ comment, setComment ] = useState('');
  const { formatMessage } = useIntl();
  const setClipboard = useSetClipboard(comment, stationCode);
  const { isMobile } = useMediaQuery();

  return (
    <ModalDialog
      open
      maxWidth={'sm'}
      title={`${formatMessage({ id: 'share' })}: ${stationCode}`}
      onClose={onClose}
      showCloseButton
      fullScreen={isMobile}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            <b>{formatMessage({ id: 'sharedStationLinkModal.text1' })}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{formatMessage({ id: 'sharedStationLinkModal.text2' })}</Typography>
        </Grid>
        <Grid item xs={12}><br /></Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            value={comment}
            label={formatMessage({ id: 'comment' })}
            helperText={`${MAX_COMMENT_LENGTH - comment.length} / ${MAX_COMMENT_LENGTH}`}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText
              }
            }}
            inputProps={{
              maxlength: MAX_COMMENT_LENGTH
            }}
            onChange={({ target: { value }}) => {
              setComment(value);
            }}
            multiline
          />
        </Grid>
        <Grid item xs={12}><br /></Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            onClick={setClipboard}
            id={COPY_STATION_LINK_BUTTON_ID}
          >
            {formatMessage({ id: 'copyMessage' })}
          </Button>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export interface SharedStationLinkModalProps {
  onClose?: () => void;
  stationCode?: Station['code'];
}