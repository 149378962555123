import React from 'react';
import SvgIcon from 'e100-react-components/lib/components/SvgIcon';
import { IProps } from 'e100-react-components/lib/components/SvgIcon/index';

const AllFuel = (props: IProps) => (
  <SvgIcon {...props}>
    <g id="XMLID_33_">
      <linearGradient id="XMLID_28_" gradientUnits="userSpaceOnUse" x1="11.9065" y1="23.3463" x2="11.9065" y2="6.0507" gradientTransform="matrix(1 0 0 -1 0 26)">
        <stop  offset="0" style={{ stopColor: "#3DA554" }}/>
        <stop  offset="1" style={{ stopColor: "#2C7B3D" }}/>
      </linearGradient>
      <path id="XMLID_5_" style={{ fill:"url('#XMLID_28_')" }} d="M4,9.1c0-0.6,0.3-1.2,0.8-1.5l6.1-4.2c0.6-0.4,1.5-0.4,2.1,0L19,7.5c0.5,0.3,0.8,0.9,0.8,1.5
        v9.1c0,1-0.8,1.8-1.8,1.8H5.8c-1,0-1.8-0.8-1.8-1.8V9.1z"/>
    </g>
    <g id="XMLID_31_">
      <linearGradient id="XMLID_29_" gradientUnits="userSpaceOnUse" x1="142.4867" y1="-61.2185" x2="154.6759" y2="-61.2185" gradientTransform="matrix(6.123234e-17 -1 -1 -6.123234e-17 -49.312 158.812)">
      <stop  offset="0" style={{ stopColor: "#EBEDF0" }}/>
      <stop  offset="1" style={{ stopColor: "#FFFFFF" }}/>
    </linearGradient>
    <path id="XMLID_4_" style={{ fill:"url('#XMLID_29_')" }} d="M6,18.8V5.2c0-0.6,0.5-1.1,1.1-1.1h9.7c0.6,0,1.1,0.5,1.1,1.1v13.6c0,0.6-0.5,1.1-1.1,1.1H7.1
      C6.5,19.9,6,19.5,6,18.8z"/>
    </g>
    <path id="XMLID_3_" style={{ fill: "#4FBC46" }} d="M11.4,11l2.4-2.4l0.5,0.5l-2.9,3l-1.5-1.5l0.5-0.5L11.4,11z"/>
    <linearGradient id="XMLID_30_" gradientUnits="userSpaceOnUse" x1="6.8907" y1="13.3454" x2="12.0433" y2="4.244230e-02" gradientTransform="matrix(1 0 0 -1 0 26)">
      <stop  offset="0" style={{ stopColor: "#4FBC46" }}/>
      <stop  offset="1" style={{ stopColor: "#3DA554" }}/>
    </linearGradient>
    <path id="XMLID_2_" style={{ fill:"url('#XMLID_30_')" }} d="M14.9,20h-9c-1,0-1.8-0.8-1.8-1.8V10c0-0.2,0-0.5,0.1-0.7L14.9,20z"/>
    <linearGradient id="XMLID_32_" gradientUnits="userSpaceOnUse" x1="14.3665" y1="16.7146" x2="14.3665" y2="6" gradientTransform="matrix(1 0 0 -1 0 26)">
      <stop  offset="0" style={{ stopColor: "#88DF81" }}/>
      <stop  offset="1" style={{ stopColor: "#4FBC46" }}/>
    </linearGradient>
    <path id="XMLID_1_" style={{ fill:"url('#XMLID_32_')" }} d="M8.9,20h9c1,0,1.8-0.8,1.8-1.8V10c0-0.2,0-0.5-0.1-0.7L8.9,20z"/>
  </SvgIcon>
);

export default AllFuel;
