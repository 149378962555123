import React, { FC, ReactNode } from 'react';
import ModalDialog, { IPropTypes as ModalDialogProps } from 'e100-react-components/lib/components/ModalDialog';
import { IClasses as ModalClasses } from 'e100-react-components/lib/components/ModalDialog/types';
import { IPropTypes as IPropTypesModalActions } from 'e100-react-components/lib/components/ModalDialog/DialogActions';

import Title from './Title';
import useModalDialogClasses from './hooks/useModalDialogClasses';
import Content from './Content';

const Modal: FC<IProps> = ({
  onClose,
  children,
  title,
  showCloseButton = true,
  labelOk,
  onOk,
  isValid = true,
  isLoading = false,
  dialogContentKey,
  fullScreen = false,
  bottomPosition = false,
  onClickExpandContent,
  showTitleArrow = false,
  classes = {},
  buttonOkId,
  buttonCloseId,
  disableButtonClose = false,
  disableEnforceFocus,
  isVisible = true,
  actionButtons
}) => {
  const modalDialogClasses = useModalDialogClasses({
    classes, fullScreen, bottomPosition
  });

  if (!isVisible) {
    return null;
  }

  return (
    <ModalDialog
      open
      title={
        title ?
        <Title
          onClickExpandContent={onClickExpandContent}
          bottomPosition={bottomPosition}
          showTitleArrow={showTitleArrow}
          classes={classes}
        >
          {title}
        </Title>
        : null
      }
      showCloseButton={showCloseButton}
      onClose={onClose}
      fullWidth
      hideBackdrop
      disableEnforceFocus={disableEnforceFocus}
      buttonCloseId={buttonCloseId}
      classes={modalDialogClasses}
      dialogContentKey={dialogContentKey}
      fullScreen={fullScreen}
      disableButtonClose={disableButtonClose}
      onOk={onOk}
      labelOk={labelOk}
      isValid={isValid}
      isLoading={isLoading}
      buttonOkId={buttonOkId}
      okButtonContainerProps={{
        breakpoints: {
          xs: 12
        }
      }}
      okButtonFullWidth
      actionButtons={actionButtons}
    >
      <Content>{children}</Content>
    </ModalDialog>
  );
};

export default Modal;
export interface Classes extends ModalClasses {
  titleArrowButton?: string;
}
export interface IProps extends Pick<ModalDialogProps, 'actionButtons'> {
  onClose: () => void,
  children: ReactNode,
  title?: ReactNode,
  showCloseButton?: boolean,
  labelOk?: string
  onOk?: () => void,
  isValid?: IPropTypesModalActions['isValid'];
  isLoading?: boolean;
  dialogContentKey?: string;
  fullScreen?: boolean;
  bottomPosition?: boolean;
  onClickExpandContent?: () => void;
  showTitleArrow?: boolean;
  classes?: Classes;
  buttonOkId?: string;
  buttonCloseId?: string;
  disableButtonClose?: boolean;
  disableEnforceFocus?: boolean;
  isVisible?: boolean;
};