import { makeStyles } from 'e100-react-components';

import { PathProps } from './Path';

import { getNavigationHistoryIconColor } from '../../../utils';

interface StylesProps extends Pick<
  PathProps,
  'type'
> {}

export const useStyles = makeStyles(({
  palette,
  spacing
}) => ({
  container: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      left: -spacing(4.1),
      top: spacing(2.75),
      width: 11,
      height: '60%',
      backgroundColor: ({ type }: StylesProps) => getNavigationHistoryIconColor(type),
      border: `5px solid ${palette.common.white}`,
      zIndex: -1
    }
  }
}));
