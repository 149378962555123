import { getMultiLineStringFromPolylines } from './getMultiLineStringFromPolylines';

export const getRouteBounds = (routes: any[]) => {
  const rect = routes.reduce((acc, directionRoute, index) => {
    const boundingBox = getMultiLineStringFromPolylines(directionRoute.sections).getBoundingBox();
    if (index === 0) {
      return boundingBox;
    }

    return acc.mergeRect(boundingBox);
  }, null);

  return ({
    TopRight: {
      latitude: rect.getTop(),
      longitude: rect.getRight()
    },
    BottomLeft: {
      latitude: rect.getBottom(),
      longitude: rect.getLeft()
    }
  });
};
