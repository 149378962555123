import { PayloadAction } from "@reduxjs/toolkit";
import { StatusLoading } from 'common/constants/statusLoading';

import { IState } from "../types";

const setUserLoadingStatus = (state: IState, action: PayloadAction<StatusLoading>) => {
  state.user.loadingStatus = action.payload;
};

export default setUserLoadingStatus;
